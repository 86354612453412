import { invert } from 'lodash'
import type { KazMon } from '../Kazmon/KazMon'
import { ApplicationVariables } from '../TelemetryRecorder'
import { getValue, ValueOrGetter } from '../Utility/getValue'
import isBrowser from '../Utility/isBrowser'

/*
    This module stores configuration properties to be used by any component

    Apps should set these configuration properties at startup (before any depndendent
    component is used).

    WARNING.  app description properties environment/site MUST be in in the server whitelist if you set them!
    https://github.docusignhq.com/pages/FrontEndShared/components/docs/recipes/logging/kazmonsdk

    NOTE: To guard against more than one componentsConfig on the page we attempt to store it's state
    on the global object (fall back to the singleton if unsuccessful).

*/

export const STANDARD_LOG_URLS = {
  PROD: 'https://telemetry.docusign.net',
  DEMO: 'https://telemetry-d.docusign.net',
  STAGE: 'https://telemetry-s.docusign.net',
  TEST: 'https://telemetry.dev.docusign.net',
}

export type AppDescription = {
  appId?: string
  appVersion?: string
  environment?: string
} & Omit<ApplicationVariables, 'Application' | 'ApplicationVersion'>

// CAUTION!  Consider other instances that might be in memory if you
// are altering the types of these properties.
interface ConfigState {
  appDescription: ValueOrGetter<AppDescription>
  applicationVariables?: ValueOrGetter<ApplicationVariables>
  simulateAnalytics: boolean
  loggingUrl?: string
  accountAPIUrl?: ValueOrGetter<string>
  accountAccessToken?: ValueOrGetter<string>
  suppressDeprecations: boolean
  kazmon?: KazMon
}

const initialState: ConfigState = {
  appDescription: {},
  simulateAnalytics: false,
  suppressDeprecations: false,
}

const STATE_WINDOW_VAR_NAME = 'DocuSignComponentsConfig'

class Config {
  private state: ConfigState
  constructor() {
    if (isBrowser()) {
      try {
        if (self[STATE_WINDOW_VAR_NAME]) {
          this.state = self[STATE_WINDOW_VAR_NAME] as unknown as ConfigState
        } else {
          this.state = initialState
          self[STATE_WINDOW_VAR_NAME] = this.state
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(
          'warning...problem getting/setting from self: docusign componentConfig may not be global if more than one config instance'
        )
        this.state = initialState
      }
    } else {
      this.state = initialState
    }
  }

  setAppDescription(appDescription: ValueOrGetter<AppDescription>) {
    this.state.appDescription = appDescription
  }

  getAppDescription(): AppDescription {
    return getValue(this.state.appDescription) || {}
  }

  setLoggingUrl(urlOrName: string) {
    this.state.loggingUrl = urlOrName
      ? STANDARD_LOG_URLS[urlOrName.toUpperCase()]
      : urlOrName
    if (!this.state.loggingUrl) {
      this.state.loggingUrl = urlOrName
    }
  }

  getLoggingUrl() {
    return this.state.loggingUrl
  }

  clearLoggingURL() {
    this.state.loggingUrl = undefined
  }

  isProdLogging() {
    return this.state.loggingUrl === STANDARD_LOG_URLS.PROD
  }

  getLoggingEnvironment() {
    if (this.getAppDescription().environment) {
      return this.getAppDescription().environment
    }
    if (this.state.loggingUrl) {
      return (
        invert(STANDARD_LOG_URLS)[this.state.loggingUrl] ||
        this.state.loggingUrl
      )
    }
    return 'UNSPECIFIED'
  }
  setAccountAPIAccessToken(tokenOrAccessor: ValueOrGetter<string>) {
    this.state.accountAccessToken = tokenOrAccessor
  }

  getAccountAPIAccessToken() {
    return getValue(this.state.accountAccessToken)
  }

  /*
        Example: https://demo.docusign.net/restapi/v2.1
    */
  setAccountAPIRootUrl(urlOrAccessor: ValueOrGetter<string>) {
    this.state.accountAPIUrl = urlOrAccessor
  }

  getAccountAPIRootUrl() {
    return getValue(this.state.accountAPIUrl)
  }

  setSimulateAnalytics(simulate: boolean) {
    this.state.simulateAnalytics = simulate
  }

  isSimulatingAnalytics() {
    return this.state.simulateAnalytics
  }

  setSuppressDeprecationWarnings(suppress: boolean) {
    this.state.suppressDeprecations = suppress
  }

  isSuppressingDeprecationWarnings() {
    return this.state.suppressDeprecations
  }

  setComponentsKazMon(kazmon: KazMon) {
    this.state.kazmon = kazmon
  }

  getComponentsKazMon() {
    return this.state.kazmon
  }
}

const componentsConfig = new Config()

export default componentsConfig
