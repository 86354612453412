import { limitShift } from '@floating-ui/react'
import type { FlipMiddleware, OffsetMiddleware, ShiftMiddleware } from '..'

export const presets = {
  flip: (
    mainAxis = true,
    crossAxis = true,
    fallbackAxisSideDirection: 'none' | 'start' | 'end' | undefined = 'none',
  ): FlipMiddleware => {
    return {
      mainAxis,
      crossAxis,
      fallbackAxisSideDirection,
    }
  },
  shift: (mainAxis = true, crossAxis = false): ShiftMiddleware => ({
    mainAxis,
    crossAxis,
    limiter: limitShift(),
  }),
  offset: (skidding = 0, distance = 0): OffsetMiddleware => ({
    mainAxis: distance,
    crossAxis: skidding,
  }),
}
