import 'whatwg-fetch'
import 'abortcontroller-polyfill'
import {
  ExtendedHttpRequest,
  HttpResponse,
  HttpMethod,
  AbortableHttpRequest,
} from '../types'
import { FetchRestHandler } from './FetchRestHandler'

/*

    For Browser only (extends the abstract FetchRestHandler using a Browser-only Fetch library).

    Fetch API
    (https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API)

    NOTE: The Promise returned from fetch() won’t reject on HTTP error status even if the response
    is an HTTP 404 or 500. Instead, it will resolve normally (with ok status set to false),
    and it will only reject on network failure or if anything prevented the request from completing.

    The HttpHandler interface which this class implements has the same approach (see the HttpHandler
    interface for details.

*/

export class BrowserFetchRestHandler extends FetchRestHandler {
  private createRequestBody(data: unknown): BodyInit | undefined {
    if (data) {
      return typeof data === 'string' || data instanceof FormData
        ? data
        : JSON.stringify(data)
    }
    return undefined
  }

  /*
    sendRequest function is specific to the environment (browser vs. node) because the libraries are different and
    some options are only relevant to browser.
  */

  protected async sendRequest(
    request: Omit<ExtendedHttpRequest, 'method'>,
    httpMethod: HttpMethod
  ): Promise<HttpResponse> {
    ;(request as ExtendedHttpRequest).method = httpMethod
    const controller = new AbortController()
    const abortSignal = controller.signal

    const options: RequestInit = {
      cache: 'no-store',
      method: httpMethod,
      mode: 'cors',
      signal: abortSignal,
    }
    if (request.headers) {
      options.headers = request.headers
    }
    ;(request as AbortableHttpRequest).abort = () => controller.abort()

    options.body = this.createRequestBody(request.data)

    const fetchResponse = await fetch(request.url, options)
    const httpResponse = this.createResponse(
      request as ExtendedHttpRequest,
      fetchResponse.headers,
      fetchResponse.status
    )

    const { responseReader } = httpResponse.request

    switch (responseReader) {
      case 'blob':
        httpResponse.data = await fetchResponse.blob()
        break
      case 'text':
        httpResponse.data = await fetchResponse.text()
        break
      case 'arrayBuffer':
        httpResponse.data = await fetchResponse.arrayBuffer()
        break
      case 'json':
        httpResponse.data = await fetchResponse.json()
        httpResponse.json = httpResponse.data
        break
      default:
        if (this.isJsonResponse(httpResponse)) {
          httpResponse.data = await fetchResponse.json()
          httpResponse.json = httpResponse.data
        } else if (this.isTextResponse(httpResponse)) {
          httpResponse.data = await fetchResponse.text()
        } else if (this.isBlobResponse(httpResponse)) {
          httpResponse.data = await fetchResponse.blob()
        }
    }

    return httpResponse
  }
}
