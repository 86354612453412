import { oliveFontTokens } from '@ds/tokens'

const detailExtraSmall = oliveFontTokens.fontDetailXS
const detailSmall = oliveFontTokens.fontDetailS

const bodyMedium = oliveFontTokens.fontBodyM
const bodyLarge = oliveFontTokens.fontBodyL
const bodyExtraLarge = oliveFontTokens.fontBodyXL

const headingXXS = oliveFontTokens.fontHeadingXXS
const headingExtraSmall = oliveFontTokens.fontHeadingXS
const headingSmall = oliveFontTokens.fontHeadingS
const headingMedium = oliveFontTokens.fontHeadingM
const headingLarge = oliveFontTokens.fontHeadingL
const headingExtraLarge = oliveFontTokens.fontHeadingXL

const displayExtraSmall = oliveFontTokens.fontDisplayXS
const displaySmall = oliveFontTokens.fontDisplayS
const displayMedium = oliveFontTokens.fontDisplayM
const displayLarge = oliveFontTokens.fontDisplayL

const sectionHeadline = oliveFontTokens.fontSectionHeadline

const tab = oliveFontTokens.fontTab
const timestamp = oliveFontTokens.fontTimestamp
const inputLabel = oliveFontTokens.fontElementLabel
const button = oliveFontTokens.fontButton

const breadcrumb = oliveFontTokens.fontBreadcrumb
const breadcrumbActive = oliveFontTokens.fontBreadcrumb

const tokens = {
  detailExtraSmall,
  detailSmall,

  bodyMedium,
  bodyLarge,
  bodyExtraLarge,

  headingXXS,
  headingExtraSmall,
  headingSmall,
  headingMedium,
  headingLarge,
  headingExtraLarge,

  displayExtraSmall,
  displaySmall,
  displayMedium,
  displayLarge,

  sectionHeadline,

  tab,
  timestamp,
  inputLabel,
  button,
  breadcrumb,
  breadcrumbActive,
} as const

export default tokens
