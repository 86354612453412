import { isTouchScreen } from '../../../styles'
import type { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      wrap: {
        backgroundColor: tokens.messageBgColorDefault,
        borderRadius: '4px',
        boxShadow: `
          0 0 0 1px ${tokens.dark.borderColorDefault},
          ${tokens.elevationMedium}
        `,
        color: tokens.fontColorInverse,
        maxWidth: '440px',
        minWidth: '144px',
        padding: '24px',
        width: 'auto',
      },

      action: {
        marginBlock: '8px 0',
        marginInline: '-16px 0',
      },

      startElement: {
        marginInlineEnd: '16px',
      },

      closeButton: {
        marginBlock: '-8px',
        marginInline: '24px -8px',

        [isTouchScreen]: {
          marginBlock: '-12px',
          marginInline: '24px -12px',
        },
      },
    },

    information: {
      icon: {
        color: tokens.iconColorInverse,
      },
    },

    warning: {
      icon: {
        color: tokens.dark.iconColorWarning,
      },
    },

    success: {
      icon: {
        color: tokens.dark.iconColorSuccess,
      },
    },

    danger: {
      icon: {
        color: tokens.dark.iconColorError,
      },
    },

    loading: {
      spinner: {
        marginTop: '4px',
      },
    },

    motionVariants: {
      toastWrap: {
        initial: {
          opacity: 0,
        },

        enter: (toastHeight: number) => ({
          y: [toastHeight, 0],
          opacity: 1,
          transition: {
            duration: 0.25,
          },
        }),

        exit: {
          opacity: 0,
          transition: {
            when: 'beforeChildren',
            duration: 0.25,
            ease: 'harmonize',
          },
        },
      },

      toastInner: {
        exit: (toastHeight: number) => ({
          height: [toastHeight, 0],
          padding: [24, 0],
          margin: [16, 0],
          transition: {
            duration: 0.25,
          },
        }),
      },
    },
  } as const
}
