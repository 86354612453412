import React from 'react'
import { dataProps } from '@ds/react-utils'
import { SystemIconKey } from '@ds/icons'
import PropTypes from 'prop-types'
import { IconSmall } from '../../../internal/components/IconSmall'

export const HeaderActionItemIcons = IconSmall.kinds

export interface HeaderActionItemIconProps {
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * The Icon to display.
   */
  kind: SystemIconKey
}

export function HeaderActionItemIcon(props: HeaderActionItemIconProps) {
  const { kind, ...restProps } = props

  return <IconSmall {...dataProps(restProps)} kind={kind} />
}

HeaderActionItemIcon.icons = HeaderActionItemIcons

HeaderActionItemIcon.propTypes = {
  kind: PropTypes.string.isRequired,
}

HeaderActionItemIcon.displayName = 'Header.ActionItemIcon'
