/*
    Generate a unique guid
    // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
 */

export default function generateGuid() {
  let d = new Date().getTime()
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    // use high-precision timer if available
    d += performance.now()
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

/*
    Validate a guid
 */
export function isGuid(stringToTest: string) {
  const regexGuid =
    // eslint-disable-next-line no-useless-escape
    /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi

  return regexGuid.test(stringToTest)
}
