import * as React from 'react'
import type { ButtonType } from '../../../components/Button'
import type {
  AnchorForwardRef,
  ButtonForwardRef,
  ConditionalTagRef,
  SpanForwardRef,
} from '../../../types'
import { AnchorTarget, keyboardEventKeys } from '../../../variables'

export type ConditionalTagElement =
  | HTMLAnchorElement
  | HTMLButtonElement
  | HTMLSpanElement

export interface ConditionalTagProps
  extends Omit<React.HTMLAttributes<ConditionalTagElement>, 'onClick'> {
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  children: React.ReactNode
  /**
   * A custom class name.
   * */
  className?: string
  /**
   * When passed in conjunction with an `onClick` will return a disabled button
   */
  disabled?: boolean
  /**
   * Whether to force render a `<span>` or `<button>`. Used in tandem with `onClick`.
   */
  forceElement?: 'button' | 'span'
  /**
   * A React ref to assign to the HTML node representing the ConditionalTag element.
   */
  forwardedRef?: ConditionalTagRef
  /**
   * If href is provided then it will render an anchor
   */
  href?: string
  /**
   * When passed without an "href" will cause a button to render unless `forceElement` is `span` then will render a span.
   */
  onClick?:
    | React.MouseEventHandler<ConditionalTagElement>
    | React.KeyboardEventHandler<HTMLSpanElement>
  /**
   * The rel of the anchor tag
   */
  rel?: string
  /**
   * The role attribute
   */
  role?: string
  /**
   * How the anchor should behave when clicked
   */
  target?: AnchorTarget
  /**
   * Text representing advisory information related to the element.
   */
  title?: string
  /**
   * The type of button to be rendered.
   */
  type?: ButtonType
}

export function ConditionalTag({
  children,
  className,
  disabled = false,
  forceElement,
  forwardedRef,
  href,
  onClick,
  rel,
  role,
  target,
  type = 'button',
  ...restProps
}: ConditionalTagProps) {
  const isDisabled = disabled || restProps['aria-disabled']

  if (href && !isDisabled) {
    return (
      <a
        {...restProps}
        className={className}
        href={href}
        onClick={onClick as React.MouseEventHandler<HTMLAnchorElement>}
        rel={rel}
        ref={forwardedRef as AnchorForwardRef}
        role={role}
        target={target}
      >
        {children}
      </a>
    )
  }

  if (onClick) {
    if (forceElement === 'span' && !isDisabled) {
      return (
        <span
          {...restProps}
          className={className}
          onClick={onClick as React.MouseEventHandler<HTMLSpanElement>}
          onKeyDown={(evt: React.KeyboardEvent<HTMLSpanElement>) => {
            // The button is activated by space on the keyup event, but the
            // default action for space is already triggered on keydown. It needs to be
            // prevented to stop scrolling the page before activating the button.
            if (evt.key === keyboardEventKeys.Space) {
              evt.preventDefault()
            } else if (evt.key === keyboardEventKeys.Enter) {
              evt.preventDefault()
              ;(onClick as React.KeyboardEventHandler<HTMLSpanElement>)(evt)
            }
          }}
          onKeyUp={(evt: React.KeyboardEvent<HTMLSpanElement>) => {
            if (evt.key === keyboardEventKeys.Space) {
              evt.preventDefault()
              ;(onClick as React.KeyboardEventHandler<HTMLSpanElement>)(evt)
            }
          }}
          ref={forwardedRef as SpanForwardRef}
          role="button"
          tabIndex={restProps.tabIndex || 0}
        >
          {children}
        </span>
      )
    }

    return (
      <button
        {...restProps}
        className={className}
        disabled={disabled}
        onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
        ref={forwardedRef as ButtonForwardRef}
        role={role}
        // eslint-disable-next-line react/button-has-type
        type={type}
      >
        {children}
      </button>
    )
  }

  if (forceElement === 'button' || isDisabled) {
    return (
      <button
        {...restProps}
        className={className}
        disabled={disabled}
        ref={forwardedRef as ButtonForwardRef}
        role={role}
        // eslint-disable-next-line react/button-has-type
        type={type}
      >
        {children}
      </button>
    )
  }

  return (
    <span
      {...restProps}
      className={className}
      ref={forwardedRef as SpanForwardRef}
      role={role}
    >
      {children}
    </span>
  )
}
