import { keyframes } from '@emotion/react'
import { styles } from '../../../utilities'
import type { Tokens } from '../../../theming/types'

const CIRCUMFERENCE_SMALL = '56.548667764616276'
export const CIRCUMFERENCE_MEDIUM = '119.38052083641213'
const CIRCUMFERENCE_LARGE = '238.76104167282426'

const expandCollapseSmall = keyframes`
  0% {
    stroke-dasharray: 5px, 57px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 43px, 57px;
    stroke-dashoffset: -7px;
  }
  100% {
    stroke-dasharray: 43px, 57px;
    stroke-dashoffset: -52px;
  }
`

const expandCollapseMedium = keyframes`
  0% {
    stroke-dasharray: 10px, 120px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 90px, 120px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 90px, 120px;
    stroke-dashoffset: -110px;
  }
`

const expandCollapseLarge = keyframes`
  0% {
    stroke-dasharray: 20px, 239px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 179px, 239px;
    stroke-dashoffset: -30px;
  }
  100% {
    stroke-dasharray: 179px, 239px;
    stroke-dashoffset: -219px;
  }
`

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        alignItems: 'center',
        display: 'inline-flex',
      },

      progress: {
        ...styles.visuallyHidden,
      },

      circleFill: {
        fill: 'transparent',
        transform: 'rotate(-90deg)',
        transformOrigin: '50% 50%',
      },

      circleTrack: {
        fill: 'transparent',
      },
    },

    small: {
      height: '22px',
      centerPointX: 11,
      centerPointY: 11,
      radius: 9,
      fillStrokeWidth: 3,
      trackStrokeWidth: 3,
      width: '22px',
      strokeDasharray: CIRCUMFERENCE_SMALL,
    },

    medium: {
      height: '44px',
      centerPointX: 22,
      centerPointY: 22,
      radius: 19,
      fillStrokeWidth: 6,
      trackStrokeWidth: 6,
      width: '44px',
      strokeDasharray: CIRCUMFERENCE_MEDIUM,
    },

    large: {
      height: '88px',
      centerPointX: 44,
      centerPointY: 44,
      radius: 38,
      fillStrokeWidth: 12,
      trackStrokeWidth: 12,
      width: '88px',
      strokeDasharray: CIRCUMFERENCE_LARGE,
    },

    text: {
      ...tokens.fontElementLabel,
      marginInlineStart: '16px',
    },

    hidden: styles.visuallyHidden,

    // Will be removed in FRNTEND-3500
    indeterminate: {
      small: {
        circleFill: {
          animation: `${expandCollapseSmall} 1.4s ease-in-out infinite`,
          strokeDasharray: '43px, 57px',
          strokeDashoffset: '0px',
        },
      },

      medium: {
        circleFill: {
          animation: `${expandCollapseMedium} 1.4s ease-in-out infinite`,
          strokeDasharray: '90px, 120px',
          strokeDashoffset: '0px',
        },
      },

      large: {
        circleFill: {
          animation: `${expandCollapseLarge} 1.4s ease-in-out infinite`,
          strokeDasharray: '180px, 239px',
          strokeDashoffset: '0px',
        },
      },
    },
  } as const
}
