export default () =>
  ({
    default: {
      title: {
        letterSpacing: '0.8px',
        marginTop: '16px',
        padding: '0 24px',
      },
    },
  }) as const
