import { createContext, useContext } from 'react'
import { consoleWarn } from '../../logging'

export interface HeaderContextValue {
  dark?: boolean
}

export const HeaderContext = createContext<HeaderContextValue | undefined>(
  undefined,
)

export const useHeaderContext = () => {
  const context = useContext(HeaderContext)
  if (!context) {
    consoleWarn(
      `It seems that you're using a Header sub-component outside of a Header component. While this works, you will not see dark styles applied.`,
    )
  }
  return context
}
