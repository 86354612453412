import { inkBorderTokens } from '@ds/tokens'

const tokens = {
  defaultShadowColor: inkBorderTokens.borderColorEmphasisInverse,
  // TODO: Need to update to match background group hover/active state
  defaultHoverShadowColor: inkBorderTokens.borderColorEmphasisInverse,
  defaultActiveShadowColor: inkBorderTokens.borderColorEmphasisInverse,
} as const

export default tokens
