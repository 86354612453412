import { inkFontTokens } from '@ds/tokens'

const tokens = {
  withColorIndexTextColor: inkFontTokens.fontColorDefault,

  largeFontSize: '20px',
  mediumFontSize: '16px',
  smallFontSize: '12px',
  xlargeFontSize: '20px',
  xxlargeFontSize: '20px',
} as const

export default tokens
