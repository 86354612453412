﻿export class CounterSource {
  public counterCategory: string

  public counterName: string

  public ranges?: number[]

  public displayUnit?: string

  public hasInstance?: boolean

  public hasMeasure?: boolean

  public hasErrorCount?: boolean

  public showVolume?: boolean

  public hideCountChart?: boolean

  protected constructor(counterSource: CounterSource) {
    this.counterCategory = counterSource.counterCategory
    this.counterName = counterSource.counterName
    this.ranges = counterSource.ranges
    this.displayUnit = counterSource.displayUnit
    this.hasInstance = counterSource.hasInstance
    this.hasMeasure = counterSource.hasMeasure
    this.hasErrorCount = counterSource.hasErrorCount
    this.showVolume = counterSource.showVolume
    this.hideCountChart = counterSource.hideCountChart
  }
}
