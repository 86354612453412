import { inkFontTokens } from '@ds/tokens'

const tokens = {
  defaultColor: inkFontTokens.fontColorDefault,
  subtleColor: inkFontTokens.fontColorSubtle,
  disabledColor: inkFontTokens.fontColorDisabled,
  successColor: inkFontTokens.fontColorSuccess,
  errorColor: inkFontTokens.fontColorError,
} as const

export default tokens
