import * as React from 'react'
import { Button, Icon, useIsInk } from '@ds/ui'
import styles from './styles'

/*

    A button used as trigger to an ds/ui Menu

*/

export interface FooterMenuTriggerButtonProps {
  text: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
  forwardedRef?: React.RefObject<HTMLButtonElement>
  'data-qa': string
}

export const FooterMenuTriggerButton: React.FunctionComponent<FooterMenuTriggerButtonProps> =
  (props) => {
    const isInk = useIsInk()
    return (
      <>
        {isInk ? (
          <div css={styles.footerMenuTriggerInkButtonCSS}>
            <Button
              kind="tertiary"
              menuTrigger={true}
              onClick={props.onClick}
              onKeyDown={props.onKeyDown}
              forwardedRef={props.forwardedRef}
              aria-haspopup="true"
              data-qa={props['data-qa']}
              text={props.text}
            />
          </div>
        ) : (
          <button
            type="button"
            css={styles.footerMenuTriggerButtonCSS}
            onClick={props.onClick}
            onKeyDown={props.onKeyDown}
            ref={props.forwardedRef}
            aria-haspopup="true"
            data-qa={props['data-qa']}
          >
            <div>
              {props.text}
              <Icon kind="menuTriangleDown" size={12} />
            </div>
          </button>
        )}
      </>
    )
  }
