import * as React from 'react'
import { docuSignThemeName, DocuSignThemeName } from '../../themes'

export type ThemeContextValueLegacy = {
  name: DocuSignThemeName
  fallbackTheme?: boolean
}

type FontResolver = (path: string) => string

type Icons = {
  default?: Record<string, string>
  small?: Record<string, string>
}

export type ThemeContextValue = ThemeContextValueLegacy & {
  fonts: FontResolver[]
  icons: Icons
  tokens: Record<string, unknown>
}

/**
 * A singular context for use in `@ds/ui`. This is to allow for multiple
 * versions of @ds/ui and @ds/components. This package will be consumed by
 * @ds/ui and as a result no matter what version of @ds/ui is being referenced
 * they should all point back to this 1 @ds/theme-context context reference.
 */
export const ThemeContext = React.createContext<ThemeContextValue>({
  /**
   * We need to fallback to Olive theme here to allow @ds/components to upgrade
   * to @ds/ui. We should remove this in a future release.
   */
  name: docuSignThemeName.olive,
  fonts: [],
  icons: {},
  tokens: {},
  fallbackTheme: true,
})

export function useThemeContext(): ThemeContextValue {
  const context = React.useContext(ThemeContext)
  return context
}
