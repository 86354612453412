import React from 'react'
import { Global, css } from '@emotion/react'

import styles from './styles'

/**
 * Add global styles to make browsers render elements more consistently.
 * This is required as it globally sets the CSS `box-sizing` property to `border-box`.
 *
 * If you exclude this component from your app, please make sure
 * to set `box-sizing: border-box` to ensure components render correctly.
 */
export function NormalizeCss() {
  return <Global styles={css(styles)} />
}
