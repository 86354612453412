import type { StorageCache } from './StorageCache'

export type HttpErrorTest = (status: number) => boolean

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export interface HttpRequest<T = unknown> {
  requestId?: string
  url: string
  method: HttpMethod
  data?: T
  headers?: { [key: string]: string }
  bearerToken?: string
  correlationToken?: string
  throwErrorWhen?: HttpErrorTest
  listener?: HttpEventListener
  csrfCookieName?: string
  csrfHeaderName?: string
  cacheHashQualifier?: unknown
  cacheSeconds?: number
  cacheName?: string
  cancelId?: string
  timeoutMs?: number
  responseReader?: 'text' | 'blob' | 'arrayBuffer' | 'json'
  retry?: RetryQueryHandler
  storageCache?: StorageCache
}

export type HttpRequestProperties<T = unknown> = Omit<HttpRequest<T>, 'method'>

export interface HttpResponseHeaders {
  get(name: string): string | null
  has(name: string): boolean
  forEach(
    callbackfn: (value: string, key: string) => void,
    thisArg?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  ): void
}

export const EMPTY_HTTP_HEADERS: HttpResponseHeaders = {
  get: () => null,
  has: () => false,
  forEach: () => '',
}

export interface HttpResponse<T = unknown> {
  request: HttpRequest
  status: number
  is2XX: boolean
  isOK: boolean // 2xx OR 304
  headers: HttpResponseHeaders
  contentType: string
  json?: T
  data?: T
  elapsedMilliseconds: number
}

export class HttpError {
  public status: number
  public description: string
  public request: HttpRequest
  public error?: Error
  public willRetry?: boolean
  public attempt?: number

  constructor(
    status: number,
    description: string,
    request: HttpRequest,
    error?: Error
  ) {
    this.request = request
    this.description = description
    this.status = status
    this.error = error
  }
}

export interface HttpEventListener {
  onRequest?: (request: HttpRequest, attempt?: number) => void
  onResponse?: (response: HttpResponse) => void
  onError?: (error: HttpError) => void
}

export interface ExtendedHttpRequest extends HttpRequest {
  requestId: string
  beginTime: Date
  attempt?: number
}

export interface AbortableHttpRequest extends ExtendedHttpRequest {
  abort?: () => void // Used by cancel.  Present if the handler is capable of actually aborting the request
}

export interface HttpHandler {
  get(request: Omit<ExtendedHttpRequest, 'method'>): Promise<HttpResponse>
  put(request: Omit<ExtendedHttpRequest, 'method'>): Promise<HttpResponse>
  patch(request: Omit<ExtendedHttpRequest, 'method'>): Promise<HttpResponse>
  post(request: Omit<ExtendedHttpRequest, 'method'>): Promise<HttpResponse>
  delete(request: Omit<ExtendedHttpRequest, 'method'>): Promise<HttpResponse>
}

export interface HttpCachedRequest {
  request: HttpRequest
  responsePromise: Promise<HttpResponse>
  complete: boolean
}

export const DS_CORRELATION_TOKEN_HDR_NAME = 'X-DocuSign-CorrelationToken'

/*

    Broker interface

    A "Broker" handles HTTP requests on behalf of RestClient.  It is provide
    by a third-party (e.g, another app)

*/

export type HttpBrokerServiceName =
  | 'AccountService' // ex: https://demo.docusign.net/restapi/v2.1/accounts/23424234/billing_plan`
  | 'AdminService'
  | 'ClmService'
  | 'MeService' // ex: https://account-d.docusign.com/me/v1/user/apps
  | 'SearchAdminService'
  | 'SearchService'
  | 'NotaryService'
  | 'ServiceProtection'
  | 'AdmService'

export interface HttpBroker {
  get?: (request: HttpBrokerRequest) => Promise<HttpBrokerResponse>
  put?: (request: HttpBrokerRequest) => Promise<HttpBrokerResponse>
  patch?: (request: HttpBrokerRequest) => Promise<HttpBrokerResponse>
  post?: (request: HttpBrokerRequest) => Promise<HttpBrokerResponse>
  delete?: (request: HttpBrokerRequest) => Promise<HttpBrokerResponse>
}

export interface HttpBrokerRequest {
  relativeUrl: string // this URL will be relative to the root API, e.g, accounts/12345?foo=bar
  data?: unknown // this should be an object, not a JSON string
  headers?: { [key: string]: string }
  serviceName?: HttpBrokerServiceName
}

export interface HttpBrokerResponse {
  fullUrl?: string // complete URL used by the broker to make the request (for debugging purposes)
  status: number
  data?: unknown // this should be an object, not a JSON string
  headers?: { [key: string]: string }
}

/**
 * @param response - The response (can use to check status and/or peek at request)
 * @param elapsedTimeMs - Milliseconds since the first attempt was started.
 * @param previousAttemptCount - The number of times we have tried this request.
 * @returns milliseconds delay until trying again.
 * -1 will cancel retry and resolve the request with this response
 */
export type RetryQueryHandler = (
  response: HttpResponse,
  elapsedTimeMs: number,
  previousAttemptCount: number
) => number
