import { SendPayload } from './Sender'

/**
 * Send the kazmon payload to the server (using xhr)
 * @param request request info
 * @param onComplete callback when send complete
 */
export const xhrHttpSender: SendPayload = (
  request: {
    url: string
    headers: { [key: string]: string }
    json: string
  },
  onComplete: (error?: Error) => void
) => {
  try {
    const xhr = new XMLHttpRequest()
    xhr.withCredentials = false
    xhr.open('POST', request.url)
    const headers = request.headers || {}
    for (const key in headers) {
      xhr.setRequestHeader(key, headers[key])
    }
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status <= 299) {
        onComplete()
      } else {
        onComplete(
          new Error(
            `Error attempting to send payload to kazmon server at ${request.url}. Status=${xhr.status}.`
          )
        )
      }
    }
    xhr.onerror = () => {
      onComplete(
        new Error(`Error sending data to Kazmon. Status = ${xhr.status}`)
      )
    }
    xhr.send(request.json)
  } catch (err) {
    onComplete(err)
  }
}
