import { HttpResponseHeaders } from '../types'

export class ResponseHeaders implements HttpResponseHeaders {
  private headers: { [key: string]: string }

  constructor(headers?: { [key: string]: string }) {
    this.headers = headers || {}
  }

  // xhr returns ALL reponse headers in a single string
  // with each header separated by cr/lf
  static fromXhrResponseHeaderString(xhrResponseHeaderString: string) {
    const headers = xhrResponseHeaderString
      .trim()
      .split('\r\n')
      .reduce((result, current) => {
        const [name, value] = current.split(': ')
        result[name.toLowerCase()] = value
        return result
      }, {})
    return new ResponseHeaders(headers)
  }

  get(name: string) {
    return this.headers[name.toLowerCase()]
  }

  has(name: string) {
    const value = this.get(name)
    return !!value
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forEach(callbackfn: (key: string, value: string) => void, thisArg?: any) {
    if (this.headers) {
      Object.keys(this.headers).forEach(
        (key) => callbackfn(key, this.headers[key]),
        thisArg
      )
    }
  }
}

export function responseHeadersToJson(headers: HttpResponseHeaders) {
  const json: { [key: string]: string } = {}
  headers.forEach((value, key) => {
    json[key] = value
  })
  return json
}

export function responseHeadersFromJson(json: { [key: string]: string }) {
  return new ResponseHeaders(json)
}
