import { times, zIndexes } from '../../../variables'
import colors from '../../../theming/docusign-themes/olive/colors'
import { CreateFocusCss } from '../../../styles'

export default {
  SkipNav: {
    transition: `margin ${times.SkipNav.exit}ms ease-out`,
    alignItems: 'center',
    background: colors.gray9_alpha90,
    color: colors.white,
    justifyContent: 'center',
    display: 'flex',
    height: '28px',
    outline: 'none',
    position: 'absolute',
    textAlign: 'center',
    textDecoration: 'underline',
    width: '100%',
    marginTop: '-28px',
    zIndex: zIndexes.SkipNav,

    ...CreateFocusCss({
      transition: `margin ${times.SkipNav.enter}ms ease-in`,
      marginTop: '0',
    }),
  },
} as const
