import consoleWarn from './consoleWarn'

function warnDeprecated(
  oldName: string,
  newName?: string,
  config: { story?: string; url?: string } = {},
) {
  const { story, url } = config

  const storybookUrl = story
    ? `https://github.docusignhq.com/pages/front-end-systems/ds-ui/?path=/story/${story}`
    : ''

  const link = url || storybookUrl
  const warning = newName
    ? `${oldName} has been deprecated and will be removed in a future release. Use ${newName} instead. ${link}`
    : `${oldName} has been deprecated and will be removed in a future release.`

  consoleWarn(warning.trim())
}

export default warnDeprecated
