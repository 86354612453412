import { useLayoutEffect, useRef } from 'react'

export function useLockBodyScroll(locked: boolean) {
  const originalOverflowRef = useRef<string>()

  useLayoutEffect(() => {
    if (locked) {
      if (!originalOverflowRef.current) {
        originalOverflowRef.current = getBodyOverflow() || undefined
      }
      setBodyOverflow('hidden')
    } else if (originalOverflowRef.current) {
      setBodyOverflow(originalOverflowRef.current)
    }
    return () => {
      if (originalOverflowRef.current) {
        setBodyOverflow(originalOverflowRef.current)
      }
    }
  }, [locked])
}

function setBodyOverflow(value: string) {
  document.body.style.overflow = value
}

function getBodyOverflow() {
  return window.getComputedStyle(document.body).overflow
}
