import { useEffect, useState } from 'react'
import { getDir, addDirListener, removeDirListener, Direction } from '@ds/base'

/**
 * A custom React hook that observes changes to the 'html' element on the page via
 * a 'useEffect' hook.
 *
 * A 'useState' hook stores the last observed value of the element's 'dir' attribute.
 *
 * When the 'dir' attribute changes, the state of the custom hook is updated and
 * the newly observed value of the attribute is returned.
 *
 * @returns {?string} The last observed value of the 'dir' attribute of
 * the 'html' element, or an empty string.
 */
export const useHtmlDir = (): string | null => {
  const [currentDir, setCurrentDir] = useState(getDir() || '')

  useEffect(() => {
    const dirListener = (dir: Direction = '') => {
      setCurrentDir(dir)
    }

    addDirListener(dirListener)

    return () => removeDirListener(dirListener)
  }, [])

  return currentDir
}
