import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'
import { ConditionalTag } from '../../../internal/components/ConditionalTag'
import {
  IconOrImage,
  iconWithImageKinds,
} from '../../../internal/components/IconOrImage'
import { consoleWarn, requiredPropMessage } from '../../../logging'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import type {
  ConditionalTagRef,
  EventListenerProps,
  OliveIcon,
  OliveImageIcon,
} from '../../../types'
import {
  AriaCurrentValues,
  AnchorTarget,
  anchorTargets,
  ariaCurrentValues,
} from '../../../variables'
import type { DotBadgeProps } from '../../DotBadge'
import type { IconProps } from '../../Icon'
import type { HeaderActionItemIconProps } from '../HeaderActionItemIcon'
import { useHeaderContext } from '../HeaderContext'
import styles from './styles'

export type HeaderActionTarget = AnchorTarget

export const headerActionItemIcons = iconWithImageKinds

export const headerActionItemIconPositions = [
  'beforeText',
  'afterText',
] as const

export type HeaderActionIconPosition =
  (typeof headerActionItemIconPositions)[number]

export interface HeaderActionItemProps
  extends EventListenerProps<HTMLAnchorElement | HTMLButtonElement> {
  /**
   * The "badge" element to display above the top-right of the Header.ActionItem.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   */
  badge?: React.ReactElement<DotBadgeProps>
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * The provided component will display at the end of the Header.ActionItem.
   */
  endElement?: React.ReactElement
  /**
   * A React ref to assign to the HTML node representing the Header.ActionItem element.
   */
  forwardedRef?: ConditionalTagRef
  /**
   * Hides the text while allowing assistive devices to identify the control.
   */
  hideText?: boolean
  /**
   * URL for navigating.
   */
  href?: string
  /**
   * @deprecated The Icon to display.
   */
  icon?: OliveIcon | OliveImageIcon
  /**
   * @deprecated Accepts any Icon node `iconElement={(<Icon kind="download" ... />)}`.
   */
  iconElement?:
    | React.ReactElement<IconProps>
    | React.ReactElement<HeaderActionItemIconProps>
  /**
   * @deprecated Position the supplied icon before or after the text.
   */
  iconPosition?: HeaderActionIconPosition
  /**
   * @deprecated Applies a margin-left of 12px to the Header.ActionItem,
   * to allow spacing Header elements.
   */
  marginLeft?: boolean
  /**
   * @deprecated Applies a margin-right of 12px to the Header.ActionItem,
   * to allow spacing Header elements.
   */
  marginRight?: boolean
  /** @ignore */
  menuTrigger?: boolean
  /**
   * The relationship of the linked URL of an anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * Applies a 'selected' treatment to the Header.ActionItem.
   */
  selected?: boolean
  /**
   * The value to supply to the aria-current attribute when this HeaderActionItem is the
   * "selected" item.
   *
   *  Within a set of related items:
   *
   * 1. there should only be one "selected" item (which will receive a value for `aria-current`)
   *
   * 2. all of the items should use the same token for `aria-current` when active (indicating that
   * the item is the current "page" within a set of pages, or the current "step" within a process,
   * or the current "location" within an environment, etc.).
   */
  selectedAriaCurrentToken?: AriaCurrentValues
  /**
   * The provided component will display at the start of the Header.ActionItem.
   */
  startElement?: React.ReactElement
  /**
   * The HTML link target to use when an href is given.
   */
  target?: HeaderActionTarget
  /**
   * The text to be displayed.
   */
  text: string
  /**
   * @deprecated Apply underline styles on hover. This is specifically for OliveLegacy styles
   * and should only be used on main navigation items.
   */
  underlineOnHover?: boolean
}

// TODO: Causing breaking change, reintroduce in v5
// export type HeaderActionItemProps = RequireAtLeastOne<
//   BaseHeaderActionItemProps,
//   'href' | 'onClick'
// >

export function HeaderActionItem({
  badge,
  'data-qa': dataQa,
  endElement,
  forwardedRef,
  hideText = false,
  href,
  icon,
  iconElement,
  iconPosition = 'beforeText',
  marginLeft = false,
  marginRight = false,
  onClick,
  selected = false,
  selectedAriaCurrentToken = 'true',
  startElement,
  rel,
  target,
  text,
  underlineOnHover = false,
  ...restProps
}: HeaderActionItemProps) {
  const headerContext = useHeaderContext()

  const sx = useThemeStyles(styles, {
    ...headerContext,
  })

  if (icon || iconElement) {
    consoleWarn(
      `The "icon", "iconPosition", and "iconElement" props have been deprecated in favor of "startElement" and "endElement". Instead of passing a string for an icon kind, you will need to construct it yourself using the <Icon /> component, and pass it into the "startElement" or "endElement" prop.`,
    )
  }
  if (underlineOnHover) {
    consoleWarn(
      `The "underlineOnHover" prop has been deprecated and will be removed in a future major release.`,
    )
  }
  if (marginRight) {
    consoleWarn(
      `The "marginRight" prop has been deprecated and will be removed in a future major release.`,
    )
  }
  if (marginLeft) {
    consoleWarn(
      `The "marginLeft" prop has been deprecated and will be removed in a future major release.`,
    )
  }
  if (!href && !onClick) {
    requiredPropMessage({
      component: 'Header.ActionItem',
      prop1: 'href',
      prop2: 'onClick',
    })
  }

  const isIconOnly =
    text && hideText && (icon || iconElement || startElement || endElement)

  const badgeNode = badge && (
    <span css={[sx.default.badge, isIconOnly && sx.isIconOnly.badge]}>
      {badge}
    </span>
  )

  const iconNode = (iconElement || icon) && (
    <span
      css={[
        sx.default.icon,
        iconPosition === 'beforeText' && sx.hasIconBeforeText.icon,
        iconPosition === 'afterText' && sx.hasIconAfterText.icon,
        isIconOnly && sx.isIconOnly.icon,
      ]}
      data-qa={dataQa && `${dataQa}-icon`}
    >
      {iconElement}
      {!iconElement && icon && <IconOrImage kind={icon} />}
    </span>
  )

  const startElementNode =
    (startElement && (
      <span
        css={[
          sx.default.icon,
          sx.hasIconBeforeText.icon,
          isIconOnly && sx.isIconOnly.icon,
        ]}
        data-qa={dataQa && `${dataQa}-start`}
      >
        {startElement}
      </span>
    )) ||
    (iconPosition === 'beforeText' && iconNode)

  const endElementNode =
    (endElement && (
      <span
        css={[
          sx.default.icon,
          sx.hasIconAfterText.icon,
          isIconOnly && sx.isIconOnly.icon,
        ]}
        data-qa={dataQa && `${dataQa}-end`}
      >
        {endElement}
      </span>
    )) ||
    (iconPosition === 'afterText' && iconNode)

  const textNode = (
    <span
      css={isIconOnly && sx.isIconOnly.text}
      data-qa={dataQa && `${dataQa}-text`}
    >
      {text}
    </span>
  )

  return (
    <ConditionalTag
      data-qa={dataQa}
      {...dataProps(restProps)}
      {...onProps(restProps)}
      aria-current={selected ? selectedAriaCurrentToken : undefined}
      css={[
        sx.default.wrap,
        underlineOnHover && sx.underlineOnHover.wrap,
        restProps.menuTrigger && sx.hasMenuTrigger.wrap,
        isIconOnly && sx.isIconOnly.wrap,
        marginLeft && sx.hasMarginLeft.wrap,
        marginRight && sx.hasMarginRight.wrap,
        selected && sx.selected.wrap,
      ]}
      forceElement="button"
      forwardedRef={forwardedRef}
      href={href}
      onClick={onClick}
      rel={rel}
      target={target}
    >
      {startElementNode}
      {textNode}
      {endElementNode}
      {badgeNode}
    </ConditionalTag>
  )
}

HeaderActionItem.icons = headerActionItemIcons
HeaderActionItem.iconPositions = headerActionItemIconPositions
HeaderActionItem.targets = anchorTargets

HeaderActionItem.propTypes = {
  badge: PropTypes.element,
  'data-.*': PropTypes.string,
  endElement: PropTypes.element,
  forwardedRef: CustomPropTypes.ReactRef,
  hideText: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.oneOf(headerActionItemIcons),
  iconElement: PropTypes.element,
  iconPosition: PropTypes.oneOf(headerActionItemIconPositions),
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
  'on[A-Z].*': PropTypes.func,
  onClick: PropTypes.func,
  rel: PropTypes.string,
  selected: PropTypes.bool,
  selectedAriaCurrentToken: PropTypes.oneOf(ariaCurrentValues),
  startElement: PropTypes.element,
  target: PropTypes.oneOf(anchorTargets),
  text: PropTypes.string.isRequired,
  underlineOnHover: PropTypes.bool,
}

HeaderActionItem.displayName = 'Header.ActionItem'
