/*
    These functions can be used to log errors, warnings and info to the console
*/

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

const previousWarnings: Set<any> = new Set()
const previousErrors: Set<any> = new Set()
const previousInfos: Set<any> = new Set()

/**
 * @param args console.warn args
 */
export const logWarn = (...args: any[]) => {
  console.warn(...args)
}

/**
 * Uses the FIRST argument to determine if previously logged
 *
 * @param args console.warn args
 */
export const logWarnOnce = (...args: any[]) => {
  if (!isEmpty(args) && !previousWarnings.has(args[0])) {
    logWarn(...args)
    previousWarnings.add(args[0])
  }
}

/**
 * @param args console.info args
 */
export const logInfo = (...args: any[]) => {
  console.info(...args)
}

/**
 * Uses the FIRST argument to determine if previously logged
 *
 * @param args console.info args
 */
export const logInfoOnce = (...args: any[]) => {
  if (!isEmpty(args) && !previousInfos.has(args[0])) {
    logInfo(...args)
    previousInfos.add(args[0])
  }
}

/**
 * @param args console.error args
 */
export const logError = (...args: any[]) => {
  console.error(...args)
}

/**
 * Uses the FIRST argument to determine if previously logged
 *
 * @param args console.error args
 */
export const logErrorOnce = (...args: any[]) => {
  if (!isEmpty(args) && !previousErrors.has(args[0])) {
    logError(...args)
    previousErrors.add(args[0])
  }
}

/** reset knowledge of previous warnings */
export const resetWarn = () => {
  previousWarnings.clear()
}

/** reset knowledge of previous errors */
export const resetError = () => {
  previousErrors.clear()
}

/** reset knowledge of previous infos */
export const resetInfo = () => {
  previousInfos.clear()
}

function isEmpty(list?: any[]) {
  return !(list && list.length > 0)
}
