import { isHexTriplet } from './isHexTriplet'

/**
 * Apply brightness factor to a color.
 *
 * @param {string} hexColor - A hex triplet prepended by '#', e.g. '#019AEF'.
 * @param {number} brightness - A positive factor by which to adjust the brightness.
 * @returns {string} A hex triplet representing the final color.
 *
 * @requires isHexTriplet
 */
export function applyBrightness(
  hexColor?: string,
  brightness = 0,
): string | undefined {
  if (!hexColor || !isHexTriplet(hexColor) || brightness < 0) {
    return undefined
  }

  const HEX = 16

  const red = parseInt(hexColor.substring(1, 3), HEX)
  const green = parseInt(hexColor.substring(3, 5), HEX)
  const blue = parseInt(hexColor.substring(5), HEX)

  const padHighNibble = (str: string): string =>
    `00${str}`.substring(str.length)

  const calculatedRed = padHighNibble(
    Math.min(Math.round(red * brightness), 255).toString(HEX),
  )
  const calculatedGreen = padHighNibble(
    Math.min(Math.round(green * brightness), 255).toString(HEX),
  )
  const calculatedBlue = padHighNibble(
    Math.min(Math.round(blue * brightness), 255).toString(HEX),
  )

  return `#${calculatedRed}${calculatedGreen}${calculatedBlue}`
}
