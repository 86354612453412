import { oliveFormTokens } from '@ds/tokens'

const tokens = {
  size: '18px',

  defaultHoverShadowColor: 'transparent',
  defaultActiveShadowColor: 'transparent',

  selectedFillColor: oliveFormTokens.formControlBgColorSelected,
  selectedHoverFillColor: oliveFormTokens.formControlBgColorSelected,
  selectedActiveFillColor: '#4186ec',
} as const

export default tokens
