/**
 * The set of `KeyboardEvent.key` values used by DsUi components.
 */
const keyboardEventKeys = {
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',
  Enter: 'Enter',
  End: 'End',
  Escape: 'Escape',
  Home: 'Home',
  Shift: 'Shift',
  Space: ' ',
  Tab: 'Tab',
}

export default keyboardEventKeys
