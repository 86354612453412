import { isHexTriplet } from './isHexTriplet'
import { hasLowPerceivedBrightness } from './hasLowPerceivedBrightness'

/**
 * An accessible fill color given the background color upon which it will display.
 *
 * @param {string} backgroundHexColor - A hex triplet prepended by '#', e.g. '#019AEF'.
 * @returns {string} A hex triplet for either a standard "light" or "dark" fill color.
 */
export function contrastingGrayscaleFillColor(
  backgroundHexColor?: string,
): string | undefined {
  if (!backgroundHexColor || !isHexTriplet(backgroundHexColor)) {
    return undefined
  }

  const FILL_LIGHT = '#ffffff'
  const FILL_DARK = '#000000'

  return hasLowPerceivedBrightness(backgroundHexColor) ? FILL_LIGHT : FILL_DARK
}
