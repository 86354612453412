import { locales } from './locales'
import { translationsVersion } from './translationsVersion'

export const cdnURLsPROD: Record<string, string> = {}

locales.forEach((locale) => {
  cdnURLsPROD[
    locale
  ] = `https://docucdn-a.akamaihd.net/components/translations/${translationsVersion}/fes/${locale}.json`
})

export const cdnURLsTEST: Record<string, string> = {}

locales.forEach((locale) => {
  cdnURLsTEST[
    locale
  ] = `https://docutest-a.akamaihd.net/241848/components/translations/${translationsVersion}/fes/${locale}.json`
})
