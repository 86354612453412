import * as React from 'react'
import {
  createDomMotionComponent,
  EventInfo,
  TapInfo,
  Target,
} from 'framer-motion'
import type {
  AnimationDefinition,
  TargetAndTransition,
  DSTransition,
  HTMLOrSVGProps,
  MotionComponent,
  TagName,
} from '../types'
import { getEasingTransition } from '../utils'

// Omit variants, use MotionVariant instead
type DSMotionProps<As extends TagName> = Omit<
  HTMLOrSVGProps<As>,
  'variants'
> & {
  /* Properties to start in before any animation happens. Set to false to
   * initialize with the values in animate (disabling the mount animation) */
  initial?: boolean | Target
  /* Values to animate to */
  animate?: TargetAndTransition | boolean
  /* Default transition. If no transition is defined in animate, it will use the
   * transition defined here */
  transition?: DSTransition
  /* Properties to animate to when the component receives focus */
  whileFocus?: TargetAndTransition
  /* Properties to animate to while the hover gesture is recognized */
  whileHover?: TargetAndTransition
  /* Callback function that fires when pointer starts hovering over the component */
  onHoverStart?: (event: MouseEvent, info: EventInfo) => void
  /* Callback function that fires when pointer stops hovering over the component */
  onHoverEnd?: (event: MouseEvent, info: EventInfo) => void
  /* Properties to animate to while the component is pressed */
  whileTap?: TargetAndTransition
  /* Callback when the tap gesture starts on this element */
  onTapStart?: (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: TapInfo
  ) => void
  /* Callback when the tap gesture ends on this element */
  onTapEnd?: (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: TapInfo
  ) => void
  /* Callback when the tap gesture ends outside this element */
  onTapCancel?: (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: TapInfo
  ) => void
  /* Callback when animation defined in animate begins */
  onAnimationStart?: () => void
  /* Callback when animation defined in animate ends */
  onAnimationEnd?: (definition: AnimationDefinition) => void
}

export type MotionProps<As extends TagName> = DSMotionProps<As> & {
  as?: As
}

/**
 * A wrapper component around Framer Motion that customizes easing properties
 * to those of the Ink Design System.
 *
 * @example
 * ```tsx
 * <Motion
 *   animate={{ opacity: 1 }}
 *   transition={{ ease: 'default' }}
 * />
 * ```
 */
export const Motion = React.forwardRef(
  <As extends TagName = 'div'>(
    props: MotionProps<As>,
    forwardedRef: React.Ref<HTMLElement | SVGElement>
  ) => {
    const {
      as = 'div',
      initial,
      animate,
      exit,
      transition,
      whileHover,
      onHoverStart,
      onHoverEnd,
      whileTap,
      onTapStart,
      onTapEnd,
      onTapCancel,
      onAnimationStart,
      onAnimationEnd,
      ...restProps
    } = props

    // account for standard transition or with animating values
    const transitionWithEase = getEasingTransition(transition)

    // get framer motion's DOM primitive counterpart (i.e. motion.div)
    const Component = React.useMemo(
      () => createDomMotionComponent(as) as MotionComponent,
      [as]
    )

    return (
      <Component
        {...restProps}
        ref={forwardedRef}
        initial={initial}
        animate={animate}
        exit={exit}
        transition={transitionWithEase}
        whileHover={whileHover}
        onHoverStart={onHoverStart}
        onHoverEnd={onHoverEnd}
        whileTap={whileTap}
        onTapStart={onTapStart}
        onTap={onTapEnd}
        onTapCancel={onTapCancel}
        onAnimationStart={onAnimationStart}
        onAnimationComplete={onAnimationEnd}
      />
    )
  }
) as <As extends TagName = 'div'>(
  props: MotionProps<As> & {
    ref?: React.Ref<HTMLElement | SVGElement>
  }
) => JSX.Element
