import { docuSignThemeName } from '../docuSignThemeName'
import fonts from './fonts'
import icons from './icons'
import tokens from './tokens'

export const OliveDocuSignTheme = {
  name: docuSignThemeName.olive,
  fonts,
  icons,
  tokens,
} as const
