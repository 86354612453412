import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import { dataProps, mergeRefs } from '@ds/react-utils'
import { MotionPresence } from '@ds/motion'
import { usePopoverPositionUpdate } from '../../hooks/usePopoverPositionUpdate'
import { logAlphaProp, noAnchorWarning } from '../../logging'
import { useThemeStyles } from '../../theming'
import { calculateScrollContainerHeight } from '../../utilities'
import { Popover } from '../Popover/index'
import { MenuUI } from './MenuUI'
import type { MenuProps } from './Menu'
import styles from './styles'
import { MenuContext } from './MenuContext'

export type MenuWithStateProps = MenuProps & {
  /**
   * Internal prop helper for nested menus. This checks to see if the Menu is nested inside the MenuItemWithSubMenu component.
   * @internal @ignore
   */
  isNestedMenu?: boolean
}

export function MenuWithState({
  alignment = 'start',
  anchor,
  children,
  forwardedRef,
  isNestedMenu = false,
  location = 'below',
  locationFixed = false,
  maxHeight,
  maxHeightAuto = false,
  minWidth = true,
  positionStatic = false,
  preserveWidth = false,
  visible = false,
  ...restProps
}: MenuWithStateProps) {
  const sx = useThemeStyles(styles)
  const menuRef = useRef<HTMLDivElement>(null)
  const popoverUpdate = usePopoverPositionUpdate(alignment, location)
  const menuContext = useContext(MenuContext)

  useEffect(() => {
    if (visible && !anchor && !positionStatic) {
      noAnchorWarning('Menu')
    }
  }, [anchor, positionStatic, visible])

  useLayoutEffect(() => {
    // Set the menu's dimensions
    const menuElement = menuRef.current
    if (menuElement && visible) {
      if (maxHeight) {
        menuElement.style.maxHeight = maxHeight
      }

      if (anchor) {
        if (minWidth) {
          menuElement.style.minWidth = `${anchor.offsetWidth}px`
        }

        if (maxHeightAuto) {
          logAlphaProp('Menu', 'maxHeightAuto')
          menuElement.style.maxHeight = calculateScrollContainerHeight(
            anchor,
            menuElement,
          )
        }
      }
    }
  }, [anchor, maxHeight, maxHeightAuto, minWidth, visible])

  return (
    <MotionPresence>
      {visible &&
        (!positionStatic ? (
          <div css={sx.positionHelper}>
            {/**
             * The menuWidthHelper is required to provide a containing block the Menu
             * content can populate that is the max width of the Menu. Without this
             * the containing block can be any width and the menu will be constrained.
             */}
            <Popover
              alignment={alignment}
              anchorElement={anchor}
              containerStyles={!minWidth ? sx.menuWidthHelper : undefined}
              location={location}
              locationFixed={locationFixed}
              /**
               * This is a temporary fix until Menu gets refactored. It manually updates
               * the Popover since Menu is not yet in the DOM at the time of the calculation.
               */
              onFirstUpdate={restProps?.onVisible}
              onUpdate={popoverUpdate.trackCurrentPosition}
            >
              <MenuUI
                {...dataProps(restProps)}
                alignment={popoverUpdate.alignment}
                forwardedRef={mergeRefs(forwardedRef, menuRef)}
                isNestedMenu={isNestedMenu}
                location={popoverUpdate.location}
                preserveWidth={preserveWidth}
                role={menuContext.MenuRootRole}
              >
                {children}
              </MenuUI>
            </Popover>
          </div>
        ) : (
          /* Static is default but being explicit */
          <div css={{ position: 'static' }}>
            {/* Was position: absolute, which shrunk the div */}
            <div css={{ display: 'inline-block' }}>
              <MenuUI
                {...dataProps(restProps)}
                forwardedRef={mergeRefs(forwardedRef, menuRef)}
                positionStatic={positionStatic}
                role={menuContext.MenuRootRole}
              >
                {children}
              </MenuUI>
            </div>
          </div>
        ))}
    </MotionPresence>
  )
}

MenuWithState.displayName = 'Menu.WithState'
