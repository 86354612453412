import { inkFocusTokens } from '@ds/tokens'

const outer = {
  outline: `${inkFocusTokens.focusOutsetS} solid ${inkFocusTokens.focusColor}`,
  outlineOffset: inkFocusTokens.focusOutsetS,
} as const

const inner = {
  outline: `${inkFocusTokens.focusOutsetS} solid ${inkFocusTokens.focusColor}`,
  outlineOffset: inkFocusTokens.focusInset,
} as const

const tokens = {
  outer,
  inner,
} as const

export default tokens
