import { oliveBackgroundTokens, oliveFontTokens } from '@ds/tokens'

const tokens = {
  backgroundColor: oliveBackgroundTokens.bgColorInverse,
  borderColor: oliveBackgroundTokens.bgColorInverse,
  color: oliveFontTokens.fontColorInverse,
  offset: '0px',
  tipSize: 6.1,
} as const

export default tokens
