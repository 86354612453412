export const cultureNames = [
  'ar',
  'bg_bg',
  'cs_cz',
  'da_dk',
  'de_de',
  'el_gr',
  'en_au',
  'en_gb',
  'en_us',
  'es_es',
  'es_mx',
  'et_ee',
  'fa_ir',
  'fi_fi',
  'fr_ca',
  'fr_fr',
  'he_il',
  'hi_in',
  'hr_hr',
  'hu_hu',
  'hy_am',
  'id_id',
  'it_it',
  'ja_jp',
  'ko_kr',
  'lt_lt',
  'lv_lv',
  'ms_my',
  'nb_no',
  'nl_nl',
  'pl_pl',
  'pt_br',
  'pt_pt',
  'ro_ro',
  'ru_ru',
  'sk_sk',
  'sl_si',
  'sr',
  'sv_se',
  'th_th',
  'tr_tr',
  'uk_ua',
  'vi_vn',
  'zh_cn',
  'zh_tw',
  'en_ca',
  'en_ie',
  'en_ph',
  'en_in',
  'en_za',
  'en_nz',
  'es_co',
  'es_pr',
  'fr_be',
  'nl_be',
  'es_ar',
  'es_cr',
  'es_cl',
  'es_pe',
  'ar_ae',
  'ar_qa',
  'ar_sa',
  'de_at',
  'de_ch',
  'de_lu',
  'fr_lu',
  'fr_ch',
  'it_ch',
  'zh_hk',
  'zh_sg',
  'sw_ke',
  'ur_pk',
  'yo_ng',
]
