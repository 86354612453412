import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'
import { MotionVariant } from '@ds/motion'
import type { EventListenerProps } from '../../../types'

export interface MenuCustomItemProps extends EventListenerProps<HTMLLIElement> {
  /**  The content of the Menu.CustomItem. */
  children: React.ReactNode
  /** Accepts custom data attributes. */
  'data-.*'?: string
  'data-qa'?: string
}

export function MenuCustomItem(props: MenuCustomItemProps) {
  const { children, ...restProps } = props

  const motionVariants = {
    initial: {
      opacity: 0,
    },

    open: {
      opacity: 1,
      transition: {
        ease: 'harmonize',
        duration: 0.15,
      },
    },
  }

  return (
    <MotionVariant
      {...dataProps(restProps)}
      {...onProps(restProps)}
      variants={motionVariants}
    >
      {children}
    </MotionVariant>
  )
}

MenuCustomItem.displayName = 'Menu.CustomItem'

MenuCustomItem.propTypes = {
  children: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,
}

MenuCustomItem.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
}
