import { oliveIconTokens } from '@ds/tokens'

const tokens = {
  smallSize: '16px',
  defaultSize: '16px',

  defaultColor: oliveIconTokens.iconColorDefault,
  disabledColor: oliveIconTokens.iconColorDisabled,
  emphasisColor: oliveIconTokens.iconColorEmphasis,
  errorColor: oliveIconTokens.iconColorError,
  warningColor: oliveIconTokens.iconColorWarning,
  successColor: oliveIconTokens.iconColorSuccess,

  hoverColor: '#666666',
} as const

export default tokens
