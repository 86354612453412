import React from 'react'
import PropTypes from 'prop-types'
import { CloseButtonProps } from '../../types'

import { useIsInk } from '../../theming'

import { PopoverClose } from '../../internal/components/PopoverClose'

export type ToastMessageCloseProps = CloseButtonProps

export function ToastMessageClose(props: ToastMessageCloseProps) {
  const isInk = useIsInk()

  return <PopoverClose {...props} dark={isInk} />
}

ToastMessageClose.propTypes = {
  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func.isRequired,
}

ToastMessageClose.displayName = 'ToastMessage.Close'
