import type { CobrandingTokens } from '../../../theming/types'

export default (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config
  if (!tokens.headerBackgroundColor) return {}
  return {
    default: {
      backgroundColor: tokens.headerBackgroundColor,
    },
  } as const
}
