import { inkTokens } from '@ds/tokens'
import type { InlineMessageKind } from '../../../../components/InlineMessage'

export interface InlineMessageTokensInk {
  backgroundColor: string
  borderColor?: string
  borderInlineStartColor?: string
  textColor: string
  iconColor?: string
  focusColor?: string
}

const tokens: Record<InlineMessageKind, InlineMessageTokensInk> = {
  danger: {
    backgroundColor: inkTokens.light.messageBgColorError,
    textColor: inkTokens.dark.fontColorDefault,
    iconColor: inkTokens.dark.iconColorDefault,
    focusColor: inkTokens.dark.focusColor,
  },
  information: {
    backgroundColor: inkTokens.light.messageBgColorSubtle,
    textColor: inkTokens.light.fontColorDefault,
    iconColor: inkTokens.light.iconColorDefault,
    focusColor: inkTokens.light.focusColor,
  },
  success: {
    backgroundColor: inkTokens.light.messageBgColorSuccess,
    textColor: inkTokens.dark.fontColorDefault,
    iconColor: inkTokens.dark.iconColorDefault,
    focusColor: inkTokens.dark.focusColor,
  },
  warning: {
    backgroundColor: inkTokens.light.messageBgColorWarning,
    textColor: inkTokens.light.fontColorDefault,
    iconColor: inkTokens.light.iconColorDefault,
    focusColor: inkTokens.light.focusColor,
  },
  emphasis: {
    backgroundColor: inkTokens.light.messageBgColorEmphasis,
    textColor: inkTokens.dark.fontColorDefault,
    focusColor: inkTokens.dark.focusColor,
  },
}

export default tokens
