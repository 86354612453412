import { breakpoints } from '../utilities'

export const isTouchScreen =
  '@media (hover: none) and (pointer: coarse)' as const

export const isNotHoverDevice = '@media (hover: none)' as const

// TODO: Update variable name to something more appropriate as it is more than
// a phone in landscape only
export const isPhoneInLandscape = `@media
  screen and (max-width: ${breakpoints.Sizes.ExtraSmall}px),
  screen and (max-width: ${breakpoints.Sizes.Small}px) and (orientation: landscape) and (hover: none)
` as const
