import { CreateFocusCss } from '../../../../styles'
import type { Tokens } from '../../../../theming/types'
import type { BaseMenuItemProps } from '..'
import { styles } from '../../../../utilities'

export default (config: {
  props: Partial<BaseMenuItemProps>
  tokens: Tokens
}) => {
  const { props, tokens } = config
  return {
    default: {
      content: {
        ...tokens.fontBodyM,
        alignItems: 'center',
        background: 'transparent',
        border: 0,
        borderRadius: 0,
        color: tokens.fontColorDefault,
        cursor: 'pointer',
        display: 'flex',
        minHeight: '30px',
        padding: '4px 16px 4px 16px',
        position: 'relative',
        textAlign: 'start',
        textDecoration: 'none',
        transitionProperty: 'background-color',
        transitionDuration: '0.1s',
        transitionTimingFunction: 'cubic-bezier(0.33, 0, 0.67, 1)',
        width: '100%',

        ...(!props?.disabled && {
          '&:hover': {
            background: tokens.itemBgColorHover,
            color: 'inherit',
            textDecoration: 'none',
          },
        }),

        ...(!props?.disabled && {
          '&:active': {
            background: tokens.itemBgColorActive,
            color: 'inherit',
            textDecoration: 'none',
          },
        }),

        ...CreateFocusCss(tokens.focusInner),

        '@media (hover: none)': {
          minHeight: '40px',
        },
      },

      startElement: {
        alignItems: 'center',
        color: tokens.iconColorDefault,
        display: 'flex',
        fill: 'currentColor',
        flex: '0 0 auto',
        marginInlineEnd: '12px',
      },

      endElement: {
        ...tokens.fontBodyM,
        alignItems: 'center',
        color: tokens.iconColorDefault,
        display: 'flex',
        fill: 'currentColor',
        flex: '0 0 auto',
        marginInlineStart: '12px',
      },

      text: {
        flex: '1 1 auto',
      },

      textWithoutIcon: {
        marginInlineStart: '28px',
      },

      description: {
        ...tokens.fontDetailS,
        color: tokens.fontColorSubtle,
        display: 'block',
      },

      badge: {
        position: 'relative',
        top: '-7px',
        insetInlineEnd: '-5px',
      },

      inputLabel: {
        ...CreateFocusCss(
          tokens.focusInner,
          (pseudoClass: string) => `input${pseudoClass} + &`,
        ),
      },

      disabled: {
        background: 'transparent',
        color: tokens.fontColorDisabled,
        cursor: 'not-allowed',
      },
    },

    active: {
      background: tokens.itemBgColorHover,
      color: 'inherit',
      textDecoration: 'none',
    },

    disabled: {
      endElement: {
        color: tokens.iconColorDisabled,
      },

      startElement: {
        color: tokens.iconColorDisabled,
      },

      iconWrap: {
        color: tokens.iconColorDisabled,
      },

      description: {
        background: 'transparent',
        color: tokens.fontColorDisabled,
      },

      text: {
        background: 'transparent',
        color: tokens.fontColorDisabled,
      },
    },

    selected: {
      background: tokens.itemBgColorSelected,
      fontWeight: 600,
    },

    selectedOption: {
      iconWrap: {
        display: 'flex',
        fill: tokens.iconColorDefault,
        flex: '0 0 auto',
        marginInlineEnd: '8px',
        width: tokens.icon.defaultSize,
      },
    },

    motionVariants: {
      default: {
        initial: {
          opacity: 0,
        },
        open: {
          opacity: 1,
          transition: {
            ease: 'harmonize',
            duration: 0.15,
          },
        },
      },
    },

    hidden: styles.visuallyHidden,
  } as const
}
