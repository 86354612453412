import { CreateFocusCss } from '../../../../styles'
import { styles } from '../../../../utilities'

export default () => {
  return {
    wrap: {
      alignItems: 'stretch',
      display: 'flex',
    },

    label: styles.visuallyHidden,

    button: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'block',
      padding: '0 12px',
      transition: 'background-color 0.2s ease',

      ...CreateFocusCss({
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineOffset: '-4px',
      }),
    },
  } as const
}
