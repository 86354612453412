import React from 'react'
import PropTypes from 'prop-types'
import { systemIconValues } from '@ds/icons'
import { BaseIcon } from '../../internal/components/BaseIcon'
import { consoleWarn } from '../../logging'
import { CustomPropTypes } from '../../support'
import { useThemeIcon, useThemeStyles } from '../../theming'
import oliveColors from '../../theming/docusign-themes/olive/colors'
import type { SystemIconKey, SpanForwardRef } from '../../types'
import styles from './styles'

/** these will be removed in a future release */
const DEPRECATED_COLOR_VALUES = {
  currentColor: 'currentColor',
  inherit: 'inherit',
  initial: 'initial',
  transparent: 'transparent',
  unset: 'unset',
}

const colors = { ...DEPRECATED_COLOR_VALUES, ...oliveColors }

export type IconColor = keyof typeof colors

export interface IconProps {
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /** The name of the icon to use. */
  kind: SystemIconKey
  /** The size of the Icon in 'px' units. */
  size?: number
  /**
   * A DsUi named color, the CSS color 'transparent',
   * or one of the CSS keywords 'inherit', 'initial', 'unset'.
   */
  color?: IconColor
  /** A React ref to assign to the HTML node representing the Icon element. */
  forwardedRef?: SpanForwardRef
  /** @ignore @internal Subject to change. Do not use */
  hexColor?: string
}

/**
 * System icons make up most of the UI and are responsible for graphical representations of an action or concept. These icons are monochromatic and have consistent sizing and line weight. Each icon has an assigned definition to help make identifying the correct asset for your use case easier.
 *
 * Not all Olive icons are available in Ink. For more detailed information on icons and their usage, view the [@ds/icons docs](https://github.docusignhq.com/pages/front-end-systems/ds-icons/).
 *
 * @deprecated See https://github.docusignhq.com/pages/front-end-systems/front-end-guides/blog/2023/02/17/ds-icons-v4.9-release
 */
export function Icon({
  color = 'currentColor',
  forwardedRef,
  kind,
  size,
  ...restProps
}: IconProps) {
  consoleWarn(
    `Icon component has been deprecated and will be removed in a future major release.
      Instead, use an icon React component from @ds/icons.`,
  )

  if (color !== 'currentColor' && color && color in DEPRECATED_COLOR_VALUES) {
    consoleWarn(`The prop values for color: 'currentColor', 'inherit', 'initial', 'transparent'
  and 'unset' are being deprecated, please use CSS instead. This will be built into the component in a future release.`)
  }

  if (kind.indexOf('-') > -1) {
    consoleWarn(
      'The value for the kind prop of the Icon component should be in camel case.',
    )
  }

  /* eslint-disable max-len */

  /**
   * Eventually we will remove the hexColor, color, and size props.
   * Adding the deprecation warnings now so when we have a migration
   *  strategy we can uncomment them.
   */

  // if (restProps.hexColor) {
  //   consoleWarn('The hexColor prop of the Icon component has been deprecated. It will be removed in a future release.')
  // }

  // if (restProps.color) {
  //   consoleWarn('The color prop of the Icon component has been deprecated. It will be removed in a future release.')
  // }

  // if (restProps.size) {
  //   consoleWarn('The size prop of the Icon component has been deprecated. It will be removed in a future release.')
  // }

  /* eslint-enable max-len */

  const svgString = useThemeIcon('default', kind)

  const sx = useThemeStyles(styles)

  return svgString ? (
    <BaseIcon
      {...restProps}
      forwardedRef={forwardedRef}
      size={size || sx.size}
      svgString={svgString}
      legacyFill={restProps.hexColor || colors[color]}
    />
  ) : null
}

Icon.colors = Object.keys(colors) as Array<keyof typeof colors>

/**
 * The icon kinds in both camelCase and paramCase.
 */
Icon.kinds = systemIconValues

// Keeping `keys` around for backwards compatibility. Can be removed in a future release.
Icon.keys = Icon.kinds

Icon.propTypes = {
  color: PropTypes.oneOf(Icon.colors),
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  kind: PropTypes.oneOf(Icon.kinds).isRequired,
  size: PropTypes.number,
}

Icon.displayName = 'Icon'
