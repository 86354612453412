import * as React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'
import { HeaderActionItem } from '../HeaderActionItem'
import { HeaderTitle } from '../HeaderTitle'
import styles from './styles'

export interface HeaderLeftProps {
  /**
   * Children in the left side of the Header.
   */
  children: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function HeaderLeft({ children, ...restProps }: HeaderLeftProps) {
  const sx = useThemeStyles(styles)

  const decoratedChildren = React.Children.map(children, (child, index) => {
    const previousChild = React.Children.toArray(children)[index - 1]
    if (
      React.isValidElement(child) &&
      child.type === HeaderTitle &&
      React.isValidElement(previousChild) &&
      previousChild.type === HeaderActionItem
    ) {
      return React.cloneElement(child as React.ReactElement, {
        precededByHeaderAction: true,
      })
    }

    return child
  })

  return (
    <div {...dataProps(restProps)} css={sx.default}>
      {decoratedChildren}
    </div>
  )
}

HeaderLeft.propTypes = {
  children: PropTypes.node.isRequired,
}

HeaderLeft.displayName = 'Header.Start'
