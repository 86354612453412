export default {
  currentColor: 'currentColor',
  inherit: 'inherit',
  initial: 'initial',
  transparent: 'transparent',
  unset: 'unset',

  white: '#ffffff',
  whiteTranslucent: 'rgba(255,255,255,0.4)',
  gray1: '#f9f9f9',
  gray2: '#f4f4f4',
  gray3: '#e9e9e9',
  gray4: '#d9d9d9',
  gray5: '#cccccc',
  gray6: '#a9a9a9',
  gray7: '#999999',
  gray8: '#777777',
  gray9: '#666666',
  gray10: '#555555',
  black: '#333333',

  gray9_alpha90: '#666666EE',
  black_alpha50: '#33333380',

  avatarBackground: '#e8edf7',
  avatarPlaceholderBackground: '#b8bfcc',

  badgeOrange: '#cc4c15',
  badgeTurquoise: '#3d7e8f',

  commentsDotBlueActive: 'rgba(65,134,236,0.8)',
  commentsDotBlueInActive: 'rgba(65,134,236,0.33)',
  commentJustPosted: '#e7edf8',

  DocuSignBluePale2: '#e3edf7',
  DocuSignBluePale: '#c9dcf0',
  DocuSignBlueDisabled: '#4f8dce',
  DocuSignBlueLight: '#2875c4',
  DocuSignBlue: '#005cb9',
  DocuSignBlueDark: '#004185',
  DocuSignBlueDark2: '#003368',

  funBluePale2: '#ebf5fc',
  funBluePale: '#d8edfa',
  funBlueLight: '#4186ec',
  funBlue: '#2c6ee1',
  funBlueDark: '#1e5bcb',
  funBlueDark2: '#19489a',

  funBlue_alpha27: '#2c6ee145',

  mantisGreenPale2: '#e3f2e9',
  mantisGreenPale: '#c6e5d3',
  mantisGreenLight: '#39a364',
  mantisGreen: '#008938',
  mantisGreenDark: '#007831',
  mantisGreenDark2: '#00672a',

  mantisGreen_alpha27: '#00893845',

  persianRedPale2: '#ffeee9',
  persianRedPale: '#ffe3e1',
  persianRedLight: '#fb3449',
  persianRed: '#d13239',
  persianRedDark: '#b22b31',
  persianRedDark2: '#98252b',

  persianRed_alpha27: '#d1323945',

  progressIndicatorInverted: '#85d8eb',

  sunglowYellowPale2: '#fffae5',
  sunglowYellowPale: '#ffefc0',
  sunglowYellowLight: '#ffd72e',
  sunglowYellow: '#ffce00',
  sunglowYellowDark: '#f6b610',
  sunglowYellowDark2: '#e5a305',

  sunglowYellow_alpha43: '#f6b6106e',

  turquoisePale: '#e1f4f8',
  turquoisePale2: '#edf8fb',

  blue60: '#0069ec',

  // INK
  semanticNeutral: {
    fade: {
      '60': 'rgba(25, 24, 35, 0.60)',
    },
  },
}
