import { styles } from '../../../../utilities'
import type { HeaderTitleProps } from '..'

export default (config: { props: Partial<HeaderTitleProps> }) => {
  const { props } = config
  return {
    borderInlineStart: props.precededByHeaderAction ? '1px solid' : undefined,
    display: 'block',
    lineHeight: '28px',
    marginInlineStart: props.precededByHeaderAction ? '4px' : undefined,
    paddingInlineStart: props.precededByHeaderAction ? '16px' : undefined,
    ...styles.ellipsis,
  } as const
}
