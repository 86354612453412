import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MotionPresence, MotionVariant } from '@ds/motion'
import { dataProps } from '@ds/react-utils'
import { consoleWarn } from '../../logging'
import { useIsInk, useThemeStyles } from '../../theming'
import type { EventListenerProps } from '../../types'
import { keyboardEvents, dragEvents, mouseEvents } from '../../variables'
import styles from './styles'

const DEPRECATED_Z_INDEX = 799

export const defaultIgnoreEvents = [
  ...keyboardEvents,
  ...dragEvents,
  ...mouseEvents,
]

const ignoreEvent = (event: Event | React.SyntheticEvent) => {
  event.stopPropagation?.()
  event.preventDefault?.()
}

export interface ScrimProps extends EventListenerProps<HTMLDivElement> {
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * Event types which will be ignored (not propogated, not defaulted)
   * by the scrim.
   */
  ignoreEventTypes?: (keyof HTMLElementEventMap)[]
  /**
   * Uses a light color for the Scrim (without this prop the Scrim displays using a dark color).
   * Note this prop only applies to Olive styles. If you're using Ink, there is no light option,
   * the Scrim will render 'dark', there is no need to pass this prop.
   */
  light?: boolean
  /**
   * Displays the Scrim.
   */
  visible?: boolean
  /**
   * The z-index to apply to the Scrim (note that it is displayed using CSS position: fixed).
   *
   * The z-index that was applied to the Scrim can be retrieved from the component via the property `Scrim.zIndex`.
   */
  zIndex?: number
}

export function Scrim({
  ignoreEventTypes = defaultIgnoreEvents,
  light = false,
  onClick,
  visible = false,
  zIndex = DEPRECATED_Z_INDEX,
  ...restProps
}: ScrimProps) {
  if (useIsInk() && light) {
    consoleWarn(
      `The 'light' prop does not apply when using Ink. The Scrim component will always be in 'light' mode when the 'Ink' theme is in use.`,
    )
  }

  const scrimRef = useRef<HTMLDivElement>(null)

  const sx = useThemeStyles(styles, { light })

  useEffect(() => {
    if (visible) {
      const scrimElement = scrimRef.current
      const types = ignoreEventTypes.filter((type) => type !== 'click')
      types.forEach((type) => {
        scrimElement?.addEventListener(type, ignoreEvent)
      })
      return () => {
        types.forEach((type) => {
          scrimElement?.removeEventListener(type, ignoreEvent)
        })
      }
    }
    return undefined
  }, [ignoreEventTypes, visible])

  return (
    <MotionPresence>
      {visible && (
        <MotionVariant
          {...dataProps(restProps)}
          initial={{ opacity: 0 }}
          css={[sx.scrim, { zIndex }]}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ignoreEvent(e)
            onClick?.(e)
          }}
          ref={scrimRef}
          role="none"
          key="scrim"
          animate="enter"
          exit="exit"
          variants={sx.motionVariants}
        />
      )}
    </MotionPresence>
  )
}

Object.defineProperty(Scrim, 'zIndex', {
  get: () => {
    consoleWarn(`
        WARN @ds/ui: This z-index (${DEPRECATED_Z_INDEX}) accessed by Scrim.zIndex
        is the default value applied when mounted directly by a consumer of the DsUi
        library. This default value is deprecated and the property will be removed in
        the next major release, if you require a specific z-index (or are expecting a specific
        z-index, like the now deprecated default) you should specify it using the new zIndex
        prop on the Scrim component.
      `)
    return DEPRECATED_Z_INDEX
  },
})

Scrim.propTypes = {
  'data-.*': PropTypes.string,
  ignoreEventTypes: PropTypes.arrayOf(PropTypes.string),
  light: PropTypes.bool,
  onClick: PropTypes.func,
  visible: PropTypes.bool,
  zIndex: PropTypes.number,
}

Scrim.displayName = 'Scrim'
