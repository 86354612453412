import * as React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'
import type { AnchorForwardRef, EventListenerProps } from '../../../types'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import { DocuSignLogo } from '../../DocuSignLogo'
import { useHeaderContext } from '../HeaderContext'
import styles from './styles'

export interface HeaderLogoProps extends EventListenerProps<HTMLAnchorElement> {
  /**
   * Adds an aria-label text to the custom logo image.
   */
  alt?: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * A React ref to assign to the HTML label element.
   */
  forwardedRef?: AnchorForwardRef
  /**
   * URL for navigation upon clicking the logo image.
   */
  href?: string
  /**
   * Render the 2024 Docusign logo
   */
  rebrand2024?: boolean
  /**
   * The text to be displayed next to the logo image (usually the name of the application).
   */
  text?: string
}

export function HeaderLogo({
  alt,
  forwardedRef,
  href = '/',
  rebrand2024 = false,
  text,
  ...restProps
}: HeaderLogoProps) {
  const headerContext = useHeaderContext()

  const sx = useThemeStyles(styles, {
    ...headerContext,
  })

  /**
   * Custom account branding sets the custom logo as a background image.
   * So we can check the `sx.link` object if it has the backgroundImage property
   *  to determine if custom branding is applied.
   */
  const hasCustomLogo = sx.link.backgroundImage

  const docuSignLogoNode = !hasCustomLogo && (
    <div css={sx.image}>
      <DocuSignLogo rebrand2024={rebrand2024} />
    </div>
  )

  const textNode = text && !hasCustomLogo && <span css={sx.text}>{text}</span>

  return (
    <div css={sx.wrap}>
      <a
        {...dataProps(restProps)}
        {...onProps(restProps)}
        aria-label={hasCustomLogo && alt}
        css={sx.link}
        href={href}
        onMouseEnter={restProps.onMouseEnter}
        onMouseLeave={restProps.onMouseLeave}
        ref={forwardedRef}
      >
        {docuSignLogoNode}
        {textNode}
      </a>
    </div>
  )
}

HeaderLogo.propTypes = {
  alt: PropTypes.string,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  href: PropTypes.string,
  'on[A-Z].*': PropTypes.func,
  rebrand2024: PropTypes.bool,
  text: PropTypes.string,
}

HeaderLogo.displayName = 'Header.Logo'
