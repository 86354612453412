import * as React from 'react'
import {
  createDomMotionComponent,
  PresenceContext,
  Target,
  VariantLabels,
} from 'framer-motion'
import type { Custom, DSVariants, MotionComponent, TagName } from '../types'
import { getEasingTransition, getEasingVariants } from '../utils'
import type { MotionProps } from './Motion'

type VariantProps = 'initial' | 'animate' | 'whileFocus' | 'whileHover'

export type MotionVariantProps<As extends TagName> = Omit<
  MotionProps<As>,
  VariantProps
> & {
  /* Variant label(s) to animate to */
  animate?: boolean | VariantLabels
  /* Custom data to use to resolve dynamic variants differently for each
   * animating component. */
  custom?: Custom
  /* Properties to start in before any animation happens. Set to false to
   * initialize with the values in animate (disabling the mount animation) */
  initial?: boolean | Target | VariantLabels
  /* Variant label(s) to animate to when the component receives focus. */
  whileFocus?: VariantLabels
  /* Variant label(s) to animate to while the hover gesture is recognized. */
  whileHover?: VariantLabels
  /* Variants allow you to define animation states and organize them by name.
   * They allow you to control animations throughout a component tree by
   * switching a single `animate` prop. */
  variants?: DSVariants
}

/**
 * Allows component animations to be configured using pre-defined visual states.
 * By giving a component and its children variants with matching names, whole
 * React trees can be animated by changing a single prop.
 */
export const MotionVariant = React.forwardRef(
  <As extends TagName = 'div'>(
    props: MotionVariantProps<As>,
    forwardedRef: React.Ref<HTMLElement | SVGElement>
  ) => {
    const {
      children,
      as = 'div',
      variants,
      transition,
      onAnimationEnd,
      onTapEnd,
      ...restProps
    } = props

    const presenceContext = React.useContext(PresenceContext)

    // account for multiple variants that might have various "ease" properites
    const variantsWithEase = getEasingVariants(
      variants,
      presenceContext?.custom ?? props.custom
    )

    // account for standard transition or with animating values
    const transitionWithEase = getEasingTransition(transition)

    // get framer motion's DOM primitive counterpart (i.e. motion.div)
    const Component = React.useMemo(
      () => createDomMotionComponent(as) as MotionComponent,
      [as]
    )

    return (
      <Component
        {...restProps}
        ref={forwardedRef}
        variants={variantsWithEase}
        transition={transitionWithEase}
        onAnimationComplete={onAnimationEnd}
        onTap={onTapEnd}
      >
        {children}
      </Component>
    )
  }
) as <As extends TagName = 'div'>(
  props: MotionVariantProps<As> & {
    ref?: React.Ref<HTMLElement | SVGElement>
  }
) => JSX.Element
