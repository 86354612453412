import { isFunction as _isFunction } from 'lodash'

export type Getter<T> = () => T
export type ValueOrGetter<T> = T | (() => T)

export const getValue = <T>(
  valueOrGetter: undefined | ValueOrGetter<T>
): T | undefined => {
  return _isFunction(valueOrGetter) ? valueOrGetter() : valueOrGetter
}
