import { inkIconTokens } from '@ds/tokens'

const tokens = {
  smallSize: '20px',
  defaultSize: '24px',

  defaultColor: inkIconTokens.iconColorDefault,
  disabledColor: inkIconTokens.iconColorDisabled,
  emphasisColor: inkIconTokens.iconColorEmphasis,
  errorColor: inkIconTokens.iconColorError,
  warningColor: inkIconTokens.iconColorWarning,
  successColor: inkIconTokens.iconColorSuccess,

  // TODO: Look into adding a token
  hoverColor: 'rgba(26, 29, 32, 0.65)',
} as const

export default tokens
