import {
  oliveBackgroundTokens,
  oliveFontTokens,
  oliveIconTokens,
} from '@ds/tokens'

const tokens = {
  defaultBackgroundColor: oliveBackgroundTokens.bgColorDefault,
  defaultTextColor: oliveFontTokens.fontColorSubtle,
  defaultIconColor: oliveIconTokens.iconColorDefault,
  hoverActiveBackgroundColor: oliveBackgroundTokens.bgColorDefault,
  hoverBackgroundColor: oliveBackgroundTokens.bgColorDefault,
  hoverTextColor: oliveFontTokens.fontColorLink,
  hoverIconColor: oliveIconTokens.iconColorDefault,
  disabledTextColor: oliveFontTokens.fontColorDisabled,
  disabledIconColor: oliveIconTokens.iconColorDisabled,
  indicatorColor: oliveFontTokens.fontColorLink,
  indicatorWidth: '3px',
  selectedTextColor: oliveFontTokens.fontColorLink,

  dark: {
    defaultBackgroundColor: 'transparent',
    defaultTextColor: '#cccccc',
    defaultIconColor: oliveIconTokens.iconColorDefault,
    hoverActiveBackgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    hoverTextColor: oliveFontTokens.fontColorInverse,
    hoverIconColor: oliveFontTokens.fontColorInverse,
    disabledTextColor: oliveFontTokens.fontColorDisabled,
    disabledIconColor: oliveIconTokens.iconColorDisabled,
    indicatorColor: oliveFontTokens.fontColorInverse,
    selectedTextColor: oliveFontTokens.fontColorInverse,
  },
} as const

export default tokens
