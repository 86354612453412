import React from 'react'
import PropTypes from 'prop-types'
import { Motion } from '@ds/motion'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'
import type { ProgressCircleProps } from '..'
import styles from '../styles'

export type ProgressCircleIndeterminateProps = Pick<
  ProgressCircleProps,
  'dark' | 'kind' | 'size' | 'data-.*' | 'data-qa'
>

const ANIMATION_ROTATION_DURATION = {
  small: 1.3,
  medium: 1.35,
  large: 1.4,
}

export function ProgressCircleIndeterminate({
  dark = false,
  'data-qa': dataQa,
  kind = 'default',
  size = 'medium',
  ...restProps
}: ProgressCircleIndeterminateProps) {
  const sx = useThemeStyles(styles, { dark })

  const circleFillStyles = [
    sx.default.circleFill,
    sx.indeterminate[size].circleFill,
    kind === 'subtle' && sx.subtle.circleFill,
  ]

  return (
    <Motion
      css={sx.default.wrap}
      // @ts-expect-error
      style={{ height: sx[size].height, width: sx[size].width }}
      animate={{ rotate: 360 }}
      transition={{
        ease: 'linear',
        repeat: Infinity,
        duration: ANIMATION_ROTATION_DURATION[size],
      }}
    >
      <svg
        {...dataProps(restProps)}
        data-qa={dataQa && `${dataQa}-svg`}
        // @ts-expect-error
        height={sx[size].height}
        // @ts-expect-error
        width={sx[size].width}
      >
        <circle
          css={sx.default.circleTrack}
          // @ts-expect-error
          r={sx[size].radius}
          // @ts-expect-error
          cx={sx[size].centerPointX}
          // @ts-expect-error
          cy={sx[size].centerPointY}
          // @ts-expect-error
          strokeWidth={sx[size].trackStrokeWidth}
        />
        <circle
          css={circleFillStyles}
          // @ts-expect-error
          r={sx[size].radius}
          // @ts-expect-error
          cx={sx[size].centerPointX}
          // @ts-expect-error
          cy={sx[size].centerPointY}
          // @ts-expect-error
          strokeWidth={sx[size].fillStrokeWidth}
        />
      </svg>
    </Motion>
  )
}

const progressCircleIndeterminateKinds = ['default', 'subtle'] as const
export const progressCircleIndeterminateSizes = [
  'small',
  'medium',
  'large',
] as const

ProgressCircleIndeterminate.propTypes = {
  kind: PropTypes.oneOf(progressCircleIndeterminateKinds),
  size: PropTypes.oneOf(progressCircleIndeterminateSizes),
}
