import { TranslationsWarehouse, Locale } from '@ds/base'
import enTranslations from './enTranslations'
import { logComponentError } from '@ds/logging'
import { TranslationKeys } from './types'
import { cdnURLsPROD } from './translationURLs'
import { translationsVersion } from './translationsVersion'
/*

    Translations warehouse for the shared components library

*/

const urlLookup = (locale: Locale) => {
  return cdnURLsPROD[locale]
}

const warehouse = new TranslationsWarehouse<TranslationKeys>(
  urlLookup,
  enTranslations,
  {
    cacheProps: { name: 'shared_components', version: translationsVersion },
    logError: (message) =>
      logComponentError(
        {
          message,
        },
        'translations-warehouse'
      ),
  }
)

export default warehouse
