import type { Tokens } from '../../../../theming/types'
import { transformOrigin } from '../../../../utilities/transformOrigin'
import { swapAlignment } from '../../../Popover/index'
import type { MenuUIProps } from '..'

export interface MotionCustom {
  isNestedMenu: boolean
  isInk: boolean
}

export default (config: {
  props: Partial<MenuUIProps> & { isRTL?: boolean }
  tokens: Tokens
}) => {
  const { props, tokens } = config

  const { isRTL, alignment = 'center' } = props
  const originAlignment = isRTL ? swapAlignment(alignment) : alignment

  return {
    menuUIBackground: {
      backgroundColor: !props.positionStatic
        ? tokens.bgColorDefault
        : undefined,
      border: !props.positionStatic
        ? (`1px solid ${tokens.popover.borderColor}` as const)
        : undefined,
      borderRadius: !props.positionStatic ? '2px' : undefined,
      boxShadow: !props.positionStatic ? tokens.elevationMedium : undefined,
      position: !props.positionStatic ? 'absolute' : undefined,
      height: '100%',
      width: '100%',
      transformOrigin: transformOrigin(originAlignment, props.location),
      zIndex: -1,
    },

    menuUIMenuItems: {
      backgroundColor: props.positionStatic ? tokens.bgColorDefault : undefined,
      border: props.positionStatic
        ? `1px solid ${tokens.popover.borderColor}`
        : undefined,
      borderRadius: props.positionStatic ? '2px' : undefined,
      boxShadow: props.positionStatic ? tokens.elevationMedium : undefined,
      color: tokens.fontColorDefault,
      float: 'none',
      fontFamily: tokens.fontBodyM.fontFamily,
      overflow: 'auto',
      overscrollBehavior: 'contain',
      padding: '0 1px',
      textAlign: 'start',
    },

    motionVariants: {
      background: {
        initial: ({ isNestedMenu, isInk }: MotionCustom) => ({
          opacity: 0,
          scale: isInk && !isNestedMenu ? 0.8 : undefined,
        }),

        open: ({ isNestedMenu, isInk }: MotionCustom) =>
          ({
            opacity: 1,
            scale: isInk && !isNestedMenu ? 1 : undefined,
            transition: {
              opacity: {
                ease: 'harmonize',
                duration: 0.25,
              },
              default: {
                ease: 'default',
                duration: 0.25,
              },
            },
          }) as const,

        exit: {
          opacity: 0,
          transition: {
            duration: 0.15,
            ease: 'harmonize',
          },
        },
      },

      menuItems: {
        initial: {
          opacity: 0,
        },
        open: {
          opacity: 1,
          transition: {
            duration: 0.15,
            delayChildren: 0.1,
            ease: 'harmonize',
          },
        },
        exit: {
          opacity: 0,
          transition: {
            duration: 0.15,
            ease: 'harmonize',
          },
        },
      },
    },
  } as const
}
