import * as React from 'react'
import { AnimatePresence } from 'framer-motion'
import type { Custom } from '../types'

interface Props {
  /**
   * When a component is removed, there’s no longer a chance to update its
   * props. So if a component’s `exit` prop is defined as a dynamic variant and
   * you want to pass a new `custom` prop, you can do so via `MotionPresence`.
   * This will ensure all leaving components animate using the latest data.
   */
  custom?: Custom
  /**
   * If set to `true`, `MotionPresence` will only render one component at a
   * time. The exiting component will finish its exit animation before the
   * entering component is rendered.
   */
  exitBeforeEnter?: boolean
  /* Enable or disable initial animations on children that are present when the component is first rendered */
  initial?: boolean
  /* Fires when all exiting nodes have completed animating out */
  onExit?: () => void
}

/**
 * Allows components to animate out when they're removed from the React tree
 */
export const MotionPresence: React.FunctionComponent<Props> = ({
  children,
  custom,
  exitBeforeEnter = false,
  initial = true,
  onExit,
}) => {
  return (
    <AnimatePresence
      custom={custom}
      exitBeforeEnter={exitBeforeEnter}
      initial={initial}
      onExitComplete={onExit}
    >
      {children}
    </AnimatePresence>
  )
}
