import hexToRgba from 'hex-to-rgba'

const brandColors = {
  white: '#ffffff',
  gray1: '#f9f9f9',
  gray2: '#f4f4f4',
  gray3: '#e9e9e9',
  gray4: '#d9d9d9',
  gray5: '#cccccc',
  gray6: '#a9a9a9',
  gray7: '#999999',
  gray8: '#868686',
  gray9: '#666666',
  gray10: '#3d3d3d',
  black: '#1e1e1e',

  badgeOrange: '#cc4c15',
  badgeTurquoise: '#3d7e8f',

  DocuSignBluePale2: '#e3edf7',
  DocuSignBluePale: '#c9dcf0',
  DocuSignBlueDisabled: '#4f8dce',
  DocuSignBlueLight: '#2875c4',
  DocuSignBlue: '#005cb9',
  DocuSignBlueDark: '#004185',
  DocuSignBlueDark2: '#003368',

  funBluePale2: '#ebf5fc',
  funBluePale: '#d8edfa',
  funBlueLight: '#4186ec',
  funBlue: '#2463d1',
  funBlueDark: '#1951b8',
  funBlueDark2: '#164295',

  mantisGreenPale2: '#e3f2e9',
  mantisGreenPale: '#c6e5d3',
  mantisGreenLight: '#39a364',
  mantisGreen: '#008938',
  mantisGreenDark: '#007831',
  mantisGreenDark2: '#00672a',

  orange: '#ff9980',
  orangeDark: '#ff8567',
  orangeDark2: '#ff5c33',
  orangeDark3: '#ff3300',

  periwinklePale2: '#f6f7fa',
  periwinklePale: '#f0f2f6',
  periwinkleLight: '#e8edf7',
  periwinkle: '#d9e1f1',
  periwinkleDark: '#c7d1e2',
  periwinkleDark2: '#b8bfcc',

  persianRedPale2: '#ffeee9',
  persianRedPale: '#ffe3e1',
  persianRedLight: '#fb3449',
  persianRed: '#d13239',
  persianRedDark: '#b22b31',
  persianRedDark2: '#98252b',

  progressIndicatorInverted: '#85d8eb',

  sunglowYellowPale2: '#fffae5',
  sunglowYellowPale: '#ffefc0',
  sunglowYellowLight: '#ffd72e',
  sunglowYellow: '#ffce00',
  sunglowYellowDark: '#f6b610',
  sunglowYellowDark2: '#e5a305',
  sunglowYellowDark3: '#be8704',

  turquoisePale2: '#edf8fb',
  turquoisePale: '#e1f4f8',
  turquoiseLight: '#9ce2f2',
  turquoise: '#6ac6dc',
  turquoiseDark: '#52b4cd',
  turquoiseDark2: '#4b9aaf',
} as const

export const recipientColorPalette = {
  recipient0: {
    Normal: '#ffd65b',
    Dark: '#f5c431',
    Dark2: '#b8860b',
  },
  recipient1: {
    Normal: '#acdce6',
    Dark: '#81afb8',
    Dark2: '#205d86',
  },
  recipient2: {
    Normal: '#c0a5cf',
    Dark: '#91789e',
    Dark2: '#886288',
  },
  recipient3: {
    Normal: '#97c9bf',
    Dark: '#70948d',
    Dark2: '#708080',
  },
  recipient4: {
    Normal: '#f7b994',
    Dark: '#e69d73',
    Dark2: '#e66c37',
  },
  recipient5: {
    Normal: '#c3d5e6',
    Dark: '#6c89a6',
    Dark2: '#67809f',
  },
  recipient6: {
    Normal: '#cfdb7f',
    Dark: '#aeb86e',
    Dark2: '#5d995d',
  },
  recipient7: {
    Normal: '#ff9980',
    Dark: '#db765c',
    Dark2: '#e26a6a',
  },
  recipient8: {
    Normal: '#e6c6e6',
    Dark: '#b38fb3',
    Dark2: '#ae59b6',
  },
  recipient9: {
    Normal: '#ffb3c6',
    Dark: '#d98298',
    Dark2: '#d25299',
  },
} as const

const recipientColors = {
  recipient0: recipientColorPalette.recipient0.Normal,
  recipient1: recipientColorPalette.recipient1.Normal,
  recipient2: recipientColorPalette.recipient2.Normal,
  recipient3: recipientColorPalette.recipient3.Normal,
  recipient4: recipientColorPalette.recipient4.Normal,
  recipient5: recipientColorPalette.recipient5.Normal,
  recipient6: recipientColorPalette.recipient6.Normal,
  recipient7: recipientColorPalette.recipient7.Normal,
  recipient8: recipientColorPalette.recipient8.Normal,
  recipient9: recipientColorPalette.recipient9.Normal,
} as const

const colorsWithAlpha = {
  black_alpha50: hexToRgba(brandColors.black, 0.5),
  gray9_alpha90: hexToRgba(brandColors.gray9, 0.9),
  white_alpha75: hexToRgba(brandColors.white, 0.75),

  funBlue_alpha27: hexToRgba(brandColors.funBlue, 0.27),
  mantisGreen_alpha27: hexToRgba(brandColors.mantisGreen, 0.27),
  persianRed_alpha27: hexToRgba(brandColors.persianRed, 0.27),
  sunglowYellowDark_alpha43: hexToRgba(brandColors.sunglowYellowDark, 0.43),
}

export default {
  ...brandColors,
  ...recipientColors,
  ...colorsWithAlpha,
}
