import React from 'react'
import PropTypes from 'prop-types'
import { SystemIconKey } from '@ds/icons'
import consoleWarn from '../../../logging/consoleWarn'
import { IconSmall } from '../../../internal/components/IconSmall'

export interface MenuItemDisclosureProps {
  /** The Icon to display. */
  kind: SystemIconKey
}

export function MenuItemDisclosure(props: MenuItemDisclosureProps) {
  const { kind } = props

  consoleWarn(
    'The `Menu.ItemDisclosure` component has been deprecated and will be removed in a future major release. Please use an icon React component from `@ds/icons` instead.',
  )

  return <IconSmall kind={kind} />
}

MenuItemDisclosure.displayName = 'Menu.ItemDisclosure'

MenuItemDisclosure.icons = IconSmall.kinds

MenuItemDisclosure.propTypes = {
  kind: PropTypes.string.isRequired,
}
