import { capitalize } from './capitalize'

/**
 * Given two strings representing a type and variant, returns a concatenated
 * string where the variant has been capitalized.
 *
 * @example
 * // returns 'typeVariant'
 * variant('type', 'variant')
 *
 * @returns The two strings concatenated into one, where the second input string
 * has been capitalized.
 */
export const variant = <T extends string, V extends string>(
  type: T,
  variantOf: V,
) =>
  (variantOf
    ? `${type}${capitalize(variantOf)}`
    : type) as `${T}${Capitalize<V>}`
