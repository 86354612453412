export function isEscape(event: { key: string }) {
  return ['Escape', 'Esc'].includes(event.key)
}

export function isEnter(event: { key: string }) {
  return 'Enter' === event.key
}

export function isUpArrow(event: { key: string }) {
  return ['ArrowUp', 'Up'].includes(event.key)
}

export function isDownArrow(event: { key: string }) {
  return ['ArrowDown', 'Down'].includes(event.key)
}

export function isLeftArrow(event: { key: string }) {
  return ['ArrowLeft', 'Left'].includes(event.key)
}

export function isRightArrow(event: { key: string }) {
  return ['ArrowRight', 'Right'].includes(event.key)
}

export function isArrow(event: { key: string }) {
  return (
    isUpArrow(event) ||
    isDownArrow(event) ||
    isLeftArrow(event) ||
    isRightArrow(event)
  )
}

export function isBackspace(event: { key: string }) {
  return 'Backspace' === event.key
}

export function isDelete(event: { key: string }) {
  return 'Delete' === event.key
}

export function isSpace(event: { key: string }) {
  return [' ', 'Spacebar'].includes(event.key)
}

export function isTab(event: { key: string }) {
  return 'Tab' === event.key
}
