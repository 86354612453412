import mixpanel from 'mixpanel-browser'
import { componentsConfig } from '@ds/logging'

/*

    Analytics collector for components.

    Components which need to record events should call initialize when loaded. It will only intialize
    once for all parties.

    The initialize method supports an optional parameter to indicate if prod or not. However, usage of
    this parameter is deprecated.  Apps should set logging and app information and that will be used
    to derive PROD vs. TEST.
    (https://github.docusignhq.com/pages/FrontEndShared/components/docs/welcome/usage#logging-configuration)

    NOTE: If the user has set DNT (Do Not Track) the analytics will not be
    sent.
    NOTE: Events will include app config meta data if set by app
    (https://github.docusignhq.com/pages/FrontEndShared/components/docs/welcome/usage#logging-configuration)

*/

const PROD_TOKEN = 'f8e5a2707c7775b3a96550f97154a12f'
const TEST_TOKEN = 'fa2704a56c2fab789f2cf9cddf5a9862'

const ANALYTICS_ATTACHED_ATTRIBUTE = 'data-analytics-attached'

const preInitCompletionEvents: AnalyticEvent[] = []
let isAnalyticsInitialized = false
let isMixPanelInitialized = false

export type AnalyticMetaData = { [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any

export interface AnalyticEvent {
  eventName: string
  meta?: AnalyticMetaData
}

export type AnalyticEventMetaProvider = (
  link: HTMLAnchorElement
) => AnalyticMetaData

export const initializeAnalytics = (isProd?: boolean) => {
  if (isSimulating()) {
    isAnalyticsInitialized = false
  } else if (!isAnalyticsInitialized) {
    isAnalyticsInitialized = true
    const useProd =
      isProd === undefined ? componentsConfig.isProdLogging() : isProd
    try {
      mixpanel.init(useProd ? PROD_TOKEN : TEST_TOKEN, {
        secure_cookie: true,
        persistence: 'localStorage',
        persistence_name: 'docusign_components',
        loaded: () => applyInitialQueuedEvents(),
      })
    } catch (error) {
      console.log('mixpanel not available...' + error.message) // eslint-disable-line no-console
    }
  }
}

export const resetAnalytics = () => {
  isAnalyticsInitialized = false
  isMixPanelInitialized = false
}

export const recordAnalyticEvent = (event: AnalyticEvent) => {
  if (isSimulating()) {
    console.log('Analytics in simulation mode. event sent', event) // eslint-disable-line no-console
  } else if (isMixPanelInitialized) {
    sendEventTomixpanel(event)
  } else {
    preInitCompletionEvents.push(event)
  }
}

export const attachAnalyticEventToLink = (
  domId: string,
  eventName: string,
  meta: AnalyticEventMetaProvider | AnalyticMetaData
) => {
  const element = document.getElementById(domId)
  /*
        To ensure we don't over-report we are adding an attribute on the element to
        indicate that we have registered the tracking listener (and checking for it before adding)
        This is because mixpanel will not prevent more than one listener on the link.
     */
  if (element && !element.getAttribute(ANALYTICS_ATTACHED_ATTRIBUTE)) {
    element.setAttribute(ANALYTICS_ATTACHED_ATTRIBUTE, 'true')
    const selector = `#${domId}`
    if (isSimulating()) {
      // eslint-disable-next-line no-console
      console.log(
        `Analytics in simulation mode. Link tracking added to selector "${selector}": will record event "${eventName}"`
      )
    } else {
      try {
        mixpanel.track_links(selector, eventName, addAppMeta(meta))
      } catch (error) {
        console.log('mixpanel not available...' + error.message) // eslint-disable-line no-console
      }
    }
  }
}

/*
    NOTE: this function does not prevent duplicate events being fired on a link if
    it is called more than once for a selector.  The consumer must provide logic
    to prevent this (consider using attachAnalyticEventToLink instead)
*/
export const attachAnalyticEventToLinkSelector = (
  selector: string,
  eventName: string,
  meta: AnalyticEventMetaProvider | AnalyticMetaData
) => {
  if (isSimulating()) {
    // eslint-disable-next-line no-console
    console.log(
      `Analytics in simulation mode. Link tracking added to selector "${selector}": will record event "${eventName}"`
    )
  } else {
    try {
      mixpanel.track_links(selector, eventName, addAppMeta(meta))
    } catch (error) {
      console.log('mixpanel not available...' + error.message) // eslint-disable-line no-console
    }
  }
}

function sendEventTomixpanel(event: AnalyticEvent) {
  try {
    mixpanel.track(event.eventName, addAppMeta(event.meta))
  } catch (error) {
    console.log('mixpanel not available...' + error.message) // eslint-disable-line no-console
  }
}

function applyInitialQueuedEvents() {
  preInitCompletionEvents.forEach((event) => {
    sendEventTomixpanel(event)
  })
  preInitCompletionEvents.length = 0
  isMixPanelInitialized = true
}

function addAppMeta(metaData?: AnalyticMetaData) {
  const appDescription = componentsConfig.getAppDescription()
  if (appDescription.appId) {
    return { ...(metaData || {}), ...appDescription }
  }
  return metaData
}

function isSimulating() {
  return componentsConfig.isSimulatingAnalytics()
}
