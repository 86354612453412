import type { Tokens } from '../../types'

export default (tokens: Tokens) =>
  ({
    html: {
      ...tokens.fontBodyM,
    },

    body: {
      background: '#ffffff',
      color: tokens.fontColorDefault,
      fontFamily: tokens.fontBodyM.fontFamily,
    },
  }) as const
