import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'
import { useHeaderContext } from '../HeaderContext'
import styles from './styles'

export interface HeaderTitleProps {
  /**
   * Text that will be rendered for the HeaderTitle element.
   */
  children: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * @ignore @internal
   */
  precededByHeaderAction?: boolean
}

export function HeaderTitle({
  children,
  precededByHeaderAction = false,
  ...restProps
}: HeaderTitleProps) {
  const headerContext = useHeaderContext()

  const sx = useThemeStyles(styles, {
    ...headerContext,
    precededByHeaderAction,
  })

  return (
    <p {...dataProps(restProps)} css={sx}>
      {children}
    </p>
  )
}

HeaderTitle.propTypes = {
  children: PropTypes.string.isRequired,
  'data-.*': PropTypes.string,
  precededByHeaderAction: PropTypes.bool,
}

HeaderTitle.displayName = 'Header.Title'
