import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from '@ds/comp-private'
import { dataProps, onProps } from '@ds/react-utils'
import { Close, CloseOlive, CloseSmall } from '@ds/icons'
import { IconWithTheme } from '../IconWithTheme'
import { useThemeStyles } from '../../../theming'
import { CustomPropTypes } from '../../../support'
import type { EventListenerProps, ButtonForwardRef } from '../../../types'
import styles from './styles'

export interface PopoverCloseProps
  extends EventListenerProps<HTMLButtonElement> {
  /**
   * The text to present to assistive devices in order to identify the PopoverClose.
   */
  accessibilityText?: string
  /**
   * Set to true to use PopoverClose on dark backgrounds.
   */
  dark?: boolean
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * A React ref to assign to the HTML node representing the PopoverClose element.
   */
  forwardedRef?: ButtonForwardRef
  /**
   *  A smaller version of PopoverClose the utilizes the IconSmall component.
   */
  small?: boolean
}

export function PopoverClose({
  accessibilityText,
  dark = false,
  forwardedRef,
  onClick,
  small = false,
  ...restProps
}: PopoverCloseProps) {
  const sx = useThemeStyles(styles, { small })
  const translate = useTranslate()

  return (
    <button
      {...dataProps(restProps)}
      {...onProps(restProps)}
      css={[sx.default.button, dark && sx.dark.button]}
      onClick={onClick}
      ref={forwardedRef}
      type="button"
    >
      <span css={sx.default.text}>
        {accessibilityText || translate('OLIVE:CLOSE')}
      </span>

      <IconWithTheme
        inkIcon={small ? <CloseSmall /> : <Close />}
        oliveIcon={<CloseOlive />}
      />
    </button>
  )
}

PopoverClose.propTypes = {
  accessibilityText: PropTypes.string,
  dark: PropTypes.bool,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  'on[A-Z].*': PropTypes.func,
  onClick: PropTypes.func,
  small: PropTypes.bool,
}

PopoverClose.displayName = 'PopoverClose'
