import { oliveFocusTokens, oliveFormTokens } from '@ds/tokens'

const tokens = {
  defaultBackgroundColor: oliveFormTokens.formControlBgColorDefault,
  defaultBorderColor: oliveFormTokens.formControlBorderColorDefault,

  hoverBorderColor: oliveFormTokens.formControlBorderColorHover,
  activeBorderColor: oliveFormTokens.formControlBorderColorActive,
  selectedBorderColor: oliveFormTokens.formControlBorderColorSelected,
  disabledBorderColor: '#868686',

  disabledBackgroundColor: oliveFormTokens.formElementInputBgColorDisabled,
  // needs to be revisited - was added to resolve some issues with Menu
  disabledTextColor: oliveFormTokens.formElementTextColorDisabled,
  disabledOpacity: 0.4,

  errorBorderColor: oliveFormTokens.formElementInputBorderColorError,

  focusOutline: `${oliveFocusTokens.focusWidth} solid ${oliveFormTokens.formElementInputBorderColorActive}`,
  focusBorderColor: oliveFormTokens.formElementInputBorderColorActive,
  focusOutlineOffset: oliveFocusTokens.focusInset,

  readOnlyBackgroundColor: oliveFormTokens.formElementInputBgColorRead,
  readOnlyBorderColor: '#e9e9e9',
  readOnlyTextColor: '#999999',
} as const

export default tokens
