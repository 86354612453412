import type { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    placeholder: {
      avatar: {
        color: tokens.iconColorEmphasis,

        '& > svg': {
          borderRadius: 0,
          fill: tokens.iconColorEmphasis,
          height: '50%',
          width: '50%',
        },
      },
    },

    sizeXxlarge: {
      avatar: {
        fontSize: '20px',
        height: '48px',
        width: '48px',
      },
    },

    sizeXlarge: {
      avatar: {
        fontSize: '20px',
        height: '48px',
        width: '48px',
      },
    },

    sizeLarge: {
      avatar: {
        fontSize: '20px',
        height: '48px',
        width: '48px',
      },
    },

    sizeMedium: {
      avatar: {
        fontSize: '16px',
        height: '40px',
        width: '40px',
      },
    },

    sizeSmall: {
      avatar: {
        fontSize: '12px',
        height: '32px',
        width: '32px',
      },
    },
  } as const
}
