import { useCallback, useState } from 'react'
import {
  Popover,
  PopoverAlignment,
  PopoverLocation,
  PopoverMiddlewareArgs,
  PopoverPlacement,
} from '../components/Popover'

/**
 * @param {string} [alignment] updates alignment prop when the Popover flips.
 * @param {string} [location] updates location prop when Popover flips.
 *
 * This custom hook maintains the Popover alignment and location as reported
 * by Popover. This can be different than the values provided by the consumer
 * if Popover calculates that it needs to flip/re-position. Typically these updated
 * values are passed down to children component alignment and location props.
 */
export const usePopoverPositionUpdate = (
  alignmentValue: PopoverAlignment,
  locationValue: PopoverLocation,
) => {
  const [alignment, setAlignment] = useState(alignmentValue)
  const [location, setLocation] = useState(locationValue)

  const trackCurrentPosition = useCallback(
    ({ placement }: PopoverMiddlewareArgs) => {
      const [currLocation, currAlignment] = Popover.parsePlacement(
        placement as PopoverPlacement,
      )
      setAlignment(currAlignment)
      setLocation(currLocation)
    },
    [],
  )

  return { alignment, location, trackCurrentPosition }
}
