import { breakpoints } from '../../../../utilities'

export default () => {
  return {
    default: {
      '> li': {
        marginInline: '12px',

        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          marginInline: '4px',
        },
      },
    },
  } as const
}
