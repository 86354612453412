import type { HeaderProps } from '..'
import type { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens; props: Partial<HeaderProps> }) => {
  const { tokens, props } = config
  return {
    default: {
      backgroundColor: props.dark
        ? tokens.bgColorInverse
        : tokens.bgColorDefault,
    },

    wrap: {
      borderBottom: '1px solid',
      borderColor: tokens.borderColorSubtle,
      color: props.dark ? tokens.fontColorInverse : tokens.fontColorDefault,
      height: '64px',
      paddingInline: props.fullWidth ? '8px 16px' : '4px',
    },
  } as const
}
