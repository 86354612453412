import { CreateFocusCss } from '../../../../styles'
import type { CobrandingTokens } from '../../../../theming/types'

export default (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config
  return {
    button: {
      color: tokens.headerTextColor,

      '&:hover': {
        backgroundColor: tokens.headerBackgroundHoverColor,
        color: tokens.headerTextColor,
      },

      ...CreateFocusCss({ outlineColor: 'currentColor' }),
    },
  } as const
}
