export const keyboardEvents = ['keydown', 'keypress', 'keyup'] as const

export const mouseEvents = [
  'auxclick',
  'click',
  'contextmenu',
  'dblclick',
  'mousedown',
  'mouseenter',
  'mouseleave',
  'mousemove',
  'mouseover',
  'mouseout',
  'mouseup',
  'select',
  'wheel',
] as const

export const dragEvents = [
  'drag',
  'dragend',
  'dragenter',
  'dragstart',
  'dragleave',
  'dragover',
  'drop',
] as const
