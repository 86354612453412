import { CreateFocusCss, isTouchScreen } from '../../../../styles'
import type { Tokens } from '../../../../theming/types'
import { styles } from '../../../../utilities'
import type { PopoverCloseProps } from '..'

export default (config: {
  tokens: Tokens
  props: Partial<PopoverCloseProps>
}) => {
  const { tokens, props } = config
  return {
    default: {
      button: {
        alignItems: 'center',
        background: 'none',
        border: 0,
        color: tokens.iconColorDefault,
        cursor: 'pointer',
        display: 'flex',
        height: props.small ? '16px' : tokens.button.defaultSize,
        justifyContent: 'center',
        padding: 0,
        transitionDuration: '0.2s',
        transitionProperty: 'background-color, color',
        transitionTimingFunction: 'ease',
        width: props.small ? '16px' : tokens.button.defaultSize,

        '&:hover': {
          color: tokens.icon.hoverColor,
        },

        ...CreateFocusCss(tokens.focusInner),

        [isTouchScreen]: {
          height: tokens.button.mobileSize,
          width: tokens.button.mobileSize,
        },
      },

      text: styles.visuallyHidden,
    },

    dark: {
      button: {
        borderRadius: '2px',
        color: 'white',

        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          color: 'white',
        },

        '&:active': {
          backgroundColor: 'rgba(255, 255, 255, 0.35)',
        },

        ...CreateFocusCss({
          ...tokens.focusInner,
          outlineColor: '#fff',
        }),
      },
    },
  } as const
}
