import { zIndexes } from '../../../variables'

export default () =>
  ({
    positionHelper: {
      insetInlineStart: 0,
      position: 'absolute',
      top: 0,
      zIndex: zIndexes.Menu,
    },
  }) as const
