import * as React from 'react'
import type { CSSObject } from '@emotion/react'
import { ariaProps, dataProps, mergeRefs, useHtmlDir } from '@ds/react-utils'
import {
  arrow as popoverArrow,
  autoUpdate,
  flip,
  hide,
  HideOptions,
  Middleware,
  MiddlewareReturn,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react'
import { useThemeStyles } from '../../theming'
import type { AriaAttributes, DivForwardRef, SpanForwardRef } from '../../types'
import { variant } from '../../utilities'
import {
  alignments,
  formatPlacement,
  locations,
  parsePlacement,
  PopoverPlacement,
  presets,
  swapLocation,
} from './utils'
import styles from './styles'

type Promisable<T> = T | Promise<T>

export type PopoverAlignment = (typeof alignments)[number]
export type PopoverCallback = (args: PopoverMiddlewareArgs) => void
export type PopoverLocation = (typeof locations)[number]
export type PopoverPosition = {
  alignment: PopoverAlignment
  location: PopoverLocation
}
export type PopoverStrategy = 'absolute' | 'fixed'

export interface PopoverMiddlewareArgs {
  initialPlacement: PopoverPlacement
  placement: PopoverPlacement
  strategy: PopoverStrategy
  x: number
  y: number
}

export type FlipMiddleware = Parameters<typeof flip>[0]
export type HideMiddleware = Pick<HideOptions, 'strategy'>['strategy']
export type OffsetMiddleware = Parameters<typeof offset>[0]
export type ShiftMiddleware = Parameters<typeof shift>[0]

interface CustomMiddleware {
  name: string
  fn: (args: PopoverMiddlewareArgs) => Promisable<MiddlewareReturn>
  options?: OffsetMiddleware
}

export interface PopoverProps extends AriaAttributes {
  /**
   * The alignment of the Popover relative to the Element it is positioned around.
   */
  alignment?: PopoverAlignment
  /**
   * The Element around which the Popover will be positioned.
   */
  anchorElement?: HTMLElement | null
  /**
   * A React element that will serve as the Popover's arrow when
   * shiftMiddleware is enabled.
   */
  arrow?: React.ReactElement
  children: React.ReactNode
  /**
   *  Additional styles to apply to the css property of the Popover.
   */
  containerStyles?: CSSObject
  /**
   * A prop to allow custom middleware.
   * https://floating-ui.com/docs/middleware
   */
  customMiddleware?: CustomMiddleware[]
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * The flip middleware can change the placement of the Popover when it's scheduled to
   * overflow a given boundary.
   *
   * Disabling this middleware means that the Popover will stay in its initial location
   * regardless of surrounding containers.
   *
   * https://floating-ui.com/docs/flip
   */
  flipMiddleware?: FlipMiddleware
  /**
   * A React ref to assign to the HTML node representing the Popover.
   */
  forwardedRef?: DivForwardRef | SpanForwardRef
  /**
   * A data provider that allows you to hide the floating element in applicable situations.
   *
   * https://floating-ui.com/docs/hide
   */
  hideMiddleware?: HideMiddleware[]
  /**
   * Use a `<span/>` as the Popover container.
   *
   * By default Popover will insert a div that wraps the content. This may
   * lead to invalid HTML. When `isSpanContainer` is `true` the container element will
   * be a span with `display: block`.
   */
  isSpanContainer?: boolean
  /**
   * The preferred location of the Popover relative to its anchor element.
   */
  location?: PopoverLocation
  /**
   * Determines whether the Popover will adjust its location when it starts to
   * overlap its anchor element (by default it will "flip" to the opposite side
   * of the anchor element).
   */
  locationFixed?: boolean
  /**
   * Displaces the Popover from its core placement along the specified axes.
   * https://floating-ui.com/docs/offset
   */
  offsetMiddleware?: OffsetMiddleware
  /**
   * A callback that fires on the Popover's first update.
   * Note: onUpdate also fires on first update, this callback fires on the first update only.
   */
  onFirstUpdate?: PopoverCallback
  /**
   * An update callback that fires whenever the Popover updates.
   *
   * A common use case is to reposition the a pointer arrow when the Popover container flips.
   */
  onUpdate?: PopoverCallback
  /**
   * The shiftMiddleware (preventOverflow) modifier prevents the Popover from being cut off
   * by moving it so that it stays visible within its boundary area.
   * https://floating-ui.com/docs/shift
   */
  shiftMiddleware?: ShiftMiddleware
  /**
   * The positioning strategy used by Popover. The default is 'absolute', but 'fixed' can also be passed.
   * https://floating-ui.com/docs/computePosition#strategy
   */
  strategy?: PopoverStrategy
}

export function Popover({
  alignment = 'center',
  anchorElement,
  arrow,
  children,
  containerStyles,
  customMiddleware = [],
  flipMiddleware: customFlipMiddleware,
  hideMiddleware = [],
  forwardedRef,
  location = 'above',
  locationFixed,
  offsetMiddleware,
  onFirstUpdate,
  onUpdate,
  shiftMiddleware,
  isSpanContainer,
  strategy = 'absolute',
  ...restProps
}: PopoverProps) {
  const arrowRef = React.useRef<HTMLDivElement | null>(null)

  const textDirection = useHtmlDir()
  const isRTL = textDirection === 'rtl'

  const sx = useThemeStyles(styles)

  const placement = formatPlacement({
    alignment,
    location: isRTL ? swapLocation(location) : location,
  })

  const firstUpdateHasBeenCalled = React.useRef(false)
  const firstUpdateCbArgs = React.useRef<PopoverMiddlewareArgs>({
    initialPlacement: placement,
    placement,
    strategy,
    x: 0,
    y: 0,
  })

  const flipMiddleware = locationFixed
    ? undefined
    : customFlipMiddleware ||
      Popover.presets.flip(
        true,
        true,
        location === 'before' || location === 'after' ? 'start' : 'none',
      )

  const middleware = React.useMemo(() => {
    const middlewareStack: Middleware[] = []

    /**
     * The order in which middleware are placed in the array matters, as middleware use the coordinates
     * that were returned from previous ones. This means they perform their work based on the current
     * positioning state.
     *
     * https://floating-ui.com/docs/middleware#ordering
     */

    /**
     * offset() should generally be placed at the beginning of your middleware array.
     */
    if (offsetMiddleware) {
      middlewareStack.push(offset(offsetMiddleware))
    }

    if (flipMiddleware) {
      middlewareStack.push(flip(flipMiddleware))
    }

    if (shiftMiddleware) {
      middlewareStack.push(shift(shiftMiddleware))
    }

    /**
     * arrow() should generally be placed toward the end of your middleware array, after shift() (if used).
     */
    if (shiftMiddleware && arrow && arrowRef.current) {
      middlewareStack.push(
        popoverArrow({
          element: arrowRef.current,
        }),
      )
    }

    if (onUpdate) {
      middlewareStack.push({
        name: 'onUpdate',
        fn: (args: PopoverMiddlewareArgs) => {
          // Debounce?
          onUpdate(args)
          return args
        },
      })
    }

    /**
     * hide() should generally be placed at the end of the middleware array.
     */
    if (hideMiddleware.length > 0) {
      const hideStrategies = hideMiddleware
        .filter((hideStrategy) => {
          return ['referenceHidden', 'escaped', undefined].includes(
            hideStrategy,
          )
        })
        .map((hideStrategy) => hide({ strategy: hideStrategy }))

      middlewareStack.push(...hideStrategies)
    }

    return [...middlewareStack, ...customMiddleware]
  }, [
    arrow,
    customMiddleware,
    flipMiddleware,
    hideMiddleware,
    offsetMiddleware,
    onUpdate,
    shiftMiddleware,
  ])

  const {
    context,
    isPositioned,
    refs,
    strategy: positioningStrategy,
    x,
    y,
  } = useFloating({
    elements: {
      reference: anchorElement,
    },
    strategy,
    placement,
    middleware,
    whileElementsMounted(...args) {
      const cleanup = autoUpdate(...args, {
        elementResize: typeof ResizeObserver === 'function',
      })
      return cleanup
    },
  })

  firstUpdateCbArgs.current = {
    initialPlacement: placement,
    placement: context.placement,
    strategy: context.strategy,
    x: context.x ?? 0, // to make TS happy
    y: context.y ?? 0,
  }
  React.useLayoutEffect(() => {
    /**
     * We want this to fire after the Popover has been positioned.
     * This is typically what is used to set focus and if we call
     * .focus before positioning the element the user will be shot
     * to the top of the page.
     */
    if (onFirstUpdate && !firstUpdateHasBeenCalled.current && isPositioned) {
      onFirstUpdate(firstUpdateCbArgs.current)
      firstUpdateHasBeenCalled.current = true
    }
  }, [isPositioned, onFirstUpdate])

  const arrowStyles = [
    sx.arrow,
    sx[variant('popover', context.placement.split('-')[0])],
    context.middlewareData.arrow && {
      left: context.middlewareData.arrow.x,
      top: context.middlewareData.arrow.y,
    },
  ]

  const hideMiddlewareVisibility =
    context.middlewareData.hide?.referenceHidden ||
    context.middlewareData.hide?.escaped
      ? 'hidden'
      : 'visible'

  const commonProps = {
    ...ariaProps(restProps),
    ...dataProps(restProps),
    'data-popover': 'true',
    'data-popover-is-positioned': isPositioned,
    ref: mergeRefs(forwardedRef, refs.setFloating),
    css: {
      // Popper (JS) component default. Used for span.
      display: 'block',
      // Enforce popover onto it's own plane; Added to fix opacity bug in Safari
      transform: 'translateZ(0)',
      // https://floating-ui.com/docs/computePosition
      width: 'max-content',
      // Allow consumers to override width and supply other styling but not override styles core to FloatingUI. This should be last to override display and width.
      ...containerStyles,
    },
    style: {
      left: x ?? 0,
      position: positioningStrategy,
      top: y ?? 0,
      visibility: hideMiddlewareVisibility,
    },
  }

  const arrowNode = arrow && (
    // @ts-expect-error quiet for now
    <div ref={arrowRef} css={arrowStyles}>
      {arrow}
    </div>
  )

  return isSpanContainer ? (
    // @ts-expect-error
    <span {...commonProps}>
      {children}
      {arrowNode}
    </span>
  ) : (
    // @ts-expect-error
    <div {...commonProps}>
      {children}
      {arrowNode}
    </div>
  )
}

Popover.alignments = alignments
Popover.locations = locations
Popover.parsePlacement = parsePlacement
Popover.presets = presets
