import type { InlineMessageKind } from '../../../../components/InlineMessage'
import colors from '../colors'

const borderColor = {
  danger: colors.persianRed_alpha27,
  information: colors.funBlue_alpha27,
  success: colors.mantisGreen_alpha27,
  warning: colors.sunglowYellowDark_alpha43,
} as const

const warningTokens = {
  backgroundColor: colors.sunglowYellowPale2,
  borderColor: borderColor.warning,
  borderInlineStartColor: colors.sunglowYellowDark3,
  iconColor: colors.sunglowYellowDark3,
  focusColor: colors.funBlue,
  textColor: colors.black,
}

export interface InlineMessageTokensOlive {
  backgroundColor: string
  borderInlineStartColor: string
  borderColor?: string
  textColor?: string
  iconColor?: string
  focusColor?: string
}

const tokens: Record<InlineMessageKind, InlineMessageTokensOlive> = {
  danger: {
    backgroundColor: colors.persianRedPale2,
    borderColor: borderColor.danger,
    borderInlineStartColor: colors.persianRed,
    iconColor: colors.persianRedDark,
    focusColor: colors.funBlue,
    textColor: colors.persianRedDark,
  },
  information: {
    backgroundColor: colors.funBluePale2,
    borderColor: borderColor.information,
    borderInlineStartColor: colors.funBlue,
    iconColor: colors.funBlue,
    focusColor: colors.funBlue,
    textColor: colors.black,
  },
  success: {
    backgroundColor: colors.mantisGreenPale2,
    borderColor: borderColor.success,
    borderInlineStartColor: colors.mantisGreen,
    iconColor: colors.mantisGreen,
    focusColor: colors.funBlue,
    textColor: colors.black,
  },
  warning: warningTokens,
  emphasis: warningTokens,
}

export default tokens
