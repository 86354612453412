import { breakpoints } from '../../../../utilities'

export default () =>
  ({
    default: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 1 25%',
      justifyContent: 'flex-end',
      minWidth: '243px',

      [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
        flexBasis: 'auto',
      },
    },
  }) as const
