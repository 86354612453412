import { useEffect, useState } from 'react'
import { getLang, addLangListener, removeLangListener, Locale } from '@ds/base'

function isValidLocale(locale: string) {
  // en or en-gb or en_gb
  return new RegExp(/^([a-zA-Z]{2})((-|_)[a-zA-Z]{2})?$/).test(locale)
}

/**
 * A custom React hook that observes changes to the 'html' element on the page via
 * a 'useEffect' hook.
 *
 * A 'useState' hook stores the last observed value of the element's 'lang' attribute.
 *
 * When the 'lang' attribute changes, the state of the custom hook is updated and
 * the newly observed value of the attribute is returned.
 *
 * @returns {?string} The last observed value of the 'lang' attribute of
 * the 'html' element, or null.
 */
export const useHtmlLang = (): string | null => {
  const [currentLocale, setCurrentLocale] = useState(getLang() || '')

  useEffect(() => {
    const langListener = (locale: Locale = '') => {
      setCurrentLocale(locale)
    }

    addLangListener(langListener)

    return () => removeLangListener(langListener)
  }, [])

  return isValidLocale(currentLocale) ? currentLocale : null
}
