import PropTypes from 'prop-types'

/**
 * Use this to short-circuit any prop types that are not server-safe.
 *
 * @example
 * anyForSSR() || PropTypes.instanceOf(HTMLElement)
 */
export const anyForSSR = () =>
  typeof window === 'undefined' ? PropTypes.any : undefined

/**
 * A custom PropType for an instance of an Element.
 *
 * The Web API Element interface is not available for consumers that render React
 * on the server. As a fallback we only check that it's an object.
 */
const ElementPropType =
  typeof Element === 'undefined'
    ? PropTypes.instanceOf(Object)
    : PropTypes.instanceOf(Element)

/**
 * A custom PropType for an instance of a React ref object.
 */
const ReactRefObjectPropType = PropTypes.shape({
  current: ElementPropType,
})

/**
 * A custom PropType for an instance of a React ref.
 *
 * It handles both types that can be provided for a React ref:
 * - a React ref object
 * - a ref callback function
 */
const ReactRefPropType = PropTypes.oneOfType([
  ReactRefObjectPropType,
  PropTypes.func,
])

export const CustomPropTypes = {
  Element: ElementPropType,
  ReactRef: ReactRefPropType,
  ReactRefObject: ReactRefObjectPropType,
}
