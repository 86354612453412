import * as React from 'react'
import PropTypes from 'prop-types'
import { systemIconValues, SystemIconKey } from '@ds/icons'
import { CustomPropTypes } from '../../../support'
import { useThemeIcon, useThemeStyles } from '../../../theming'
import type { SpanForwardRef } from '../../../types'
import { BaseIcon } from '../BaseIcon'
import styles from './styles'

export interface IconSmallProps {
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * A React ref to assign to the HTML node representing the Icon element.
   */
  forwardedRef?: SpanForwardRef
  /**
   * Make use of the 'currentColor' by passing it to the fill of the underlying SVG.
   */
  isCurrentColor?: boolean
  /**
   * The name of the icon to use.
   */
  kind: SystemIconKey
}

export const IconSmall = ({
  forwardedRef,
  isCurrentColor = false,
  kind,
  ...restProps
}: IconSmallProps) => {
  const sx = useThemeStyles(styles)
  const svgString = useThemeIcon('small', kind)

  return (
    <BaseIcon
      {...restProps}
      forwardedRef={forwardedRef}
      legacyFill={isCurrentColor ? 'currentColor' : undefined}
      size={sx.size}
      svgString={svgString}
    />
  )
}

IconSmall.kinds = systemIconValues

IconSmall.propTypes = {
  forwardedRef: CustomPropTypes.ReactRef,
  isCurrentColor: PropTypes.bool,
  kind: PropTypes.oneOf(IconSmall.kinds).isRequired,
}

IconSmall.displayName = 'IconSmall'
