import {
  inkBorderTokens,
  inkFocusTokens,
  inkFontTokens,
  inkFormTokens,
} from '@ds/tokens'

const tokens = {
  defaultBackgroundColor: inkFormTokens.formControlBgColorDefault,
  defaultBorderColor: inkFormTokens.formControlBorderColorDefault,

  hoverBorderColor: inkFormTokens.formControlBorderColorHover,
  activeBorderColor: inkFormTokens.formControlBorderColorActive,
  selectedBorderColor: inkFormTokens.formControlBorderColorSelected,
  disabledBorderColor: inkBorderTokens.borderColorSubtle,

  disabledBackgroundColor: inkFormTokens.formElementInputBgColorDisabled,
  // needs to be revisited - was added to resolve some issues with Menu
  disabledTextColor: inkFormTokens.formElementTextColorDisabled,
  disabledOpacity: 0.25,

  errorBorderColor: inkFormTokens.formElementInputBorderColorError,

  focusOutline: `${inkFocusTokens.focusWidth} solid ${inkFormTokens.formElementInputBorderColorActive}`,
  focusBorderColor: inkFormTokens.formElementInputBorderColorActive,
  focusOutlineOffset: `-${inkFocusTokens.focusWidth}`,

  readOnlyBackgroundColor: inkFormTokens.formElementInputBgColorRead,
  readOnlyBorderColor: 'transparent',
  readOnlyTextColor: inkFontTokens.fontColorDefault,
} as const

export default tokens
