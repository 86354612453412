import * as React from 'react'
import PropTypes from 'prop-types'
import { useUniqueId } from '@ds/react-utils'
import { BaseMenuItem } from '../../../internal/components/BaseMenuItem'
import { requiredPropMessage } from '../../../logging'
import { CustomPropTypes } from '../../../support'
import type {
  InputEventListenerProps,
  LabelForwardRef,
  ConditionalTagRef,
} from '../../../types'
import type { DotBadgeProps } from '../../DotBadge'

export interface MenuItemFileInputProps extends InputEventListenerProps {
  /**
   * The prop accepts a string with a list of comma-separated content-type specifiers.
   * (file extensions prefixed with '.', valid MIME types without extensions)
   * e.g. '.js, image/*'.
   */
  accept?: string
  /**
   * The text to present to assistive devices in order to identify the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText?: string
  /**
   * The "badge" element to display above the top-right of the Menu.Item.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   */
  badge?: React.ReactElement<DotBadgeProps>
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * An optional description.
   */
  description?: string
  /**
   * Applies the 'disabled' attribute.
   */
  disabled?: boolean
  /**
   * The provided component will display at the end of the menu item.
   */
  endElement?: React.ReactNode
  /**
   * A React ref to assign to the HTML node representing the component.
   */
  forwardedRef?: LabelForwardRef | ConditionalTagRef
  /**
   * Allows the user to select more than one file when the Menu.Item acts as a file input.
   * This prop is only used when a value is provided for 'accept'.
   */
  multiple?: boolean
  /**
   * The function to call when a 'keydown' event is fired.
   */
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*'?: React.EventHandler<React.SyntheticEvent<HTMLInputElement>>
  /**
   * The provided component will display at the start of the menu item.
   */
  startElement?: React.ReactNode
  /**
   * The text of the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  text?: string
}

export function MenuItemFileInput({
  accept,
  accessibilityText,
  description,
  disabled = false,
  endElement,
  forwardedRef,
  multiple = false,
  onChange,
  onClick,
  onKeyDown,
  startElement,
  text,
  ...restProps
}: MenuItemFileInputProps) {
  const inputId = useUniqueId('short')

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'Menu.ItemFileInput',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  return (
    <BaseMenuItem
      {...restProps}
      accept={accept}
      accessibilityText={accessibilityText}
      description={description}
      disabled={disabled}
      endElement={endElement}
      fileInput
      forwardedRef={forwardedRef}
      id={inputId}
      multiple={multiple}
      onChange={onChange}
      onClick={onClick}
      onKeyDown={onKeyDown}
      startElement={startElement}
      text={text}
    />
  )
}

MenuItemFileInput.propTypes = {
  accept: PropTypes.string,
  accessibilityText: PropTypes.string,
  badge: PropTypes.element,
  'data-.*': PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  endElement: PropTypes.node,
  forwardedRef: CustomPropTypes.ReactRef,
  multiple: PropTypes.bool,
  'on[A-Z].*': PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  startElement: PropTypes.node,
  text: PropTypes.string,
}

MenuItemFileInput.displayName = 'Menu.ItemFileInput'
