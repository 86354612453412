import {
  inkButtonTokens,
  inkCTATokens,
  inkFontTokens,
  inkIconTokens,
} from '@ds/tokens'

const main = {
  defaultBackgroundColor: inkCTATokens.ctaBgColorPrimaryDefault,
  defaultBorderColor: undefined,
  defaultTextColor: inkFontTokens.fontColorInverse,
  hoverBackgroundColor: inkCTATokens.ctaBgColorPrimaryHover,
  hoverTextColor: undefined,
  activeBackgroundColor: inkCTATokens.ctaBgColorPrimaryActive,
  activeTextColor: undefined,
  disabledBackgroundColor: inkCTATokens.ctaBgColorPrimaryDefault,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: 0.25,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: inkFontTokens.fontColorDefault,
} as const

const primary = {
  defaultBackgroundColor: inkCTATokens.ctaBgColorPrimaryDefault,
  defaultBorderColor: undefined,
  defaultTextColor: inkFontTokens.fontColorInverse,
  hoverBackgroundColor: inkCTATokens.ctaBgColorPrimaryHover,
  hoverTextColor: undefined,
  activeBackgroundColor: inkCTATokens.ctaBgColorPrimaryActive,
  activeTextColor: undefined,
  disabledBackgroundColor: inkCTATokens.ctaBgColorPrimaryDefault,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: 0.25,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: inkFontTokens.fontColorDefault,
} as const

const secondary = {
  defaultBackgroundColor: inkCTATokens.ctaBgColorSecondaryDefault,
  defaultBorderColor: inkCTATokens.ctaBorderColorSecondaryDefault,
  defaultTextColor: inkFontTokens.fontColorDefault,
  hoverBackgroundColor: inkCTATokens.ctaBgColorSecondaryHover,
  hoverTextColor: undefined,
  activeBackgroundColor: inkCTATokens.ctaBgColorSecondaryActive,
  activeTextColor: undefined,
  disabledBackgroundColor: inkCTATokens.ctaBgColorSecondaryDefault,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: 0.25,
  iconColor: inkIconTokens.iconColorDefault,
  iconHoverColor: inkIconTokens.iconColorDefault,
  buttonGroupSeparatorColor: inkIconTokens.iconColorDefault,
} as const

const tertiary = {
  defaultBackgroundColor: inkCTATokens.ctaBgColorTertiaryDefault,
  defaultBorderColor: undefined,
  defaultTextColor: inkFontTokens.fontColorDefault,
  hoverBackgroundColor: inkCTATokens.ctaBgColorTertiaryHover,
  hoverTextColor: undefined,
  activeBackgroundColor: inkCTATokens.ctaBgColorTertiaryActive,
  activeTextColor: undefined,
  disabledBackgroundColor: inkCTATokens.ctaBgColorTertiaryDefault,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: 0.25,
  iconColor: inkIconTokens.iconColorDefault,
  iconHoverColor: inkIconTokens.iconColorDefault,
  buttonGroupSeparatorColor: undefined,
} as const

const danger = {
  defaultBackgroundColor: inkButtonTokens.danger.defaultBackgroundColor,
  defaultBorderColor: undefined,
  defaultTextColor: inkButtonTokens.danger.defaultTextColor,
  hoverBackgroundColor: inkButtonTokens.danger.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: inkButtonTokens.danger.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: inkButtonTokens.danger.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: inkButtonTokens.danger.disabledOpacity,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: inkButtonTokens.danger.buttonGroupSeparatorColor,
} as const

const tokens = {
  main,
  primary,
  secondary,
  tertiary,
  danger,

  invertedBackgroundColor: inkButtonTokens.invertedBackgroundColor,
  invertedTextColor: inkButtonTokens.invertedTextColor,

  defaultSize: inkButtonTokens.defaultSize,
  mobileSize: inkButtonTokens.mobileSize,
} as const

export default tokens
