import { inkFontTokens } from '@ds/tokens'

const tokens = {
  defaultColor: inkFontTokens.fontColorLink,
  defaultHoverColor: inkFontTokens.fontColorLinkHover,
  defaultActiveColor: inkFontTokens.fontColorLinkActive,
  defaultVisitedColor: inkFontTokens.fontColorLinkVisited,
  subtleColor: inkFontTokens.fontColorLinkSubtle,
  subtleHoverColor: inkFontTokens.fontColorLinkHover,
  subtleActiveColor: inkFontTokens.fontColorLinkActive,
  subtleVisitedColor: inkFontTokens.fontColorLinkVisitedSubtle,
  lightColor: inkFontTokens.fontColorLink, // adding for parity with Olive Tokens, not in use
  lightHoverColor: inkFontTokens.fontColorLinkHover,
} as const

export default tokens
