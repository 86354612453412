import {
  LocalePolicy,
  SupportedGroup1LocaleName,
  SupportedLocaleName,
} from '../types'
import {
  setTelemetry,
  reportException,
  getPerformance,
} from '../telemetry/telemetry'
/*
 * @ds/i18nlayer - localePolicy
 */

const _languageToLocale = {
  bg: 'bg_bg',
  cs: 'cs_cz',
  da: 'da_dk',
  de: 'de_de',
  el: 'el_gr',
  en: 'en_us',
  es: 'es_es',
  et: 'et_ee',
  fa: 'fa_ir',
  fi: 'fi_fi',
  fr: 'fr_fr',
  he: 'he_il',
  hi: 'hi_in',
  hr: 'hr_hr',
  hu: 'hu_hu',
  hy: 'hy_am',
  id: 'id_id',
  it: 'it_it',
  ja: 'ja_jp',
  ko: 'ko_kr',
  lt: 'lt_lt',
  lv: 'lv_lv',
  ms: 'ms_my',
  nb: 'nb_no',
  no: 'nb_no',
  nl: 'nl_nl',
  pl: 'pl_pl',
  pt: 'pt_pt',
  ro: 'ro_ro',
  ru: 'ru_ru',
  sk: 'sk_sk',
  sl: 'sl_si',
  sv: 'sv_se',
  th: 'th_th',
  tr: 'tr_tr',
  uk: 'uk_ua',
  vi: 'vi_vn',
}

// predefined locale poliy at client side.
// These values are used as default format value for each regional format.
const predefinedPolicy: LocalePolicy[] = [
  {
    cultureName: 'de',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'leading_1_period_234_period_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_period_234_period_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'en',
    effectiveTimeZone: 'tz_66_pacific',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyAccountingFormat:
      'opar_csym_1_comma_234_comma_567_period_89_cpar',
    currencySign: 'accounting',
    effectiveCurrencyCode: 'usd',
  },
  {
    cultureName: 'en_au',
    effectiveTimeZone: 'tz_28_east_australia',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'aud',
  },
  {
    cultureName: 'en_us',
    effectiveTimeZone: 'tz_66_pacific',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyAccountingFormat:
      'opar_csym_1_comma_234_comma_567_period_89_cpar',
    currencySign: 'accounting',
    effectiveCurrencyCode: 'usd',
  },
  {
    cultureName: 'en_gb',
    effectiveTimeZone: 'tz_37_gmt',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'gbp',
  },
  {
    cultureName: 'es',
    effectiveTimeZone: 'tz_70_romance',
    effectiveCurrencyPositiveFormat:
      'leading_1_period_234_period_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_period_234_period_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'es_mx',
    effectiveTimeZone: 'tz_24_central_mexico',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'mxn',
  },
  {
    cultureName: 'fr',
    effectiveTimeZone: 'tz_70_romance',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'fr_ca',
    effectiveTimeZone: 'tz_13_canada_central',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyAccountingFormat:
      'opar_1_space_234_space_567_comma_89_space_csym_cpar',
    currencySign: 'accounting',
    effectiveCurrencyCode: 'cad',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'it',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'ja',
    effectiveTimeZone: 'tz_83_tokyo',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveAddressFormat: 'ja_jp',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89', // 'csym_1_comma_234_comma_567',
    effectiveCurrencyNegativeFormat:
      'csym_minus_12_comma_34_comma_567_period_89', // minus_csym_1_comma_234_comma_567',
    effectiveCurrencyCode: 'jpy',
  },
  {
    cultureName: 'ko',
    effectiveTimeZone: 'tz_48_korea',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveAddressFormat: 'ja_jp',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89', // 'csym_1_comma_234_comma_567',
    effectiveCurrencyNegativeFormat:
      'csym_minus_12_comma_34_comma_567_period_89', // 'minus_csym_1_comma_234_comma_567',
    effectiveCurrencyCode: 'krw',
  },
  {
    cultureName: 'nl',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'csym_space_minus_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'pt',
    effectiveTimeZone: 'tz_37_gmt',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'pt_br',
    effectiveTimeZone: 'tz_30_east_south_america',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'brl',
  },
  {
    cultureName: 'ru',
    effectiveTimeZone: 'tz_71_russian',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'rub',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'zh_cn',
    effectiveTimeZone: 'tz_25_china',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_minus_12_comma_34_comma_567_period_89',
    effectiveCurrencyCode: 'cny',
  },
  {
    cultureName: 'zh_tw',
    effectiveTimeZone: 'tz_81_taipei',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'twd',
  },
]
const baseLocalePolicy: LocalePolicy = {
  effectiveNameFormat: 'first_middle_last',
  effectiveInitialFormat: 'first1last1',
  effectiveAddressFormat: 'en_us',
  effectiveCalendarType: 'gregorian',
  effectiveDateFormat: 'default',
  effectiveTimeZone: 'tz_37_gmt',
  effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
  effectiveCurrencyNegativeFormat: 'minus_csym_1_comma_234_comma_567_period_89',
  effectiveCurrencyCode: 'usd',
  useLongCurrencyFormat: false,
  effectivePercentFormat: 'np',
}
const predefinedPolicyV2: LocalePolicy[] = [
  {
    cultureName: 'ar',
    isRTL: true,
    effectiveTimeZone: 'tz_05_arabic',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_1_comma_234_comma_567_period_89_minus',
    effectiveCurrencyCode: 'ars',
  },
  {
    cultureName: 'bg_bg',
    effectiveTimeZone: 'tz_35_fli',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'bgn',
  },
  {
    cultureName: 'cs_cz',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'czk',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'da_dk',
    effectiveTimeZone: 'tz_70_romance',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'csym_space_minus_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'dkk',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'de_de',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'el_gr',
    effectiveTimeZone: 'tz_40_gtb',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'en_au',
    effectiveTimeZone: 'tz_28_east_australia',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'aud',
  },
  {
    cultureName: 'en_gb',
    effectiveTimeZone: 'tz_37_gmt',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'gbp',
  },
  {
    cultureName: 'en_us',
    effectiveTimeZone: 'tz_66_pacific',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyAccountingFormat:
      'opar_csym_1_comma_234_comma_567_period_89_cpar',
    currencySign: 'accounting',
    effectiveCurrencyCode: 'usd',
  },
  {
    cultureName: 'es_es',
    effectiveTimeZone: 'tz_70_romance',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'es_mx',
    effectiveTimeZone: 'tz_24_central_mexico',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'mxn',
  },
  {
    cultureName: 'et_ee',
    effectiveTimeZone: 'tz_35_fli',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_period_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_period_89_space_csym',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'fa_ir',
    isRTL: true,
    effectiveTimeZone: 'tz_43_iran',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'irr',
  },
  {
    cultureName: 'fi_fi',
    effectiveTimeZone: 'tz_35_fli',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'fr_ca',
    effectiveTimeZone: 'tz_13_canada_central',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyAccountingFormat:
      'opar_1_space_234_space_567_comma_89_space_csym_cpar',
    currencySign: 'accounting',
    effectiveCurrencyCode: 'cad',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'fr_fr',
    effectiveTimeZone: 'tz_70_romance',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'he_il',
    isRTL: true,
    effectiveTimeZone: 'tz_44_israel',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_minus_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'ils',
  },
  {
    cultureName: 'hi_in',
    effectiveTimeZone: 'tz_42_india',
    effectiveCurrencyPositiveFormat: 'csym_12_comma_34_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_12_comma_34_comma_567_period_89',
    effectiveCurrencyCode: 'inr',
  },
  {
    cultureName: 'hr_hr',
    effectiveTimeZone: 'tz_21_central_european',
    effectiveCurrencyCode: 'hrk',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'hu_hu',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'huf',
  },
  {
    cultureName: 'hy_am',
    effectiveTimeZone: 'tz_15_caucasus',
    effectiveCurrencyPositiveFormat:
      'leading_1_comma_234_comma_567_period_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_comma_234_comma_567_period_89_space_csym',
    effectiveCurrencyCode: 'amd',
  },
  {
    cultureName: 'id_id',
    effectiveTimeZone: 'tz_76_se_asia',
    effectiveCurrencyPositiveFormat: 'csym_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'idr',
  },
  {
    cultureName: 'it_it',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'ja_jp',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveAddressFormat: 'ja_jp',
    effectiveTimeZone: 'tz_83_tokyo',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567',
    effectiveCurrencyNegativeFormat: 'minus_csym_1_comma_234_comma_567',
    effectiveCurrencyCode: 'jpy',
  },
  {
    cultureName: 'ko_kr',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveAddressFormat: 'ja_jp',
    effectiveTimeZone: 'tz_48_korea',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567',
    effectiveCurrencyNegativeFormat: 'minus_csym_1_comma_234_comma_567',
    effectiveCurrencyCode: 'krw',
  },
  {
    cultureName: 'lt_lt',
    effectiveTimeZone: 'tz_35_fli',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'lv_lv',
    effectiveTimeZone: 'tz_35_fli',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_space_234_space_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_space_234_space_567_comma_89',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'ms_my',
    effectiveTimeZone: 'tz_77_singapore',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyAccountingFormat:
      'opar_csym_1_comma_234_comma_567_period_89_cpar',
    effectiveCurrencyCode: 'myr',
    currencySign: 'accounting',
  },
  {
    cultureName: 'nb_no',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_space_234_space_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'csym_space_minus_1_space_234_space_567_comma_89',
    effectiveCurrencyCode: 'nok',
  },
  {
    cultureName: 'nl_nl',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'csym_space_minus_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'pl_pl',
    effectiveTimeZone: 'tz_21_central_european',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'pln',
  },
  {
    cultureName: 'pt_br',
    effectiveTimeZone: 'tz_30_east_south_america',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'brl',
  },
  {
    cultureName: 'pt_pt',
    effectiveTimeZone: 'tz_37_gmt',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'ro_ro',
    effectiveTimeZone: 'tz_40_gtb',
    effectiveCurrencyCode: 'ron',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'ru_ru',
    effectiveTimeZone: 'tz_71_russian',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'rub',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'sk_sk',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'sl_si',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyCode: 'eur',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'sr',
    effectiveTimeZone: 'tz_72_sa_eastern',
    effectiveCurrencyCode: 'rsd',
  },
  {
    cultureName: 'sv_se',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'sek',
    effectivePercentFormat: 'n_p',
  },
  {
    cultureName: 'th_th',
    effectiveTimeZone: 'tz_76_se_asia',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'thb',
  },
  {
    cultureName: 'tr_tr',
    effectiveTimeZone: 'tz_85_turkey',
    effectiveCurrencyCode: 'try',
    effectivePercentFormat: 'pn',
  },
  {
    cultureName: 'uk_ua',
    effectiveTimeZone: 'tz_35_fli',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_csym',
    effectiveCurrencyCode: 'uah',
  },
  {
    cultureName: 'vi_vn',
    effectiveTimeZone: 'tz_76_se_asia',
    effectiveCurrencyCode: 'vnd',
  },
  {
    cultureName: 'zh_cn',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveAddressFormat: 'zh_cn_tw',
    effectiveTimeZone: 'tz_25_china',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_minus_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'cny',
  },
  {
    cultureName: 'zh_tw',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveAddressFormat: 'zh_cn_tw',
    effectiveTimeZone: 'tz_81_taipei',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'twd',
  },
  // expand regions
  {
    cultureName: 'en_ca',
    effectiveTimeZone: 'tz_13_canada_central',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'cad',
  },
  {
    cultureName: 'en_ie',
    effectiveTimeZone: 'tz_37_gmt',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'en_ph',
    effectiveTimeZone: 'tz_77_singapore',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyAccountingFormat:
      'opar_csym_1_comma_234_comma_567_period_89_cpar',
    effectiveCurrencyCode: 'php',
    currencySign: 'accounting',
  },
  {
    cultureName: 'en_in',
    effectiveTimeZone: 'tz_42_india',
    effectiveCurrencyPositiveFormat:
      'csym_space_12_comma_34_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_space_minus_12_comma_34_comma_567_period_89',
    effectiveCurrencyCode: 'inr',
  },
  {
    cultureName: 'en_za',
    effectiveTimeZone: 'tz_78_south_africa',
    effectiveCurrencyPositiveFormat: 'csym_1_space_234_space_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_space_234_space_567_comma_89',
    effectiveCurrencyCode: 'zar',
  },
  {
    cultureName: 'en_nz',
    effectiveTimeZone: 'tz_61_new_zealand',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'nzd',
  },
  {
    cultureName: 'es_co',
    effectiveTimeZone: 'tz_73_sa_pacific',
    effectiveCurrencyPositiveFormat: 'csym_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'cop',
  },
  {
    cultureName: 'es_pr',
    effectiveTimeZone: 'tz_07_atlantic',
    effectiveCurrencyPositiveFormat: 'csym_1234_comma_567_period_89',
    effectiveCurrencyNegativeFormat: 'minus_csym_1234_comma_567_period_89',
    effectiveCurrencyCode: 'usd',
  },
  {
    cultureName: 'fr_be',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'nl_be',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'csym_space_minus_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'es_ar',
    effectiveTimeZone: 'tz_06_argentina',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'csym_minus_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'ars',
  },
  {
    cultureName: 'es_cr',
    effectiveTimeZone: 'tz_17_central_america',
    effectiveCurrencyPositiveFormat: 'csym_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'crc',
  },
  {
    cultureName: 'es_cl',
    effectiveTimeZone: 'tz_69_paraguay',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_period_234_period_567_comma_89',
    effectiveCurrencyCode: 'clp',
  },
  {
    cultureName: 'es_pe',
    effectiveTimeZone: 'tz_73_sa_pacific',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_space_minus_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'pen',
  },
  {
    cultureName: 'ar_ae',
    isRTL: true,
    effectiveTimeZone: 'tz_04_arabian',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_1_comma_234_comma_567_period_89_minus',
    effectiveCurrencyCode: 'aed',
  },
  {
    cultureName: 'ar_qa',
    isRTL: true,
    effectiveTimeZone: 'tz_03_arab',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_1_comma_234_comma_567_period_89_minus',
    effectiveCurrencyCode: 'qar',
  },
  {
    cultureName: 'ar_sa',
    isRTL: true,
    effectiveTimeZone: 'tz_03_arab',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_1_comma_234_comma_567_period_89_minus',
    effectiveCurrencyCode: 'sar',
  },
  {
    cultureName: 'de_at',
    effectiveTimeZone: 'tz_20_central_europe',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'de_ch',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_quote_234_quote_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_minus_1_quote_234_quote_567_period_89',
    effectiveCurrencyCode: 'chf',
  },
  {
    cultureName: 'de_lu',
    effectiveTimeZone: 'tz_20_central_europe',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'fr_lu',
    effectiveTimeZone: 'tz_20_central_europe',
    effectiveCurrencyPositiveFormat:
      'leading_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_space_234_space_567_comma_89_space_csym',
    effectiveCurrencyCode: 'eur',
  },
  {
    cultureName: 'fr_ch',
    effectiveTimeZone: 'tz_70_romance',
    effectiveCurrencyPositiveFormat:
      'leading_1_quote_234_quote_567_period_89_space_csym',
    effectiveCurrencyNegativeFormat:
      'minus_1_quote_234_quote_567_period_89_space_csym',
    effectiveCurrencyCode: 'chf',
  },
  {
    cultureName: 'it_ch',
    effectiveTimeZone: 'tz_93_west_europe',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_quote_234_quote_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_minus_1_quote_234_quote_567_period_89',
    effectiveCurrencyCode: 'chf',
  },
  {
    cultureName: 'zh_hk',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveAddressFormat: 'zh_cn_tw',
    effectiveTimeZone: 'tz_25_china',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyAccountingFormat:
      'opar_csym_1_comma_234_comma_567_period_89_cpar',
    effectiveCurrencyCode: 'hkd',
    currencySign: 'accounting',
  },
  {
    cultureName: 'zh_sg',
    effectiveNameFormat: 'last_first_cjk',
    effectiveInitialFormat: 'last2_cjk',
    effectiveAddressFormat: 'zh_cn_tw',
    effectiveTimeZone: 'tz_77_singapore',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyAccountingFormat:
      'opar_csym_1_comma_234_comma_567_period_89_cpar',
    effectiveCurrencyCode: 'sgd',
    currencySign: 'accounting',
  },
  {
    cultureName: 'sw_ke',
    effectiveTimeZone: 'tz_27_east_africa',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'kes',
  },
  {
    cultureName: 'ur_pk',
    isRTL: true,
    effectiveTimeZone: 'tz_68_pakistan',
    effectiveCurrencyPositiveFormat: 'csym_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'csym_1_comma_234_comma_567_period_89_minus',
    effectiveCurrencyCode: 'pkr',
  },
  {
    cultureName: 'yo_ng',
    effectiveTimeZone: 'tz_92_west_central_africa',
    effectiveCurrencyPositiveFormat:
      'csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyNegativeFormat:
      'minus_csym_space_1_comma_234_comma_567_period_89',
    effectiveCurrencyCode: 'ngn',
  },
]

const baseLocalePolicyV2: LocalePolicy = {
  effectiveNameFormat: 'full',
  effectiveInitialFormat: 'first1last1',
  effectiveAddressFormat: 'en_us',
  effectiveCalendarType: 'gregorian',
  effectiveDateFormat: 'default',
  effectiveTimeZone: 'tz_37_gmt',
  effectiveCurrencyPositiveFormat:
    'leading_1_period_234_period_567_comma_89_space_csym',
  effectiveCurrencyNegativeFormat:
    'minus_1_period_234_period_567_comma_89_space_csym',
  effectiveCurrencyCode: 'usd',
  useLongCurrencyFormat: false,
  effectivePercentFormat: 'np',
}

// return base locale policy of specified language defined by server side
function _getBaseLocalePolicy(
  lang: string,
  predefinedPolicies: LocalePolicy[],
  baseLocalePolicies: LocalePolicy
) {
  let k
  const policy = { cultureName: lang }
  for (k in baseLocalePolicies) {
    policy[k] = baseLocalePolicies[k]
  }
  const predefined = predefinedPolicies.filter(function (obj: LocalePolicy) {
    return obj.cultureName === lang
  })[0]
  for (k in predefined) {
    policy[k] = predefined[k]
  }
  return policy
}

function _getSupportedCultureName(
  languageOrRegion: string | undefined,
  regionOnly: boolean,
  languageOnly = false
): string {
  const region = languageOrRegion
    ? languageOrRegion.replace('-', '_').toLowerCase()
    : 'en'
  if (
    _languageToLocale[region] ||
    predefinedPolicyV2.find(function (obj) {
      return obj.cultureName === region
    })
  ) {
    if (languageOnly && !['zh_cn', 'zh_tw'].includes(region)) {
      return region.substring(0, 2)
    }
    return regionOnly ? _languageToLocale[region] || region : region // supported language or region
  }
  if (
    region.length > 2 &&
    region[2] === '_' &&
    _languageToLocale[region.substring(0, 2)]
  ) {
    const fallback = region.substring(0, 2)
    return regionOnly ? _languageToLocale[fallback] : fallback // fallback language
  }
  return regionOnly ? 'en_us' : 'en'
}

function _getLocalePolicy(
  language: SupportedGroup1LocaleName | string | undefined
): LocalePolicy {
  const lang = _getSupportedCultureName(language, false)
  return _getBaseLocalePolicy(lang, predefinedPolicy, baseLocalePolicy)
}

function _getRegionLocalePolicy(
  languageOrRegion: SupportedLocaleName | string | null
): LocalePolicy {
  const region = _getSupportedCultureName(languageOrRegion as string, true)
  return _getBaseLocalePolicy(region, predefinedPolicyV2, baseLocalePolicyV2)
}

function getSupportedCultureName(
  languageOrRegion: string | undefined,
  regionOnly: boolean,
  languageOnly = false
): string {
  try {
    const t0 = getPerformance()
    const result = _getSupportedCultureName(
      languageOrRegion,
      regionOnly,
      languageOnly
    )
    setTelemetry(getSupportedCultureName.name, t0)
    return result
  } catch (ex) {
    reportException(getSupportedCultureName.name, ex)
    return 'en'
  }
}
function getLocalePolicy(
  language: SupportedGroup1LocaleName | string | undefined
): LocalePolicy {
  try {
    const t0 = getPerformance()
    const result = _getLocalePolicy(language)
    setTelemetry(getLocalePolicy.name, t0)
    return result
  } catch (ex) {
    reportException(getLocalePolicy.name, ex)
    return _getLocalePolicy('en')
  }
}
function getRegionLocalePolicy(
  languageOrRegion: SupportedLocaleName | string | null
): LocalePolicy {
  try {
    const t0 = getPerformance()
    const result = _getRegionLocalePolicy(languageOrRegion)
    setTelemetry(getRegionLocalePolicy.name, t0)
    return result
  } catch (ex) {
    reportException(getRegionLocalePolicy.name, ex)
    return _getRegionLocalePolicy('en')
  }
}

function toLowerCaseLocale(locale: SupportedLocaleName): SupportedLocaleName {
  if (!locale) {
    return 'en_us'
  }
  return locale.toLowerCase().replace('-', '_') as SupportedLocaleName
}

function toUpperCaseLocale(locale: SupportedLocaleName): string {
  if (!locale) {
    return 'en_US'
  }
  if (locale.length < 3) {
    return locale.toLowerCase()
  }
  return (
    locale.substring(0, 2) + locale.slice(2).toUpperCase().replace('-', '_')
  )
}

function languageToLocale(language: string): SupportedLocaleName {
  return _getSupportedCultureName(language, true) as SupportedLocaleName
}

function isRTL(language: string): boolean {
  if (
    language !== undefined &&
    language.toLowerCase().replace('-', '_') === 'en_rt'
  ) {
    return true
  }
  return !!_getRegionLocalePolicy(language).isRTL
}

export {
  toLowerCaseLocale,
  toUpperCaseLocale,
  languageToLocale,
  getLocalePolicy,
  getRegionLocalePolicy,
  _getRegionLocalePolicy,
  getSupportedCultureName,
  _languageToLocale,
  predefinedPolicyV2,
  isRTL,
}
