import * as React from 'react'
import { FooterMenuTriggerButton } from './FooterMenuTriggerButton'
import { Locale, TranslateFunction } from '@ds/base'
import { TranslationKeys } from '@ds/comp-private'
import { getDisplayName } from './localeDisplay'
import { LanguageSelectorReveal } from './LanguageSelectorReveal'

interface FooterLanguageSelectorPhoneProps {
  locales: Locale[]
  currentLocale: Locale
  translate: TranslateFunction<TranslationKeys>
  onLocaleSelected: (locale: Locale) => void
  onMenuOpened?: () => void
  menuButtonDataQa: string
}

export const FooterLanguageSelectorPhone: React.FunctionComponent<FooterLanguageSelectorPhoneProps> =
  (props) => {
    const [isShowingMenu, setIsShowingMenu] = React.useState(false)

    return (
      <div>
        <FooterMenuTriggerButton
          text={getDisplayName(props.currentLocale)}
          data-qa={props.menuButtonDataQa}
          onClick={() => {
            if (!isShowingMenu && props.onMenuOpened) {
              props.onMenuOpened()
            }
            setIsShowingMenu(true)
          }}
        />
        <LanguageSelectorReveal
          locales={props.locales}
          currentLocale={props.currentLocale}
          visible={isShowingMenu}
          onLocaleSelected={(locale: Locale) => {
            setIsShowingMenu(false)
            props.onLocaleSelected(locale)
          }}
          onCancel={() => setIsShowingMenu(false)}
        />
      </div>
    )
  }
