import colors from '../../../../theming/docusign-themes/olive/colors'
import type { HeaderTitleProps } from '..'

export default (config: { props: Partial<HeaderTitleProps> }) => {
  const { props } = config
  return {
    borderColor: props.precededByHeaderAction
      ? colors.DocuSignBlueLight
      : undefined,
    color: colors.periwinkleLight,
  } as const
}
