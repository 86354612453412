import * as React from 'react'
import type { DocuSignThemeName } from '../../themes'
import { ThemeContext, ThemeContextValue } from './context'

export interface ThemeProviderProps {
  /**
   * The content to be displayed and to receive the styles and icons provided by
   * this component (for those eligible components).
   */
  children: React.ReactNode
  /**
   * Which DocuSign theme to use. Currently supports Olive and Ink themes.
   */
  docuSignTheme: DocuSignThemeName | ThemeContextValue
}

/**
 * Use of the library requires that you pass your application content as a child
 * to the top-level `<ThemeProvider>` component with a DocuSign theme name.
 */
export function ThemeProvider({ children, docuSignTheme }: ThemeProviderProps) {
  const name =
    typeof docuSignTheme === 'string' ? docuSignTheme : docuSignTheme.name

  const theme = React.useMemo(
    () =>
      typeof docuSignTheme === 'string'
        ? ({} as ThemeContextValue)
        : docuSignTheme,
    [docuSignTheme]
  )

  const contextValue = React.useMemo(() => ({ ...theme, name }), [name, theme])

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  )
}
