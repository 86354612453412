/* globals document */
import React, { cloneElement, isValidElement, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Global, css } from '@emotion/react'

import { globalIds, zIndexes } from '../../../variables'
import { breakpoints } from '../../../utilities'
import { useIsInk } from '../../../theming'
import { DATA_ARIA_ACTIVE } from '../../../utilities/portalUtils'
import type { ToastMessageProps } from '..'

import type { ConditionalReactElementChildOrArray } from '../../../types'

const toastMsgContainerClassOlive = 'olv-toastMsg-portal'
const toastMsgContainerClassInk = 'ink-toastMsg-portal'

const commonContainerStyles = `
  min-width: auto;
  padding: 0 8px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: ${zIndexes.ToastMessage};
`

const toastMsgContainerOliveStyles = `
.olv-toastMsg-portal {
  ${commonContainerStyles}
  left: 0;
  margin: 80px auto 0 auto;
  max-width: 508px;
  top: 0;
  bottom: auto;
}
`

const toastMsgContainerInkStyles = `
.ink-toastMsg-portal {
  ${commonContainerStyles}
  bottom: 40px;
  left: 40px;
  margin: 0;
  max-width: 440px;
  top: auto;

  ${breakpoints.lessThan(breakpoints.Sizes.Large)} {
    margin: 0 auto;
  }

  ${breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)} {
    left: 0;
    padding: 0 40px;
  }
}
`

export interface ToastMessagePortalProps {
  /**
   * The `ToastMessage.Portal` component expects a single `ToastMessage` as a child,
   * which it will mount to a container element (managing accessibility concerns
   * as ToastMessages are displayed).
   */
  children: ConditionalReactElementChildOrArray<ToastMessageProps>
  /**
   * The id of the container element (i.e. portal target) that the ToastMessages.Portal
   * component should use for mounting its child Toast Message.
   *
   * If an element with the provided id cannot be found, one will be created and appended
   * to document.body for this purpose.
   */
  targetId?: string
}

export function ToastMessagePortal({
  children,
  targetId = globalIds.DsUiToastMessages,
}: ToastMessagePortalProps) {
  const isInk = useIsInk()

  const [toastMessagesContainer, setToastMessageContainer] =
    useState<HTMLElement>()

  useEffect(() => {
    const EXISTING_TOASTMESSAGE_CONTAINER = document.getElementById(targetId)

    let portalTarget = EXISTING_TOASTMESSAGE_CONTAINER
    if (!portalTarget) {
      portalTarget = document.createElement('div')
      portalTarget.setAttribute('aria-atomic', 'false')
      portalTarget.setAttribute('id', targetId)
      portalTarget.setAttribute('role', 'status')

      document.body.appendChild(portalTarget)
    }

    portalTarget.setAttribute(
      'class',
      isInk ? toastMsgContainerClassInk : toastMsgContainerClassOlive,
    )
    portalTarget.setAttribute(DATA_ARIA_ACTIVE, 'true')

    setToastMessageContainer(portalTarget)

    return () => {
      if (!EXISTING_TOASTMESSAGE_CONTAINER && portalTarget) {
        document.body.removeChild(portalTarget)
      }
    }
  }, [isInk, targetId])

  return toastMessagesContainer ? (
    <>
      <Global
        styles={css(
          isInk ? toastMsgContainerInkStyles : toastMsgContainerOliveStyles,
        )}
      />
      {ReactDOM.createPortal(
        React.Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement<ToastMessageProps>(child, {
              toastMessagePortalChild: true,
            })
          }
          return null
        }),
        toastMessagesContainer,
      )}
    </>
  ) : null
}

ToastMessagePortal.propTypes = {
  children: PropTypes.node.isRequired,
  targetId: PropTypes.string,
}

ToastMessagePortal.displayName = 'ToastMessage.Portal'
