import {
  HttpRequest,
  HttpResponse,
  HttpError,
  ExtendedHttpRequest,
  EMPTY_HTTP_HEADERS,
} from './types'

import { HttpTimeout } from './RequestSender'

const HTTP_CANCEL = 499 // HTTP 499 = Client Closed Request

export const notifyCancel = (request: ExtendedHttpRequest) => {
  notifyResponse({
    request,
    status: HTTP_CANCEL,
    is2XX: false,
    isOK: false,
    headers: EMPTY_HTTP_HEADERS,
    contentType: 'N/A',
    elapsedMilliseconds: new Date().getTime() - request.beginTime.getTime(),
  })
}

export const notifyRequest = (request: HttpRequest, attempt?: number) => {
  request.listener?.onRequest?.(scrubRequest(request), attempt)
}

export const notifyResponse = (response: HttpResponse) => {
  response.request.listener?.onResponse?.(scrubResponse(response))
}

export const notifyError = (error: HttpError | HttpTimeout) => {
  if (error.request.listener && error.request.listener.onError) {
    const scrubbedError = scrubError(
      error instanceof HttpTimeout
        ? new HttpError(0, error.message, error.request, error)
        : error
    )
    error.request.listener.onError(scrubbedError)
  }
}

export const scrubRequest = (request: HttpRequest) => {
  // remove bearer token
  if (request.bearerToken) {
    const scrubbedRequest = {
      ...request,
      bearerToken: 'SCRUBBED',
      listener: undefined,
      throwErrorWhen: undefined,
    }
    if (request.headers) {
      scrubbedRequest.headers = {
        ...request.headers,
        Authorization: 'SCRUBBED',
      }
    }
    return scrubbedRequest
  }
  return request
}

export const scrubResponse = (response: HttpResponse) => {
  return { ...response, request: scrubRequest(response.request) }
}

export const scrubError = (error: HttpError) => {
  const scrubbedError = new HttpError(
    error.status,
    error.description,
    scrubRequest(error.request),
    error.error
  )
  scrubbedError.willRetry = error.willRetry
  scrubbedError.attempt = error.attempt
  return scrubbedError
}
