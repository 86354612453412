import { useContext } from 'react'
import { CobrandingContext } from '../components/Cobranding'
import type { CobrandingTokens } from '../types'

export interface CobrandingThemeConfig<P> {
  props: P | Record<string, never>
  tokens: CobrandingTokens
}

export function useCobrandingStyles<P, S>(
  styles: ((config: CobrandingThemeConfig<P>) => S) | undefined,
  props?: P,
) {
  const { tokens } = useContext(CobrandingContext)
  if (!Object.keys(tokens).length || !styles) return {}
  return styles({ props: props || {}, tokens })
}
