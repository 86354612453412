import { breakpoints } from '../../../../utilities'

export default () =>
  ({
    default: {
      content: {
        padding: '8px 24px',
        textTransform: 'none',
        minHeight: '40px',

        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          minHeight: '48px',
        },
      },

      startElement: {
        marginInlineEnd: '16px',
      },

      endElement: {
        '& svg': {
          height: '20px !important',
          width: '20px !important',
        },
      },

      contentWithEndElement: {
        paddingBlock: '8px',
        paddingInline: '24px 12px',
      },

      textWithoutIcon: {
        marginInlineStart: '40px',
      },

      badge: {
        top: '-12px',
      },
    },

    selectedOption: {
      iconWrap: {
        marginInlineStart: '-12px',
        marginInlineEnd: '12px',
      },
    },
  }) as const
