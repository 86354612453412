import { KazMonConfig } from './KazMonConfig'
import { STANDARD_LOG_URLS } from '../Config/componentsConfig'

export class Initialization {
  public static getDefaultConfig(): KazMonConfig {
    const config = {} as KazMonConfig

    config.endpointUrl = STANDARD_LOG_URLS.TEST
    config.disableTelemetry = false
    config.disableFlushOnBeforeUnload = false
    config.emitLineDelimitedJson = false
    config.enableDebug = false
    config.maxBatchSizeBytes = 102400 // 100kb
    config.maxBatchIntervalMs = 15000

    return config
  }
}
