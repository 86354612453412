import { TranslateFunction, Locale } from '@ds/base'
import { useWarehouseTranslate } from '@ds/react-utils'
import translationsWarehouse from './translationsWarehouse'
import { TranslationKeys } from './types'

/*
    This react hook returns a TranslateFunction for doing
    text language translation.  See TranslateFunction for
    details on the function.

    By default the hook uses the HTML lang attribute to determine the locale.
    You can override this by providing a locale parameter.

*/

function useTranslate(locale?: Locale): TranslateFunction<TranslationKeys> {
  return useWarehouseTranslate<TranslationKeys>(translationsWarehouse, locale)
}

export default useTranslate
