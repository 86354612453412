import * as React from 'react'
import { dataProps } from '@ds/react-utils'
import PropTypes from 'prop-types'
import { MenuTriangleDownSmall, MenuTriangleDownOlive } from '@ds/icons'
import { IconWithTheme } from '../IconWithTheme'
import { useThemeStyles } from '../../../theming'
import styles from './styles'

export interface CaretProps {
  /**
   * Disables the Caret.
   */
  disabled?: boolean
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function Caret({ disabled = false, ...restProps }: CaretProps) {
  const sx = useThemeStyles(styles)

  return (
    <span
      {...dataProps(restProps)}
      css={[sx.default.caret, disabled && sx.disabled.caret]}
    >
      <IconWithTheme
        inkIcon={<MenuTriangleDownSmall />}
        oliveIcon={<MenuTriangleDownOlive />}
      />
    </span>
  )
}

Caret.propTypes = {
  'data-.*': PropTypes.string,
  disabled: PropTypes.bool,
}
