// Region to locale
export const byRegion = {
  AU: 'en_au',
  BR: 'pt_br',
  CA: 'en_ca',
  DE: 'de',
  ES: 'es',
  FR: 'fr',
  GB: 'en_gb',
  IT: 'it',
  JP: 'ja',
  KR: 'ko',
  MY: 'en_my',
  PH: 'en_ph',
  PT: 'pt_br',
  SG: 'en_sg',
  TW: 'en_tw',
  US: 'en_us',

  // Spanish-speaking countries in Latin America map to 'es_mx' until
  // we have URLs for those specific countries
  AR: 'es_mx',
  BO: 'es_mx',
  CL: 'es_mx',
  CO: 'es_mx',
  CR: 'es_mx',
  CU: 'es_mx',
  DO: 'es_mx',
  EC: 'es_mx',
  SV: 'es_mx',
  GT: 'es_mx',
  HN: 'es_mx',
  MX: 'es_mx',
  NI: 'es_mx',
  PA: 'es_mx',
  PY: 'es_mx',
  PE: 'es_mx',
  UY: 'es_mx',
  VE: 'es_mx',
}
