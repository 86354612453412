import * as React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'
import { RequireExactlyOne } from 'type-fest'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import type { ButtonForwardRef, EventListenerProps } from '../../../types'
import { Avatar } from '../../Avatar'
import { useHeaderContext } from '../HeaderContext'
import styles from './styles'

export interface HeaderAvatarProps
  extends EventListenerProps<HTMLButtonElement> {
  /**
   * The text to present to assistive devices in order to identify the Header.Avatar.
   */
  accessibilityText?: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * A React ref to assign to the HTML node representing the Header.Avatar element.
   */
  forwardedRef?: ButtonForwardRef
  /**
   * If URL for an image is supplied, it will take the highest priority and be displayed.
   */
  image?: string
  /**
   * Initials will take first two letter of the initials string supplied.
   */
  initials?: string
  /**
   * Role of the Header.Avatar component
   * (the label does not display, required for accessibility).
   *
   * @deprecated Use `accessibilityText` instead.
   */
  label?: string // for accessibility only...not displayed
}

export function HeaderAvatar({
  accessibilityText,
  'data-qa': dataQa,
  forwardedRef,
  image = '',
  initials = '',
  label,
  onClick,
  ...restProps
}: RequireExactlyOne<HeaderAvatarProps, 'accessibilityText' | 'label'>) {
  const headerContext = useHeaderContext()

  const sx = useThemeStyles(styles, {
    ...headerContext,
  })

  return (
    <div css={sx.wrap}>
      <button
        data-qa={dataQa}
        {...dataProps(restProps)}
        {...onProps(restProps)}
        css={sx.button}
        onClick={onClick}
        ref={forwardedRef}
        type="button"
      >
        <Avatar
          data-qa={dataQa && `${dataQa}-avatar`}
          image={image}
          initials={initials}
          size="medium"
          hideAriaLabel
        />

        <span data-qa={dataQa && `${dataQa}-label`} css={sx.label}>
          {accessibilityText || label}
        </span>
      </button>
    </div>
  )
}

HeaderAvatar.propTypes = {
  accessibilityText: PropTypes.string,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  image: PropTypes.string,
  initials: PropTypes.string,
  label: PropTypes.string,
  'on[A-Z].*': PropTypes.func,
  onClick: PropTypes.func,
}

HeaderAvatar.displayName = 'Header.Avatar'
