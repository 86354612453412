import { CreateButtonCss } from '../../../styles'
import type { CobrandingTokens } from '../../../theming/types'

export default (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config

  return {
    button: {
      ...(tokens.buttonMainBackgroundColor && {
        main: {
          ...CreateButtonCss({
            // @ts-expect-error
            defaultBackgroundColor: tokens.buttonMainBackgroundColor,
            // @ts-expect-error
            defaultTextColor: tokens.buttonMainTextColor,
            // @ts-expect-error
            hoverBackgroundColor: tokens.buttonMainBackgroundHoverColor,
            // @ts-expect-error
            activeBackgroundColor: tokens.buttonMainBackgroundActiveColor,
            disabledOpacity: 0.4,
          }),
        },
      }),
      ...(tokens.buttonPrimaryBackgroundColor && {
        primary: {
          ...CreateButtonCss({
            // @ts-expect-error
            defaultBackgroundColor: tokens.buttonPrimaryBackgroundColor,
            // @ts-expect-error
            defaultTextColor: tokens.buttonPrimaryTextColor,
            // @ts-expect-error
            hoverBackgroundColor: tokens.buttonPrimaryBackgroundHoverColor,
            // @ts-expect-error
            activeBackgroundColor: tokens.buttonPrimaryBackgroundActiveColor,
            disabledOpacity: 0.4,
          }),
        },
      }),
    },
  } as const
}
