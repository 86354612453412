import {
  logError as logConsoleError,
  logWarnOnce,
  resetInfo,
  resetWarn,
  ErrorData,
  ErrorLogger,
  createErrorLogger,
  createErrorLoggerWithKazmon,
} from '../Logger'
import { KazMonErrorData } from '../Kazmon'
import { componentsConfig } from '../Config'
import { ApplicationVariables } from '../TelemetryRecorder'

/*
    These functions can be used by components to log errors, warnings or info.

    Warnings and Info are logged to console.

    Errors are logged to kazmon IF the app has set properities in componentsConfig
    for kazmon logging. Otherwise errors are logged to console.

    @see componentsConfig
*/

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

const KAZMON_INSTRUMENTATION_KEY = '7ddc99fc-d1b6-40c5-9b3b-80686b0d29aa'
const KAZMON_APP = 'ds-components'
const DEFAULT_APP_ID = 'unknown'
const DEFAULT_APP_VERSION = 'unknown'
const MAX_SEND_WAIT_SECONDS = 5

let errorLogger: ErrorLogger | undefined

const previousErrors: Set<any> = new Set()

export type EventData = {
  [key: string]: string | number | EventData
}

/**
 * Log an error
 *
 * @param error
 * @param componentName
 */
export const logComponentError = (
  error: ErrorData,
  componentName?: string,
  componentVersion?: string
) => {
  const title = componentName ? `${componentName} error` : 'Error'
  const version = componentVersion ? ` (version ${componentVersion})` : ''
  if (isInitialized()) {
    errorLogger?.logError(error, componentName, componentVersion)
  }
  logConsoleError(title + version, error)
}

/**
 * Log an error only once. Error message argument (and component name if provided) used to determine if previously logged.
 *
 * @param error
 * @param componentName
 */
export const logComponentErrorOnce = (
  error: ErrorData,
  componentName?: string,
  componentVersion?: string
) => {
  if (error) {
    const errorKey =
      error.message +
      (componentName ? `@@@${componentName}` : '') +
      (componentVersion ? `@@@${componentVersion}` : '')
    if (!previousErrors.has(errorKey)) {
      logComponentError(error, componentName, componentVersion)
      previousErrors.add(errorKey)
    }
  }
}

/**
 * Log an http error
 *
 * @param httpError
 * @param componentName
 */
export const logComponentHttpError = (
  httpError: {
    request: { method: string; url: string }
    description: string
    status: number
  },
  componentName?: string,
  componentVersion?: string
) => {
  const request = httpError.request || {}
  const error: KazMonErrorData = {
    message: httpError.description,
    meta: {
      status: httpError.status,
      method: request.method,
      url: request.url,
    },
  }
  logComponentError(error, componentName, componentVersion)
}

/**
 * Log a non-error event to kazmon
 *
 * @param eventData
 * @param componentName
 */
export const logComponentEvent = (
  eventData: EventData,
  componentName?: string,
  componentVersion?: string
) => {
  if (isInitialized()) {
    errorLogger?.kazmon?.emitRequestEvent({
      ...appMetaData(),
      ...eventData,
      componentName: componentName || '?',
      componentVersion: componentVersion || '?',
    })
  }
}

/**
 * Log a deprecation warning
 *
 * @param message
 * @param componentName
 */
export const logComponentDeprecation = (
  message: string,
  componentName?: string
) => {
  if (!componentsConfig.isSuppressingDeprecationWarnings()) {
    const componentQualifier = componentName
      ? ` (component ${componentName})`
      : ''
    logWarnOnce(`Deprecation Warning${componentQualifier}: ${message}`)
  }
}

/**
 * Reset the logger (primarily for testing purposes)
 */
export const resetComponentsLogging = () => {
  resetWarn()
  resetInfo()
  errorLogger = undefined
  previousErrors.clear()
}

export const getComponentsKazMon = () => {
  initializeKazmonErrorLogger()
  return errorLogger?.kazmon
}

function isInitialized(): boolean {
  return initializeKazmonErrorLogger()
}

function initializeKazmonErrorLogger(): boolean {
  /*
        This lazy initializer also logs warning regarding WHY it cannot initialize the logger
        or what might be missing when logging
    */
  if (!errorLogger) {
    const endpointUrl = componentsConfig.getLoggingUrl()
    const preConfiguredKazMon = componentsConfig.getComponentsKazMon()
    if (preConfiguredKazMon) {
      errorLogger = createErrorLoggerWithKazmon(preConfiguredKazMon)
      return true
    } else if (endpointUrl) {
      errorLogger = createErrorLogger(
        {
          endpointUrl,
          instrumentationKey: KAZMON_INSTRUMENTATION_KEY,
          application: KAZMON_APP,
          environment: componentsConfig.getLoggingEnvironment(),
          maxBatchIntervalMs: MAX_SEND_WAIT_SECONDS * 1000,
          applicationVariables: getApplicationVariables,
        },
        '',
        appMetaData()
      )
      const appId = componentsConfig.getAppDescription().appId
      if (!appId) {
        logWarnOnce(
          `WARNING!!! Logging is configured but no appId is configured. You should use componentsConfig.setAppDescription() to set the app ID and version. Meanwhile "${DEFAULT_APP_ID}" will be used as the appId.`
        )
      }
      return true
    }
    logWarnOnce(
      'WARNING!!! components logging not configured. You should use componentsConfig.setLoggingUrl() to set the URL OR provide a KazMon instance via componentsConfig.setComponentKazMon(). Meanwhile logging will be sent to console.'
    )
    return false
  }
  return true
}

function appMetaData() {
  return {
    appId: componentsConfig.getAppDescription().appId || DEFAULT_APP_ID,
    appVersion:
      componentsConfig.getAppDescription().appVersion || DEFAULT_APP_VERSION,
  }
}

/** Gets AppDescription converted into ApplicationVariables shape */
const getApplicationVariables = (): Partial<ApplicationVariables> => {
  const { appId, appVersion, ...commonApplicationVariables } =
    componentsConfig.getAppDescription()

  return {
    Application: appId,
    ApplicationVersion: appVersion,
    ...commonApplicationVariables,
  }
}
