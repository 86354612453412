/*

    Creates a string representation of the object which can be used for comparison or subsequent hash creation.
    The object will be deeply recursed to find all objects.
    Circular references are handled automatically (an object will not be re-visited once it has been handled).
    Thread-safe (designed to be a singleton)
    NOTE: This should only be used for data objects (e.g., something which can be translated to/from JSON without loss)
    NOTE: The output is potentially long. You should consider using a hash algorithm to shorten it for storage.

*/

export interface ObjectToStringCanonicalizerOptions {
  // NOTE: Undefined properties are skipped.
  treatNullAsUndefined?: boolean // default is false (NULL properties will be written).
  treatEmptyStringsAsUndefined?: boolean // default is false (empty strings will be written)
  ignoredProperties?: Set<string> // to ignore certain properties, include a list of property names to ignore
}

/* eslint-disable @typescript-eslint/no-explicit-any */

class ObjectToStringCanonicalizer {
  public toCanonicalString(
    object: any,
    options: ObjectToStringCanonicalizerOptions = {}
  ) {
    const allProps: any[] = []
    const visitedObjects: any[] = []
    this.pushObject(object, allProps, options ?? {}, 0, visitedObjects)
    return allProps.join('')
  }

  public toCanonicalEscapedString(
    object: any,
    options: ObjectToStringCanonicalizerOptions = {}
  ) {
    return encodeURIComponent(this.toCanonicalString(object, options))
  }

  private pushObject(
    obj: any,
    allProps: any[],
    options: ObjectToStringCanonicalizerOptions,
    level: number,
    visitedObjects: any[]
  ) {
    if (typeof obj === 'object' && obj !== null) {
      const visitedReference = this.getVisitedReference(obj, visitedObjects)
      if (visitedReference) {
        allProps[allProps.length] = visitedReference
      } else {
        visitedObjects.push(obj)
        let props: string[] = []
        Object.keys(obj).forEach((prop) => props.push(prop))
        props = props.sort()
        props.forEach((propName) => {
          const childObj = obj[propName]
          if (this.shouldPush(childObj, options, propName)) {
            allProps.push('\n')
            allProps.push('.'.repeat(level))
            allProps.push(propName)
            allProps.push(':')
            this.pushObject(
              childObj,
              allProps,
              options,
              level + 1,
              visitedObjects
            )
          }
        })
      }
    } else {
      allProps.push(
        obj === null || obj === undefined
          ? 'null'
          : obj === ''
          ? 'EMPTY_STRING'
          : obj
      )
    }
  }

  private shouldPush(
    value: any,
    options: ObjectToStringCanonicalizerOptions,
    propName: string
  ) {
    if (
      propName &&
      options.ignoredProperties &&
      options.ignoredProperties.has(propName)
    ) {
      return false
    }
    if (typeof value === 'function' || value === undefined) {
      return false
    }
    if (options.treatNullAsUndefined && value === null) {
      return false
    }
    if (options.treatEmptyStringsAsUndefined && value === '') {
      return false
    }
    return true
  }

  private getVisitedReference(object: any, visitedObjects: any[]) {
    for (let i = 0; i < visitedObjects.length; i++) {
      if (visitedObjects[i] === object) {
        return '@REF' + i
      }
    }
    return null
  }
}

export default ObjectToStringCanonicalizer
