// Only polyfill if Proxy is not defined. Either the native environment will
// provide or apps will provide a polyfill themselves.
// This is not a problem with ES modules as long as apps perform tree-shaking as
// part of their bundling stategy. However, teams are likely to still run into
// this locally.
if (typeof Proxy === 'undefined') {
  // @ts-expect-error
  self.Proxy = class ProxyPolyfill {
    constructor() {
      // noop
      // This is required because Framer Motion under-the-hood
      // sets a `motion` variable using `new Proxy()` which instantly
      // gets called when we load the package even though we never
      // call it directly. We can shim with the very simple "polyfill"
      // since the Proxy code is never in our code path.
    }
  }
}

export {}
