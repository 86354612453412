import {
  inkAvatarTokens,
  inkBackgroundTokens,
  inkBadgeTokens,
  inkBarTokens,
  inkBorderTokens,
  inkBreakpointTokens,
  inkCTATokens,
  inkElevationTokens,
  inkFocusTokens,
  inkFontTokens,
  inkFormTokens,
  inkIconTokens,
  inkItemTokens,
  inkMessageTokens,
  inkOpacityTokens,
  inkSpacingTokens,
  inkStatusTokens,
  inkTagTokens,
  inkTokens,
} from '@ds/tokens'

export default {
  ...inkAvatarTokens,
  ...inkBackgroundTokens,
  ...inkBadgeTokens,
  ...inkBarTokens,
  ...inkBorderTokens,
  ...inkBreakpointTokens,
  ...inkCTATokens,
  ...inkElevationTokens,
  ...inkFocusTokens,
  ...inkFontTokens,
  ...inkFormTokens,
  ...inkIconTokens,
  ...inkItemTokens,
  ...inkMessageTokens,
  ...inkOpacityTokens,
  ...inkSpacingTokens,
  ...inkStatusTokens,
  ...inkTagTokens,
  dark: inkTokens.dark,
}
