import { CreateFocusCss } from '../../../../styles'
import colors from '../../../../theming/docusign-themes/olive/colors'

export default () => {
  return {
    wrap: {
      height: '52px',
    },

    button: {
      color: colors.white,

      '&:hover, &:active, &[aria-expanded="true"]': {
        backgroundColor: colors.DocuSignBlueLight,
      },

      ...CreateFocusCss({
        outlineColor: colors.white,
      }),
    },
  } as const
}
