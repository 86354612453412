import type { Tokens } from '../../../theming/types'
import type { ScrimProps } from '..'

export default (config: {
  props: Pick<ScrimProps, 'light'>
  tokens: Tokens
}) => {
  const { props, tokens } = config

  return {
    scrim: {
      backgroundColor: tokens.bgColorScrim,
      bottom: 0,
      display: 'block',
      insetInlineEnd: 0,
      insetInlineStart: 0,
      position: 'fixed',
      top: 0,
    },

    motionVariants: {
      enter: {
        opacity: [0, props.light ? 0.75 : tokens.scrim.opacityLevel],
        transition: {
          ease: 'harmonize',
          duration: 0.4,
        },
      },

      exit: {
        opacity: [props.light ? 0.75 : tokens.scrim.opacityLevel, 0],
        transition: {
          ease: 'harmonize',
          duration: 0.35,
        },
      },
    },
  } as const
}
