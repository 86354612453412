import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'
import styles from './styles'

export interface HeaderNavProps {
  /**
   * The normal usage pattern is:
   *
   * ```
   * <Header.Nav>
   *   <Header.ActionItem />
   *   <Header.ActionItem />
   *   // any number of Header.ActionItem components
   * </Header.Nav>
   * ```
   *
   * If you are using a routing library you can wrap
   * `Header.ActionItem` with a routing component from that library,
   * without passing `href` or `onClick` to `Header.ActionItem`.
   *
   * e.g. using `react-router`:
   *
   * ```
   * import { Link } from 'react-router-dom'
   *
   * <Header.Nav>
   *   <Link to={`${matchUrl}/documents`}> <Header.ActionItem text="Documents" /> </Link>
   *   <Link to={`${matchUrl}/tasks`}> <Header.ActionItem text="Tasks" /> </Link>
   * </Header.Nav>
   * ```
   */
  children: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function HeaderNav({
  children,
  'data-qa': dataQa,
  ...restProps
}: HeaderNavProps) {
  const sx = useThemeStyles(styles)

  const headerNavItems = React.Children.map(children, (child, idx) => (
    <li data-qa={dataQa && `${dataQa}-item-${idx}`}>{child}</li>
  ))

  return (
    <ul {...dataProps(restProps)} css={sx.default} data-qa={dataQa}>
      {headerNavItems}
    </ul>
  )
}

HeaderNav.propTypes = {
  children: PropTypes.node.isRequired,
}

HeaderNav.displayName = 'Header.Nav'
