import { inkFontTokens } from '@ds/tokens'

const detailExtraSmall = inkFontTokens.fontDetailXS
const detailSmall = inkFontTokens.fontDetailS
const bodyMedium = inkFontTokens.fontBodyM
const bodyLarge = inkFontTokens.fontBodyL
const bodyExtraLarge = inkFontTokens.fontBodyXL

const headingXXS = inkFontTokens.fontHeadingXXS
const headingExtraSmall = inkFontTokens.fontHeadingXS
const headingSmall = inkFontTokens.fontHeadingS
const headingMedium = inkFontTokens.fontHeadingS
const headingLarge = inkFontTokens.fontHeadingM
const headingExtraLarge = inkFontTokens.fontHeadingM

const displayExtraSmall = inkFontTokens.fontDisplayXS
const displaySmall = inkFontTokens.fontDisplayS
const displayMedium = inkFontTokens.fontDisplayM
const displayLarge = inkFontTokens.fontDisplayL

const sectionHeadline = {
  ...inkFontTokens.fontSectionHeadline,
  letterSpacing: '0.8px',
} as const
const tab = inkFontTokens.fontTab
const timestamp = inkFontTokens.fontTimestamp
const inputLabel = inkFontTokens.fontElementLabel
const button = inkFontTokens.fontButton

const breadcrumb = inkFontTokens.fontBreadcrumb
const breadcrumbActive = inkFontTokens.fontBreadcrumbActive

const tokens = {
  detailExtraSmall,
  detailSmall,

  bodyMedium,
  bodyLarge,
  bodyExtraLarge,

  headingXXS,
  headingExtraSmall,
  headingSmall,
  headingMedium,
  headingLarge,
  headingExtraLarge,

  displayExtraSmall,
  displaySmall,
  displayMedium,
  displayLarge,

  sectionHeadline,

  tab,
  timestamp,
  inputLabel,
  button,
  breadcrumb,
  breadcrumbActive,
} as const

export default tokens
