/**
 * The reach in like this is not ideal, and will not scale.
 * However, it gets the job done for now.
 * Once we add tokens it will be easier to "theme" the button.
 */
export default () =>
  ({
    wrap: {
      'a, a:hover, button, button:hover': {
        color: 'inherit',
      },
    },
  }) as const
