import hexToRgba from 'hex-to-rgba'
import type { CobrandingTokens } from '../../../../theming/types'
import type { HeaderTitleProps } from '..'

export default (config: {
  props: Partial<HeaderTitleProps>
  tokens: CobrandingTokens
}) => {
  const { props, tokens } = config
  if (!tokens.headerTextColor) return {}
  return {
    color: tokens.headerTextColor,

    ...(props.precededByHeaderAction && {
      borderColor: hexToRgba(tokens.headerTextColor, 0.3),
    }),
  } as const
}
