export const languageToLocale = {
  bg: 'bg_bg',
  cs: 'cs_cz',
  da: 'da_dk',
  de: 'de_de',
  el: 'el_gr',
  en: 'en_us',
  es: 'es_es',
  et: 'et_ee',
  fa: 'fa_ir',
  fi: 'fi_fi',
  fr: 'fr_fr',
  he: 'he_il',
  hi: 'hi_in',
  hr: 'hr_hr',
  hu: 'hu_hu',
  hy: 'hy_am',
  id: 'id_id',
  it: 'it_it',
  ja: 'ja_jp',
  ko: 'ko_kr',
  lt: 'lt_lt',
  lv: 'lv_lv',
  ms: 'ms_my',
  nb: 'nb_no',
  no: 'nb_no',
  nl: 'nl_nl',
  pl: 'pl_pl',
  pt: 'pt_pt',
  ro: 'ro_ro',
  ru: 'ru_ru',
  sk: 'sk_sk',
  sl: 'sl_si',
  sv: 'sv_se',
  th: 'th_th',
  tr: 'tr_tr',
  uk: 'uk_ua',
  vi: 'vi_vn',
}
