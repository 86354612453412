import {
  oliveAvatarTokens,
  oliveBackgroundTokens,
  oliveBadgeTokens,
  oliveBarTokens,
  oliveBorderTokens,
  oliveBreakpointTokens,
  oliveCTATokens,
  oliveElevationTokens,
  oliveFocusTokens,
  oliveFontTokens,
  oliveFormTokens,
  oliveIconTokens,
  oliveItemTokens,
  oliveMessageTokens,
  oliveOpacityTokens,
  oliveSpacingTokens,
  oliveStatusTokens,
  oliveTagTokens,
} from '@ds/tokens'

export default {
  ...oliveAvatarTokens,
  ...oliveBackgroundTokens,
  ...oliveBadgeTokens,
  ...oliveBarTokens,
  ...oliveBorderTokens,
  ...oliveBreakpointTokens,
  ...oliveCTATokens,
  ...oliveElevationTokens,
  ...oliveFocusTokens,
  ...oliveFontTokens,
  ...oliveFormTokens,
  ...oliveIconTokens,
  ...oliveItemTokens,
  ...oliveMessageTokens,
  ...oliveOpacityTokens,
  ...oliveSpacingTokens,
  ...oliveStatusTokens,
  ...oliveTagTokens,
  dark: {} as Record<string, never>,
}
