export const visuallyHidden = {
  clipPath: 'inset(50%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
} as const

export const styles = {
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  visuallyHidden,

  // Set font size to 16px to prevent mobile browsers from zooming when input gains focus
  touchScreenFormFieldFontSize: {
    '@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 768px) and (hover: none)':
      {
        fontSize: '16px',
      },
  },

  userSelectNone: {
    userSelect: 'none',
  },

  breakWord: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
} as const

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeStyleProps = (props: Record<string, any>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { css, style, ...restProps } = props
  return restProps
}
