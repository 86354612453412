import React, { useRef, useCallback } from 'react'

interface UniqueIdProviderProps {
  /**
   * Will be prefixed to all IDs generated for the children of the provider. Can be used to
   * avoid name conflicts between different parts of an app. If you can use a single provider
   * at the root you probably don't need this.
   */
  prefix?: string
  children: React.ReactNode
}

export const UniqueIdProvider = ({
  prefix = 'unique-id-',
  children,
}: UniqueIdProviderProps) => {
  const countRef = useRef(0)

  const getId: GetId = useCallback(
    (consumerPrefix) => {
      return '' + prefix + consumerPrefix + countRef.current++
    },
    [prefix]
  )

  return (
    <UniqueIdContext.Provider value={getId}>
      {children}
    </UniqueIdContext.Provider>
  )
}

type GetId = (consumerPrefix: string) => string

export const UniqueIdContext = React.createContext<GetId | undefined>(undefined)
