import { CreateFocusCss } from '../../../../styles'
import colors from '../../../../theming/docusign-themes/olive/colors'

export default () => {
  return {
    link: {
      ...CreateFocusCss({
        outlineColor: colors.white,
      }),
    },

    image: {
      svg: {
        fill: colors.white,
      },
    },

    text: {
      borderColor: colors.DocuSignBlueLight,
      color: colors.white,
    },
  } as const
}
