import { oliveFontTokens } from '@ds/tokens'

const tokens = {
  defaultColor: oliveFontTokens.fontColorLink,
  defaultHoverColor: oliveFontTokens.fontColorLinkHover,
  defaultActiveColor: oliveFontTokens.fontColorLinkActive,
  defaultVisitedColor: oliveFontTokens.fontColorLinkVisited,
  subtleColor: oliveFontTokens.fontColorSubtle,
  subtleHoverColor: '#3d3d3d',
  subtleActiveColor: '#3d3d3d',
  subtleVisitedColor: oliveFontTokens.fontColorLinkVisitedSubtle,
  lightColor: '#4186ec',
  lightHoverColor: '#d8edfa',
} as const

export default tokens
