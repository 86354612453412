import { useMemoizedDeepmerge } from '@ds/react-utils'

import { OliveDocuSignTheme } from '../docusign-themes/olive'
import { useTheme } from './useTheme'

export const useThemeIcons = () => {
  const { icons } = useTheme()
  const themeIcons = useMemoizedDeepmerge(OliveDocuSignTheme.icons, icons)
  return themeIcons
}
