import type {
  PopoverAlignment,
  PopoverLocation,
} from '../components/Popover/index'

const transformOriginPositions = [
  {
    start: {
      below: 'left top',
      above: 'left bottom',
      before: 'right top',
      after: 'left top',
    },
    center: {
      below: 'center top',
      above: 'center bottom',
      before: 'right center',
      after: 'left center',
    },
    end: {
      below: 'right top',
      above: 'right bottom',
      before: 'right bottom',
      after: 'left bottom',
    },
  },
] as const

export const transformOrigin = (
  alignmentValue: PopoverAlignment = 'start',
  locationValue: PopoverLocation = 'below',
) =>
  transformOriginPositions.map(
    (position) => position[alignmentValue][locationValue],
  )
