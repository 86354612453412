import React from 'react'
import PropTypes from 'prop-types'
import { RequireAtLeastOne } from 'type-fest'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'

import { requiredPropMessage } from '../../../logging'
import {
  AnchorOrButtonForwardRef,
  AriaAttributes,
  EventListenerProps,
} from '../../../types'
import { AnchorTarget, anchorTargets } from '../../../variables'

import { Button } from '../../Button'

import styles from './styles'

export interface BaseToastMessageActionProps
  extends EventListenerProps<HTMLButtonElement | HTMLAnchorElement>,
    AriaAttributes {
  /** The text to present to assistive devices in order to identify the ToastMessageAction. (!) At least one of the props 'text' or 'accessibilityText' is required. */
  accessibilityText?: string
  /** A React ref to assign to the HTML node representing the ToastMessageAction element. */
  forwardedRef?: AnchorOrButtonForwardRef
  /** URL for navigating. If a URL is supplied it renders as an anchor element, if not, a button element. */
  href?: string
  /**  * The relationship of the linked URL of an anchor as space-separated link types. Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types */
  rel?: string
  /** The HTML link target to use when an href is given. */
  target?: AnchorTarget
  /** The text to be displayed inside of the Button. (!) At least one of the props 'text' or 'accessibilityText' is required. */
  text?: string
  /** Accepts custom data attributes. */
  'data-qa'?: string
  'data-.*'?: string
}

export type ToastMessageActionProps = RequireAtLeastOne<
  BaseToastMessageActionProps,
  'accessibilityText' | 'text'
>

export function ToastMessageAction({
  accessibilityText,
  forwardedRef,
  href,
  onClick,
  rel,
  target,
  text,
  ...restProps
}: ToastMessageActionProps) {
  const sx = useThemeStyles(styles)

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'ToastMessageAction',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  return (
    <div css={sx.wrap}>
      <Button
        accessibilityText={accessibilityText}
        forwardedRef={forwardedRef}
        href={href}
        kind="tertiary"
        onClick={onClick}
        rel={rel}
        target={target}
        text={text}
        {...restProps}
      />
    </div>
  )
}

ToastMessageAction.propTypes = {
  accessibilityText: PropTypes.string,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  href: PropTypes.string,
  'on[A-Z].*': PropTypes.func,
  onClick: PropTypes.func,
  rel: PropTypes.string,
  target: PropTypes.oneOf(anchorTargets),
  text: PropTypes.string,
}

ToastMessageAction.defaultProps = {
  // `accessibilityText` and `text` are required with `RequireAtLeastOne`
  accessibilityText: undefined,
  text: undefined,
}

ToastMessageAction.displayName = 'ToastMessage.Action'
