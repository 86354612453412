import consoleError from './consoleError'

interface MessageParts {
  component: string
  prop1: string
  prop2: string
}

const requiredPropMessage = ({ component, prop1, prop2 }: MessageParts) =>
  consoleError(
    `${component} - At least one of the props '${prop1}' or '${prop2}' are required, but neither prop received a value.`,
  )

export default requiredPropMessage
