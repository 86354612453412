import type { ButtonKind } from '../components/Button'
import type { ButtonTokens } from '../theming/types'

type CustomBrandingOpacity = 0.4
type SystemBrandingOpacity = 0.25 | undefined

type ButtonKindTokens = Omit<ButtonTokens[ButtonKind], 'disabledOpacity'> & {
  disabledOpacity: CustomBrandingOpacity | SystemBrandingOpacity
}

const CreateButtonCss = (tokens: ButtonKindTokens) => {
  const {
    defaultBackgroundColor,
    defaultBorderColor,
    defaultTextColor,
    hoverBackgroundColor,
    hoverTextColor,
    activeBackgroundColor,
    activeTextColor,
    disabledBackgroundColor,
    disabledBorderColor,
    disabledTextColor,
    disabledOpacity,
    iconColor,
    iconHoverColor,
    buttonGroupSeparatorColor,
  } = tokens

  const activeStyles = {
    backgroundColor: activeBackgroundColor,
    color: activeTextColor || defaultTextColor,
    transitionDuration: '0.15s',

    '& svg': {
      fill: activeTextColor || iconColor,
    },
  }

  const disabledStyles = {
    backgroundColor: disabledBackgroundColor,
    borderColor: disabledBorderColor,
    color: disabledTextColor,
    ...(disabledOpacity && { opacity: disabledOpacity }),

    '&:hover': {
      backgroundColor: disabledBackgroundColor,
      borderColor: disabledBorderColor,
      color: disabledTextColor,
      ...(disabledOpacity && { opacity: disabledOpacity }),
    },

    '& svg': {
      fill: disabledTextColor,
    },

    '& img': {
      opacity: '0.5',
    },
  }

  return {
    default: {
      backgroundColor: defaultBackgroundColor,
      borderColor: defaultBorderColor,
      color: defaultTextColor,

      '& svg': {
        fill: iconColor || defaultTextColor,
      },

      '&:hover': {
        backgroundColor: hoverBackgroundColor,
        color: hoverTextColor || defaultTextColor,

        '& svg': {
          fill: iconHoverColor || defaultTextColor,
        },
      },

      '&:active, &[aria-expanded="true"]': activeStyles,

      '&:disabled': disabledStyles,
    },

    active: activeStyles,

    disabled: disabledStyles,

    menuTrigger: {
      '&::after': {
        borderTopColor: iconColor || defaultTextColor,
      },
    },

    buttonGroupSeparatorColor: {
      borderInlineStartColor: buttonGroupSeparatorColor,
    },
  }
}

export default CreateButtonCss
