import { RequestSender } from './RequestSender'
import {
  HttpResponse,
  HttpHandler,
  ExtendedHttpRequest,
  HttpError,
} from './types'
import { notifyError, notifyRequest } from './notification'

/*
    RequestRetrier uses RequestSender to make requests. Retry strategy is used
    to retry failed requests.
*/

const MAX_ATTEMPTS = 5 // backstop...don't let hell break loose.

/**
 * RequestRetrier uses RequestSender to make requests. The retrier will use the request's
 * retry handler to decide if a given response should be retried.
 */
export class RequestRetrier {
  private httpHandler: HttpHandler
  private request: ExtendedHttpRequest
  private activeRequest: RequestSender

  /**
   * @param httpHandler a HttpHandler
   * @param httpRequest a request
   */
  constructor(httpHandler: HttpHandler, httpRequest: ExtendedHttpRequest) {
    this.httpHandler = httpHandler
    this.request = httpRequest
    this.activeRequest = new RequestSender(this.httpHandler, this.request)
  }

  /**
   * Send the request (retry failures as prescribed by the request's retry function)
   *
   * @returns A Promise which resolves with a HttpResponse. The promise will
   * resolve/reject after either:
   *      1.) successful response,
   *      2.) all retries have been exhausted,
   *      3.) an non-http error (e.g., timeout or cancel)
   */
  public async send(): Promise<HttpResponse> {
    const retry = this.request.retry || (() => -1)
    let attemptCount = 0
    let delayBeforeSend = 0
    let response: HttpResponse
    while (attemptCount < MAX_ATTEMPTS) {
      attemptCount++
      this.activeRequest = new RequestSender(this.httpHandler, this.request)
      if (attemptCount > 1) {
        this.notifyRetry(attemptCount)
      }
      response = await this.activeRequest.send(delayBeforeSend)
      if (response.isOK) {
        return response
      }
      delayBeforeSend = retry(response, this.elapsedTime(), attemptCount)
      if (delayBeforeSend < 0) {
        return response
      }
      this.notifyRetriedError(response, attemptCount)
    }
    return response!
  }

  /**
   * Cancel the request
   */
  public cancel() {
    this.activeRequest.cancel()
  }

  private elapsedTime() {
    return new Date().getTime() - this.request.beginTime.getTime()
  }

  private notifyRetriedError(response: HttpResponse, attempt: number) {
    const description = `HTTP ${response.request.method} failure at URL ${response.request.url}, status=${response.status}`
    const httpError = new HttpError(
      response.status,
      description,
      response.request,
      new Error(description)
    )
    httpError.willRetry = true
    httpError.attempt = attempt
    notifyError(httpError)
  }

  private notifyRetry(attemptCount: number) {
    notifyRequest(this.request, attemptCount)
  }
}
