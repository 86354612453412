import { inkTokens } from '@ds/tokens'

const tokens = {
  defaultTrackColor: inkTokens.light.barTrackColorDefault,
  defaultFillColor: inkTokens.light.barFillColorDefault,
  subtleFillColor: inkTokens.light.barFillColorSubtle,
  highFillColor: inkTokens.light.barFillColorSuccess,
  mediumFillColor: inkTokens.light.barFillColorWarning,
  lowFillColor: inkTokens.light.barFillColorError,
  borderRadius: inkTokens.global.borderRadiusDefault,
  fillHeight: '8px',
  trackHeight: '2px',

  dark: {
    defaultTrackColor: inkTokens.dark.barTrackColorDefault,
    defaultFillColor: inkTokens.dark.barFillColorDefault,
    subtleFillColor: inkTokens.dark.barFillColorSubtle,
  },
} as const

export default tokens
