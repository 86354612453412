import colors from '../../../theming/docusign-themes/olive/colors'
import fontFamilies from '../../../theming/docusign-themes/olive/fontFamilies'
import type { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    button: {
      default: {
        color: colors.black,
        fontFamily: fontFamilies.MavenPro,
        letterSpacing: '0.66px',
        textTransform: 'uppercase',
      },

      sizeSmall: {
        fontSize: '11px',
        minHeight: '22px',
        minWidth: '22px',
        padding: '1px 10px',
      },

      sizeMedium: {
        fontSize: '12px',
        minHeight: tokens.button.defaultSize,
        minWidth: tokens.button.defaultSize,
        padding: '4px 14px',
      },

      sizeLarge: {
        fontSize: '13px',
        minHeight: '34px',
        minWidth: '34px',
        padding: '7px 18px',
      },

      sizeXlarge: {
        fontSize: '13px',
        minHeight: '40px',
        minWidth: '40px',
        padding: '10px 20px',
      },

      round: {
        borderRadius: '50%',
        boxShadow: tokens.elevationLow,
        height: '48px',
        minHeight: '48px',
        padding: 0,
        width: '48px',

        '&:hover': {
          boxShadow: tokens.elevationMedium,
        },
      },

      inverted: {
        primary: {
          backgroundColor: tokens.button.invertedBackgroundColor,
          borderColor: tokens.button.invertedBackgroundColor,
          color: tokens.button.invertedTextColor,

          '& svg': {
            fill: tokens.button.invertedTextColor,
          },

          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: tokens.button.invertedBackgroundColor,
            color: tokens.button.invertedBackgroundColor,

            '& svg': {
              fill: tokens.button.invertedBackgroundColor,
            },
          },

          '&:active': {
            opacity: 0.7,
          },

          '&:disabled, &:disabled:hover': {
            opacity: 0.3,
          },
        },
      },
    },

    icon: {
      beforeText: {
        marginInline: '-4px 6px',
      },

      afterText: {
        marginInline: '6px -4px',
      },
    },

    menuTrigger: {
      paddingInlineEnd: '6px',
    },

    caret: {
      marginInlineStart: '2px',
    },

    pill: {
      borderRadius: '20px',
    },
  } as const
}
