import React from 'react'
import PropTypes from 'prop-types'
import { systemIconValues, SystemIconKey } from '@ds/icons'
import { OliveImages } from '@olive/images'
import { Icon } from '../../../components/Icon'
import { Image } from '../../../components/Image'
import type { OliveImageIcon } from '../../../types'

/**
 * Run the OliveImages() function with no args to get a key/value object
 * of all the imge keys and their CDN URLs
 */
const imageSourceUrls = OliveImages()

export const iconKinds = systemIconValues
export const imageKinds = Object.keys(imageSourceUrls).sort()

/**
 * Remap the image kinds to have a prefix to be used in the component's icon prop
 */
const IMAGE_ICON_PREFIX = 'IMAGE.'
const prefixedImageKinds = imageKinds.map(
  (image) => `${IMAGE_ICON_PREFIX}${image}`,
)

/**
 * Because we do this prefix thing, test if the icon kind used in a component
 * is actually an image kind with prefix
 */
export const isImageKind = (kind: Kind): kind is OliveImageIcon =>
  kind && RegExp(`^${IMAGE_ICON_PREFIX}`).test(kind)

/**
 * Since we do this prefix thing, this is a helper function to remove the prefix
 * and then get the right URL
 */
const getImageSource = (kind: OliveImageIcon) =>
  imageSourceUrls[kind.replace(IMAGE_ICON_PREFIX, '')]

/**
 * Export a combined array of icons kinds, and prefixed image kinds,
 * to be used for the icon prop in components.
 *
 * This is absolutely not ideal, and eventually we will replace
 * the icon/iconPosition props with startElement/endElement props
 */
export const iconWithImageKinds = [...iconKinds, ...prefixedImageKinds]

type Kind = SystemIconKey | OliveImageIcon

export interface IconOrImageProps {
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  kind: Kind
}

/**
 * Export a helper React component to take care of this logic
 */
export function IconOrImage({ kind, ...restProps }: IconOrImageProps) {
  return isImageKind(kind) ? (
    <Image {...restProps} iconSizing alt="" src={getImageSource(kind)} />
  ) : (
    <Icon {...restProps} kind={kind} />
  )
}

IconOrImage.propTypes = {
  'data-.*': PropTypes.string,
  kind: PropTypes.oneOf(iconWithImageKinds).isRequired,
}
