﻿export class CounterData {
  public measure?: number

  public instance?: string

  public success?: boolean

  constructor(measure?: number, instance?: string, success?: boolean) {
    this.measure = measure
    this.instance = instance
    this.success = success
  }
}
