import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { MotionVariant } from '@ds/motion'
import { ariaProps, dataProps, onProps } from '@ds/react-utils'
import { CheckOlive, CheckSmall } from '@ds/icons'
import type { DotBadgeProps } from '../../../components/DotBadge'
import type { BadgeProps } from '../../../components/Badge'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import type {
  ConditionalTagRef,
  EventListenerProps,
  LabelForwardRef,
} from '../../../types'
import {
  AnchorTarget,
  anchorTargets,
  keyboardEventKeys,
} from '../../../variables'
import { ConditionalTag } from '../ConditionalTag'
import { IconWithTheme } from '../IconWithTheme'
import styles from './styles'
import { MenuContext } from '../../../components/Menu/MenuContext'

const selectedOptions = ['on', 'auto'] as const
export type SelectedOptions = (typeof selectedOptions)[number]

export interface BaseMenuItemProps
  extends EventListenerProps<
    HTMLAnchorElement | HTMLButtonElement | HTMLInputElement
  > {
  /**
   * If you provide a value for 'accept', the Menu.Item will act as a file input.
   *
   * The prop accepts a string with a list of comma-separated content-type specifiers.
   * (file extensions prefixed with '.', valid MIME types without extensions)
   * e.g. '.js, image/*'.
   */
  accept?: string
  /**
   * The text to present to assistive devices in order to identify the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText?: string
  /**
   * When BaseMenuItem is used as a trigger in a nested menu it is necessary to force
   * a hover state color to indicate to the user the originating menu item.
   */
  active?: boolean
  /**
   * The "badge" element to display above the top-end of the Menu.Item.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   */
  badge?: React.ReactElement<DotBadgeProps | BadgeProps>
  /**
   * Accepts custom data attributes.
   */
  'data-qa'?: string
  /**
   * An optional description.
   */
  description?: string
  /**
   * Applies the 'disabled' attribute.
   */
  disabled?: boolean
  /**
   * The provided component will display at the end of the MenuItem.
   */
  endElement?: React.ReactNode
  /**
   * Renders the menu item as a file input.
   */
  fileInput?: boolean
  /**
   * A React ref to assign to the HTML node representing the BaseMenuComponent component.
   */
  forwardedRef?: ConditionalTagRef | LabelForwardRef
  /**
   * Used in `Menu.Group` to account for an icon at the start of the `Menu.Item` text (or to add margin to other `Menu.Item`s if those don't have an icon).
   * @internal @ignore
   */
  hasStartElementIcon?: boolean
  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element,
   * otherwise it renders as a button element.
   */
  href?: string
  /**
   * The ID of the menu item.
   */
  id?: string
  /**
   * Allows the user to select more than one file when the Menu.Item acts as a file input.
   * This prop is only used when a value is provided for 'accept'.
   */
  multiple?: boolean
  /**
   * The relationship of the linked URL of a anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * The aria role to be added to the BaseMenuItem
   */
  role?: string
  /**
   * A boolean for indicating if the BaseMenuItem is selected. Only used for styling
   */
  selected?: boolean
  /**
   * For use with the SelectMenu component. Adds a checkmark icon when selectedOption
   * is set to "on". Default "auto" adds space at the start of the option text for the checkmark.
   */
  selectedOption?: SelectedOptions
  /**
   * The provided component will display at the start of the MenuItem.
   */
  startElement?: React.ReactNode
  /**
   * The HTML link target (if an `href` is _not_ provided, this is ignored).
   * When `target="_blank"` use `rel="noreferrer"` or `rel="noopener"` to avoid the vulnerability.
   */
  target?: AnchorTarget
  /**
   * The text of the `Menu.Item`.
   *
   * (!) At least one of the props `text` or `accessibilityText` is required.
   */
  text?: string
}

/**
 * BaseMenuItem is an internal component and should not be exported for use externally.
 */
export function BaseMenuItem({
  accept,
  accessibilityText,
  active,
  badge,
  'data-qa': dataQa,
  description,
  disabled = false,
  endElement,
  fileInput = false,
  forwardedRef,
  hasStartElementIcon = false,
  href,
  id,
  multiple = false,
  onChange,
  onClick,
  onKeyDown,
  onKeyUp,
  rel,
  role,
  selected = false,
  selectedOption,
  startElement,
  target,
  text,
  ...restProps
}: BaseMenuItemProps) {
  const sx = useThemeStyles(styles, { disabled })
  const menuContext = useContext(MenuContext)

  const badgeElement = badge && <span css={sx.default.badge}>{badge}</span>

  // #region startElement
  const startElementStyles = [
    sx.default.startElement,
    disabled && sx.disabled.startElement,
  ]

  const startElementNode = startElement && (
    <span css={startElementStyles}>{startElement}</span>
  )
  // #endregion

  // #region endElement
  const endElementStyles = [
    sx.default.endElement,
    disabled && sx.disabled.endElement,
  ]

  const endElementNode = endElement && (
    <span css={endElementStyles}>{endElement}</span>
  )
  // #endregion

  // #region Icon
  const selectMenuIconStyles = [
    sx.selectedOption.iconWrap,
    disabled && sx.disabled.iconWrap,
  ]

  const selectMenuNode = selectedOption && (
    <span css={selectMenuIconStyles}>
      {selectedOption === 'on' && (
        <IconWithTheme inkIcon={<CheckSmall />} oliveIcon={<CheckOlive />} />
      )}
    </span>
  )
  // #endregion

  // #region Text node
  const descriptionStyles = [
    sx.default.description,
    disabled && sx.disabled.description,
  ]

  const descriptionNode = description && (
    <span css={descriptionStyles}>{description}</span>
  )

  const accessibilityTextSpan = accessibilityText && (
    <span css={sx.hidden} data-qa={dataQa && `${dataQa}-accessibility-text`}>
      {accessibilityText}
    </span>
  )

  const textStyles = [
    sx.default.text,
    disabled && sx.disabled.text,
    // This originates in Menu.Group. It checks to see if the Menu.Group contains startElementIcons.
    hasStartElementIcon && !startElement && sx.default.textWithoutIcon,
  ]

  const textNode = (
    <span css={textStyles}>
      <span
        aria-hidden={accessibilityText ? 'true' : undefined}
        data-qa={dataQa && `${dataQa}-text`}
      >
        {text}
      </span>
      {badgeElement}
      {descriptionNode}
      {accessibilityTextSpan}
    </span>
  )
  // #endregion

  const childrenNode = (
    <>
      {selectMenuNode}
      {startElementNode}
      {textNode}
      {endElementNode}
    </>
  )

  if (fileInput) {
    const contentStyles = [
      sx.default.content,
      sx.default.inputLabel,
      disabled && sx.default.disabled,
      endElement && sx.default.contentWithEndElement,
    ]

    return (
      <MotionVariant variants={sx.motionVariants.default}>
        <input
          {...dataProps(restProps)}
          data-qa={dataQa}
          accept={accept}
          aria-disabled={disabled || undefined}
          css={sx.hidden}
          id={id}
          multiple={multiple}
          onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
          onClick={!disabled ? onClick : (evt) => evt.preventDefault()}
          onKeyDown={onKeyDown}
          role={menuContext.MenuItemRole}
          type="file"
        />

        <label
          {...onProps(restProps)}
          css={contentStyles}
          htmlFor={id}
          ref={forwardedRef as LabelForwardRef}
        >
          {childrenNode}
        </label>
      </MotionVariant>
    )
  }

  const contentStyles = [
    sx.default.content,
    active && sx.active,
    disabled && sx.default.disabled,
    selected && sx.selected,
    endElement && sx.default.contentWithEndElement,
  ]

  return (
    <MotionVariant variants={sx.motionVariants.default}>
      <ConditionalTag
        {...ariaProps(restProps)}
        {...dataProps(restProps)}
        data-qa={dataQa}
        {...onProps(restProps)}
        aria-disabled={disabled || undefined}
        css={contentStyles}
        forceElement="button"
        forwardedRef={forwardedRef}
        id={id}
        href={href}
        rel={rel}
        target={target}
        onClick={
          !disabled
            ? onClick
            : (
                evt:
                  | React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
                  | React.KeyboardEvent<HTMLSpanElement>,
              ) => evt.preventDefault()
        }
        onKeyDown={(
          evt: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>,
        ) =>
          disabled &&
          [
            keyboardEventKeys.Space,
            keyboardEventKeys.Enter,
            keyboardEventKeys.ArrowLeft,
            keyboardEventKeys.ArrowRight,
          ].includes(evt.key)
            ? evt.preventDefault()
            : onKeyDown?.(evt)
        }
        onKeyUp={(
          evt: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>,
        ) => {
          /**
           * This is a work around for a Firefox bug – FRNTEND-1659
           *
           * Currently when a user uses the Space key to open a menu, the
           * keydown will open the menu as well as focus the first item but by
           * the time the keyup event is fired, the Firefox bug fires a click
           * event on that focused first item resulting in the onClick above
           * being called and closing the menu immediately. We need to call
           * evt.preventDefault() to prevent Firefox from firing the click event
           * when the keyup event is fired for the Space key.
           */
          evt.preventDefault()

          // Optionally call onKeyUp if consumer passes in
          onKeyUp?.(evt)
        }}
        role={role}
      >
        {childrenNode}
      </ConditionalTag>
    </MotionVariant>
  )
}

BaseMenuItem.selectedOptions = selectedOptions
BaseMenuItem.targets = anchorTargets

BaseMenuItem.propTypes = {
  accept: PropTypes.string,
  accessibilityText: PropTypes.string,
  active: PropTypes.bool,
  badge: PropTypes.element,
  'data-.*': PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  endElement: PropTypes.node,
  fileInput: PropTypes.bool,
  forwardedRef: CustomPropTypes.ReactRef,
  href: PropTypes.string,
  id: PropTypes.string,
  multiple: PropTypes.bool,
  'on[A-Z].*': PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  rel: PropTypes.string,
  role: PropTypes.string,
  selected: PropTypes.bool,
  selectedOption: PropTypes.oneOf(selectedOptions),
  startElement: PropTypes.node,
  target: PropTypes.oneOf(anchorTargets),
  text: PropTypes.string,
}
