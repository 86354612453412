import { oliveFontTokens } from '@ds/tokens'

const tokens = {
  defaultColor: oliveFontTokens.fontColorDefault,
  subtleColor: oliveFontTokens.fontColorSubtle,
  disabledColor: oliveFontTokens.fontColorDisabled,
  successColor: oliveFontTokens.fontColorSuccess,
  errorColor: oliveFontTokens.fontColorError,
} as const

export default tokens
