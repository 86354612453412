import { FooterLink } from './types'
import { getLink } from '@ds/i18nlayer'
import type { LinkTarget } from '@ds/ui/types/components/Link'

// REMINDER: textId is used to lookup i18n translation
export const standardFooterLinks: { [key: string]: FooterLink } = {
  CONTACT_US: {
    id: 'CONTACT_US',
    textId: 'Contact Us',
    url: (locale: string) => getLink('support', locale),
  },
  TERMS: {
    id: 'TERMS',
    textId: 'Terms of Use',
    url: (locale: string) => getLink('term', locale),
  },
  PRIVACY: {
    id: 'PRIVACY',
    textId: 'Privacy',
    url: (locale: string) => getLink('privacy', locale),
  },
  INTELLECTUAL_PROPERTY: {
    id: 'INTELLECTUAL_PROPERTY',
    textId: 'Intellectual Property',
    url: (locale: string) => getLink('ip', locale),
  },
  XDTM: {
    id: 'XDTM',
    textId: 'xDTM Compliant',
    url: 'https://xdtm.org/',
  },
  TRUST: {
    id: 'TRUST',
    textId: 'FOOTER:TRUST',
    url: 'https://www.docusign.com/trust',
  },
}

export const defaultFooterLinks: FooterLink[] = [
  standardFooterLinks.CONTACT_US,
  standardFooterLinks.TERMS,
  standardFooterLinks.PRIVACY,
  standardFooterLinks.INTELLECTUAL_PROPERTY,
  standardFooterLinks.TRUST,
]

export function defaultLinkRel() {
  return 'noopener'
}

export function defaultLinkTarget(): LinkTarget {
  return '_blank'
}
