import React from 'react'
import PropTypes from 'prop-types'
import { Motion } from '@ds/motion'
import { dataProps, useUniqueId } from '@ds/react-utils'
import { useThemeStyles } from '../../theming'
import { consoleWarn } from '../../logging'
import { ProgressCircleIndeterminate } from './ProgressCircleIndeterminate'
import styles from './styles'

export const progressCircleKinds = ['default', 'subtle'] as const
export const progressCircleSizes = ['small', 'medium', 'large'] as const

export type ProgressCircleKind = (typeof progressCircleKinds)[number]
export type ProgressCircleSize = (typeof progressCircleSizes)[number]

export interface ProgressCircleProps {
  /**
   * @deprecated
   */
  backgroundColor?: string
  /**
   * The dark color scheme variant (Ink only).
   */
  dark?: boolean
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * Hides the ProgressCircle text while allowing assistive devices to identify it.
   */
  hideText?: boolean
  /**
   * A unique identifier for the progress element.
   */
  id?: string
  /**
   * Whether the ProgressCircle displays the default style or subtle.
   */
  kind?: ProgressCircleKind
  /**
   * The max attribute, if present, must have a value greater than 0
   * and be a valid floating point number.
   */
  max?: number
  /**
   * The size of the ProgressCircle.
   */
  size?: ProgressCircleSize
  /**
   * The text to be displayed at the end of the Circle.
   */
  text: string
  /**
   * Must be a valid floating point number between 0 and `max`.
   * If value is omitted, the ProgressBar will display an indeterminate state.
   */
  value?: number
}

/**
 * Progress Circle is a loading indicator that notifies users that an action or system operation is being processed.
 */
export function ProgressCircle({
  backgroundColor,
  dark = false,
  'data-qa': dataQa,
  hideText = false,
  id,
  kind = 'default',
  max = 1,
  size = 'medium',
  text,
  value,
  ...restProps
}: ProgressCircleProps) {
  const sx = useThemeStyles(styles, { dark })

  const generatedProgressId = useUniqueId('short')
  const progressId = id || generatedProgressId

  if (backgroundColor) {
    consoleWarn(
      "ProgressCircle - The 'backgroundColor' has been deprecated and will be removed in a future release.",
    )
  }

  const hasValue = value !== undefined && value !== null

  const getStrokeDashOffset = () => {
    let offsetValue
    // @ts-expect-error
    const circumference = sx[size].strokeDasharray
    if (hasValue) {
      if (value >= max) {
        offsetValue = 0 // Locks the offset at 100% of the circle circumference.
      } else if (value >= 0) {
        offsetValue = circumference - (value / max) * circumference
      } else {
        offsetValue = circumference // Locks the offset at 0% of the circle circumference.
      }
    }
    return offsetValue
  }

  const textStyles = [sx.text, hideText && sx.hidden]

  const circleFillStyles = [
    sx.default.circleFill,
    kind === 'subtle' && sx.subtle.circleFill,
  ]

  return (
    <div {...dataProps(restProps)} data-qa={dataQa} css={sx.default.wrap}>
      <progress
        data-qa={dataQa && `${dataQa}-bar`}
        css={sx.default.progress}
        id={progressId}
        max={max === 1 ? undefined : max}
        value={value || undefined}
      />
      {hasValue ? (
        <svg
          // @ts-expect-error
          height={sx[size].height}
          // @ts-expect-error
          width={sx[size].width}
          aria-hidden="true"
          data-qa={dataQa && `${dataQa}-svg`}
        >
          <circle
            css={sx.default.circleTrack}
            // @ts-expect-error
            r={sx[size].radius}
            // @ts-expect-error
            cx={sx[size].centerPointX}
            // @ts-expect-error
            cy={sx[size].centerPointY}
            // @ts-expect-error
            strokeWidth={sx[size].trackStrokeWidth}
          />
          <Motion
            as="circle"
            css={circleFillStyles}
            // @ts-expect-error
            r={sx[size].radius}
            // @ts-expect-error
            cx={sx[size].centerPointX}
            // @ts-expect-error
            cy={sx[size].centerPointY}
            // @ts-expect-error
            strokeWidth={sx[size].fillStrokeWidth}
            animate={{
              // @ts-expect-error
              strokeDasharray: `${sx[size].strokeDasharray} ${sx[size].strokeDasharray}`,
              strokeDashoffset: getStrokeDashOffset(),
            }}
          />
        </svg>
      ) : (
        <ProgressCircleIndeterminate
          data-qa={dataQa && `${dataQa}-indeterminate`}
          dark={dark}
          kind={kind}
          size={size}
        />
      )}
      <label
        aria-live="polite"
        data-qa={dataQa && `${dataQa}-label`}
        htmlFor={progressId}
        css={textStyles}
      >
        {text}
      </label>
    </div>
  )
}

ProgressCircle.kinds = progressCircleKinds
ProgressCircle.sizes = progressCircleSizes

ProgressCircle.propTypes = {
  backgroundColor: PropTypes.string,
  dark: PropTypes.bool,
  'data-.*': PropTypes.string,
  hideText: PropTypes.bool,
  kind: PropTypes.oneOf(progressCircleKinds),
  max: PropTypes.number,
  size: PropTypes.oneOf(progressCircleSizes),
  text: PropTypes.string.isRequired,
  value: PropTypes.number,
}

ProgressCircle.displayName = 'ProgressCircle'
