import { inkBackgroundTokens, inkBorderTokens, inkFontTokens } from '@ds/tokens'

const tokens = {
  backgroundColor: inkBackgroundTokens.bgColorInverse,
  borderColor: inkBorderTokens.borderColorDefault,
  color: inkFontTokens.fontColorInverse,
  offset: '2px',
  tipSize: 8,
} as const

export default tokens
