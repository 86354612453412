import * as React from 'react'
import PropTypes from 'prop-types'
import { useIsInk } from '../../../theming'

export interface IconWithThemeProps {
  inkIcon?: React.ReactElement<React.SVGProps<SVGSVGElement>>
  oliveIcon?: React.ReactElement<React.SVGProps<SVGSVGElement>>
}

export const IconWithTheme = ({ inkIcon, oliveIcon }: IconWithThemeProps) => {
  const isInk = useIsInk()

  return <>{isInk ? inkIcon : oliveIcon}</>
}

IconWithTheme.propTypes = {
  inkIcon: PropTypes.element,
  oliveIcon: PropTypes.element,
}

IconWithTheme.displayName = 'IconWithTheme'
