import {
  getLink as _getLink,
  getLocalizableURLs as _getLocalizableURLs,
} from '@ds/i18nlink'
import {
  setTelemetry,
  reportException,
  getPerformance,
} from '../telemetry/telemetry'

export { getLink, getLocalizableURLs }

function getLink(topic: string, language: string | undefined): string {
  try {
    const t0 = getPerformance()
    const result = _getLink(topic, language)
    setTelemetry(getLink.name, t0)
    return result
  } catch (ex) {
    reportException(getLink.name, ex)
    return topic
  }
}
function getLocalizableURLs(): string[] {
  try {
    const t0 = getPerformance()
    const result = _getLocalizableURLs()
    setTelemetry(getLocalizableURLs.name, t0)
    return result
  } catch (ex) {
    reportException(getLocalizableURLs.name, ex)
    return []
  }
}
