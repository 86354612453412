import React from 'react'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { useFontFace } from './useFontFace'

export function FontFaceDeclarations(props: {
  fonts: Array<(path: string) => string>
}) {
  const { fonts } = props

  const fontFacePath = useFontFace()

  const declarations = fonts.map((x) => x(fontFacePath)).join('\n')

  return <Global styles={css(declarations)} />
}

FontFaceDeclarations.propTypes = {
  fonts: PropTypes.arrayOf(PropTypes.func).isRequired,
}
