import { KazMonConfig } from './KazMonConfig'
import { isBrowser } from '../Utility'
export interface ClientVariables {
  Host: string
  Path: string
  UserAgent: string
  Platform: string
  Dimension: string
  QueryString?: { [key: string]: string }
  Hash?: string
}

export function getClientVariables(
  config: KazMonConfig
): ClientVariables | undefined {
  if (isBrowser()) {
    const location = window.location
    const cvars: ClientVariables = {
      Host: location.host,
      Path: location.pathname,
      UserAgent: window.navigator.userAgent,
      Platform: window.navigator.platform,
      Dimension: `${window.innerWidth} x ${window.innerHeight}`,
    }
    if (location.search) {
      const permittedPropNames = new Set(config.queryStringWhitelist || [])
      const rawProps = new URLSearchParams(location.search)
      const scrubbedProps = {}
      // @ts-expect-error  ts def is wrong...missing keys() on URLSearchParams
      for (const key of rawProps.keys()) {
        if (permittedPropNames.has(key)) {
          const values = rawProps.getAll(key)
          scrubbedProps[key] = values.length > 1 ? values : values[0]
        } else {
          scrubbedProps[key] = 'SCRUBBED'
        }
      }
      cvars.QueryString = scrubbedProps
    }
    if (location.hash) {
      const permittedHashValues = new Set(config.hashWhitelist || [])
      const hashValue = location.hash.replace('#', '')
      cvars.Hash = permittedHashValues.has(hashValue) ? hashValue : 'SCRUBBED'
    }
    return cvars
  }
  return undefined
}
