import { memoize } from 'lodash'
import { getRegionLocalePolicy, getMetaData } from '@ds/i18nlayer'
import { Locale } from '@ds/base'
import { LocaleDescription } from './types'

export const getDisplayName = (locale: Locale) => {
  const displayName = localeDisplayNames()[locale]
  return displayName || locale
}

const localeDisplayNames: () => { [key: string]: string } = memoize(() => {
  const names: { [key: string]: string } = {}
  const valueDisplayList = getMetaData(getRegionLocalePolicy('en'), 'locale')

  valueDisplayList.forEach((valueDisplay) => {
    names[valueDisplay.value] = valueDisplay.display
    names[valueDisplay.value.toLowerCase()] = valueDisplay.display
    names[valueDisplay.value.replace('_', '-')] = valueDisplay.display
    names[valueDisplay.value.replace('_', '-').toLowerCase()] =
      valueDisplay.display
  })
  return names
})

export const sortedLocaleAndDisplayName: (
  locales: Locale[]
) => LocaleDescription[] = memoize((locales: Locale[]) => {
  const localeAndNameList: LocaleDescription[] = []
  locales.forEach((locale) =>
    localeAndNameList.push({
      locale,
      displayName: getDisplayName(locale),
    })
  )
  localeAndNameList.sort((localeAndNameA, localeAndNameB) => {
    if (localeAndNameA.locale === 'en') {
      return -1
    }
    if (localeAndNameB.locale === 'en') {
      return 1
    }
    const nameA = localeAndNameA.displayName.toUpperCase()
    const nameB = localeAndNameB.displayName.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
  return localeAndNameList
})
