const DOCUSIGN_IS_MOTION_DISABLED = 'DOCUSIGN_IS_MOTION_DISABLED'

const globalNamespace = typeof self === 'undefined' ? global : self

globalNamespace[DOCUSIGN_IS_MOTION_DISABLED] = false

/**
 * Determines if motion is currently disabled.
 * @returns {boolean}
 */
export function isMotionDisabled(): boolean {
  return globalNamespace[DOCUSIGN_IS_MOTION_DISABLED]
}

/**
 * Disables motion across all instances until re-enabled.
 * @returns {void}
 */
export function disableMotion(): void {
  globalNamespace[DOCUSIGN_IS_MOTION_DISABLED] = true
}

/**
 * Enables motion across all instances.
 * @returns {void}
 */
export function enableMotion(): void {
  globalNamespace[DOCUSIGN_IS_MOTION_DISABLED] = false
}
