import { oliveBarTokens, oliveBorderTokens } from '@ds/tokens'

const tokens = {
  defaultTrackColor: oliveBarTokens.barTrackColorDefault,
  defaultFillColor: oliveBarTokens.barFillColorDefault,
  subtleFillColor: oliveBarTokens.barFillColorSubtle,
  highFillColor: oliveBarTokens.barFillColorSuccess,
  mediumFillColor: oliveBarTokens.barFillColorWarning,
  lowFillColor: oliveBarTokens.barFillColorError,
  borderRadius: oliveBorderTokens.borderRadiusAlternate,
  fillHeight: '8px',
  trackHeight: '8px',

  dark: {
    defaultTrackColor: undefined,
    defaultFillColor: undefined,
    subtleFillColor: undefined,
  },
} as const

export default tokens
