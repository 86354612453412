import type {
  TranslationWithInstruction,
  ComplexTranslation,
  TranslationKeys,
} from './types'

/*

    For new translations please apply these conventions for consistency and/or helping translator understand context:

    1.) use upper case only for key names
    2.) prefix the key with a component scope (e.g., "CMTS:COMMENT_INPUT")
    3.) use upper case only for subsitution parameters, e.g., "Page {{CURRENT_PAGE}} of {{NUMBER_PAGES}}"
    4.) use BTN or BUTTON in the key name to indicate the text is for a button
    5.) suffix the key with :A11Y if the string is for accessibility only (hidden for sighted users)
    6.) use short but understandable names for keys

    IMPORTANT: Do not change the meaning or substitution parameters of a key's value after the change has
    been merged to main!!! Previous versions of the translations may be used by code may be reading it.

    Plurals:

    Plural substitutions are defined differently than other substitution parameters. Instead of a single phrase
    you must provide an object with two properties: "one" and "other" (singular and plural). The parameter
    name must be PLURAL_COUNT.

    Example:

    "SEND_TO": {
        "one": "Send {{PLURAL_COUNT}} notice to {{NAME}}",
        "other": "Send {{PLURAL_COUNT}} notices to {{NAME}}"
    }


    NOTE: There can be only one plural substitution in a phrase.

    See translateText function for details on plural handling
    https://github.docusignhq.com/FrontEndShared/components/blob/HEAD/packages/base/src/Translation/Translation.ts


*/

export const flattenTranslations = (
  translations: TranslationWithInstruction
) => {
  return Object.entries(translations).reduce((t, [k, v]) => {
    if ('translation' in v) {
      return {
        ...t,
        [k]: (v as ComplexTranslation).translation,
      }
    }
    if ('instruction' in v) {
      delete v.instruction
    }
    return {
      ...t,
      [k]: v,
    }
  }, {})
}

export const enTranslationsWithInstructions = {
  'Powered by': {
    translation: 'Powered by {{DOCUSIGN_LOGO}}',
  },
  'Contact Us': {
    translation: 'Contact Us',
  },
  'Terms of Use': {
    translation: 'Terms of Use',
  },
  Privacy: {
    translation: 'Privacy',
  },
  'Intellectual Property': {
    translation: 'Intellectual Property',
  },
  'xDTM Compliant': {
    translation: 'xDTM Compliant',
  },
  'FOOTER:TRUST': {
    translation: 'Trust',
  },
  'Copyright © {{CURRENT_YEAR}} DocuSign, Inc. All rights reserved': {
    translation:
      'Copyright © {{CURRENT_YEAR}} Docusign, Inc. All rights reserved',
  },
  Feedback: {
    translation: 'Feedback',
  },
  'Aria-language-selector': {
    translation: 'language selector',
  },
  'CMTS:NAME_NOT_AVAILABLE': {
    translation: 'Name not available',
  },
  'CMTS:SELECTED_TEXT_LABEL': {
    translation: 'SELECTED:',
  },
  'CMTS:PRIVACY_DESCRIPTION_NOT_AVAILABLE': {
    translation: 'Privacy Description not available',
  },
  'CMTS:PRIVACY_TOOLTIP_NOT_AVAILABLE': {
    translation: 'Privacy Tooltip not available',
  },
  'CMTS:MORE_REPLIES_ONE': {
    translation: '1 more reply',
  },
  'CMTS:MORE_REPLIES_MULTIPLE': {
    translation: '{{HIDDEN_COMMENT_COUNT}} more replies',
  },
  'CMTS:REPLY_1': {
    translation: '1 reply',
  },
  'CMTS:REPLIES_COUNT_MULTIPLE': {
    translation: '{{COUNT}} replies',
  },
  'CMTS:UPDATE_1': {
    translation: '1 update',
  },
  'CMTS:UPDATE_COUNT_MULTIPLE': {
    translation: '{{COUNT}} updates',
  },
  'CMTS:ASSIGNMENT': {
    translation: 'Assign task to',
  },
  'CMTS:ASSIGNMENT:READ_ONLY:USER': {
    translation: 'Task assigned to <Button>{{USER}}</Button>',
  },
  'CMTS:ASSIGNMENT:READ_ONLY:FIRST_PERSON': {
    translation: 'Task assigned to <Button>Me</Button>',
  },
  'CMTS:ASSIGNMENT:READ_ONLY:FIRST_PERSON_FOR_GROUP': {
    translation:
      'Task assigned to <Button>Me</Button> for <GroupButton>{{GROUP}}</GroupButton>',
  },
  'CMTS:ASSIGNMENT:READ_ONLY:USER_FOR_GROUP': {
    translation:
      'Task assigned to <Button>{{USER}}</Button> for <GroupButton>{{GROUP}}</GroupButton>',
  },
  'CMTS:ASSIGNMENT:READ_ONLY:CLAIM': {
    translation: 'Claim',
  },
  'CMTS:ASSIGNMENT:READ_ONLY:ASSIGN': {
    translation: 'Assign',
  },
  'CMTS:ASSIGNMENT:READ_ONLY:REASSIGN': {
    translation: 'Reassign',
  },
  'CMTS:ASSIGNMENT:BUTTON:NO_ONE': {
    translation: 'Assign task to <Button>no one</Button>',
  },
  'CMTS:ASSIGNMENT:BUTTON:USER': {
    translation: 'Assign task to <Button>{{USER}}</Button>',
  },
  'CMTS:ASSIGNMENT:BUTTON:FIRST_PERSON': {
    translation: 'Assign task to <Button>Me</Button>',
  },
  'CMTS:ASSIGNMENT:LIST:NO_ONE': {
    translation: 'Assign to no one (notify only)',
  },
  'CMTS:ASSIGNMENT:LIST:USER': {
    translation: 'Assign to {{USER}}',
  },
  'CMTS:ASSIGNMENT:LIST:FIRST_PERSON': {
    translation: 'Assign to Me',
  },
  'CMTS:MENTION': {
    translation: 'Select who to notify',
  },
  'CMTS:MENTION:A11Y': {
    translation: 'Select a user to notify in the comment',
  },
  'CMTS:MENTION:NO_MATCHES': {
    translation: 'No matches found',
  },
  'CMTS:MENTION:DELETED_USER': {
    translation: 'Deleted User',
  },
  'CMTS:MENTION:DELETED_TASK_GROUP': {
    translation: 'Deleted Task Group',
  },
  'CMTS:PRIVACY': {
    translation: 'Who can see this',
  },
  'CMTS:PRIVACY_TOOLTIP_PUBLIC': {
    translation: 'All recipients can see the comment',
  },
  'CMTS:ALL_RECIPIENTS': {
    translation: 'All Recipients',
  },
  'CMTS:LIMITED_RECIPIENTS': {
    translation: 'Limited Recipients',
  },
  'CMTS:LIMITED_RECIPIENTS_LIST': {
    translation: 'All except {{LIMITED_RECIPIENT_LIST}}',
  },
  'CMTS:DONE': {
    translation: 'DONE',
  },
  'CMTS:PUBLIC': {
    translation: 'Public',
  },
  'CMTS:REPLY': {
    translation: 'Reply',
  },
  'CMTS:POST': {
    translation: 'Post',
  },
  'CMTS:COMMENT_INPUT': {
    translation: 'Comment Input',
  },
  'CMTS:COMMENT_OR_MENTION': {
    translation: 'Comment or type @ to mention someone...',
  },
  'CMTS:WRITE_COMMENT': {
    translation: 'Write a comment...',
  },
  'CMTS:RESOLVE': {
    translation: 'RESOLVE',
  },
  'CMTS:RE_OPEN': {
    translation: 'RE-OPEN',
  },
  'CMTS:SELECTED': {
    translation: 'Selected',
  },
  'CMTS:CLOSE': {
    translation: 'CLOSE',
  },
  'CMTS:PRIVACY_HELP_TEXT': {
    translation:
      'All comments will be visible to anyone with access to this document',
  },
  'CMTS:VIEW_COMMENT_FROM_PLACEHOLDER': {
    translation: 'View comment from placeholder',
  },
  'CMTS:ADD_REPLY:A11Y': {
    translation: 'Add reply to comment',
  },
  'CMTS:ADD_NEW:A11Y': {
    translation: 'Post new comment',
  },
  'CMTS:ADD_REPLY:TOOLTIP': {
    translation: ' ', // default to a space if it shouldn't be shown
  },
  'CMTS:ADD_NEW:TOOLTIP': {
    translation: ' ', // default to a space if it shouldn't be shown
  },
  'CMTS:RESOLVE_THREAD:A11Y': {
    translation: 'Resolve',
  },
  'CMTS:CLOSE:A11Y': {
    translation: 'Close comment',
  },
  'CMTS:RE_OPEN:A11Y': {
    translation: 'Reopen comment thread',
  },
  'CMTS:UNREAD_COMMENT:A11Y': {
    translation: 'Unread comment',
  },
  'CMTS:SELECTED:A11Y': {
    translation: 'Selected from document',
  },
  'CMTS:COMMENT_ACTIVITY:A11Y': {
    translation: 'Comment Activity',
  },
  'CMTS:COMMENT_PRIVACY:A11Y': {
    translation: 'Comment privacy',
  },
  'CMTS:PRIVACY_SETTINGS': {
    translation: 'Privacy settings',
  },
  'CMTS:COMMENT_ACTION_MENU_TOOLTIP': {
    translation: 'More Actions',
  },
  'CMTS:COMMENT_ACTION_MENU:A11Y': {
    translation: 'More actions menu',
  },
  'CMTS:COMMENT_ACTION_MENU_ITEMS:A11Y': {
    translation: 'More actions menu items',
  },
  'CMTS:START_NEW_THREAD_BUTTON:A11Y': {
    translation: 'Add a new comment',
  },
  'CMTS:START_NEW_THREAD_BUTTON_TOOLTIP': {
    translation: 'To add a comment, highlight some text on the document first.',
  },
  'CMTS:EDIT': {
    translation: 'Edit',
  },
  'CMTS:DELETE': {
    translation: 'Delete',
  },
  'CMTS:ACTIONS:A11Y': {
    translation: 'Actions',
  },
  'CMTS:MORE_ACTIONS_MENU:A11Y': {
    translation: 'More Actions',
  },
  'CMTS:MORE_ACTIONS_MENU_ITEMS:A11Y': {
    translation: 'More Actions menu items',
  },
  'CMTS:SAVE': {
    translation: 'Save',
  },
  'CMTS:CANCEL': {
    translation: 'Cancel',
  },
  'CMTS:MENTION_OTHERS': {
    translation: 'Select a user to notify in your comment.',
  },
  'CMTS:ERROR_EMPTY': {
    translation: 'Enter text to post comment',
  },
  'CMTS:ERROR_LENGTH': {
    translation: "Comments can't be over {{MAX_LENGTH}} characters",
  },
  'CMTS:COMMENT_RESOLVED': {
    translation: 'Comment resolved',
  },
  'CMTS:COMMENT_RESOLVED_VERSION': {
    translation: 'Comment resolved in version {{RESOLVED_DOC_VERSION}}',
  },
  'CMTS:COMMENT_RESOLVED_VERSION_VIA_WORD': {
    translation:
      'Comment resolved via Word in version {{RESOLVED_DOC_VERSION}}. Uploaded by <Bold>@{{AUTHOR}}</Bold>',
  },
  'CMTS:COMMENT_EDITED': {
    translation: 'Comment edited',
  },
  'CMTS:TASK_CLAIMED': {
    translation: 'Task claimed',
  },
  'CMTS:TASK_ASSIGNED': {
    translation: 'Task assigned to @{{ASSIGNED_USER}}',
  },
  'CMTS:COMMENT_EDITED_TAG': {
    translation: '(edited)',
  },
  'CMTS:WORD_INGESTION_LABEL': {
    translation: 'External users will not be notified about replies',
  },
  'CMTS:WORD_INGESTION_EXTERNAL_AUTHOR': {
    translation: 'External • {{EXTERNAL_USER}}',
  },
  'D_P:January': {
    translation: 'January',
  },
  'D_P:February': {
    translation: 'February',
  },
  'D_P:March': {
    translation: 'March',
  },
  'D_P:April': {
    translation: 'April',
  },
  'D_P:May': {
    translation: 'May',
  },
  'D_P:June': {
    translation: 'June',
  },
  'D_P:July': {
    translation: 'July',
  },
  'D_P:August': {
    translation: 'August',
  },
  'D_P:September': {
    translation: 'September',
  },
  'D_P:October': {
    translation: 'October',
  },
  'D_P:November': {
    translation: 'November',
  },
  'D_P:December': {
    translation: 'December',
  },
  'D_P:Su': {
    translation: 'Su',
  },
  'D_P:Mo': {
    translation: 'Mo',
  },
  'D_P:Tu': {
    translation: 'Tu',
  },
  'D_P:We': {
    translation: 'We',
  },
  'D_P:Th': {
    translation: 'Th',
  },
  'D_P:Fr': {
    translation: 'Fr',
  },
  'D_P:Sa': {
    translation: 'Sa',
  },
  'D_P:Invalid': {
    translation: 'Invalid ({{EXPECTED_DATE_FORMAT}})',
  },
  'D_P:PreviousMonth': {
    translation: 'Previous Month',
  },
  'D_P:NextMonth': {
    translation: 'Next Month',
  },
  'D_P:RangePickerLegend:A11Y': {
    translation: 'select a range of dates',
  },
  'D_P:OpenFromDatePicker': {
    translation: 'open from date picker',
  },
  'D_P:OpenToDatePicker': {
    translation: 'open to date picker',
  },
  'D_P:OpenDatePicker:A11Y': {
    translation: 'pick date',
  },
  'D_P:RangePicker:A11Y': {
    translation: 'pick dates from calendar',
  },
  'DAY:Sunday': {
    translation: 'Sunday',
  },
  'DAY:Monday': {
    translation: 'Monday',
  },
  'DAY:Tuesday': {
    translation: 'Tuesday',
  },
  'DAY:Wednesday': {
    translation: 'Wednesday',
  },
  'DAY:Thursday': {
    translation: 'Thursday',
  },
  'DAY:Friday': {
    translation: 'Friday',
  },
  'DAY:Saturday': {
    translation: 'Saturday',
  },
  'FILTER_BOX:ADD_FILTER': {
    translation: 'Add Filter',
  },
  'FILTER_BOX:APPLY_FILTER': {
    translation: 'Apply Filter',
  },
  'FILTER_BOX:DELETE_FILTER_A11Y': {
    translation: 'Delete Filter',
  },
  'FILTER_BOX:DIMENSION_SEARCH_RESULTS_A11Y': {
    translation: 'Showing {{COUNT}} search results.',
  },
  'FILTER_BOX:NO-CANDIDATES': {
    translation: 'No values found',
  },
  'FILTER_BOX:CANDIDATES_ERROR': {
    translation: 'Error occurred while loading the data',
  },
  'FILTER_BOX:STRING_MATCH_PLACEHOLDER': {
    translation: 'Type value',
  },
  'FILTER_BOX:DIMENSION_SEARCH_PLACEHOLDER': {
    translation: 'Try Searching...',
  },
  'FILTER_BOX:FILTERS_REGION_A11Y': {
    translation: 'Selected Filters',
  },
  'FILTER_BOX:DUPLICATE': {
    translation: 'This is a duplicate of another filter.',
  },
  'FILTER_BOX:ADD_CUSTOM_CANDIDATE': {
    translation: 'Add custom value for {{DIMENSION_NAME}}',
  },
  'FILTER_BOX:SAVE_CUSTOM_CANDIDATE': {
    translation: 'Save',
  },
  'FILTER_BOX:REMOVE_CUSTOM_CANDIDATE': {
    translation: 'Remove',
  },
  'FILTER_BOX:DUPLICATE_CANDIDATE_ERROR': {
    translation: 'Duplicate value',
  },
  'FILTER_BOX:EDIT_CANDIDATE:A11Y': {
    translation: 'Edit the custom value for {{CANDIDATE_VALUE}}',
  },
  'FILTER_BOX:CUSTOM_CANDIDATE_INPUT_DESCRIPTION': {
    translation: 'Enter a custom value for {{DIMENSION_NAME}}',
  },
  'FILTER_BOX:DUPLICATE_DIM_OPER': {
    translation:
      'A previous filter for "{{DIMENSION_NAME}}" with "{{OPERATOR}}" has already been created. Edit the previous filter?',
  },
  'FILTER_BOX:EDIT_OTHER': {
    translation: 'Edit Previous Filter',
  },
  'FILTER_BOX:IS_OPERATOR': {
    translation: 'is',
  },
  'FILTER_BOX:IS_NOT_OPERATOR': {
    translation: 'is not',
  },
  'FILTER_BOX:ONE_OF_OPERATOR': {
    translation: 'one of',
  },
  'FILTER_BOX:NOT_ONE_OF_OPERATOR': {
    translation: 'not one of',
  },
  'FILTER_BOX:CONTAINS_OPERATOR': {
    translation: 'contains',
  },
  'FILTER_BOX:NOT_CONTAINS_OPERATOR': {
    translation: 'not contains',
  },
  'FILTER_BOX:BEFORE_OPERATOR': {
    translation: 'before',
  },
  'FILTER_BOX:AFTER_OPERATOR': {
    translation: 'after',
  },
  'FILTER_BOX:BETWEEN_OPERATOR': {
    translation: 'between',
  },
  'FILTER_BOX:NOT_BETWEEN_OPERATOR': {
    translation: 'not between',
  },
  'FILTER_BOX:EXISTS_OPERATOR': {
    translation: 'exists',
  },
  'FILTER_BOX:NOT EXISTS_OPERATOR': {
    translation: 'does not exist',
  },
  'FILTER_BOX:CONFLICTS_OPERATOR': {
    translation: 'has conflicting values',
  },
  'FILTER_BOX:NO_CONFLICTS_OPERATOR': {
    translation: 'not have conflicting values',
  },
  'FILTER_BOX:IS': {
    translation: '{{DIMENSION_NAME}} is {{VALUE}}',
  },
  'FILTER_BOX:IS_NOT': {
    translation: '{{DIMENSION_NAME}} is not {{VALUE}}',
  },
  'FILTER_BOX:ONE_OF': {
    translation: '{{DIMENSION_NAME}} one of {{VALUE_LIST}}',
  },
  'FILTER_BOX:NOT_ONE_OF': {
    translation: '{{DIMENSION_NAME}} not one of {{VALUE_LIST}}',
  },
  'FILTER_BOX:CONTAINS': {
    translation: '{{DIMENSION_NAME}} contains {{VALUE}}',
  },
  'FILTER_BOX:NOT_CONTAINS': {
    translation: '{{DIMENSION_NAME}} does not contains {{VALUE}}',
  },
  'FILTER_BOX:BEFORE': {
    translation: '{{DIMENSION_NAME}} before {{VALUE}}',
  },
  'FILTER_BOX:AFTER': {
    translation: '{{DIMENSION_NAME}} after {{VALUE}}',
  },
  'FILTER_BOX:BETWEEN': {
    translation: '{{DIMENSION_NAME}} between {{VALUE_LIST}}',
  },
  'FILTER_BOX:NOT_BETWEEN': {
    translation: '{{DIMENSION_NAME}} not between {{VALUE_LIST}}',
  },
  'FILTER_BOX:EXISTS': {
    translation: '{{DIMENSION_NAME}} exists',
  },
  'FILTER_BOX:NOT EXISTS': {
    translation: '{{DIMENSION_NAME}} does not exist',
  },
  'FILTER_BOX:CONFLICTS': {
    translation: '{{DIMENSION_NAME}} has conflicting values',
  },
  'FILTER_BOX:NO_CONFLICTS': {
    translation: '{{DIMENSION_NAME}} does not have conflicting values',
  },
  'HDR:Logout': {
    translation: 'Log Out',
  },
  'HDR:MyApps': {
    translation: 'My Apps',
  },
  'HDR:SwitchAcct': {
    translation: 'Switch Account',
  },
  'HDR:ManageProfile': {
    translation: 'Manage Profile',
  },
  'HDR:Profile': {
    translation: 'Profile',
  },
  'HDR:Account#': {
    translation: 'Account #',
  },
  'HDR:AppsMenu': {
    translation: 'Apps Menu',
  },
  'HDR:Notifications': {
    translation: 'Notifications',
  },
  'HDR:Help': {
    translation: 'Help',
  },
  'HDR:help-selector': {
    translation: 'help selector',
  },
  'HDR:NEW_ITEMS:A11Y': {
    translation: 'New items',
  },
  'HDR:NEW_NOTIFICATIONS:A11Y': {
    translation: 'New notifications',
  },
  'HDR:NEW_HELP_MESSAGES:A11Y': {
    translation: 'New help messages',
  },
  'HDR:profile-menu': {
    translation: 'profile menu',
  },
  'HDR:profile-info:A11Y': {
    translation: 'profile information',
  },
  'HDR:profile-actions:A11Y': {
    translation: 'Account Navigation',
  },
  'HDR:show-site-nav': {
    translation: 'Show site navigation',
  },
  'HDR:site-nav': {
    translation: 'Site navigation',
  },
  'HDR:Footer': {
    translation: 'Site',
  },
  'HDR:AppInfo': {
    translation: 'App Information',
  },
  'HDR:CustomLogoAltText:A11Y': {
    translation: 'my account logo',
  },
  'subject-menu': {
    translation: '{{SUBJECT}} menu',
  },
  close: {
    translation: 'close',
  },
  help: {
    translation: 'help',
  },
  search: {
    translation: 'search',
  },
  settings: {
    translation: 'settings',
  },
  previousMenu: {
    translation: 'previous menu',
  },
  notifications: {
    translation: 'notifications',
  },
  logo: {
    translation: 'logo',
  },
  selectedNavItem: {
    translation: '- Selected',
  },
  updatedItem: {
    translation: '- Updated',
  },
  OK: {
    translation: 'OK',
  },
  back: {
    translation: 'Back',
  },
  loading: {
    translation: 'Loading...',
  },
  'PICKER:SELECT_INPUT_ACCESSIBILITY_TEST': {
    translation: 'Selector for item: {{ITEM_ID}}',
  },
  'PICKER:NO_RESULTS_MESSAGE': {
    translation: 'No results',
  },
  'PICKER:SHOW_MORE_FOLDERS_LINK': {
    translation: 'Show more',
  },
  'PICKER:SHOW_LESS_FOLDERS_LINK': {
    translation: 'Show less',
  },
  'PICKER:NO_SELECTION_MESSAGE': {
    translation: 'You have nothing selected',
  },
  'PICKER:LOADING_MESSAGE': {
    translation: 'Loading',
  },
  'PICKER:BROWSE_TAB_TITLE': {
    translation: 'Browse',
  },
  'PICKER:INLINE_FOLDER_BUTTON': {
    translation: 'Open Folder',
  },
  'PICKER:CLOSE_BUTTON_ACCESSIBILITY_TEXT': {
    translation: 'Close dialogue',
  },
  'PICKER:SELECTED_TAB_TITLE': {
    translation: 'Selected ({{SELECTED_COUNT}})',
  },
  'PICKER:SEARCH_BUTTON_ACCESSIBILITY_TEXT': {
    translation: 'Search',
  },
  'PICKER:CLEAR_BUTTON_ACCESSIBILITY_TEXT': {
    translation: 'Clear search',
  },
  'PICKER:SEARCH_TYPE_LABEL': {
    translation: 'Search by',
  },
  'PICKER:SEARCH_INPUT_LABEL': {
    translation: 'Search',
  },
  'PICKER:SEARCH_INPUT_PLACEHOLDER': {
    translation: 'Search',
  },
  'PICKER:ROOT_FOLDER_LINK_TEXT': {
    translation: 'All Folders',
  },
  'PICKER:SELECTED_COLUMN_HEADER': {
    translation: 'Select',
  },
  'PICKER:TABLE_ACTION_CELL_META': {
    translation: 'Meta Action',
  },
  'PICKER:SELECT_ALL': {
    translation: 'Select all',
  },
  'TIMEINPUT:BUTTON_ACCESSIBILITY_TEXT': {
    translation: '{{INPUT_LABEL}} Options',
  },
  'TIMEINPUT:MENU_GROUP_ACCESSIBILITY_TEXT': {
    translation: '{{INPUT_LABEL}} - Select using menu control',
  },
  'TUTORIALS:OPEN_CALLOUT': {
    translation: 'show tip',
  },
  'TUTORIALS:TRY_IT': {
    translation: 'Try it',
  },
  'TUTORIALS:NEXT_PAGE': {
    translation: 'Next',
  },
  'TUTORIALS:PREVIOUS_PAGE': {
    translation: 'Previous',
  },
  'TUTORIALS:ACKNOWLEDGED': {
    translation: 'Got It',
  },
  'TUTORIALS:PAGE_POSITION': {
    translation: 'Page {{CURRENT_PAGE}} of {{NUMBER_PAGES}}',
  },
  'TUTORIALS:NEW': {
    translation: 'NEW',
  },
  'ADDUSER:TITLE': {
    translation: 'Add Users',
  },
  'ADDUSER:MULTI_USER_PAYMENT_HEADER': {
    translation: 'Invite New User {{position}} of {{count}}',
  },
  'ADDUSER:SINGLE_USER_PAYMENT_HEADER': {
    translation: 'Invite New User',
  },
  'ADDUSER:DEFAULT_INFO_MSG': {
    translation: 'You will be charged when the new user activates the account.',
  },
  'ADDUSER:INVITED_SOME_TOAST_MSG': {
    translation:
      "You've invited {{count}} user(s). You'll only be charged when they sign up.",
  },
  'ADDUSER:INVITED_NONE_TOAST_MSG': {
    translation:
      'No seats or charges have been added to your account. You can add users anytime.',
  },
  'ADDUSER:UPDATE_CARD': {
    translation: 'Update Card',
  },
  'ADDUSER:NEXT': {
    translation: 'Next',
  },
  'ADDUSER:CANCEL': {
    translation: 'Cancel',
  },
  'ADDUSER:FULL_NAME': {
    translation: 'Full Name',
  },
  'ADDUSER:EMAIL': {
    translation: 'Email',
  },
  'ADDUSER:INVITEUSER': {
    translation: 'Invite User',
  },
  'ADDUSER:ADDITIONAL_USERS': {
    translation: 'Additional users',
  },
  'ADDUSER:ANNUALLY': {
    translation: 'Annually',
  },
  'ADDUSER:MONTHLY': {
    translation: 'Monthly',
  },
  'ADDUSER:ESTIMATED_PRORATED_ADJUSTMENTS': {
    translation: 'Estimated prorated adjustments',
  },
  'ADDUSER:ESTIMATED_TOTAL': {
    translation: 'Estimated total',
  },
  'ADDUSER:NEXT_BILL': {
    translation: 'Next Bill on {{date}}',
  },
  'ADDUSER:PAYMENT_METHOD': {
    translation: 'Payment method',
  },
  'ADDUSER:SUCCESS_INVITE_MSG': {
    translation: 'You successfully invited {{name}}!',
  },
  'ADDUSER:PERMISSION_PROFILE': {
    translation: 'Permission profile',
  },
  'ADDUSER:PERMISSION_DROPDOWN': {
    translation: 'Permission Profile Dropdown',
  },
  'ADDUSER:CC_EXPIRED': {
    translation: 'Your credit card expired on {{date}}.',
  },
  'ADDUSER:ADMINISTRATOR': {
    translation:
      'Can manage account and sending settings, users, and integrations.',
  },
  'ADDUSER:SENDER': {
    translation: 'Can send and sign envelopes, use and create templates.',
  },
  'ADDUSER:VIEWER': {
    translation: 'Can sign and view envelopes sent to them.',
  },
  'ADDUSER:NAME_VALIDATION': {
    translation: 'Enter a name to invite a user',
  },
  'ADDUSER:EMAIL_VALIDATION': {
    translation: 'Enter a valid email to invite a user',
  },
  'ADDUSER:LOADING': {
    translation: 'Loading',
  },
  'ADDUSER:ACTIVE_USER_EXISTS_ERROR_MSG': {
    translation: 'The user already exists. Edit the user in their profile',
  },
  'ADDUSER:CLOSED_USER_EXISTS_ERROR_MSG': {
    translation:
      'A closed user with this email already exists. You can reactivate them in the ACTIONS menu',
  },
  'ADDUSER:USER_AWAITING_INFO_MSG': {
    translation:
      "{{name}} has been invited but not yet activated. We've resent the activation email.",
  },
  'OLIVE:AVATAR_COUNTER': {
    translation: '+{{NUM_AVATARS}}',
  },
  'OLIVE:BREADCRUMB:A11Y': {
    translation: 'Breadcrumb',
  },
  'OLIVE:BUTTON_GROUP:A11Y': {
    translation: 'Actions',
  },
  'OLIVE:CHARS_REMAINING': {
    translation: 'Characters remaining:',
  },
  'OLIVE:CHARS_OVER_LIMIT': {
    translation: 'Characters over limit:',
  },
  'OLIVE:CLOSE': {
    translation: 'Close',
  },
  'OLIVE:COLORPICKER_ARIA_LIVE_GRADIENT_ANOUNCEMENT:A11Y': {
    translation:
      'Saturation: {{SAT}}. Brightness: {{VAL}}. Hex color value {{HEXCODE}}',
  },
  'OLIVE:COLORPICKER_ARIA_LIVE_HEX_INPUT_ANOUNCEMENT:A11Y': {
    translation: 'Hex color value {{HEXCODE}} selected',
  },
  'OLIVE:COLORPICKER_ARIA_LIVE_HUE_SLIDER_ANOUNCEMENT:A11Y': {
    translation: 'Hex color value {{HEXCODE}}',
  },
  'OLIVE:COLORPICKER_COLOR_PICKER:A11Y': {
    translation: 'Color picker',
  },
  'OLIVE:COLORPICKER_COLOR_SELECTOR:A11Y': {
    translation: 'Color selector',
  },
  'OLIVE:COLORPICKER_FILEDROP_IMAGE:A11Y': {
    translation: 'Image color palette',
  },
  'OLIVE:COLORPICKER_FILEDROP_TEXT': {
    translation: 'Drop image file here',
  },
  'OLIVE:COLORPICKER_GRADIENT:A11Y': {
    translation: 'Gradient color palette',
  },
  'OLIVE:COLORPICKER_HEX_ERROR_MSG_HASH': {
    translation: 'CSS hex codes must begin with a (#) hash symbol.',
  },
  'OLIVE:COLORPICKER_HEX_ERROR_MSG_INVALID': {
    translation: 'You entered an invalid hex value.',
  },
  'OLIVE:COLORPICKER_HIDDEN_INSTRUCTIONS_COLOR_SELECTOR:A11Y': {
    translation:
      'Select saturation using the right and left arrow keys. Select brightness using the up and down arrow keys.',
  },
  'OLIVE:COLORPICKER_HIDDEN_INSTRUCTIONS_HEX_INPUT:A11Y': {
    translation:
      'Valid 3-digit and 6-digit hex codes are allowed. Hex codes must begin with a hash symbol.',
  },
  'OLIVE:COLORPICKER_HIDDEN_INSTRUCTIONS_LOUPE:A11Y': {
    translation:
      'Use the up, down, right, and left arrow keys to move the color selector cursor.',
  },
  'OLIVE:COLORPICKER_HUE_SLIDER:A11Y': {
    translation: 'Hue',
  },
  'OLIVE:COLORPICKER_PALETTE_TOGGLE_BTN:A11Y': {
    translation: 'More color swatches',
  },
  'OLIVE:COLORPICKER_SAVED_COLORS:A11Y': {
    translation: 'Saved color swatches',
  },
  'OLIVE:COLORPICKER_SWATCHES:A11Y': {
    translation: 'Color swatches',
  },
  'OLIVE:COLORPICKER_TOGGLE_BTN_GRADIENT': {
    translation: 'Select from gradient',
  },
  'OLIVE:COLORPICKER_TOGGLE_BTN_IMAGE': {
    translation: 'Select from image',
  },
  'OLIVE:COLORPICKER_SWATCH_BLACK:A11Y': {
    translation: 'Black',
  },
  'OLIVE:COLORPICKER_SWATCH_DARK_GRAY:A11Y': {
    translation: 'Dark Gray',
  },
  'OLIVE:COLORPICKER_SWATCH_GRAY:A11Y': {
    translation: 'Gray',
  },
  'OLIVE:COLORPICKER_SWATCH_LIGHT_GRAY:A11Y': {
    translation: 'Light Gray',
  },
  'OLIVE:COLORPICKER_SWATCH_WHITE:A11Y': {
    translation: 'White',
  },
  'OLIVE:COLORPICKER_SWATCH_DARK_RED:A11Y': {
    translation: 'Dark Red',
  },
  'OLIVE:COLORPICKER_SWATCH_RED:A11Y': {
    translation: 'Red',
  },
  'OLIVE:COLORPICKER_SWATCH_ORANGE:A11Y': {
    translation: 'Orange',
  },
  'OLIVE:COLORPICKER_SWATCH_YELLOW:A11Y': {
    translation: 'Yellow',
  },
  'OLIVE:COLORPICKER_SWATCH_LIME:A11Y': {
    translation: 'Lime',
  },
  'OLIVE:COLORPICKER_SWATCH_AQUA:A11Y': {
    translation: 'Aqua',
  },
  'OLIVE:COLORPICKER_SWATCH_ROYAL_BLUE:A11Y': {
    translation: 'Royal Blue',
  },
  'OLIVE:COLORPICKER_SWATCH_BLUE:A11Y': {
    translation: 'Blue',
  },
  'OLIVE:COLORPICKER_SWATCH_VIOLET:A11Y': {
    translation: 'Violet',
  },
  'OLIVE:COLORPICKER_SWATCH_FUCHSIA:A11Y': {
    translation: 'Fuchsia',
  },
  'OLIVE:COLORPICKER_SWATCH_FIRE_BRICK:A11Y': {
    translation: 'Fire Brick',
  },
  'OLIVE:COLORPICKER_SWATCH_SALMON:A11Y': {
    translation: 'Salmon',
  },
  'OLIVE:COLORPICKER_SWATCH_LIGHT_ORANGE:A11Y': {
    translation: 'Light Orange',
  },
  'OLIVE:COLORPICKER_SWATCH_LIGHT_YELLOW:A11Y': {
    translation: 'Light Yellow',
  },
  'OLIVE:COLORPICKER_SWATCH_LIGHT_GREEN:A11Y': {
    translation: 'Light Green',
  },
  'OLIVE:COLORPICKER_SWATCH_MEDIUM_STEEL_BLUE:A11Y': {
    translation: 'Medium Steel Blue',
  },
  'OLIVE:COLORPICKER_SWATCH_CORNFLOWER_BLUE:A11Y': {
    translation: 'Cornflower Blue',
  },
  'OLIVE:COLORPICKER_SWATCH_SKY_BLUE:A11Y': {
    translation: 'Sky Blue',
  },
  'OLIVE:COLORPICKER_SWATCH_LIGHT_VIOLET:A11Y': {
    translation: 'Light Violet',
  },
  'OLIVE:COLORPICKER_SWATCH_MAUVE:A11Y': {
    translation: 'Mauve',
  },
  'OLIVE:COLORPICKER_SWATCH_PALE_ORANGE_RED:A11Y': {
    translation: 'Pale Orange Red',
  },
  'OLIVE:COLORPICKER_SWATCH_PINK:A11Y': {
    translation: 'Pink',
  },
  'OLIVE:COLORPICKER_SWATCH_PALE_ORANGE:A11Y': {
    translation: 'Pale Orange',
  },
  'OLIVE:COLORPICKER_SWATCH_PALE_YELLOW:A11Y': {
    translation: 'Pale Yellow',
  },
  'OLIVE:COLORPICKER_SWATCH_PALE_GREEN:A11Y': {
    translation: 'Pale Green',
  },
  'OLIVE:COLORPICKER_SWATCH_LIGHT_CYAN:A11Y': {
    translation: 'Light Cyan',
  },
  'OLIVE:COLORPICKER_SWATCH_POWDER_BLUE:A11Y': {
    translation: 'Powder Blue',
  },
  'OLIVE:COLORPICKER_SWATCH_PALE_BLUE:A11Y': {
    translation: 'Pale Blue',
  },
  'OLIVE:COLORPICKER_SWATCH_LAVENDER:A11Y': {
    translation: 'Lavender',
  },
  'OLIVE:COLORPICKER_SWATCH_PALE_MAUVE:A11Y': {
    translation: 'Pale Mauve',
  },
  'OLIVE:DEFAULT_PROFILE_IMAGE': {
    translation: 'Default profile image',
  },
  'OLIVE:DELETE': {
    translation: 'Delete',
  },
  'OLIVE:FORM_ELEMENT_REQUIRED:A11Y': {
    translation: '{{formElementLabel}}, required',
  },
  'OLIVE:SIGNED_BY': {
    translation: 'Signed by:',
  },
  'OLIVE:SIGNATURE_INITIALS': {
    translation: 'Initials',
  },
  'OLIVE:HIDE_CONTENT': {
    translation: 'Hide content',
  },
  'OLIVE:ICON_DISPLAYING_INITIALS': {
    translation: 'Icon displaying your initials',
  },
  'OLIVE:INITIALED_BY:A11Y': {
    translation: 'Initialed by:',
  },
  'OLIVE:MAX_FILE_SIZE': {
    translation: 'Maximum File Size: {{size}}',
  },
  'OLIVE:MENU': {
    translation: 'Menu',
  },
  'OLIVE:MESSAGE_DANGER:A11Y': {
    translation: 'Alert:',
  },
  'OLIVE:MESSAGE_EDIT:A11Y': {
    translation: 'Editing:',
  },
  'OLIVE:MESSAGE_INFORMATION:A11Y': {
    translation: 'Information:',
  },
  'OLIVE:MESSAGE_SUCCESS:A11Y': {
    translation: 'Success:',
  },
  'OLIVE:MESSAGE_VIEW:A11Y': {
    translation: 'Viewing:',
  },
  'OLIVE:MESSAGE_WARNING:A11Y': {
    translation: 'Warning:',
  },
  'OLIVE:PAGINATION_CURRENT:A11Y': {
    translation: 'Page {{page}}',
  },
  'OLIVE:PAGINATION_GOTO:A11Y': {
    translation: 'Go to page {{page}}',
  },
  'OLIVE:PAGINATION_PREV:A11Y': {
    translation: 'Go to previous page',
  },
  'OLIVE:PAGINATION_NEXT:A11Y': {
    translation: 'Go to next page',
  },
  'OLIVE:PAGINATION_SHOWING:A11Y': {
    translation:
      'Now showing page {{currentPage}}, {{firstItemCount}} through {{lastItemCount}} of {{totalItems}}',
  },
  'OLIVE:PAGINATION_RESULTS_PER_PAGE': {
    translation: 'Results per page',
  },
  'OLIVE:SHOW_CONTENT': {
    translation: 'Show content',
  },
  'OLIVE:SIDE_NAV:A11Y': {
    translation: 'Secondary',
  },
  'OLIVE:SIDE_NAV_BUTTON:A11Y': {
    translation: 'Show text labels',
  },
  'OLIVE:SKIP_TO_MAIN': {
    translation: 'Skip to main content',
  },
  'OLIVE:SUPPORTED_FORMATS': {
    translation: 'Supported Formats: {{formats}}',
  },
  'OLIVE:TEXT_COLOR': {
    translation: 'Text color',
  },
  'OLIVE:TIMELINE:A11Y': {
    translation: 'Timeline',
  },
  'OLIVE:TIMELINE_STEP_COMPLETE:A11Y': {
    translation: 'Completed step',
  },
  'OLIVE:TIMELINE_STEP_ERROR:A11Y': {
    translation: 'Error step',
  },
  'OLIVE:TIMELINE_STEP_INCOMPLETE:A11Y': {
    translation: 'Incomplete step',
  },
  'OLIVE:TIMELINE_STEP_SUCCESS:A11Y': {
    translation: 'Successful step',
  },
  'OLIVE:TIMELINE_STEP_WARNING:A11Y': {
    translation: 'Warning step',
  },
  'OLIVE:TREE:A11Y': {
    translation: 'View {{folderName}} items',
  },
  'OLIVE:TREE_DISCLOSURE:A11Y': {
    translation: 'View items',
  },
  'OLIVE:UPLOADED_PROFILE_IMAGE': {
    translation: 'Your uploaded profile image',
  },
  'OLIVE:LINK_OPENS_IN_NEW_WINDOW:A11Y': {
    translation: '{{LINK}}, opens in new window',
  },
  'OLIVE:WORKFLOWSTEP_NUMBER:A11Y': {
    translation: 'Step {{INDEX}}',
  },
  'OLIVE:WORKFLOWSTEP_COMPLETED:A11Y': {
    translation: 'Completed step {{INDEX}}',
  },
  'OLIVE:STATUS_SUCCESS': {
    translation: 'Success',
  },
  'OLIVE:STATUS_ALERT': {
    translation: 'Alert',
  },
  'OLIVE:STATUS_WARNING': {
    translation: 'Warning',
  },
  'OLIVE:TABLE_CAPTION:A11Y': {
    translation: ', column headers with buttons are sortable',
  },
  'OLIVE:TABLE_CONTENT:A11Y': {
    translation: 'Table content',
  },
  'OLIVE:RATING_EXCELLENT': {
    translation: 'Excellent',
  },
  'OLIVE:RATING_ABOVE_AVERAGE': {
    translation: 'Above average',
  },
  'OLIVE:RATING_NEUTRAL': {
    translation: 'Neutral',
  },
  'OLIVE:RATING_BELOW_AVERAGE': {
    translation: 'Below average',
  },
  'OLIVE:RATING_POOR': {
    translation: 'Poor',
  },
  'OLIVE:RATING_STAR_COUNT:A11Y': {
    one: '{{PLURAL_COUNT}} star, {{RATING_LEVEL}}',
    other: '{{PLURAL_COUNT}} stars, {{RATING_LEVEL}}',
  },
  'COLOR:PICK_COLORS': {
    translation: 'Pick colors from an image',
  },
  'COLOR:MUST_LOAD_IMAGE': {
    translation: 'To sample colors, you must first upload an image.',
  },
  'COLOR:MUST_PICK_IMAGE': {
    translation: 'To sample colors, you must first choose an image.',
  },
  'COLOR:CLICK_TO_SAMPLE': {
    translation: 'Click anywhere on the image below to sample a color.',
  },
  'COLOR:CHOOSE_IMAGE': {
    translation: 'Choose Image',
  },
  'COLOR:DONE': {
    translation: 'Done',
  },
  'COLOR:DRAG_HERE': {
    translation: 'Drag and drop image file here',
  },
  'COLOR:DROP_AREA_LABEL:A11Y': {
    translation: 'Drop area',
  },
  'COLOR:INVALID_DROP': {
    translation:
      'The file dropped is not a supported image file format. Please choose a file with one of these types:',
  },
  'COLOR:REPLACE_IMAGE_BUTTON': {
    translation: 'Replace Image',
  },
  'COLOR:HEX_INPUT': {
    translation: 'Hex',
  },
  'COLOR:HEX_INPUT_ERROR': {
    translation: 'Invalid',
  },
  'COLOR:HEX_INPUT_ERROR:A11Y': {
    translation: 'Invalid Hex Color',
  },
  'COLOR:SELECTED:A11Y': {
    translation: 'Selected Color',
  },
  'COLOR:RECENT_COLORS': {
    translation: 'Recent',
  },
  'COLOR:RECENT_COLOR:A11Y': {
    translation: 'Recently Used Color {{COLOR}}',
  },
  'COLOR:DISCRETE_SELECT-TAB:A11Y': {
    translation: 'Choose from matrix of colors - optimized for accessibility',
  },
  'COLOR:CONTINOUS_SELECT:A11Y': {
    translation: 'Choose from color palette',
  },
  'COLOR:DROPPER_SELECT:A11Y': {
    translation: 'Choose color from image',
  },
  'COMBOBOX:MULTISELECT_TEXT:A11Y': {
    translation: 'Multiple options may be selected',
  },
  'COMBOBOX:NO_RESULTS': {
    translation: 'No results',
  },
  'ZOOM_CONTROL:A11Y': {
    translation: 'Zoom control',
  },
  'ZOOM_CONTROL:ZOOM_IN:A11Y': {
    translation: 'Zoom in',
  },
  'ZOOM_CONTROL:ZOOM_OUT:A11Y': {
    translation: 'Zoom out',
  },
  'DATATABLE:SELECTED_COLUMN_HEADER': {
    translation: 'Selected',
  },
  'SEARCH:ALL_FILTER_INPUTS_TOGGLE_TEXT': {
    translation: 'All Filters',
  },
  'SEARCH:ALL_FILTER_INPUTS_TOGGLE:A11Y': {
    translation: 'Toggle all filters',
  },
  'SEARCH:APPLIED_FILTER:CLOSE:A11Y': {
    translation: 'Clear this applied filter',
  },
  'SEARCH:APPLIED_FILTER:DATE': {
    translation: '{{fromDate}} to {{toDate}}',
  },
  'SEARCH:APPLIED_FILTERS:CONTAINER_TITLE': {
    translation: 'Current Filters',
  },
  'SEARCH:DATE_COMPLETED_FILTER_DISPLAY_NAME': {
    translation: 'Date Completed',
  },
  'SEARCH:DATE_FILTER_LABEL_FROM': {
    translation: 'From',
  },
  'SEARCH:DATE_FILTER_LABEL_TO': {
    translation: 'To',
  },
  'SEARCH:DATE_FILTER_MENU_OPTION_ALL': {
    translation: 'All',
  },
  'SEARCH:DATE_FILTER_MENU_OPTION_CUSTOM': {
    translation: 'Custom',
  },
  'SEARCH:DATE_FILTER_MENU_OPTION_ONE_YEAR_AGO': {
    translation: 'Last 12 Months',
  },
  'SEARCH:DATE_FILTER_MENU_OPTION_SEVEN_DAYS_AGO': {
    translation: 'Last Week',
  },
  'SEARCH:DATE_FILTER_MENU_OPTION_SIX_MONTHS_AGO': {
    translation: 'Last 6 Months',
  },
  'SEARCH:DATE_FILTER_MENU_OPTION_THIRTY_DAYS_AGO': {
    translation: 'Last 30 Days',
  },
  'SEARCH:DATE_FILTER_MENU_OPTION_TWENTY_FOUR_HOURS_AGO': {
    translation: 'Last 24 Hours',
  },
  'SEARCH:ERROR_MESSAGE_LOADSETTINGSTIMEOUT': {
    translation:
      'The system is taking longer than usual and timed out. Please refresh the page and try again.',
  },
  'SEARCH:ERROR_MESSAGE_LOADSETTINGSUNKNOWNERROR': {
    translation:
      'We encountered a system error. Please refresh the page and try again.',
  },
  'SEARCH:ERROR_MESSAGE_LOADPREVIEWERROR': {
    translation: 'Failed loading document for envelope id: {{envelopeId}}',
  },
  'SEARCH:ERROR_MESSAGE_PERFORMSEARCHTIMEOUT': {
    translation: 'The system is taking longer than usual and timed out.',
  },
  'SEARCH:ERROR_MESSAGE_PERFORMSEARCHUNKNOWNERROR': {
    translation: 'We encountered a system error. Please try again.',
  },
  'SEARCH:ERROR_MESSAGE_SEARCHDISABLED': {
    translation:
      'You don’t have access to this search. To request access, contact your administrator.',
  },
  'SEARCH:ERROR_RETRY_BUTTON': {
    translation: 'Retry',
  },
  'SEARCH:FORM_ANY_DATE': {
    translation: 'Any',
  },
  'SEARCH:FORM_APPLY': {
    translation: 'Apply',
  },
  'SEARCH:FORM_CANCEL': {
    translation: 'Cancel',
  },
  'SEARCH:FORM_RESET': {
    translation: 'Reset',
  },
  'SEARCH:LOADING_INDICATOR_LABEL': {
    translation: 'Loading',
  },
  'SEARCH:LOADING_INDICATOR_LABEL_WITH_ELLIPSIS': {
    translation: 'Loading...',
  },
  'SEARCH:NO_SEARCH_RESULTS': {
    translation:
      'We couldn\'t find any results for "{{searchTerm}}" and those filters.',
  },
  'SEARCH:PAGE_CONTEXT_SHOW_APPROXIMATE_RESULTS': {
    translation:
      'Showing <Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber />+',
  },
  'SEARCH:PAGE_CONTEXT_SHOW_RESULTS': {
    translation:
      'Showing <Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber />',
  },
  'SEARCH:PAGE_CONTEXT_SHOWING_RESULTS': {
    translation: 'Showing {{range}} of {{total}}',
  },
  'SEARCH:PREVIEW_ENVELOPE_BUTTON:A11Y': {
    translation: 'Click to preview envelope',
  },
  'SEARCH:SURVEY_DESCRIPTION': {
    translation: 'Any problems using search?',
  },
  'SEARCH:SURVEY_CALL_TO_ACTION': {
    translation: 'Any problems using search? <Link>Tell us about it.</Link>',
  },
  'SEARCH:SURVEY_IMAGE': {
    translation: 'Search survey image',
  },
  'SEARCH:SURVEY_LINK_PLACEHOLDER': {
    translation: 'Tell us all about it!',
  },
  'SEARCH:SEARCH_BAR_INPUT_LABEL:A11Y': {
    translation: 'Search Input',
  },
  'SEARCH:SEARCH_INPUT_PLACEHOLDER': {
    translation: 'Search your envelopes',
  },
  'SEARCH:RECIPIENT_FILTER_DISPLAY_NAME': {
    translation: 'Recipient',
  },
  'SEARCH:RECIPIENT_FILTER_MENU_OPTION_ANYONE': {
    translation: 'Received by Anyone',
  },
  'SEARCH:RECIPIENT_FILTER_MENU_OPTION_ME': {
    translation: 'Received by Me',
  },
  'SEARCH:RECIPIENT_FILTER_MENU_OPTION_OTHER': {
    translation: 'Received by Other',
  },
  'SEARCH:RESULTS_ACTION_ITEM_VIEW': {
    translation: 'View',
  },
  'SEARCH:RESULTS_SHOW_APPROXIMATE_COUNT_CONTEXT': {
    translation:
      'Showing <Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber />+ Results',
  },
  'SEARCH:RESULTS_SHOW_COUNT_CONTEXT': {
    translation:
      'Showing <Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber /> Results',
  },
  'SEARCH:RESULTS_COUNT_CONTEXT': {
    translation: 'Showing {{RANGE}} of {{TOTAL_RESULT_COUNT}} Results',
  },
  'SEARCH:RESULTS_COUNT_NONE': {
    translation: 'No Results',
  },
  'SEARCH:SENDER_FILTER_DISPLAY_NAME': {
    translation: 'Sender',
  },
  'SEARCH:SENDER_FILTER_MENU_OPTION_ANYONE': {
    translation: 'Sent by Anyone',
  },
  'SEARCH:SENDER_FILTER_MENU_OPTION_ME': {
    translation: 'Sent by Me',
  },
  'SEARCH:SENDER_FILTER_MENU_OPTION_OTHER': {
    translation: 'Sent by Other',
  },
  'SEARCH:SUBMIT_SEARCH_BUTTON:A11Y': {
    translation: 'Submit Search',
  },
  'SEARCH:WELCOME_DESCRIPTION': {
    translation:
      'Locate completed agreements with matching field data or envelope properties.',
  },
  'SEARCH:WELCOME_HEADING_PART_ONE': {
    translation: 'At your fingertips.',
  },
  'SEARCH:WELCOME_HEADING_PART_TWO': {
    translation: 'Search within agreements.',
  },
  'SEARCH:WELCOME_IMAGE:A11Y': {
    translation: 'Search Welcome Image',
  },
  'SEARCH:BOOLEAN_FILTER_OPTION_TRUE': {
    translation: 'True',
  },
  'SEARCH:BOOLEAN_FILTER_OPTION_FALSE': {
    translation: 'False',
  },
  'SEARCH:BOOLEAN_FILTER_OPTION_NO_PREFERENCE': {
    translation: '-- No Preference --',
  },
  /** Search Admin Start */
  'SEARCH_ADMIN:ACCOUNT_SETTINGS_FAILED_TO_LOAD_ERROR_MSG': {
    translation: "We couldn't load your search settings. Try again.",
  },
  'SEARCH_ADMIN:ACCUMULATOR_TABLE_AVAILABLE': {
    translation: 'Available',
  },
  'SEARCH_ADMIN:ACCUMULATOR_TABLE_AVAILABLE_EMPTY_STATE_DESCRIPTION': {
    translation: 'Add new attributes for use in search configurations.',
  },
  'SEARCH_ADMIN:ACCUMULATOR_TABLE_AVAILABLE_EMPTY_STATE_HEADER': {
    translation: 'No attributes available',
  },
  'SEARCH_ADMIN:ACCUMULATOR_TABLE_SELECTED_EMPTY_STATE_DESCRIPTION': {
    translation: 'Select attributes from the Available tab.',
  },
  'SEARCH_ADMIN:ACCUMULATOR_TABLE_SELECTED_EMPTY_STATE_HEADER': {
    translation: 'No attributes selected',
  },
  'SEARCH_ADMIN:ACCUMULATOR_TABLE_SELECTED': {
    translation: 'Selected ({{NUM_SELECTED}})',
  },
  'SEARCH_ADMIN:ACTION_MENU_BUTTON_TEXT': {
    translation: 'Actions',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_ADD_FROM_LIBRARY': {
    translation: 'Add from Index',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_BRING_TO_FRONT': {
    translation: 'Bring to Front',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_CLEAR_ALL_SELECTED_FIELDS': {
    translation: 'Clear All',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_COPY_ALL_FIELDS_FROM_FILTERS_SECTION': {
    translation: 'Copy All from Filters',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_COPY_ALL_FIELDS_FROM_VIEW_SECTION': {
    translation: 'Copy All from View',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_DETAILS': {
    translation: 'View Details',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_MOVE_BACKWARDS': {
    translation: 'Move Backwards',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_MOVE_FORWARD': {
    translation: 'Move Forward',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_REMOVE': {
    translation: 'Remove',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_RENAME': {
    translation: 'Rename',
  },
  'SEARCH_ADMIN:ACTION_MENU_ITEM_SEND_TO_BACK': {
    translation: 'Send to Back',
  },
  'SEARCH_ADMIN:ACTION_TABLE_ACTIONS_HEADER': {
    translation: 'Actions',
  },
  'SEARCH_ADMIN:ADD_ATTRIBUTE_BTN_NONE_SELECTED': {
    translation: 'Add Attributes',
  },
  'SEARCH_ADMIN:ADD_ATTRIBUTE_BTN_SOME_SELECTED': {
    one: 'Add {{PLURAL_COUNT}} Attribute',
    other: 'Add {{PLURAL_COUNT}} Attributes',
  },
  'SEARCH_ADMIN:ADD_FROM_LIBRARY_MODAL_TITLE': {
    translation: 'Add from Index',
  },
  'SEARCH_ADMIN:ADD_FROM_LIBRARY_TABLE_EMPTY_STATE_BTN': {
    translation: 'Go To Attribute Index',
  },
  'SEARCH_ADMIN:ADD_FROM_LIBRARY_TABLE_EMPTY_STATE_DESCRIPTION': {
    translation:
      'No attributes available or all have been used. Add more to your attribute index and try again.',
  },
  'SEARCH_ADMIN:ADD_FROM_LIBRARY_TABLE_EMPTY_STATE_TITLE': {
    translation: 'No Attributes Available',
  },
  'SEARCH_ADMIN:ADD_TO_INDEX_BTN': {
    translation: 'Add to Index',
  },
  'SEARCH_ADMIN:ADD_TO_LIBRARY_FIELD_PROMOTION_SUCCESS_TOAST': {
    one: 'You added {{PLURAL_COUNT}} attribute.',
    other: 'You added {{PLURAL_COUNT}} attributes.',
  },
  'SEARCH_ADMIN:ADD_TO_LIBARRY_SELECT_SOURCES_STEP_DESCRIPTION': {
    translation:
      "Decide which attribute sources you want to use in search configurations. The available attributes were found in this account's completed envelopes.",
  },
  'SEARCH_ADMIN:ADD_TO_LIBARRY_SELECT_SOURCES_STEP_HEADER': {
    translation: 'Select',
  },
  'SEARCH_ADMIN:ADD_TO_LIBRARY_CUSTOMIZE_STEP_HEADER': {
    translation: 'Customize',
  },
  'SEARCH_ADMIN:ADD_TO_LIBRARY_MODAL_DESCRIPTION': {
    translation: 'Select the attributes to show in the display options.',
  },
  'SEARCH_ADMIN:ADD_TO_LIBRARY_PAGE_AVAILABLE_SOURCES_TABLE_ERROR_MSG': {
    translation: "We couldn't show any available sources. Try again.",
  },
  'SEARCH_ADMIN:ADD_TO_LIBRARY_PAGE_HEADER': {
    translation: 'Add To Index',
  },
  'SEARCH_ADMIN:ADD_TO_LIBRARY_REVIEW_STEP_HEADER': {
    translation: 'Review',
  },
  'SEARCH_ADMIN:ASSIGN_TO_GROUPS_MODAL_SAVE_BTN': {
    translation: 'Save Changes',
  },
  'SEARCH_ADMIN:ASSIGN_TO_GROUPS_SUCCESS_TOAST_MSG': {
    translation: 'Groups assigned to configuration updated.',
  },
  'SEARCH_ADMIN:ASSIGN_TO_GROUPS_MODAL_TITLE': {
    translation: 'Assign Groups',
  },
  'SEARCH_ADMIN:ASSIGN_TO_GROUPS_TABLE_AVAILABLE_TAB': {
    translation: 'All Groups ({{NUM_GROUPS}})',
  },
  'SEARCH_ADMIN:ASSIGN_TO_GROUPS_TABLE_DESCRIPTION': {
    translation:
      'Select groups to assign to the configuration. You can assign groups to multiple configurations.',
  },
  'SEARCH_ADMIN:ASSIGN_TO_GROUPS_TABLE_NAME_HEADER': {
    translation: 'Name',
  },
  'SEARCH_ADMIN:ASSIGN_TO_GROUPS_TABLE_USER_COUNT_HEADER': {
    translation: 'User Count',
  },
  'SEARCH_ADMIN:ATTRIBUTE_DETAILS_MODAL_DATE_FIRST_USED_TITLE': {
    translation: 'Date First Used',
  },
  'SEARCH_ADMIN:ATTRIBUTE_DETAILS_MODAL_DATE_LAST_USED_TITLE': {
    translation: 'Date Last Used',
  },
  'SEARCH_ADMIN:ATTRIBUTE_DETAILS_MODAL_DISPLAY_NAME_LABEL': {
    translation: 'Display Name',
  },
  'SEARCH_ADMIN:ATTRIBUTE_DETAILS_MODAL_DOCUMENT_COUNT_TITLE': {
    translation: 'Documents (approx)',
  },
  'SEARCH_ADMIN:ATTRIBUTE_DETAILS_MODAL_DONE_BUTTON': {
    translation: 'Save',
  },
  'SEARCH_ADMIN:ATTRIBUTE_DETAILS_MODAL_FIELD_TYPE_TITLE': {
    translation: 'Value Type',
  },
  'SEARCH_ADMIN:ATTRIBUTE_DETAILS_MODAL_SAVE_FAILURE_MESSAGE': {
    translation: "We couldn't update the display name. Try again.",
  },
  'SEARCH_ADMIN:ATTRIBUTE_DETAILS_MODAL_TITLE': {
    translation: 'Attribute Details',
  },
  'SEARCH_ADMIN:ATTRIBUTE_DETAILS_MODAL_TYPE_TITLE': {
    translation: 'User Input Type',
  },
  'SEARCH_ADMIN:ATTRIBUTE_IN_USE_BY_CONFIGURATION_DISPLAY_OPTIONS': {
    translation: 'display options',
  },
  'SEARCH_ADMIN:ATTRIBUTE_IN_USE_BY_CONFIGURATION_SCOPE_PARAMETERS': {
    translation: 'scope parameters',
  },
  'SEARCH_ADMIN:ATTRIBUTE_IN_USE_MODAL_CLOSE_BTN': {
    translation: 'Close',
  },
  'SEARCH_ADMIN:ATTRIBUTE_IN_USE_MODAL_DESCRIPTION': {
    translation:
      'Before removing {{ATTRIBUTE_NAME}} from the index, you need to remove it from the following configurations: <ConfigurationList></ConfigurationList>',
  },
  'SEARCH_ADMIN:ATTRIBUTE_IN_USE_MODAL_TITLE': {
    translation: 'Attribute currently in use',
  },
  'SEARCH_ADMIN:ATTRIBUTE_IN_USE_MODAL_USAGE_LIST_ITEM': {
    translation: '{{CONFIG_NAME}} in {{COMMA_SEPARATED_USAGE_LIST}}',
  },
  'SEARCH_ADMIN:ATTRIBUTES_TABLE_EMPTY_STATE_DESCRIPTION': {
    translation: 'Get started by choosing an action',
  },
  'SEARCH_ADMIN:ATTRIBUTES_TABLE_EMPTY_STATE_TITLE': {
    translation: 'No added attributes',
  },
  'SEARCH_ADMIN:ATTRIBUTES_TABLE_FILTERS_DESCRIPTION': {
    translation: 'Choose the attributes that are filterable.',
  },
  'SEARCH_ADMIN:ATTRIBUTES_TABLE_FILTERS_HEADER': {
    translation: 'Filter Attributes',
  },
  'SEARCH_ADMIN:ATTRIBUTES_TABLE_VIEW_DESCRIPTION': {
    translation:
      'Choose the attributes that can be displayed as columns in results.',
  },
  'SEARCH_ADMIN:ATTRIBUTES_TABLE_VIEW_HEADER': {
    translation: 'View Attributes',
  },
  'SEARCH_ADMIN:CONFIGURATION_ACTION_ITEM_DELETE': {
    translation: 'Delete Configuration',
  },
  'SEARCH_ADMIN:CELL_UNAVAILABLE': {
    translation: 'Data unavailable',
  },
  'SEARCH_ADMIN:CONFIGURATION_ACTION_ITEM_DETAILS': {
    translation: 'View Details',
  },
  'SEARCH_ADMIN:CONFIGURATION_ACTION_ITEM_DISABLE': {
    translation: 'Disable',
  },
  'SEARCH_ADMIN:CONFIGURATION_ACTION_ITEM_DUPLICATE': {
    translation: 'Duplicate',
  },
  'SEARCH_ADMIN:CONFIGURATION_ACTION_ITEM_ENABLE': {
    translation: 'Enable',
  },
  'SEARCH_ADMIN:CONFIGURATION_ACTION_ITEM_RESET': {
    translation: 'Restore to Original',
  },
  'SEARCH_ADMIN:CONFIGURATION_ACTION_MENU_BTN': {
    translation: 'Actions',
  },
  'SEARCH_ADMIN:CONFIGURATION_ASSIGN_GROUPS_BTN': {
    translation: 'Assign Groups',
  },
  'SEARCH_ADMIN:CONFIGURATION_ASSIGN_TO_GROUPS_SUBSECTION_DESCRIPTION': {
    translation:
      'Select groups to assign to the configuration. You can assign groups to multiple configurations.',
  },
  'SEARCH_ADMIN:CONFIGURATION_ASSIGN_TO_GROUPS_SUBSECTION_HEADER': {
    translation: 'Groups',
  },
  'SEARCH_ADMIN:CONFIGURATION_BASIC_INFORMATION_SECTION_HEADER': {
    translation: 'Basic Information',
  },
  'SEARCH_ADMIN:CONFIGURATION_DELETE_SECTION_DELETE_BTN': {
    translation: 'Delete',
  },
  'SEARCH_ADMIN:CONFIGURATION_DELETE_SECTION_DESCRIPTION': {
    translation:
      'Remove the configuration entirely and prevent others using it. This will not remove any underlying attribute data.',
  },
  'SEARCH_ADMIN:CONFIGURATION_DELETE_SECTION_HEADER': {
    translation: 'Delete Configuration',
  },
  'SEARCH_ADMIN:CONFIGURATION_DELETE_SUCCESS_TOAST': {
    translation: '{{CONFIG_NAME}} deleted.',
  },
  'SEARCH_ADMIN:CONFIGURATION_DESCRIPTION_INPUT_BOTTOM_LABEL': {
    translation: 'Characters remaining: {{REMAINING_CHARACTERS}}',
  },
  'SEARCH_ADMIN:CONFIGURATION_DESCRIPTION_INPUT_LABEL': {
    translation: 'Description',
  },
  'SEARCH_ADMIN:CONFIGURATION_DESCRIPTION_INPUT_PLACEHOLDER': {
    translation: 'Enter a description',
  },
  'SEARCH_ADMIN:CONFIGURATION_DETAIL_FOOTER_CANCEL_BTN': {
    translation: 'Cancel',
  },
  'SEARCH_ADMIN:CONFIGURATION_DETAIL_FOOTER_SAVE_BTN': {
    translation: 'Save Changes',
  },
  'SEARCH_ADMIN:CONFIGURATION_DETAIL_PAGE_DESCRIPTION': {
    translation: 'Modify the details of this configuration.',
  },
  'SEARCH_ADMIN:CONFIGURATION_DETAIL_PAGE_TITLE': {
    translation: 'Configuration Details',
  },
  'SEARCH_ADMIN:CONFIGURATION_DISABLED': {
    translation: 'Disabled',
  },
  'SEARCH_ADMIN:CONFIGURATION_DISPLAY_OPTIONS_SECTION_DESCRIPTION': {
    translation: 'Add additional filters or view options.',
  },
  'SEARCH_ADMIN:CONFIGURATION_DISPLAY_OPTIONS_SECTION_HEADER': {
    translation: 'Display Options',
  },
  'SEARCH_ADMIN:CONFIGURATION_ENABLED': {
    translation: 'Enabled',
  },
  'SEARCH_ADMIN:CONFIGURATION_EVERYONE_ASSIGNED': {
    translation: 'Everyone',
  },
  'SEARCH_ADMIN:CONFIGURATION_GROUPS_ASSIGNED_SUBSECTION_HEADER': {
    translation: 'Groups Assigned',
  },
  'SEARCH_ADMIN:CONFIGURATION_GROUPS_SECTION_CONFIGURATION_SUMMARY_HEADER': {
    translation: 'Configuration Summary',
  },
  'SEARCH_ADMIN:CONFIGURATION_GROUPS_SECTION_DESCRIPTION': {
    translation:
      'Select groups to assign to the configuration. You can assign groups to multiple configurations.',
  },
  'SEARCH_ADMIN:CONFIGURATION_GROUPS_SECTION_HEADER': {
    translation: 'Groups',
  },
  'SEARCH_ADMIN:CONFIGURATION_NAME_INPUT_EMPTY_ERROR_MESSAGE': {
    translation: 'Enter a display name',
  },
  'SEARCH_ADMIN:CONFIGURATION_NAME_INPUT_LABEL': {
    translation: 'Configuration Name',
  },
  'SEARCH_ADMIN:CONFIGURATION_NAME_INPUT_PLACEHOLDER': {
    translation: 'Enter a name',
  },
  'SEARCH_ADMIN:CONFIGURATION_NO_SCOPE_PARAMETERS': {
    translation: 'None',
  },
  'SEARCH_ADMIN:CONFIGURATION_RESULTS_SCOPE_SECTION_DESCRIPTION': {
    translation:
      'Give visibility to additional results using scopes. Users can only view contents of envelopes if they have permissions to do so.',
  },
  'SEARCH_ADMIN:CONFIGURATION_RESULTS_SCOPE_SECTION_HEADER': {
    translation: 'Results Scope',
  },
  'SEARCH_ADMIN:CONFIGURATION_REVIEW_SECTION_DESCRIPTION': {
    translation: 'Confirm the configuration information.',
  },
  'SEARCH_ADMIN:CONFIGURATION_REVIEW_SECTION_HEADER': {
    translation: 'Review',
  },
  'SEARCH_ADMIN:CONFIGURATION_REVIEW_SECTION_SAVE_BTN': {
    translation: 'Save Configuration',
  },
  'SEARCH_ADMIN:CONFIGURATION_SAVE_GENERIC_FAILURE_ERROR_MSG': {
    translation: 'The configuration failed to save. Please try again later.',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ADD_PARAMETER_APPLY_BTN': {
    translation: 'Apply',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ADD_PARAMETER_BTN': {
    translation: 'Add parameter',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ADD_PARAMETER_INPUT_ERROR_DUPLICATE': {
    translation: 'This is a duplicate of another scope parameter.',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ADD_PARAMETER_INPUT_ERROR_NUMBER': {
    translation: 'Enter a number.',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ADD_PARAMETER_INPUT_ERROR_UUID': {
    translation: 'GUID must match format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ADD_PARAMETER_INPUT_PLACEHOLDER': {
    translation: 'Enter a value',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ADD_PARAMETER_INPUT_PLACEHOLDER_NUMBER': {
    translation: 'Ex: 10000',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ADD_PARAMETER_INPUT_PLACEHOLDER_UUID': {
    translation: 'Enter a GUID',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ADD_PARAMETER_SEARCH_INPUT_PLACEHOLDER': {
    translation: 'Find by attribute name...',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_ATTRIBUTE_LIBRARY_DIMENSION_GROUP': {
    translation: 'Attribute Index',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_FILTER_BOX_DESCRIPTION': {
    translation:
      'Specify attribute values to define a parameter for matching results',
  },
  'SEARCH_ADMIN:CONFIGURATION_SCOPE_FILTER_BOX_LABEL': {
    translation: 'Scope Parameters',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_BODY_CREATED_SECTION_HEADING': {
    translation: 'Created',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_BODY_EDIT_LINK': {
    translation: 'Edit',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_BODY_FILTERS_SECTION_HEADING': {
    translation: 'Filter Attributes',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_BODY_GROUPS_SECTION_HEADING': {
    translation: 'Groups Assigned',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_BODY_SCOPE_SECTION_HEADING': {
    translation: 'Results Scope',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_BODY_VIEWS_SECTION_HEADING': {
    translation: 'View Attributes',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_GROUP_DESCRIPTION': {
    translation:
      'Create configurations to customize how your users can search. A configuration allows you to adjust the results scope, filters, and how users view results.',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_GROUP_HEADER': {
    translation: 'Configurations',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_GROUP_LOAD_MORE': {
    translation: 'Show more...',
  },
  'SEARCH_ADMIN:CONFIGURATION_SETTINGS_PANEL_STANDARD_CONFIG_BADGE': {
    translation: 'Default',
  },
  'SEARCH_ADMIN:CONFIGURATION_SUMMARY_SECTION_HEADER': {
    translation: 'Summary',
  },
  'SEARCH_ADMIN:CONFIGURATION_UPDATE_BASIC_INFORMATION_UPDATE_FAILURE_ERROR_MSG':
    { translation: 'Basic Information could not be saved' },
  'SEARCH_ADMIN:CONFIGURATION_UPDATE_ERROR_MSG': {
    translation: "We couldn't update the configuration. Try again.",
  },
  'SEARCH_ADMIN:CONFIGURATION_UPDATE_FAILURE_ERROR_MSG': {
    translation:
      'Some changes could not be saved. There was an error saving the following content: <ErrorList></ErrorList>',
  },
  'SEARCH_ADMIN:CONFIGURATION_UPDATE_FILTERS_UPDATE_FAILURE_ERROR_MSG': {
    translation: 'Filter Attributes could not be saved',
  },
  'SEARCH_ADMIN:CONFIGURATION_UPDATE_GROUP_ASSIGNMENT_UPDATE_FAILURE_ERROR_MSG':
    { translation: 'Some groups could not be saved' },
  'SEARCH_ADMIN:CONFIGURATION_UPDATE_SCOPE_UPDATE_FAILURE_ERROR_MSG': {
    translation: 'Results Scope could not be saved',
  },
  'SEARCH_ADMIN:CONFIGURATION_UPDATE_UNKNOWN_FAILURE_ERROR_MSG': {
    translation: 'An unknown error occured',
  },
  'SEARCH_ADMIN:CONFIGURATION_UPDATE_VIEWS_UPDATE_FAILURE_ERROR_MSG': {
    translation: 'View Attributes could not be saved',
  },
  'SEARCH_ADMIN:CONFIGURATION_ZERO_GROUPS_ASSIGNED': {
    translation: 'None',
  },
  'SEARCH_ADMIN:CONFIRM_DISCARD_SELECTION_MODAL_CALL_TO_ACTION': {
    translation: 'Continue editing your information before leaving this page?',
  },
  'SEARCH_ADMIN:CONFIRM_DISCARD_SELECTION_MODAL_PRIMARY_BUTTON': {
    translation: 'Exit',
  },
  'SEARCH_ADMIN:CONFIRM_DISCARD_SELECTION_MODAL_SECONDARY_BUTTON': {
    translation: 'Continue editing',
  },
  'SEARCH_ADMIN:CONFIRM_DISCARD_SELECTION_MODAL_TITLE': {
    translation: 'You have unsaved changes',
  },
  'SEARCH_ADMIN:CREATE_CONFIGURATION_SUCCESS_MESSAGE': {
    translation: '{{CONFIG_NAME}} created.',
  },
  'SEARCH_ADMIN:CREATE_NEW_CONFIGURATION_BTN': {
    translation: 'New Configuration',
  },
  'SEARCH_ADMIN:CREATE_NEW_CONFIGURATION_PAGE_DESCRIPTION': {
    translation:
      'Create configurations to customize how your users can search. A configuration allows you to adjust the results scope, filters, and how users view results.',
  },
  'SEARCH_ADMIN:CREATE_NEW_CONFIGURATION_PAGE_HEADER': {
    translation: 'Create a New Configuration',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_ACTION_MENU_REMOVE': {
    translation: 'Remove',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_ACTION_MENU_RESET_TO_DEFAULT': {
    translation: 'Restore to Original',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_ACTION_MENU_SHOW_DATA': {
    translation: 'Details',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_DISPLAY_NAME_EMPTY_ERROR': {
    translation: 'Enter a display name',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_NO_SOURCES_SELECTED_BUTTON': {
    translation: 'Select Sources',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_NO_SOURCES_SELECTED_MESSAGE': {
    translation:
      'You can add attributes by selecting them from the list of sources.',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_NO_SOURCES_SELECTED_TITLE': {
    translation: 'No sources selected',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_TABLE_DISPLAY_NAME_HEADER_CELL': {
    translation: 'Display Name',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_TABLE_DISPLAY_NAME_LABEL_A11Y': {
    translation: 'Display name',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_TABLE_SOURCE_NAME_HEADER_CELL': {
    translation: 'Attribute Source',
  },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_TABLE_SOURCE_SELECTION_NUMBER_HEADER_CELL_A11Y':
    {
      translation: 'Selection Number',
    },
  'SEARCH_ADMIN:CUSTOMIZE_SOURCES_TABLE_SOURCE_SELECTION_NUMBER': {
    translation: '{{SELECTION_NUMBER}}.',
  },
  'SEARCH_ADMIN:DATA_SOURCES_SETTINGS_PANEL_GROUP_DESCRIPTION': {
    translation:
      'Build an index of attributes to create configurations for your users.',
  },
  'SEARCH_ADMIN:DATA_OUT_OF_DATE_ERROR_MSG': {
    translation: 'Data shown is out of date. Reload page to update data.',
  },
  'SEARCH_ADMIN:DATA_SOURCES_SETTINGS_PANEL_GROUP_HEADER': {
    translation: 'Indexing',
  },
  'SEARCH_ADMIN:DATA_TYPE_DISPLAY_NAME_BOOLEAN': {
    translation: 'Boolean',
  },
  'SEARCH_ADMIN:DATA_TYPE_DISPLAY_NAME_DATE': {
    translation: 'Date',
  },
  'SEARCH_ADMIN:DATA_TYPE_DISPLAY_NAME_DECIMAL': {
    translation: 'Decimal',
  },
  'SEARCH_ADMIN:DATA_TYPE_DISPLAY_NAME_KEYWORD': {
    translation: 'Keyword',
  },
  'SEARCH_ADMIN:DATA_TYPE_DISPLAY_NAME_LIST': {
    translation: 'List',
  },
  'SEARCH_ADMIN:DATA_TYPE_DISPLAY_NAME_NUMBER': {
    translation: 'Number',
  },
  'SEARCH_ADMIN:DATA_TYPE_DISPLAY_NAME_PATH': {
    translation: 'Path',
  },
  'SEARCH_ADMIN:DATA_TYPE_DISPLAY_NAME_TEXT': {
    translation: 'Text',
  },
  'SEARCH_ADMIN:DATA_TYPE_DISPLAY_NAME_UNIQUE_ID': {
    translation: 'Unique ID',
  },
  'SEARCH_ADMIN:DATA_TYPE_FRIENDLY_TEXT_TEMPLATE': {
    translation: '{{DISPLAY_NAME}} ({{SUBTEXT}})',
  },
  'SEARCH_ADMIN:DATA_TYPE_SUBTEXT_EXACT_OR_RANGE_NUMBER': {
    translation: 'Exact or range',
  },
  'SEARCH_ADMIN:DATA_TYPE_SUBTEXT_EXACT_OR_RANGE_DATE': {
    translation: 'Exact or range',
  },
  'SEARCH_ADMIN:DATA_TYPE_SUBTEXT_EXACT_TERM': {
    translation: 'Exact term',
  },
  'SEARCH_ADMIN:DATA_TYPE_SUBTEXT_EXACT_URL': {
    translation: 'Exact URL',
  },
  'SEARCH_ADMIN:DATA_TYPE_SUBTEXT_EXACT': {
    translation: 'Exact',
  },
  'SEARCH_ADMIN:DATA_TYPE_SUBTEXT_PARTIAL_TERM': {
    translation: 'Partial term',
  },
  'SEARCH_ADMIN:DATA_TYPE_SUBTEXT_TRUE_OR_FALSE': {
    translation: 'True or false',
  },
  'SEARCH_ADMIN:DELETE_CONFIGURATION_ERROR_MSG': {
    translation: "We couldn't delete the configuration. Try again.",
  },
  'SEARCH_ADMIN:DELETE_CONFIGURATION_MODAL_CONFIRMATION_BTN': {
    translation: 'Delete',
  },
  'SEARCH_ADMIN:DELETE_CONFIGURATION_MODAL_DESCRIPTION_INLINE_MESSAGE': {
    translation: '{{CONFIG_NAME}} is assigned to these groups:',
  },
  'SEARCH_ADMIN:DELETE_CONFIGURATION_MODAL_DESCRIPTION_PARAGRAPH_ONE': {
    translation:
      'Permanently deleting the {{CONFIG_NAME}} configuration clears scope, display options, and assigned groups.',
  },
  'SEARCH_ADMIN:DELETE_CONFIGURATION_MODAL_DESCRIPTION_PARAGRAPH_TWO': {
    translation: 'You can disable the configuration instead of deleting it.',
  },
  'SEARCH_ADMIN:DELETE_CONFIGURATION_MODAL_TITLE': {
    translation: 'Delete Configuration',
  },
  'SEARCH_ADMIN:DISABLE_CONFIGURATION_BODY_PART_ONE': {
    translation:
      'If you disable {{CONFIG_NAME}}, any groups assigned to this configuration will no longer have access to the scope and display options.',
  },
  'SEARCH_ADMIN:DISABLE_CONFIGURATION_BODY_PART_TWO': {
    translation: 'Do you want to disable this configuration?',
  },
  'SEARCH_ADMIN:DISABLE_CONFIGURATION_CONFIRM_BTN': {
    translation: 'Disable',
  },
  'SEARCH_ADMIN:DISABLE_CONFIGURATION_ERROR_MSG': {
    translation: "We couldn't disable {{CONFIG_NAME}}. Try again.",
  },
  'SEARCH_ADMIN:DISABLE_CONFIGURATION_SUCCESS_MSG': {
    translation: '{{CONFIG_NAME}} disabled.',
  },
  'SEARCH_ADMIN:DISABLE_CONFIGURATION_TITLE': {
    translation: 'Disable Configuration',
  },
  'SEARCH_ADMIN:DISCARD_CHANGES_MODAL_DESCRIPTION': {
    translation: 'Continue editing your information before leaving this page?',
  },
  'SEARCH_ADMIN:DISCARD_CHANGES_MODAL_EXIT_BTN': {
    translation: 'Exit',
  },
  'SEARCH_ADMIN:DISCARD_CHANGES_MODAL_CONTINUE_EDITING_BTN': {
    translation: 'Continue Editing',
  },
  'SEARCH_ADMIN:DISCARD_CHANGES_MODAL_TITLE': {
    translation: 'You have unsaved changes',
  },
  'SEARCH_ADMIN:DISPLAY_OPTIONS_SELECTION_LIMIT_REACHED_TOAST_MSG': {
    translation:
      "Only {{ATTRIBUTE_LIMIT}} attributes are allowed in filters or view. You've reached the limit.",
  },
  'SEARCH_ADMIN:ENABLE_CONFIGURATION_SUCCESS_MSG': {
    translation: '{{CONFIG_NAME}} enabled.',
  },
  'SEARCH_ADMIN:ENABLE_CONFIGURATION_ERROR_MSG': {
    translation: "We couldn't enable {{CONFIG_NAME}}. Try again.",
  },
  'SEARCH_ADMIN:ERROR_TOAST_REFETCH_BTN': { translation: 'Reload' },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_COUNT_ZERO': {
    translation: 'Attributes',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_COUNT': {
    one: '{{PLURAL_COUNT}} Attribute',
    other: '{{PLURAL_COUNT}} Attributes',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_HYDRATION_DEFAULT_MSG': {
    translation:
      'Depending on the number of envelopes, indexing may take some time. You can leave this page and come back later.',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_HYDRATION_QUEUED_MSG': {
    translation:
      'Queued for indexing. The process begins when a new envelope is completed.',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_PAGE_DESCRIPTION': {
    translation:
      'Indexing allows a specific attribute to be used in search configurations.',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_PAGE_HEADER': {
    translation: 'eSignature Attribute Index',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_SETTINGS_PANEL_DESCRIPTION': {
    translation:
      'Indexing attributes including metadata, custom fields, and document field data in completed envelopes.',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_SETTINGS_PANEL_HEADER': {
    translation: 'eSignature Attributes',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_SETTINGS_PANEL_IN_PROGRESS_ACTION_BTN':
    {
      translation: 'In Progress',
    },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_SETTINGS_PANEL_MANAGE_ACTION_BTN': {
    translation: 'Manage',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_SETTINGS_PANEL_PREVIEW_BTN': {
    translation: 'Preview',
  },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_SETTINGS_PANEL_START_INDEXING_ACTION_BTN':
    {
      translation: 'Start Indexing',
    },
  'SEARCH_ADMIN:ESIGN_ATTRIBUTE_LIBRARY_SETTINGS_PANEL_WAITING_ACTION_BTN': {
    translation: 'Waiting',
  },
  'SEARCH_ADMIN:FIELD_DATA_TYPE_UNKNOWN': {
    translation: 'unknown',
  },
  'SEARCH_ADMIN:FIELD_SELECTION_TABLE_ACTION_BTN': {
    translation: 'View Sample',
  },
  'SEARCH_ADMIN:FIELD_SELECTION_TABLE_ACTIONS_HEADER': {
    translation: 'Actions',
  },
  'SEARCH_ADMIN:GROUP_ASSIGNMENT_FAILURE_ERROR_MSG': {
    translation:
      "We couldn't update group assignments for this configuration. Try again.",
  },
  'SEARCH_ADMIN:LOAD_CONFIGURATIONS_FAILURE_MSG_ATTRIBUTE_INDEX_PAGE': {
    translation:
      "We couldn't load the configurations. To make this data available in the list, try again.",
  },
  'SEARCH_ADMIN:HYDRATION_METER_ATTRIBUTE_LIBRARY_IN_PROGRESS_MSG': {
    translation:
      'More attributes become available as indexing continues. You can add to the attribute index while in progress.',
  },
  'SEARCH_ADMIN:HYDRATION_METER_COMPLETED_MESSAGE_TEXT': {
    translation: 'Indexing envelopes is complete.',
  },
  'SEARCH_ADMIN:HYDRATION_PROGRESS_BAR_LEFT_LABEL': {
    translation: 'Indexing...',
  },
  'SEARCH_ADMIN:HYDRATION_PROGRESS_BAR_RIGHT_LABEL': {
    translation: '{{PERCENTAGE}}% complete',
  },
  'SEARCH_ADMIN:HYDRATION_PROGRESS_FAILED_TO_LOAD_ERROR_MSG': {
    translation: "We couldn't finish indexing these attributes. Try again.",
  },
  'SEARCH_ADMIN:LOAD_CONFIGURATION_DETAILS_ERROR_MSG': {
    translation: "We couldn't load the configuration details. Try again.",
  },
  'SEARCH_ADMIN:LOAD_CONFIGURATIONS_ERROR_MSG': {
    translation: "We couldn't load the configurations list. Try again.",
  },
  'SEARCH_ADMIN:MAIN_PAGE_DESCRIPTION': {
    translation: 'Manage and configure how your users can search.',
  },
  'SEARCH_ADMIN:MAIN_PAGE_TITLE': {
    translation: 'Search Settings',
  },
  'SEARCH_ADMIN:MODAL_CANCEL_BTN': {
    translation: 'Cancel',
  },
  'SEARCH_ADMIN:MODAL_SAVE_BTN': {
    translation: 'Save',
  },
  'SEARCH_ADMIN:NO_RESULTS_EMPTY_STATE_CLEAR_SEARCH_BTN': {
    translation: 'Clear Search',
  },
  'SEARCH_ADMIN:NO_RESULTS_EMPTY_STATE_MESSAGE': {
    translation: 'The current search produced no results',
  },
  'SEARCH_ADMIN:NO_RESULTS_EMPTY_STATE_TITLE': {
    translation: 'No results',
  },
  'SEARCH_ADMIN:PROMOTED_FIELDS_FETCH_ERROR_MSG': {
    translation: "We couldn't load the list of attributes. Try again.",
  },
  'SEARCH_ADMIN:PROMOTED_FIELDS_PROMOTE_BATCH_FAILURE_ERROR_MSG': {
    translation:
      "Attributes added, except for the following, which weren't saved: <ErrorList></ErrorList>",
  },
  'SEARCH_ADMIN:PROMOTED_FIELDS_PROMOTE_FAILURE_ERROR_MSG': {
    translation: "We couldn't add these attributes. Try again.",
  },
  'SEARCH_ADMIN:PROMOTED_FIELD_STATUS_INITIATED': {
    translation: 'Pending...',
  },
  'SEARCH_ADMIN:PROMOTED_FIELD_STATUS_PROCESSING': {
    translation: 'Updating...',
  },
  'SEARCH_ADMIN:PROMOTED_FIELD_STATUS_COMPLETE': {
    translation: 'Ready',
  },
  'SEARCH_ADMIN:REMOVE_ATTRIBUTE_ERROR_MESSAGE': {
    translation: "We couldn't remove the attribute from the index. Try again.",
  },
  'SEARCH_ADMIN:REMOVE_ATTRIBUTE_MODAL_BODY': {
    translation:
      'If you remove the attribute {{ATTRIBUTE_NAME}}, you will have to add it to the index again to use its values in a search configuration.',
  },
  'SEARCH_ADMIN:REMOVE_ATTRIBUTE_MODAL_CONFIRM_BTN': {
    translation: 'Remove',
  },
  'SEARCH_ADMIN:REMOVE_ATTRIBUTE_MODAL_TITLE': {
    translation: 'Remove attribute',
  },
  'SEARCH_ADMIN:REMOVE_ATTRIBUTE_SUCCESS_MESSAGE': {
    translation: 'Attribute removed.',
  },
  'SEARCH_ADMIN:RESET_CONFIGURATION_MODAL_CONFIRM_BTN': {
    translation: 'Reset',
  },
  'SEARCH_ADMIN:RESET_CONFIGURATION_MODAL_DESCRIPTION_LIST_ITEM_ONE': {
    translation: 'Removes changes to view and filter attributes',
  },
  'SEARCH_ADMIN:RESET_CONFIGURATION_MODAL_DESCRIPTION_LIST_ITEM_TWO': {
    translation: 'Applies to this configuration only',
  },
  'SEARCH_ADMIN:RESET_CONFIGURATION_MODAL_DESCRIPTION_LIST_TITLE': {
    translation:
      'Going back to the original settings for your default configuration:',
  },
  'SEARCH_ADMIN:RESET_CONFIGURATION_MODAL_TITLE': {
    translation: 'Restore to Original',
  },
  'SEARCH_ADMIN:RESET_CONFIGURATION_SUCCESS_TOAST': {
    translation: 'Standard configuration reset.',
  },
  'SEARCH_ADMIN:RESET_FIELD_CUSTOMIZATION_TOAST_MESSAGE': {
    translation:
      '{{SOURCE_DISPLAY_NAME}} restored to its default display name.',
  },
  'SEARCH_ADMIN:REVIEW_SOURCES_ACTION_MENU_CUSTOMIZE_BUTTON': {
    translation: 'Customize',
  },
  'SEARCH_ADMIN:REVIEW_SOURCES_ACTION_MENU_REMOVE_BUTTON': {
    translation: 'Remove',
  },
  'SEARCH_ADMIN:REVIEW_SOURCES_SAVE_CALL_TO_ACTION': {
    translation: 'Add Attributes',
  },
  'SEARCH_ADMIN:SEARCH_INPUT_CLEAR_BTN_A11Y': {
    translation: 'Clear',
  },
  'SEARCH_ADMIN:SEARCH_VISIBILITY_DROPDOWN_LABEL': {
    translation: 'Search Visibility',
  },
  'SEARCH_ADMIN:SEARCH_VISIBILITY_OPTION_ADMINS_ONLY': {
    translation: 'Administrators',
  },
  'SEARCH_ADMIN:SEARCH_VISIBILITY_OPTION_EVERYONE': {
    translation: 'All Users',
  },
  'SEARCH_ADMIN:SEARCH_VISIBILITY_OPTION_NONE': {
    translation: 'No Users',
  },
  'SEARCH_ADMIN:SEARCH_VISIBILITY_SECTION_DESCRIPTION': {
    translation:
      'Determine who can access the search interface. You can restrict access during setup and testing.',
  },
  'SEARCH_ADMIN:SEARCH_VISIBILITY_SECTION_HEADER': {
    translation: 'Visibility of Search Interface',
  },
  'SEARCH_ADMIN:SELECT_SOURCES_TABLE_COUNT_ZERO': {
    translation: 'Select from available attribute sources',
  },
  'SEARCH_ADMIN:SELECT_SOURCES_TABLE_COUNT': {
    one: '{{PLURAL_COUNT}} source selected',
    other: '{{PLURAL_COUNT}} sources selected',
  },
  'SEARCH_ADMIN:SETTINGS_PANEL_EDIT_BTN': {
    translation: 'Edit',
  },
  'SEARCH_ADMIN:SETTINGS_PANEL_MANAGE_BTN': {
    translation: 'Manage',
  },
  'SEARCH_ADMIN:SETTINGS_PANEL_START_BTN': {
    translation: 'Start',
  },
  'SEARCH_ADMIN:SOURCE_DATA_PREVIEW_MODAL_DONE_BUTTON': {
    translation: 'Done',
  },
  'SEARCH_ADMIN:SOURCE_DATA_PREVIEW_MODAL_TABLE_HEADER': {
    translation: 'Sample Values',
  },
  'SEARCH_ADMIN:SOURCE_DATA_PREVIEW_MODAL_TABLE_SUBTEXT': {
    translation:
      'View sample values as they appear in the data. Not all possible formats for the values are represented.',
  },
  'SEARCH_ADMIN:SOURCE_DATA_PREVIEW_MODAL_TITLE': {
    translation: 'Sample Source and Values',
  },
  'SEARCH_ADMIN:SOURCE_DATA_PREVIEW_SOURCE_NAME_LABEL': {
    translation: 'Attribute Source',
  },
  'SEARCH_ADMIN:SOURCE_DATA_PREVIEW_TABLE_FAILED_TO_LOAD_MESSAGE': {
    translation: "We couldn't load sample values. Try again.",
  },
  'SEARCH_ADMIN:SOURCE_DATA_PREVIEW_TABLE_LOADING_A11Y': {
    translation: 'Loading values',
  },
  'SEARCH_ADMIN:SOURCE_DATA_PREVIEW_TABLE_NO_RESULTS_MESSAGE': {
    translation: 'No results found',
  },
  'SEARCH_ADMIN:SOURCE_DATA_PREVIEW_TABLE_VALUE_HEADER': {
    translation: 'Value',
  },
  'SEARCH_ADMIN:SOURCE_REMOVED_TOAST_MESSAGE': {
    translation: '{{SOURCE_DISPLAY_NAME}} removed.',
  },
  'SEARCH_ADMIN:SOURCE_REMOVED_TOAST_UNDO_BUTTON': {
    translation: 'Undo',
  },
  'SEARCH_ADMIN:STANDARD_ATTRIBUTES_ADDED_MSG': {
    translation: 'Standard attributes have been added to the index.',
  },
  'SEARCH_ADMIN:STANDARD_CONFIGURATION_DEFAULT_DESCRIPTION': {
    translation: 'Default configuration',
  },
  'SEARCH_ADMIN:STANDARD_CONFIGURATION_DEFAULT_NAME': {
    translation: 'Standard',
  },
  'SEARCH_ADMIN:TABLE_ACTION_CONTAINER_ACTIONS_BTN': {
    translation: 'Actions',
  },
  'SEARCH_ADMIN:TABLE_ACTION_MENU_ITEM_SHOW_DATA': {
    translation: 'Show Details',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_CONFIGURATION_USE': {
    translation: 'Configuration Use',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_DISPLAY_NAME': {
    translation: 'Display Name',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_DOCUMENT_COUNT': {
    translation: 'Documents (approx)',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_LAST_MODIFIED': {
    translation: 'Last Modified',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_LAST_USED': {
    translation: 'Last Used',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_NAME': {
    translation: 'Name',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_STATUS': {
    translation: 'Status',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_SOURCE_NAME': {
    translation: 'Attribute Source',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_TYPE': {
    translation: 'User Input Type',
  },
  'SEARCH_ADMIN:TABLE_COLUMN_HEADER_VALUE_TYPE': {
    translation: 'Value Type',
  },
  'SEARCH_ADMIN:TABLE_DOCUMENT_COUNT_TOOLTIP': {
    translation: 'Approximate count',
  },
  'SEARCH_ADMIN:TABLE_FILTER_BTN_TEXT': {
    translation: 'Search',
  },
  'SEARCH_ADMIN:TABLE_FILTER_INPUT_A11Y': {
    translation: 'Filter table results search input',
  },
  'SEARCH_ADMIN:TABLE_FILTER_INPUT_TEXT': {
    translation: 'Find by name...',
  },
  'SEARCH_ADMIN:TABLE_LAST_USED_TOOLTIP': {
    translation: 'Last Used: {{DATE_TIME}}',
  },
  'SEARCH_ADMIN:TABLE_PROMOTED_FIELD_DISPLAY_NAME_TOOLTIP': {
    translation: 'Attribute Source: {{FIELD_PATH}}',
  },
  'SEARCH_ADMIN:TABLE_SELECTION_COUNT': {
    translation: '{{COUNT}} selected',
  },
  'SEARCH_ADMIN:TRUNCATED_LIST_OVERFLOW_INDICATOR': {
    translation: '+{{NUM_MORE_ITEMS}} more',
  },
  'SEARCH_ADMIN:UPDATE_CONFIGURATION_SUCCESS_MESSAGE': {
    translation: 'Configuration changes saved.',
  },
  'SEARCH_ADMIN:UPDATE_PROMOTED_FIELD_SUCCESS_TOAST_MESSAGE': {
    translation: '{{DISPLAY_NAME}} saved.',
  },
  'SEARCH_ADMIN:UPDATE_SEARCH_VISIBILITY_ERROR_MSG': {
    translation:
      "We couldn't save your update to search visibility. Select it again.",
  },
  'SEARCH_ADMIN:WORKFLOW_STEPPER_EDIT_BUTTON': {
    translation: 'Edit',
  },
  'SEARCH_ADMIN:WORKFLOW_STEPPER_FOOTER_NEXT': {
    translation: 'Next',
  },
  'SEARCH_ADMIN:WORKFLOW_STEPPER_VIEW_BUTTON': {
    translation: 'View',
  },
  /** Search Admin End */
  'T_A:{{duration}} day ago': {
    translation: '{{duration}} day ago',
  },
  'T_A:{{duration}} days ago': {
    translation: '{{duration}} days ago',
  },
  'T_A:{{duration}} hour ago': {
    translation: '{{duration}} hour ago',
  },
  'T_A:{{duration}} hours ago': {
    translation: '{{duration}} hours ago',
  },
  'T_A:Just Now': {
    translation: 'Just Now',
  },
  'T_A:{{duration}} minutes ago': {
    translation: '{{duration}} minutes ago',
  },
  'RECIP_CARD:ORDER:A11Y': {
    translation: 'Recipient order',
  },
  'RECIP_CARD:CUST_MENU_TRIGGER': {
    translation: 'Customize',
  },
  'RECIP_CARD:DELETE_RECIP:A11Y': {
    translation: 'Delete recipient',
  },
  'RECIP_CARD:DELETE_DRAWER:A11Y': {
    translation: 'Delete {{TITLE}}',
  },
  'RECIP_CARD:EXPAND_DRAWER:A11Y': {
    translation: 'Expand {{TITLE}}',
  },
  'RECIP_CARD:COLLAPSE_DRAWER:A11Y': {
    translation: 'Collapse {{TITLE}}',
  },
  'RECIP_CARD_GRP:CARD-LIST': {
    translation: 'Recipients',
  },
  'RECIP_CARD_GRP:EXPAND:A11Y': {
    translation: 'Expand Recipients',
  },
  'RECIP_CARD_GRP:COLLAPSE:A11Y': {
    translation: 'Collapse Recipients',
  },
  'ADMIN:BUTTON_GROUP_CANCEL_TEXT': {
    translation: 'Cancel',
  },
  'ADMIN:BUTTON_GROUP_SAVE_TEXT': {
    translation: 'Save',
  },
  'ADMIN:DETAILS_ACTION_BUTTON': {
    translation: 'Details',
  },
  'ADMIN:DISPLAY_ACTION_ITEM_MENU_BUTTON_TEXT': {
    translation: 'More Actions',
  },
  'ADMIN:LOADING_SPINNER_WITH_ELLIPSIS_TEXT': {
    translation: 'Loading...',
  },
  'ADMIN:PAGING_FIRST': {
    translation: 'First',
  },
  'ADMIN:PAGING_LAST': {
    translation: 'Last',
  },
  'ADMIN:PAGING_PREV': {
    translation: 'Previous',
  },
  'ADMIN:PAGING_NEXT': {
    translation: 'Next',
  },
  // example: 1 - 10 of 50
  'ADMIN:PAGING_DETAILS_NO_ITEM_TYPE': {
    translation:
      '<Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber />',
  },
  // example: 1 - 10 of 50+
  'ADMIN:PAGING_DETAILS_APPROXIMATE_NO_ITEM_TYPE': {
    translation:
      '<Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber />+',
  },
  // example: 1 - 10 of 50 items
  'ADMIN:PAGING_DETAILS': {
    translation:
      '<Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber /> <ItemType />',
  },
  // example: 1 - 10 of 50+ items
  'ADMIN:PAGING_DETAILS_APPROXIMATE': {
    translation:
      '<Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber />+ <ItemType />',
  },
  'AUTO-SUGG:CLEAR:A11Y': {
    translation: 'clear {{LABEL}}',
  },
  'PHONE_INPUT:LABEL': {
    translation: 'Phone Number',
  },
  'PHONE_INPUT:EXTENSION': {
    translation: 'Extension',
  },
  'PHONE_INPUT:EXTENSION_ABBREVIATED': {
    translation: 'Ext.',
  },
  'PHONE_INPUT:INVALID_ERROR': {
    translation: 'Phone number is invalid',
  },
  'PHONE_INPUT:EMPTY_SEARCH_RESULT': {
    translation: 'No matches found',
  },
  'PHONE_INPUT:FREEFORM_OPTION': {
    translation: 'Not Applicable (—)',
  },
  'PHONE_INPUT:FREEFORM_SELECTED': {
    translation: '-',
  },
  'PHONE_INPUT:COUNTRY_SELECT_HEADING': {
    translation: 'International Calling Codes',
  },
  'PHONE_INPUT:SEARCH_LABEL': {
    translation: 'Search',
  },
  'PHONE_INPUT:TRIGGER_ARIA_LABEL': {
    translation: 'international calling code',
  },
  'DRAGGABLE_LIST:ON_FOCUS:A11Y': {
    translation: 'Select {{DESCRIPTION}}. Press Spacebar to reorder.',
  },
  'DRAGGABLE_LIST:HANDLE_INSTRUCTIONS:A11Y': {
    translation:
      'Activate to start a drag. When dragging you can use the arrow keys to move the item around and Escape to cancel. Ensure your screen reader is in focus mode or forms mode.',
  },
  'DRAGGABLE_LIST:ON_SELECTION:A11Y': {
    translation:
      '{{ITEM_DESCRIPTION}}, grabbed. Current position in list is {{CURRENT_POSITION}} OF {{NUMBER_OF_ITEMS}}. Press up and down arrow keys to change position, Spacebar to drop, Escape key to cancel.',
  },
  'DRAGGABLE_LIST:ON_REORDER:A11Y': {
    translation: `{{ITEM_DESCRIPTION}}. Current position in list is {{CURRENT_POSITION}} of {{NUMBER_OF_ITEMS}}.`,
  },
  'DRAGGABLE_LIST:ON_CANCEL:A11Y': {
    translation: '{{ITEM_DESCRIPTION}} reorder cancelled.',
  },
  'DRAGGABLE_LIST:ON_DROP:A11Y': {
    translation:
      '{{ITEM_DESCRIPTION}}, dropped. Final position in list is {{CURRENT_POSITION}} of {{NUMBER_OF_ITEMS}}.',
  },
  'DRAGGABLE_LIST:INVALID_DRAG_AREA:A11Y': {
    translation: 'You are dragging over an area that cannot be dropped on.',
  },
  'IPH:HELP_FOR_PAGE': {
    translation: 'Help for this Page',
  },
  'IPH:HELP_FOR_PAGE_NAME': {
    translation: 'Help for {{PAGE_NAME}}',
  },
  'IPH:GO_TO_SITE_DSC': {
    translation: 'Go to site: Docusign Support Center',
  },
  'IPH:DEFAULT_MENU_MSG': {
    translation:
      'Our content is currently unavailable. Please try again later or visit the support center.',
  },
  'IPH:DEFAULT_DRAWER_MSG': {
    translation:
      'Our content is currently unavailable. Please try again later or visit the support center.',
  },
  'IPH:FULL_ARTICLE_LINK': {
    translation: 'Full Article Link',
  },
  'IPH:BACK_TO_MENU': {
    translation: 'Back To help section',
  },
  'IPH:CLOSE_HELP': {
    translation: 'Close help and return to main navigation',
  },
  'IPH:ARTICLES_GUIDES': {
    translation: 'Articles & Guides',
  },
  'IPH:HELP_FORUM': {
    translation: 'Help Forum',
  },
  'IPH:CONTACT_US': {
    translation: 'Contact Us',
  },
  'IPH:HELP_ARTICLE_PREVIEW': {
    translation: 'Help Article Preview',
  },
  'IPH:MORE_INFORMATION': {
    translation: 'More information',
  },
  'IPH:HELP': {
    translation: 'Help',
  },
  CTY_AB: {
    translation: 'Abkhazia',
  },
  CTY_AC: {
    translation: 'Ascension Island',
  },
  CTY_AD: {
    translation: 'Andorra',
  },
  CTY_AE: {
    translation: 'United Arab Emirates',
  },
  CTY_AF: {
    translation: 'Afghanistan',
  },
  CTY_AG: {
    translation: 'Antigua and Barbuda',
  },
  CTY_AI: {
    translation: 'Anguilla',
  },
  CTY_AL: {
    translation: 'Albania',
  },
  CTY_AM: {
    translation: 'Armenia',
  },
  CTY_AO: {
    translation: 'Angola',
  },
  CTY_AQ: {
    translation: 'Antarctica',
  },
  CTY_AR: {
    translation: 'Argentina',
  },
  CTY_AS: {
    translation: 'American Samoa',
  },
  CTY_AT: {
    translation: 'Austria',
  },
  CTY_AU: {
    translation: 'Australia',
  },
  CTY_AW: {
    translation: 'Aruba',
  },
  CTY_AX: {
    translation: 'Åland Islands',
  },
  CTY_AZ: {
    translation: 'Azerbaijan',
  },
  CTY_BA: {
    translation: 'Bosnia and Herzegovina',
  },
  CTY_BB: {
    translation: 'Barbados',
  },
  CTY_BD: {
    translation: 'Bangladesh',
  },
  CTY_BE: {
    translation: 'Belgium',
  },
  CTY_BF: {
    translation: 'Burkina Faso',
  },
  CTY_BG: {
    translation: 'Bulgaria',
  },
  CTY_BH: {
    translation: 'Bahrain',
  },
  CTY_BI: {
    translation: 'Burundi',
  },
  CTY_BJ: {
    translation: 'Benin',
  },
  CTY_BL: {
    translation: 'Saint Barthélemy',
  },
  CTY_BM: {
    translation: 'Bermuda',
  },
  CTY_BN: {
    translation: 'Brunei Darussalam',
  },
  CTY_BO: {
    translation: 'Bolivia',
  },
  CTY_BQ: {
    translation: 'Bonaire, Sint Eustatius and Saba',
  },
  CTY_BR: {
    translation: 'Brazil',
  },
  CTY_BS: {
    translation: 'Bahamas',
  },
  CTY_BT: {
    translation: 'Bhutan',
  },
  CTY_BV: {
    translation: 'Bouvet Island',
  },
  CTY_BW: {
    translation: 'Botswana',
  },
  CTY_BY: {
    translation: 'Belarus',
  },
  CTY_BZ: {
    translation: 'Belize',
  },
  CTY_CA: {
    translation: 'Canada',
  },
  CTY_CC: {
    translation: 'Cocos (Keeling) Islands',
  },
  CTY_CD: {
    translation: 'Congo, Democratic Republic of the',
  },
  CTY_CF: {
    translation: 'Central African Republic',
  },
  CTY_CG: {
    translation: 'Congo',
  },
  CTY_CH: {
    translation: 'Switzerland',
  },
  CTY_CI: {
    translation: "Cote d'Ivoire",
  },
  CTY_CK: {
    translation: 'Cook Islands',
  },
  CTY_CL: {
    translation: 'Chile',
  },
  CTY_CM: {
    translation: 'Cameroon',
  },
  CTY_CN: {
    translation: 'China',
  },
  CTY_CO: {
    translation: 'Colombia',
  },
  CTY_CR: {
    translation: 'Costa Rica',
  },
  CTY_CU: {
    translation: 'Cuba',
  },
  CTY_CV: {
    translation: 'Cape Verde',
  },
  CTY_CW: {
    translation: 'Curaçao',
  },
  CTY_CX: {
    translation: 'Christmas Island',
  },
  CTY_CY: {
    translation: 'Cyprus',
  },
  CTY_CZ: {
    translation: 'Czech Republic',
  },
  CTY_DE: {
    translation: 'Germany',
  },
  CTY_DJ: {
    translation: 'Djibouti',
  },
  CTY_DK: {
    translation: 'Denmark',
  },
  CTY_DM: {
    translation: 'Dominica',
  },
  CTY_DO: {
    translation: 'Dominican Republic',
  },
  CTY_DZ: {
    translation: 'Algeria',
  },
  CTY_EC: {
    translation: 'Ecuador',
  },
  CTY_EE: {
    translation: 'Estonia',
  },
  CTY_EG: {
    translation: 'Egypt',
  },
  CTY_EH: {
    translation: 'Western Sahara',
  },
  CTY_ER: {
    translation: 'Eritrea',
  },
  CTY_ES: {
    translation: 'Spain',
  },
  CTY_ET: {
    translation: 'Ethiopia',
  },
  CTY_FI: {
    translation: 'Finland',
  },
  CTY_FJ: {
    translation: 'Fiji',
  },
  CTY_FK: {
    translation: 'Falkland Islands',
  },
  CTY_FM: {
    translation: 'Federated States of Micronesia',
  },
  CTY_FO: {
    translation: 'Faroe Islands',
  },
  CTY_FR: {
    translation: 'France',
  },
  CTY_GA: {
    translation: 'Gabon',
  },
  CTY_GB: {
    translation: 'United Kingdom',
  },
  CTY_GD: {
    translation: 'Grenada',
  },
  CTY_GE: {
    translation: 'Georgia',
  },
  CTY_GF: {
    translation: 'French Guiana',
  },
  CTY_GG: {
    translation: 'Guernsey',
  },
  CTY_GH: {
    translation: 'Ghana',
  },
  CTY_GI: {
    translation: 'Gibraltar',
  },
  CTY_GL: {
    translation: 'Greenland',
  },
  CTY_GM: {
    translation: 'Gambia',
  },
  CTY_GN: {
    translation: 'Guinea',
  },
  CTY_GP: {
    translation: 'Guadeloupe',
  },
  CTY_GQ: {
    translation: 'Equatorial Guinea',
  },
  CTY_GR: {
    translation: 'Greece',
  },
  CTY_GS: {
    translation: 'South Georgia and the South Sandwich Islands',
  },
  CTY_GT: {
    translation: 'Guatemala',
  },
  CTY_GU: {
    translation: 'Guam',
  },
  CTY_GW: {
    translation: 'Guinea-Bissau',
  },
  CTY_GY: {
    translation: 'Guyana',
  },
  CTY_HK: {
    translation: 'Hong Kong',
  },
  CTY_HM: {
    translation: 'Heard Island and McDonald Islands',
  },
  CTY_HN: {
    translation: 'Honduras',
  },
  CTY_HR: {
    translation: 'Croatia',
  },
  CTY_HT: {
    translation: 'Haiti',
  },
  CTY_HU: {
    translation: 'Hungary',
  },
  CTY_ID: {
    translation: 'Indonesia',
  },
  CTY_IE: {
    translation: 'Ireland',
  },
  CTY_IL: {
    translation: 'Israel',
  },
  CTY_IM: {
    translation: 'Isle of Man',
  },
  CTY_IN: {
    translation: 'India',
  },
  CTY_IO: {
    translation: 'British Indian Ocean Territory',
  },
  CTY_IQ: {
    translation: 'Iraq',
  },
  CTY_IR: {
    translation: 'Iran',
  },
  CTY_IS: {
    translation: 'Iceland',
  },
  CTY_IT: {
    translation: 'Italy',
  },
  CTY_JE: {
    translation: 'Jersey',
  },
  CTY_JM: {
    translation: 'Jamaica',
  },
  CTY_JO: {
    translation: 'Jordan',
  },
  CTY_JP: {
    translation: 'Japan',
  },
  CTY_KE: {
    translation: 'Kenya',
  },
  CTY_KG: {
    translation: 'Kyrgyzstan',
  },
  CTY_KH: {
    translation: 'Cambodia',
  },
  CTY_KI: {
    translation: 'Kiribati',
  },
  CTY_KM: {
    translation: 'Comoros',
  },
  CTY_KN: {
    translation: 'Saint Kitts and Nevis',
  },
  CTY_KP: {
    translation: 'North Korea',
  },
  CTY_KR: {
    translation: 'South Korea',
  },
  CTY_KW: {
    translation: 'Kuwait',
  },
  CTY_KY: {
    translation: 'Cayman Islands',
  },
  CTY_KZ: {
    translation: 'Kazakhstan',
  },
  CTY_LA: {
    translation: 'Laos',
  },
  CTY_LB: {
    translation: 'Lebanon',
  },
  CTY_LC: {
    translation: 'Saint Lucia',
  },
  CTY_LI: {
    translation: 'Liechtenstein',
  },
  CTY_LK: {
    translation: 'Sri Lanka',
  },
  CTY_LR: {
    translation: 'Liberia',
  },
  CTY_LS: {
    translation: 'Lesotho',
  },
  CTY_LT: {
    translation: 'Lithuania',
  },
  CTY_LU: {
    translation: 'Luxembourg',
  },
  CTY_LV: {
    translation: 'Latvia',
  },
  CTY_LY: {
    translation: 'Libya',
  },
  CTY_MA: {
    translation: 'Morocco',
  },
  CTY_MC: {
    translation: 'Monaco',
  },
  CTY_MD: {
    translation: 'Moldova',
  },
  CTY_ME: {
    translation: 'Montenegro',
  },
  CTY_MF: {
    translation: 'Saint Martin (French Part)',
  },
  CTY_MG: {
    translation: 'Madagascar',
  },
  CTY_MH: {
    translation: 'Marshall Islands',
  },
  CTY_MK: {
    translation: 'North Macedonia',
  },
  CTY_ML: {
    translation: 'Mali',
  },
  CTY_MM: {
    translation: 'Myanmar',
  },
  CTY_MN: {
    translation: 'Mongolia',
  },
  CTY_MO: {
    translation: 'Macao',
  },
  CTY_MP: {
    translation: 'Northern Mariana Islands',
  },
  CTY_MQ: {
    translation: 'Martinique',
  },
  CTY_MR: {
    translation: 'Mauritania',
  },
  CTY_MS: {
    translation: 'Montserrat',
  },
  CTY_MT: {
    translation: 'Malta',
  },
  CTY_MU: {
    translation: 'Mauritius',
  },
  CTY_MV: {
    translation: 'Maldives',
  },
  CTY_MW: {
    translation: 'Malawi',
  },
  CTY_MX: {
    translation: 'Mexico',
  },
  CTY_MY: {
    translation: 'Malaysia',
  },
  CTY_MZ: {
    translation: 'Mozambique',
  },
  CTY_NA: {
    translation: 'Namibia',
  },
  CTY_NC: {
    translation: 'New Caledonia',
  },
  CTY_NE: {
    translation: 'Niger',
  },
  CTY_NF: {
    translation: 'Norfolk Island',
  },
  CTY_NG: {
    translation: 'Nigeria',
  },
  CTY_NI: {
    translation: 'Nicaragua',
  },
  CTY_NL: {
    translation: 'Netherlands',
  },
  CTY_NO: {
    translation: 'Norway',
  },
  CTY_NP: {
    translation: 'Nepal',
  },
  CTY_NR: {
    translation: 'Nauru',
  },
  CTY_NU: {
    translation: 'Niue',
  },
  CTY_NZ: {
    translation: 'New Zealand',
  },
  CTY_OM: {
    translation: 'Oman',
  },
  CTY_OS: {
    translation: 'South Ossetia',
  },
  CTY_PA: {
    translation: 'Panama',
  },
  CTY_PE: {
    translation: 'Peru',
  },
  CTY_PF: {
    translation: 'French Polynesia',
  },
  CTY_PG: {
    translation: 'Papua New Guinea',
  },
  CTY_PH: {
    translation: 'Philippines',
  },
  CTY_PK: {
    translation: 'Pakistan',
  },
  CTY_PL: {
    translation: 'Poland',
  },
  CTY_PM: {
    translation: 'Saint Pierre and Miquelon',
  },
  CTY_PN: {
    translation: 'Pitcairn',
  },
  CTY_PR: {
    translation: 'Puerto Rico',
  },
  CTY_PS: {
    translation: 'Palestine',
  },
  CTY_PT: {
    translation: 'Portugal',
  },
  CTY_PW: {
    translation: 'Palau',
  },
  CTY_PY: {
    translation: 'Paraguay',
  },
  CTY_QA: {
    translation: 'Qatar',
  },
  CTY_RE: {
    translation: 'Reunion',
  },
  CTY_RO: {
    translation: 'Romania',
  },
  CTY_RS: {
    translation: 'Serbia',
  },
  CTY_RU: {
    translation: 'Russia',
  },
  CTY_RW: {
    translation: 'Rwanda',
  },
  CTY_SA: {
    translation: 'Saudi Arabia',
  },
  CTY_SB: {
    translation: 'Solomon Islands',
  },
  CTY_SC: {
    translation: 'Seychelles',
  },
  CTY_SD: {
    translation: 'Sudan',
  },
  CTY_SE: {
    translation: 'Sweden',
  },
  CTY_SG: {
    translation: 'Singapore',
  },
  CTY_SH: {
    translation: 'Saint Helena',
  },
  CTY_SI: {
    translation: 'Slovenia',
  },
  CTY_SJ: {
    translation: 'Svalbard and Jan Mayen',
  },
  CTY_SK: {
    translation: 'Slovakia',
  },
  CTY_SL: {
    translation: 'Sierra Leone',
  },
  CTY_SM: {
    translation: 'San Marino',
  },
  CTY_SN: {
    translation: 'Senegal',
  },
  CTY_SO: {
    translation: 'Somalia',
  },
  CTY_SR: {
    translation: 'Suriname',
  },
  CTY_SS: {
    translation: 'South Sudan',
  },
  CTY_ST: {
    translation: 'Sao Tome and Principe',
  },
  CTY_SV: {
    translation: 'El Salvador',
  },
  CTY_SX: {
    translation: 'Sint Maarten',
  },
  CTY_SY: {
    translation: 'Syria',
  },
  CTY_SZ: {
    translation: 'Swaziland',
  },
  CTY_TA: {
    translation: 'Tristan da Cunha',
  },
  CTY_TC: {
    translation: 'Turks and Caicos Islands',
  },
  CTY_TD: {
    translation: 'Chad',
  },
  CTY_TF: {
    translation: 'French Southern Territories',
  },
  CTY_TG: {
    translation: 'Togo',
  },
  CTY_TH: {
    translation: 'Thailand',
  },
  CTY_TJ: {
    translation: 'Tajikistan',
  },
  CTY_TK: {
    translation: 'Tokelau',
  },
  CTY_TL: {
    translation: 'Timor-Leste',
  },
  CTY_TM: {
    translation: 'Turkmenistan',
  },
  CTY_TN: {
    translation: 'Tunisia',
  },
  CTY_TO: {
    translation: 'Tonga',
  },
  CTY_TR: {
    translation: 'Türkiye',
  },
  CTY_TT: {
    translation: 'Trinidad and Tobago',
  },
  CTY_TV: {
    translation: 'Tuvalu',
  },
  CTY_TW: {
    translation: 'Taiwan',
  },
  CTY_TZ: {
    translation: 'Tanzania',
  },
  CTY_UA: {
    translation: 'Ukraine',
  },
  CTY_UG: {
    translation: 'Uganda',
  },
  CTY_UM: {
    translation: 'United States Minor Outlying Islands',
  },
  CTY_US: {
    translation: 'United States',
  },
  CTY_UY: {
    translation: 'Uruguay',
  },
  CTY_UZ: {
    translation: 'Uzbekistan',
  },
  CTY_VA: {
    translation: 'Holy See (Vatican City State)',
  },
  CTY_VC: {
    translation: 'Saint Vincent and the Grenadines',
  },
  CTY_VE: {
    translation: 'Venezuela',
  },
  CTY_VG: {
    translation: 'Virgin Islands, British',
  },
  CTY_VI: {
    translation: 'Virgin Islands, U.S.',
  },
  CTY_VN: {
    translation: 'Vietnam',
  },
  CTY_VU: {
    translation: 'Vanuatu',
  },
  CTY_WF: {
    translation: 'Wallis and Futuna',
  },
  CTY_WS: {
    translation: 'Samoa',
  },
  CTY_XK: {
    translation: 'Kosovo',
  },
  CTY_YE: {
    translation: 'Yemen',
  },
  CTY_YT: {
    translation: 'Mayotte',
  },
  CTY_ZA: {
    translation: 'South Africa',
  },
  CTY_ZM: {
    translation: 'Zambia',
  },
  CTY_ZW: {
    translation: 'Zimbabwe',
  },
  'ADM:ADD_ENTITY_BTN': {
    translation: 'Add Entity',
  },
  'ADM:ENTITY_NAME': {
    translation: 'Entity',
  },
  'ADM:ENTITY_PICKER_DESCRIPTION': {
    translation:
      'Choose an entity to enable the document to reuse data from previous documents.',
  },
  'ADM:ENTITY_PICKER_HEADER': {
    translation: 'Prefill Information',
  },
  'ADM:SEARCH_BTN': {
    translation: 'Search',
  },
  'ADM:SELECT_BTN': {
    translation: 'Select',
  },
  'ADM:CREATE_NEW_ENTITY_BTN': {
    translation: 'Create New Entity',
  },
  'ADM:EDIT_SEARCH_BTN': {
    translation: 'Edit Search',
  },
  'ADM:REMOVE_ENTITY_BTN': {
    translation: 'Remove Entity',
  },
  'ADM:SEARCH_TEXT': {
    translation: 'Search for an Entity',
  },
  'ADM:SELECT_ENTITY_ERROR': {
    translation: 'An error occurred while selecting an entity.',
  },
  'ADM:REMOVE_ENTITY_ERROR': {
    translation: 'An error occurred while removing the entity.',
  },
  'ADM:SEARCH_ENTITY_ERROR': {
    translation: 'An error occurred while searching for an entity.',
  },
  'ADM:CREATE_ENTITY_ERROR': {
    translation: 'An error occurred while creating a new entity.',
  },
  'ADM:FETCH_METADATA_ERROR': {
    translation: 'An error occurred while loading the data for this feature.',
  },
  'ADM:FETCH_ENTITY_ERROR': {
    translation: 'An error occurred while loading the selected entity.',
  },
  'ADM:NO_RESULTS_TEXT': {
    translation: 'No results. Create a new record, or adjust your search.',
  },
}

const enTranslations = flattenTranslations(enTranslationsWithInstructions)

type Plural = { one: string; other: string }

export default enTranslations as { [k in TranslationKeys]: string } | Plural
