export default (config: { size: number | string }) => {
  const { size } = config
  return {
    wrap: {
      display: 'inline-flex',
      lineHeight: 0,

      svg: {
        speak: 'none',
        height: size,
        width: size,
      },
    },
  } as const
}
