import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { MotionVariant } from '@ds/motion'
import { dataProps, mergeRefs, useHtmlDir } from '@ds/react-utils'
import { CustomPropTypes } from '../../../support'
import { useIsInk, useThemeStyles } from '../../../theming'
import { alignments, locations } from '../../Popover/index'
import type { MenuWithStateProps } from '../MenuWithState'
import styles from './styles'

export type MenuUIProps = Pick<
  MenuWithStateProps,
  | 'alignment'
  | 'children'
  | 'data-.*'
  | 'forwardedRef'
  | 'isNestedMenu'
  | 'location'
  | 'positionStatic'
  | 'preserveWidth'
> & { role?: string }

/**
 * The internal UI component to be used in Menu and SelectMenu.
 */
export function MenuUI({
  alignment = 'start',
  children,
  forwardedRef,
  isNestedMenu = false,
  location = 'below',
  positionStatic = false,
  preserveWidth = false,
  role,
  ...restProps
}: MenuUIProps) {
  const isInk = useIsInk()
  const textDirection = useHtmlDir()
  const [minWidth, setMinWidth] = useState<number>()
  const containerRef = useRef<HTMLDivElement>(null)
  const isRTL = textDirection === 'rtl'

  const sx = useThemeStyles(styles, {
    isRTL,
    alignment,
    location,
    positionStatic,
  })

  const maybePreserveWidth = useCallback(() => {
    const { current: containerElement } = containerRef
    if (preserveWidth && containerElement) {
      const { width } = containerElement.getBoundingClientRect()
      setMinWidth(width)
    }
  }, [preserveWidth])

  return (
    <>
      {/* Background Animation */}
      <MotionVariant
        aria-hidden="true"
        css={sx.menuUIBackground}
        custom={{ isNestedMenu, isInk }}
        initial="initial"
        animate="open"
        exit="exit"
        variants={sx.motionVariants.background}
      />
      {/* MenuItems Animation */}
      <MotionVariant
        {...dataProps(restProps)}
        css={sx.menuUIMenuItems}
        custom={{ isNestedMenu, isInk }}
        initial="initial"
        animate="open"
        exit="exit"
        onAnimationEnd={maybePreserveWidth}
        variants={sx.motionVariants.menuItems}
        style={{ minWidth }}
        ref={mergeRefs(containerRef, forwardedRef)}
        role={role}
      >
        {children}
      </MotionVariant>
    </>
  )
}

MenuUI.propTypes = {
  alignment: PropTypes.oneOf(alignments),
  children: PropTypes.node.isRequired,
  forwardedRef: CustomPropTypes.ReactRef,
  isNestedMenu: PropTypes.bool,
  location: PropTypes.oneOf(locations),
  positionStatic: PropTypes.bool,
  preserveWidth: PropTypes.bool,
}
