import { inkFormTokens, inkItemTokens } from '@ds/tokens'

const tokens = {
  size: '20px',

  defaultHoverShadowColor: inkFormTokens.formControlHighlightBgColorDefault,
  defaultActiveShadowColor: inkItemTokens.itemBgColorActive,

  selectedFillColor: inkFormTokens.formControlBgColorSelected,
  selectedHoverFillColor: inkFormTokens.formControlBgColorHover,
  selectedActiveFillColor: inkFormTokens.formControlBgColorSelectedActive,
} as const

export default tokens
