import { oliveButtonTokens, oliveCTATokens, oliveFontTokens } from '@ds/tokens'

const tokens = {
  defaultBackgroundColor: oliveCTATokens.ctaBgColorTertiaryDefault,
  defaultHoverBackgroundColor: oliveCTATokens.ctaBgColorTertiaryHover,
  defaultActiveBackgroundColor: oliveCTATokens.ctaBgColorTertiaryActive,
  defaultTextColor: oliveFontTokens.fontColorSubtle,

  iconColor: oliveButtonTokens.secondary.iconColor,

  selectedBackgroundColor: oliveCTATokens.ctaToggleBgColorSelected,
  selectedHoverBackgroundColor: oliveCTATokens.ctaToggleBgColorSelectedHover,
  selectedActiveBackgroundColor: undefined,
  selectedTextColor: oliveFontTokens.fontColorSubtle,

  defaultHeight: oliveButtonTokens.defaultSize,
  mobileHeight: oliveButtonTokens.mobileSize,
} as const

export default tokens
