export default {
  ModalContainer: 'ModalContainer',
  OverlayContainer: 'OverlayContainer',
  SkipNavContainer: 'SkipNavContainer',
  ToastMessageContainer: 'ToastMessageContainer',

  DsUiOverlays: 'DsUiOverlays',
  DsUiModals: 'DsUiModals',
  DsUiToastMessages: 'DsUiToastMessages',
}
