import { EventSource } from './EventSource'
import { CounterSource } from './CounterSource'
import { ClientContext } from './ClientContext'

export class DefaultSources {
  private requestEvent: EventSource
  private requestCounter: CounterSource
  private errorEvent: EventSource
  private loadTimeEvent: EventSource
  private loadTimeCounter: CounterSource

  constructor(context: ClientContext) {
    this.requestEvent = {
      eventCategory: context.application,
      eventName: 'Client Request',
      eventType: 'DocuSign.Monitoring.ClientTelemetry',
    }

    this.requestCounter = {
      counterCategory: context.application,
      counterName: 'Client Requests',
      ranges: [10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
      displayUnit: 'milliseconds',
      showVolume: false,
      hideCountChart: false,
      hasInstance: true,
      hasMeasure: true,
      hasErrorCount: true,
    }

    this.errorEvent = {
      eventCategory: context.application,
      eventName: 'Client Error',
      eventType: 'DocuSign.Monitoring.ClientTelemetry',
    }

    this.loadTimeEvent = {
      eventCategory: context.application,
      eventName: 'Load Time',
      eventType: 'DocuSign.Monitoring.ClientTelemetry',
    }

    this.loadTimeCounter = {
      counterCategory: context.application,
      counterName: 'Load Time',
      ranges: [10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
      displayUnit: 'milliseconds',
      showVolume: false,
      hideCountChart: false,
      hasInstance: true,
      hasMeasure: true,
      hasErrorCount: true,
    }
  }

  public getRequestEventSource(): EventSource {
    return this.requestEvent
  }

  public getRequestCounterSource(): CounterSource {
    return this.requestCounter
  }

  public getErrorEventSource(): EventSource {
    return this.errorEvent
  }

  public getLoadTimeEventSource(): EventSource {
    return this.loadTimeEvent
  }

  public getLoadTimeCounterSource(): CounterSource {
    return this.loadTimeCounter
  }
}
