/**
 * A wrapper for an expression that should not be evaluated until needed.
 *
 * @example
 * const lazyDocument = new Lazy(() => document);
 */
export class Lazy<V> {
  private thunk: () => V

  constructor(thunk: () => V) {
    this.thunk = thunk
  }

  get value(): V {
    return this.thunk()
  }
}

export const lazyWindow = new Lazy(() => window)

export const lazyDocument = new Lazy(() => document)
