/**
 * A predicate function that determines whether a given string is in the shape
 * of a hex triplet (e.g. '#FF00FF').
 *
 * @param {string} str
 * @returns {boolean} Whether the provided string represents a hex triplet
 *
 * @example
 * // returns true
 * isHexTriplet('#a29f0b')
 *
 * // returns false
 * isHexTriplet('#c23')
 */
export function isHexTriplet(str: string): boolean {
  return /^#[0-9A-Fa-f]{6}$/.test(str)
}
