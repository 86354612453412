import colors from '../colors'

const outer = {
  boxShadow: 'none',
  outline: `2px solid ${colors.funBlue}`,
  outlineOffset: '2px',
} as const

const inner = {
  boxShadow: 'none',
  outline: `2px solid ${colors.funBlue}`,
  outlineOffset: '-2px',
} as const

const tokens = {
  outer,
  inner,
} as const

export default tokens
