import type { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      circleFill: {
        stroke: '#666666',
      },

      circleTrack: {
        stroke: tokens.barTrackColorDefault,
      },
    },

    subtle: {
      circleFill: {
        stroke: tokens.barFillColorSubtle,
      },
    },
  } as const
}
