import { useEventListener } from './useEventListener'
import { lazyDocument } from './Lazy'
import { isEscape } from './keyboardUtils'

/**
 * Listens to escape key (on the document)
 */

export function useEscapeListener(
  listener: EventListener,
  enabled = true,
  capture = false
) {
  useEventListener(
    'keydown',
    (event: Event) => handleKeydown(event as KeyboardEvent, listener),
    lazyDocument,
    enabled,
    capture
  )
}

function handleKeydown(event: KeyboardEvent, listener: EventListener) {
  if (isEscape(event)) {
    listener(event)
  }
}
