import { CreateFocusCss } from '../../../../styles'
import type { HeaderContextValue } from '../../HeaderContext'
import type { Tokens } from '../../../../theming/types'

export default (config: { tokens: Tokens; props: HeaderContextValue }) => {
  const { tokens, props } = config
  return {
    link: {
      paddingInlineStart: '12px',
      paddingTop: '3px',

      ...CreateFocusCss({
        outlineColor: props.dark ? tokens.dark.focusColor : tokens.focusColor,
        outlineOffset: '2px',
      }),
    },

    image: {
      width: '111px',

      svg: {
        // TODO: Tokens - Maybe we create logo fill tokens
        fill: props.dark ? '#ffffff' : '#000000',
      },
    },

    text: {
      borderColor: props.dark
        ? tokens.borderColorSubtleInverse
        : tokens.borderColorSubtle,
      color: props.dark ? tokens.fontColorInverse : tokens.fontColorDefault,
      fontSize: '16px',
      fontWeight: 500,
      height: '24px',
      lineHeight: '24px',
      marginInlineStart: '12px',
      paddingInlineStart: '12px',
    },
  } as const
}
