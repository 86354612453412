import { TranslateFunction, TranslationsWarehouse, Locale } from '@ds/base'
import { useLocaleLoaded } from './useLocaleLoaded'
import { useHtmlLang } from './useHtmlLang'

/*
    This react hook returns a TranslateFunction for doing
    text language translation.  See TranslateFunction for
    details on the function.

    By default the hook uses the HTML lang attribute to determine the locale.
    You can override this by providing a locale parameter.

    The generic parameter is the type which includes all unique trans

*/

export function useWarehouseTranslate<T extends string>(
  warehouse: TranslationsWarehouse<T>,
  locale?: Locale
): TranslateFunction<T> {
  useLocaleLoaded(warehouse) // force refresh when locale loaded
  const pageLocale = useHtmlLang()
  return warehouse.getTranslate(
    locale || pageLocale || 'en'
  ) as TranslateFunction<T>
}
