import { CreateFocusCss } from '../../../../styles'
import colors from '../../../../theming/docusign-themes/olive/colors'

export default () => {
  return {
    default: {
      wrap: {
        color: colors.white,

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: colors.DocuSignBlueLight,
          color: colors.white,
        },

        ...CreateFocusCss({
          outlineColor: colors.white,
        }),
      },
    },

    selected: {
      wrap: {
        color: colors.white,

        '&::after': {
          backgroundColor: colors.white,
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          color: colors.white,

          '&::after': {
            backgroundColor: colors.white,
          },
        },
      },
    },

    hasMenuTrigger: {
      wrap: {
        '&::after': {
          borderColor: `${colors.white} transparent transparent transparent`,
        },
      },
    },

    underlineOnHover: {
      wrap: {
        color: colors.DocuSignBluePale,

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: 'transparent',
          color: colors.DocuSignBluePale,

          '&::after': {
            backgroundColor: colors.DocuSignBluePale,
          },
        },
      },
    },
  } as const
}
