import baseStyles from './base'
import inkStyles from './ink'
import oliveStyles from './olive'
import cobrandingStyles from './cobranding'

export default {
  base: baseStyles,
  ink: inkStyles,
  olive: oliveStyles,
  cobranding: cobrandingStyles,
}
