const badgeStyles = (size: string) => ({
  badge: {
    height: size,
    width: size,
  },
})

export default () => {
  return {
    sizeXxlarge: badgeStyles('12px'),
    sizeXlarge: badgeStyles('12px'),
  } as const
}
