import * as React from 'react'

type InterpolateResult = {
  tree: Array<React.ReactElement<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
  component?: string
}

/**
 * Component interpolation regex - where the magic (horror) happens
 *
 * This regex evaluates a string and captures patterns into 4 different buckets/cases.
 * Each "quad" in the array can be thought of as a set. note: some indices may be empty:
 *  [
 *    'plain text',
 *    'TagName',
 *    'TagName's children',
 *    '<SelfClosing/>,
 *    ...repeat
 * ]
 *
 * - Scenario 0: matches all text that is neither a component tag nor the child of a component tag
 * - Scenario 1: matches the component name of tags that have a opening and closing notation(<Link>child</Link>)
 * - Scenario 2: matches the children of scenario 1 components
 * - Scenario 3: matches self closing tags(<br />)
 */
const regex =
  // eslint-disable-next-line no-useless-escape
  /<([^\/.]+?)(?:\s*|\s+[^\/\>]+)>(.*?)<\/\1>|<([^\/.]+?)(?:\s*|\s+[^\/\>]+)\/>/

/**
 * Interpolate takes a string of text that is optionally marked up with Component Tags:
 *
 * "A <Bold>very cool<Bold/> site to visit is the <DocuSign/> <HomePageLink>Home Page</HomePageLink>"
 *
 * Each tag (Bold, DocuSign, HomePageLink) will have an associated function that Interpolate will call
 * to create an array of JSX.Elements from the tag:
 *
 * Bold: (content) => <strong>{content}</strong>
 * DocuSign: () => <span style={{color: dsblue}}>DocuSign</span>
 * HomePageLink: (content) => <Link text={content} href="docusign.com" />
 */
const Interpolate: React.FunctionComponent<{
  text: string
  components: {
    [name: string]: (children?: React.ReactNode) => React.ReactNode
  }
}> = ({ text, components }) => {
  const splits = text.split(regex)

  const initialValue: InterpolateResult = { tree: [] }

  const result = splits.reduce<InterpolateResult>(
    (acc, curr: string, index: number) => {
      const scenario = index % 4

      if (scenario === 0) {
        /* Plain text that is neither a component tag nor the child of a component tag */
        if (curr.length !== 0) {
          return {
            // eslint-disable-next-line react/no-array-index-key
            tree: [...acc.tree, <span key={`${index}:${curr}`}>{curr}</span>],
          }
        }
      }

      if (scenario === 1) {
        /**
         * Matches the component tag's name that has an opening and closing notation (e.g. <Link>child</Link>)
         * Store the tag name on component for next iteration which will be case 2.
         */
        return { tree: acc.tree, component: curr }
      }

      if (scenario === 2) {
        /**
         * This is the children of the tag identified in case 1
         * - There is/should be a component name on acc.component
         * - We get the function the component name maps to and call it with Interpolate(children)
         *   to handle the case where there are any nested component tags:
         *
         *      ex: <Highlight><Upper>...</Upper><Highlight>
         */
        if (acc.component && components[acc.component]) {
          const interpolationFn = components[acc.component]

          const childInterpolation = (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${index}:${acc.component}`}>
              {interpolationFn(
                <Interpolate text={curr} components={components} />
              )}
            </React.Fragment>
          )

          return {
            tree: [...acc.tree, childInterpolation],
          }
        }

        return { tree: acc.tree }
      }

      if (scenario === 3) {
        /**
         * Matches any self closing tags:
         * - <br/>
         * - <Number1 />  (ex: () => '1')
         */
        if (curr && components[curr]) {
          const interpolationFn = components[curr]
          const interpolationResult = (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${index}:${curr}:`}>
              {interpolationFn()}
            </React.Fragment>
          )

          return {
            tree: [...acc.tree, interpolationResult],
          }
        }
      }

      return { tree: acc.tree }
    },
    initialValue
  )

  return <>{result.tree}</>
}

// Export
export default Interpolate
