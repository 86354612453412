import { PluralCategory, SupportedLocaleName } from '../types'
import { logComponentErrorOnce } from '../telemetry/telemetry'

export const PLURAL_DEFAULT_CATEGORY = 'other'

/**
 * Given a count, provide the best category for a plural phrase
 *
 * @param locale
 * @param count number of items being referenced in the plural phrase, e.g, "{{PLURAL_COUNT}} envelopes"
 * @param availableCategories category names provided for this translation, e.g. ("one", "other", "many")
 */
export function getPluralCategory(
  locale: SupportedLocaleName | 'en_ps' | 'en_rt',
  count: number,
  availableCategories?: Set<PluralCategory>
): PluralCategory {
  try {
    const adjustedLocale =
      locale === 'en_ps' ? 'en' : locale === 'en_rt' ? 'ar' : locale
    // Check if the count matches a specified exact match (e.g. "=0", "=1")
    const exactCat = `=${count}` as PluralCategory
    if (availableCategories?.has(exactCat)) {
      return exactCat
    }

    if (isIntlPluralRulesSupported()) {
      // Otherwise get the category from CLDR data
      const categoryFromCLDR = new Intl.PluralRules(adjustedLocale).select(
        count
      ) as PluralCategory

      // We need to track when the category from CLDR is not available
      if (
        availableCategories === undefined ||
        availableCategories.size === 0 ||
        availableCategories.has(categoryFromCLDR)
      ) {
        return categoryFromCLDR
      }
      throw new Error(
        'The available categories set does not contain the category from the CLDR.'
      )
    }
  } catch (err) {
    logComponentErrorOnce(
      {
        message: `Translation plural error locale=${locale} count=${count} categories=${availableCategories}`,
        error: err,
        meta: {
          locale,
          count,
          availableCategories: availableCategories
            ? Array.from(availableCategories)
            : [],
        },
      },
      'i18nlayer.plurals'
    )
  }
  return count === 1 && availableCategories?.has('one') ? 'one' : 'other'
}

function isIntlPluralRulesSupported() {
  return window.Intl && window.Intl.PluralRules
}
