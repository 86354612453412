type EventData = {
  [key: string]: string | number | EventData
}

type ErrorData = {
  message: string
  error?: Error
  meta?: Record<string, unknown>
}

type ComponentEventFunc = (
  eventData: EventData,
  componentName?: string,
  componentVersion?: string
) => void
type ComponentErrorFunc = (
  errorData: ErrorData,
  componentName?: string,
  componentVersion?: string
) => void
type LogErrorFunc = (args: string) => void

const isCalled = {}
let previousErrors: Set<string> = new Set()
let telemetryEnabled = false
let logComponentEvent: ComponentEventFunc | undefined
let logComponentError: ComponentErrorFunc | undefined
let _logError: LogErrorFunc | undefined

function emitTelemetry(
  functionName: string,
  isError: boolean,
  option: number | Error | null
): boolean {
  try {
    if (!telemetryEnabled || isCalled[functionName]) {
      return true
    }
    isCalled[functionName] = true
    if (isError) {
      const errorData: ErrorData = {
        message: `${functionName}: `,
      }
      if (option instanceof Error) {
        errorData.error = option
      }
      if (typeof logComponentError === 'function') {
        logComponentError(errorData)
      }
    } else {
      const eventData: EventData = {
        measure: getPerformance() - (option as number),
        instance: `${functionName}`,
      }
      if (typeof logComponentEvent === 'function') {
        logComponentEvent(eventData, 'I18nLayer')
      }
    }
  } catch (ex) {
    if (typeof _logError === 'function') {
      _logError(`Error attempting to log component ${ex}`)
    }
    return false
  }
  return true
}

function setTelemetry(functionName: string, measure: number): boolean {
  return emitTelemetry(functionName, false, measure)
}

function reportException(functionName: string, error: Error): boolean {
  return emitTelemetry(`Exception at ${functionName}`, true, error)
}

function enableTelemetry(onoff: boolean): boolean {
  telemetryEnabled = onoff
  return true
}

function getPerformance(): number {
  if (!telemetryEnabled) return 0
  if (window && window.performance && window.performance.now)
    return window.performance.now()
  return 0
}

function logComponentErrorOnce(
  error: ErrorData,
  componentName?: string,
  componentVersion?: string
): void {
  if (error && typeof logComponentError === 'function') {
    const errorKey =
      error.message +
      (componentName ? `@@@${componentName}` : '') +
      (componentVersion ? `@@@${componentVersion}` : '')
    if (!previousErrors.has(errorKey)) {
      logComponentError(error, componentName, componentVersion)
      previousErrors.add(errorKey)
    }
  }
}

function logError(args: string): void {
  if (typeof _logError === 'function') {
    _logError(args)
  }
}

function logErrorOnce(message: string, args: string): void {
  if (message && typeof _logError === 'function') {
    if (!previousErrors.has(message)) {
      _logError(message + args)
      previousErrors.add(message)
    }
  }
}

function logErrorComponent(
  errorData: ErrorData,
  componentName?: string,
  _componentVersion?: string
): void {
  if (errorData && typeof _logError === 'function') {
    _logError(
      `${componentName ?? ''}: ${errorData.message} ${
        errorData.error ? errorData.error.toString() : ''
      }`
    )
  }
}

function setupLogError(logErrorFunc: LogErrorFunc): void {
  if (typeof logErrorFunc === 'function' || logErrorFunc === undefined) {
    _logError = logErrorFunc
    previousErrors = new Set()
    if (!logComponentError) {
      logComponentError = logErrorComponent
    }
  }
}

function setupComponentLog(
  logErrorFunc: ComponentErrorFunc | undefined,
  logEventFunc: ComponentEventFunc | undefined
): void {
  if (typeof logErrorFunc === 'function' || logErrorFunc === undefined) {
    logComponentError = logErrorFunc
    previousErrors = new Set()
  }
  if (typeof logEventFunc === 'function' || logEventFunc === undefined) {
    logComponentEvent = logEventFunc
  }
}

export {
  enableTelemetry,
  setTelemetry,
  reportException,
  getPerformance,
  logComponentErrorOnce,
  setupLogError,
  setupComponentLog,
  logError,
  logErrorOnce,
}
