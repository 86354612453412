import { oliveFontTokens } from '@ds/tokens'

const tokens = {
  withColorIndexTextColor: oliveFontTokens.fontColorDefault,

  largeFontSize: '15px',
  mediumFontSize: '15px',
  smallFontSize: '13px',
  xlargeFontSize: '22px',
  xxlargeFontSize: '22px',
} as const

export default tokens
