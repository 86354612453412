import { CreateFocusCss } from '../../../../styles'
import type { Tokens } from '../../../../theming/types'
import { styles } from '../../../../utilities'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 0,
        cursor: 'pointer',
        display: 'flex',
        fontFamily: tokens.fontHeadingM.fontFamily,
        fontSize: '13px',
        fontWeight: 700,
        height: '52px',
        letterSpacing: '0.66px',
        paddingInline: '12px',
        position: 'relative',
        textDecoration: 'none',
        textTransform: 'uppercase',
        transition: `
          background-color 0.2s ease,
          color 0.2s ease
        `,
        whiteSpace: 'nowrap',

        '&::after': {
          backgroundColor: 'transparent',
          bottom: '14px',
          border: 'none',
          content: '""',
          display: 'block',
          height: '2px',
          insetInlineStart: 0,
          margin: 'auto',
          position: 'absolute',
          insetInlineEnd: 0,
          transition: 'background-color 0.2s ease',
          width: 'calc(100% - 12px * 2)',
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          textDecoration: 'none',
        },

        '&:active': {
          outline: 'none',
        },

        ...CreateFocusCss({
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineOffset: '-4px',
        }),
      },

      icon: {
        display: 'flex',
        pointerEvents: 'none',

        'svg, img': {
          fill: 'currentColor',
          width: tokens.icon.defaultSize,
        },
      },

      badge: {
        display: 'flex',
        position: 'absolute',
        insetInlineEnd: 0,
        top: '12px',
      },
    },

    isIconOnly: {
      wrap: {
        padding: '0 12px',
      },

      text: {
        ...styles.visuallyHidden,
      },

      icon: {
        margin: 0,
      },

      badge: {
        insetInlineEnd: '6px',
      },
    },

    hasIconBeforeText: {
      icon: {
        marginInlineEnd: '6px',
      },
    },

    hasIconAfterText: {
      icon: {
        marginInlineStart: '6px',
      },
    },

    hasMarginLeft: {
      wrap: {
        marginInlineStart: '12px',
      },
    },

    hasMarginRight: {
      wrap: {
        marginInlineEnd: '12px',
      },
    },

    hasMenuTrigger: {
      wrap: {
        paddingInlineEnd: '27px',

        '&::after': {
          borderStyle: 'solid',
          borderBlock: '6px 0',
          borderInline: '4px',
          content: '""',
          display: 'block',
          height: 0,
          pointerEvents: 'none',
          position: 'absolute',
          insetInlineEnd: '12px',
          top: 'calc(50% - 3px)',
          width: 0,
        },
      },
    },
  } as const
}
