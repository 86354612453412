import colors from '../../../theming/docusign-themes/olive/colors'
import type { HeaderProps } from '..'

export default (config: { props: Partial<HeaderProps> }) => {
  const { props } = config
  return {
    default: {
      backgroundColor: colors.DocuSignBlue,
    },

    wrap: {
      color: colors.white,
      height: '52px',
      paddingInline: props.fullWidth ? '8px 16px' : '24px 12px',
    },
  } as const
}
