import {
  inkButtonTokens,
  inkCTATokens,
  inkFontTokens,
  inkIconTokens,
} from '@ds/tokens'

const tokens = {
  defaultBackgroundColor: inkCTATokens.ctaBgColorTertiaryDefault,
  defaultHoverBackgroundColor: inkCTATokens.ctaBgColorTertiaryHover,
  defaultActiveBackgroundColor: inkCTATokens.ctaBgColorTertiaryActive,
  defaultTextColor: inkFontTokens.fontColorDefault,

  iconColor: inkIconTokens.iconColorDefault,

  selectedBackgroundColor: inkCTATokens.ctaToggleBgColorSelected,
  selectedHoverBackgroundColor: inkCTATokens.ctaToggleBgColorSelectedHover,
  selectedActiveBackgroundColor: inkCTATokens.ctaToggleBgColorSelectedActive,
  selectedTextColor: inkFontTokens.fontColorInverse,

  defaultHeight: inkButtonTokens.defaultSize,
  mobileHeight: inkButtonTokens.mobileSize,
} as const

export default tokens
