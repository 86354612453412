import { HttpResponse, ExtendedHttpRequest, HttpCachedRequest } from './types'
import type { CacheItem } from './StorageCache'
import {
  responseHeadersToJson,
  responseHeadersFromJson,
} from './HTTPHandlers/ResponseHeaders'

/*

  Utility functions for put/get http responses to StorageCache

*/

export function putStorageCachedRequest(
  hash: string,
  request: ExtendedHttpRequest,
  response: HttpResponse
) {
  if (request.storageCache && request.cacheSeconds) {
    const cacheItem: CacheItem = {
      key: hash,
      expireSeconds: request.cacheSeconds,
      data: httpResponseAsStorageCacheData(response),
      cacheName: request.cacheName,
    }
    request.storageCache.put(cacheItem)
  }
}

export function getStorageCachedRequest(
  hash: string,
  request: ExtendedHttpRequest
) {
  const cachedItem = request.storageCache?.get(hash)
  if (cachedItem) {
    const response = httpResponseFromStorageCacheData(cachedItem.data, request)
    const cachedRequest: HttpCachedRequest = {
      request,
      responsePromise: Promise.resolve(response),
      complete: true,
    }
    return cachedRequest
  }
  return undefined
}

function httpResponseAsStorageCacheData(httpResponse: HttpResponse): string {
  return JSON.stringify({
    ...httpResponse,
    request: undefined,
    headers: responseHeadersToJson(httpResponse.headers),
  })
}

function httpResponseFromStorageCacheData(
  data: string,
  request: ExtendedHttpRequest
) {
  const response = JSON.parse(data)
  response.headers = responseHeadersFromJson(response.headers)
  response.request = request
  return response as HttpResponse
}
