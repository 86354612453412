import template from './template'

const consoleWarnMsgSet = new Set()

/**
 * Print a warning in the browser's development console.
 *
 * @param {string} msg - The warning message to display in the console.
 */
const consoleWarn = (msg: string) => {
  if (process.env.NODE_ENV !== 'production' && !consoleWarnMsgSet.has(msg)) {
    /* eslint-disable-next-line no-console */
    console.warn(template('Warning', msg))
    consoleWarnMsgSet.add(msg)
  }
}

export default consoleWarn
