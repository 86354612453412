import type { Tokens } from '../../../../theming/types'
import type { PopoverCloseProps } from '..'

export default (config: {
  tokens: Tokens
  props: Partial<PopoverCloseProps>
}) => {
  const { tokens, props } = config
  return {
    default: {
      button: {
        height: props.small ? '30px' : tokens.button.defaultSize,
        width: props.small ? '30px' : tokens.button.defaultSize,
      },
    },
  } as const
}
