import colors from '../../Common/colors'
import { CSSObject } from '@emotion/react'
import { inkFontTokens, inkBorderTokens } from '@ds/tokens'

const footerBackgroundColor = colors.white
const textColor = colors.gray9
const linkHoverColor = colors.black
const linkSeparatorBarColor = colors.gray5
const footerBorderColor = linkSeparatorBarColor
const langTriggerButtonColor = colors.gray9
const visitedColor = colors.DocuSignBlue
const focusOutlineColor = visitedColor
const phoneFooterRowHeight = 44

const footerContainerCSS: (isInk: boolean) => CSSObject = (isInk) => {
  return {
    label: 'FOOTER_CONTAINER',
    backgroundColor: footerBackgroundColor,
    borderTop: `1px solid ${footerBorderColor}`,
    lineHeight: '12px',
    paddingInlineStart: 24,
    paddingInlineEnd: 24,
    fontSize: 10,
    color: textColor,
    overflow: 'hidden',
    ...(isInk && {
      fontFamily: inkFontTokens.fontBodyM.fontFamily,
      color: inkFontTokens.fontColorDefault,
    }),
  }
}

const footerMainCSS: CSSObject = {
  alignItems: 'center',
  display: 'flex',
}

const footerMainRowExtendedCSS: CSSObject = {
  ...footerMainCSS,
  label: 'FOOTER_MAIN_ROW',
  justifyContent: 'space-between',
  listStyleType: 'none',
  margin: '5px 0',
}

const footerMainRowWCenteredCSS: CSSObject = {
  ...footerMainCSS,
  label: 'FOOTER_MAIN_ROW_NO_LOGO',
  listStyleType: 'none',
  margin: '5px 0',
}

const footerTopBottomRowCSS: CSSObject = {
  display: 'flex',
}

const footerTopRowCSS: CSSObject = {
  label: 'FOOTER_TOP_ROW',
  ...footerTopBottomRowCSS,
  margin: '8px 0',
}

const footerBottomRowCSS: CSSObject = {
  label: 'FOOTER_BOTTOM_ROW',
  ...footerTopBottomRowCSS,
  listStyleType: 'none',
  margin: '8px 0',
}

const footerLinkListCSS: (isInk: boolean) => CSSObject = (isInk) => {
  return {
    label: 'FOOTER_LINK_LIST',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyleType: 'none',
    padding: 'inherit',
    /* seperator bar */
    '& [data-link] + [data-link]': {
      marginInlineStart: '8px',
    },

    '& [data-link]:after': {
      content: '"|"',
      marginInlineStart: '8px',
      ...(isInk && {
        color: inkBorderTokens.borderColorSubtle,
      }),
    },

    '& [data-link]:last-child:after': {
      content: 'none',
    },
  }
}

const footerLinkListMobileCSS: CSSObject = {
  label: 'FOOTER_MOBILE_LINKS',
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  listStyleType: 'none',
  padding: 'inherit',
  margin: 'inherit',
  /* seperator bar */
  '& [data-link]': {
    marginInlineEnd: '8px',
  },
}

const footerSeperatorCSS: CSSObject = {
  borderInlineStart: `1px solid ${linkSeparatorBarColor}`,
  paddingInlineStart: '8px',
  marginInlineStart: '8px',
  whiteSpace: 'nowrap',
}

const footerLogoCSS: CSSObject = {
  label: 'FOOTER_LOGO',
  display: 'flex',
  whiteSpace: 'nowrap',

  '& span': {
    display: 'block',
  },
}

const footerLogoSvgCSS: (isInk: boolean) => CSSObject = (isInk) => {
  return {
    label: 'FOOTER_LOGO_SVG',
    marginTop: isInk ? '-1.5px' : '-1px',
    marginInlineStart: '3px',

    '& svg': {
      maxHeight: '16px',
      width: '50px',
    },
  }
}

const footerMenuTriggerButtonCSS: CSSObject = {
  label: 'FOOTER_LANG_SELECT_BUTTON',
  border: 'none',
  background: 'none',
  fontSize: '10px',
  color: langTriggerButtonColor,
  cursor: 'pointer',
  marginInlineEnd: '10px',
  padding: 0,
  '&:focus': {
    outlineOffset: 2,
    outline: `${focusOutlineColor} solid 2px`,
  },
  '& div': {
    display: 'flex',
  },
}

const footerMenuTriggerInkButtonCSS: CSSObject = {
  label: 'FOOTER_LANG_SELECT_INK_BUTTON',
  '& button': {
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    marginInlineEnd: '10px',
    minHeight: 'inherit',
    paddingBlock: '0',
    paddingInline: '6px 0',
    '& span:nth-of-type(2)': {
      marginTop: '-2px',
      marginInlineStart: '2px',
    },
  },
}

const footerLinkCSS: (isInk: boolean) => CSSObject = (isInk) => {
  return {
    label: 'FOOTER_LINK',
    color: isInk ? inkFontTokens.fontColorDefault : textColor,
    textDecoration: 'none',
    '&:visited': {
      color: isInk ? 'inherit' : visitedColor,
    },
    '&:hover': {
      color: isInk ? inkFontTokens.fontColorLinkHover : linkHoverColor,
      textDecoration: isInk ? 'none' : 'underline',
    },
    '&:focus': {
      outlineOffset: 2,
      outline: `${focusOutlineColor} solid 2px`,
    },
    ...(isInk && {
      '&:active': {
        color: inkFontTokens.fontColorLinkActive,
      },
    }),
  }
}

const footerPhoneLanguageSelectorContainerCSS: CSSObject = {
  label: 'FOOTER_LANG_SELECT_PHONE_CONTAINER',
  color: 'white',
  margin: 0,
  listStyle: 'none',
  paddingInlineStart: 0,
  paddingInlineEnd: 0,
}

const footerPhoneLanguageSelectorRowCSS: CSSObject = {
  label: 'FOOTER_LANG_SELECT_PHONE_ROW',
  alignItems: 'center',
  background: 'none',
  color: 'black',
  border: 0,
  borderBottom: '1px solid #e9e9e9',
  padding: '0px 32px',
  cursor: 'pointer',
  font: 'inherit',
  outline: 'none',
  width: '100%',
  display: 'flex',
  height: phoneFooterRowHeight,
  fontSize: 13,
  '&:hover': {
    backgroundColor: colors.gray2,
    fontWeight: 500,
  },
  '&:focus': {
    boxShadow: `
          0 0 0 1px ${colors.funBlueLight} inset
        `,
    outline: 0,
  },
}

export default {
  footerContainerCSS,
  footerMainRowExtendedCSS,
  footerMainRowWCenteredCSS,
  footerTopRowCSS,
  footerBottomRowCSS,
  footerLinkListCSS,
  footerLinkListMobileCSS,
  footerSeperatorCSS,
  footerLogoCSS,
  footerLogoSvgCSS,
  footerLinkCSS,
  footerPhoneLanguageSelectorContainerCSS,
  footerPhoneLanguageSelectorRowCSS,
  footerMenuTriggerButtonCSS,
  footerMenuTriggerInkButtonCSS,
  phoneFooterRowHeight,
}
