﻿export class EventSource {
  public eventCategory: string

  public eventName: string

  public eventType: string

  protected constructor(eventSource: EventSource) {
    this.eventCategory = eventSource.eventCategory
    this.eventName = eventSource.eventName
    this.eventType = eventSource.eventType
  }
}
