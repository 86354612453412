import { useMemo } from 'react'

import deepmerge from 'deepmerge'

export const useMemoizedDeepmerge = <
  T1 extends Record<string, unknown>,
  T2 extends Record<string, unknown>
>(
  mergeTo: T1,
  mergeFrom: T2
): T1 & T2 =>
  useMemo<T1 & T2>(
    () => deepmerge<T1, T2>(mergeTo || {}, mergeFrom || {}),
    [mergeTo, mergeFrom]
  )
