import nanoid from 'nanoid'

/**
 * @param {string} [prefix] The prefix to prepend to the unique ID.
 * @param {number} [length] The length of the generated string (not including the prefix).
 *
 * @returns {string} A short, non-sequential, URL-friendly unique ID.
 *
 * @requires nanoid
 */
export const createId = (prefix?: string, length = 10): string =>
  `${prefix ? prefix : ''}${nanoid(length)}`
