export default {
  Collapsible: {
    enter: 200,
    exit: 200,
  },

  Menu: {
    enter: 300,
    exit: 350,
  },

  Modal: {
    enter: 500,
    exit: 350,
  },

  Overlay: {
    enter: 250,
    exit: 150,
  },

  SelectMenu: {
    enter: 300,
    exit: 350,
  },

  Scrim: {
    enter: 300,
    exit: 350,
  },

  SkipNav: {
    enter: 300,
    exit: 300,
  },

  TagHover: {
    enter: 200,
  },

  Tooltip: {
    enter: 300,
    exit: 350,
  },
}
