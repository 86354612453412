import { CreateButtonCss, isTouchScreen } from '../../../styles'
import type { Tokens } from '../../../theming/types'
import { breakpoints } from '../../../utilities'
import type { ButtonProps } from '..'

export default (config: {
  tokens: Tokens
  props: Pick<ButtonProps, 'animate' | 'loading'>
}) => {
  const { tokens, props } = config

  const sizeStyles = {
    minHeight: tokens.button.defaultSize,
    minWidth: props.animate ? undefined : '80px',
    padding: props.animate ? '4px 8px' : '4px 15px',

    [isTouchScreen]: {
      minHeight: tokens.button.mobileSize,
    },
  } as const

  return {
    button: {
      default: {
        color: tokens.fontColorDefault,
        letterSpacing: 'normal',
        textTransform: 'none',
      },

      sizeSmall: sizeStyles,
      sizeMedium: sizeStyles,
      sizeLarge: sizeStyles,
      sizeXlarge: sizeStyles,

      inverted: {
        primary: {
          ...CreateButtonCss(tokens.button.primary),

          '&:hover': {
            backgroundColor: tokens.dark.ctaBgColorPrimaryHover,
          },

          '&:active': {
            backgroundColor: tokens.dark.ctaBgColorPrimaryActive,
            transitionDuration: '0.15s',
          },
        },
      },
    },

    menuTrigger: {
      paddingInlineEnd: '11px',
    },

    caret: {
      marginInlineStart: '12px',
    },

    hideText: {
      minWidth: tokens.button.defaultSize,
      paddingBlock: props.loading ? '7px' : undefined,
      paddingInline: props.loading ? '7px' : undefined,

      [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
        paddingBlock: '7px',
        paddingInline: '7px',
      },

      [isTouchScreen]: {
        minWidth: tokens.button.mobileSize,
        paddingBlock: '11px',
        paddingInline: '11px',
      },
    },

    icon: {
      beforeText: {
        marginInline: '0 8px',
      },

      afterText: {
        marginInline: '8px 0',
      },

      hideText: {
        padding: '7px',

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          padding: 0,
        },
      },
    },

    iconButton: {
      default: {
        paddingBlock: '7px',
        paddingInline: '15px',

        [isTouchScreen]: {
          paddingBlock: '11px',
        },
      },

      beforeText: {
        paddingInlineStart: '11px',

        [isTouchScreen]: {
          paddingInlineStart: '15px',
        },
      },

      afterText: {
        paddingInlineEnd: '11px',

        [isTouchScreen]: {
          paddingInlineEnd: '15px',
        },
      },
    },

    pill: {
      borderRadius: '24px',
    },
  } as const
}
