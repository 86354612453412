import type { HeaderContextValue } from '../../HeaderContext'
import { CreateFocusCss } from '../../../../styles'
import { breakpoints } from '../../../../utilities'
import type { Tokens } from '../../../../theming/types'

export default (config: { tokens: Tokens; props: HeaderContextValue }) => {
  const { tokens, props } = config
  return {
    default: {
      wrap: {
        color: props.dark
          ? tokens.dark.fontColorSubtle
          : tokens.fontColorSubtle,
        fontSize: '16px',
        fontWeight: 600,
        height: '64px',
        letterSpacing: 'normal',
        textTransform: 'initial',
        paddingInline: '24px',

        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          paddingInline: '12px',
        },

        '&::after': {
          bottom: 0,
          height: '3px',
          width: '100%',
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: props.dark
            ? tokens.dark.ctaBgColorSecondaryHover
            : tokens.ctaBgColorSecondaryHover,
          color: props.dark
            ? tokens.dark.fontColorDefault
            : tokens.fontColorDefault,

          '&::after': {
            backgroundColor: 'transparent',
          },

          svg: {
            color: props.dark
              ? tokens.dark.fontColorDefault
              : tokens.fontColorDefault,
          },
        },

        ...CreateFocusCss({
          outlineColor: props.dark ? tokens.dark.focusColor : tokens.focusColor,
        }),
      },

      icon: {
        'svg, img': {
          width: '20px',
          height: '20px',
        },
      },

      badge: {
        insetInlineEnd: '15px',
        top: '6px',
      },
    },

    selected: {
      wrap: {
        color: props.dark
          ? tokens.dark.fontColorDefault
          : tokens.fontColorDefault,

        '&::after': {
          backgroundColor: props.dark
            ? tokens.bgColorDefault
            : tokens.dark.bgColorDefault,
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          color: props.dark
            ? tokens.dark.fontColorDefault
            : tokens.fontColorDefault,

          '&::after': {
            backgroundColor: props.dark
              ? tokens.bgColorDefault
              : tokens.dark.bgColorDefault,
          },
        },
      },
    },

    isIconOnly: {
      badge: {
        insetInlineEnd: '7px',
      },
    },

    underlineOnHover: {
      wrap: {
        color: props.dark
          ? tokens.dark.fontColorSubtle
          : tokens.fontColorSubtle,

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: props.dark
            ? tokens.dark.ctaBgColorSecondaryHover
            : tokens.ctaBgColorSecondaryHover,
          color: props.dark
            ? tokens.dark.fontColorDefault
            : tokens.fontColorDefault,

          svg: {
            color: props.dark
              ? tokens.dark.fontColorDefault
              : tokens.fontColorDefault,
          },

          '&::after': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  } as const
}
