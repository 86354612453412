/* eslint-disable no-param-reassign */
import {
  CurrencyParseOptions,
  LocalePolicy,
  NumberFormatInfo,
  NumberParseOptions,
  EnumCurrencyCode,
  CurrencyCode,
} from '../types'
import {
  setTelemetry,
  reportException,
  getPerformance,
} from '../telemetry/telemetry'
import { escapeRegexSpecials } from './utilities'
import { getRegionLocalePolicy } from './localePolicy'
/*
 * @ds/i18nlayer - currency format support
 */

export const currencyISOtoSymbol = {
  aed: 'د.إ',
  afn: '؋',
  all: 'L',
  amd: '֏',
  ang: 'ƒ',
  aoa: 'Kz',
  ars: '$',
  aud: '$',
  awg: 'ƒ',
  azn: '₼',
  bam: 'KM',
  bbd: '$',
  bdt: '৳',
  bgn: 'лв',
  bhd: '.د.ب',
  bif: 'FBu',
  bmd: '$',
  bnd: '$',
  bob: '$b',
  bov: 'BOV',
  brl: 'R$',
  bsd: '$',
  // btc, '₿', // Excluding cryptocurrencies
  btn: 'Nu.',
  bwp: 'P',
  byn: 'Br',
  byr: 'Br',
  bzd: 'BZ$',
  cad: '$',
  cdf: 'FC',
  che: 'CHE',
  chf: 'CHF',
  chw: 'CHW',
  clf: 'CLF',
  clp: '$',
  cny: '¥',
  cop: '$',
  cou: 'COU',
  crc: '₡',
  cuc: '$',
  cup: '₱',
  cve: '$',
  czk: 'Kč',
  djf: 'Fdj',
  dkk: 'kr',
  dop: 'RD$',
  dzd: 'دج',
  egp: '£',
  ern: 'Nfk',
  etb: 'Br',
  // eth, 'Ξ', // Excluding cryptocurrencies
  eur: '€',
  fjd: '$',
  fkp: '£',
  gbp: '£',
  gel: '₾',
  ghs: 'GH₵',
  gip: '£',
  gmd: 'D',
  gnf: 'FG',
  gtq: 'Q',
  gyd: '$',
  hkd: '$',
  hnl: 'L',
  hrk: 'kn',
  htg: 'G',
  huf: 'Ft',
  idr: 'Rp',
  ils: '₪',
  inr: '₹',
  iqd: 'ع.د',
  irr: '﷼',
  isk: 'kr',
  jmd: 'J$',
  jod: 'JD',
  jpy: '¥',
  kes: 'KSh',
  kgs: 'лв',
  khr: '៛',
  kmf: 'CF',
  kpw: '₩',
  krw: '₩',
  kwd: 'KD',
  kyd: '$',
  kzt: '₸',
  lak: '₭',
  lbp: '£',
  lkr: '₨',
  lrd: '$',
  lsl: 'M',
  // ltc, 'Ł', // Excluding cryptocurrencies
  lyd: 'LD',
  mad: 'MAD',
  mdl: 'lei',
  mga: 'Ar',
  mkd: 'ден',
  mmk: 'K',
  mnt: '₮',
  mop: 'MOP$',
  mro: 'UM',
  mur: '₨',
  mvr: 'Rf',
  mwk: 'MK',
  mxn: '$',
  mxv: 'MXV',
  myr: 'RM',
  mzn: 'MT',
  nad: '$',
  ngn: '₦',
  nio: 'C$',
  nok: 'kr',
  npr: '₨',
  nzd: '$',
  omr: '﷼',
  pab: 'B/.',
  pen: 'S/.',
  pgk: 'K',
  php: '₱',
  pkr: '₨',
  pln: 'zł',
  pyg: 'Gs',
  qar: '﷼',
  ron: 'lei',
  rsd: 'Дин.',
  rub: '₽',
  rwf: 'R₣',
  sar: '﷼',
  sbd: '$',
  scr: '₨',
  sdg: 'ج.س.',
  sek: 'kr',
  sgd: 'S$',
  shp: '£',
  sll: 'Le',
  sos: 'S',
  srd: '$',
  ssp: '£',
  std: 'Db',
  svc: '$',
  syp: '£',
  szl: 'E',
  thb: '฿',
  tjs: 'SM',
  tmt: 'T',
  tnd: 'د.ت',
  top: 'T$',
  try: '₺',
  ttd: 'TT$',
  twd: 'NT$',
  tzs: 'TSh',
  uah: '₴',
  ugx: 'USh',
  usd: '$',
  usn: '$',
  uyi: 'UYI',
  uyu: '$U',
  uzs: 'лв',
  vef: 'Bs',
  vnd: '₫',
  vuv: 'VT',
  wst: 'WS$',
  xaf: 'FCFA',
  xag: 'XAG', // Silver
  xau: 'XAU', // Gold
  xba: 'XBA', // Bond Markets Units European Composite Unit (EURCO)
  xbb: 'XBB', // European Monetary Unit (E.M.U.-6)
  xbc: 'XBC', // European Unit of Account 9(E.U.A.-9)
  xbd: 'XBD', // European Unit of Account 17(E.U.A.-17)
  // xbt, 'Ƀ', // Excluding cryptocurrencies
  xcd: '$',
  xdr: 'XDR', // Special Drawing Rights (SDRs), units of account for IMF
  xof: 'CFA',
  xpd: 'XPD', // Palladium
  xpf: '₣',
  xpt: 'XPT', // Platinum
  xsu: 'Sucre',
  xts: 'XTS', // Code reserved for testing purposes
  xua: 'XUA',
  xxx: 'XXX', // Denotes transaction involving no currency
  yer: '﷼',
  zar: 'R',
  zmw: 'ZK',
  zwl: '$',
}

// Create a regular expression matching supported currency symbols
const supportedCurrencySymbols = Object.values(currencyISOtoSymbol)
  .map((sym) => sym.toLowerCase())
  // Remove duplicates
  .filter((val: string, i: number, list: string[]) => {
    return list.indexOf(val) === i
  })
const supportedCurrencySymbolRegex = new RegExp(
  supportedCurrencySymbols
    .sort((a, b) => b.length - a.length)
    .map(escapeRegexSpecials)
    .join('|'),
  'g'
)

const PATTERN_CURRENCY_SYMBOL = '$'
const currencyNumberFormatInfo: Record<string, NumberFormatInfo> = {
  default: {
    positivePattern: '$n',
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1_comma_234_comma_567_period_89: {
    positivePattern: '$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_1_period_234_period_567_comma_89: {
    positivePattern: '$ n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_period_234_period_567_comma_89_space_csym: {
    positivePattern: 'n $',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_space_234_space_567_comma_89_space_csym: {
    positivePattern: 'n $',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_1_quote_234_quote_567_period_89: {
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1_comma_234_comma_567: {
    positivePattern: '$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_space_12_comma_34_comma_567_period_89: {
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  csym_space_minus_12_comma_34_comma_567_period_89: {
    negativePattern: '$ -n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  csym_space_1234_comma_567_period_89: {
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 0,
    decimalDigits: 2,
  },
  leading_1_space_234_space_567_period_89_space_csym: {
    positivePattern: 'n $',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_1_space_234_space_567_comma_89: {
    positivePattern: '$ n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_1_space_234_space_567_period_89: {
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_space_234_space_567_comma_89_csym: {
    positivePattern: 'n$',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_comma_234_comma_567_period_89_space_csym: {
    positivePattern: 'n $',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1_period_234_period_567: {
    positivePattern: '$n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_12_comma_34_comma_567_period_89: {
    positivePattern: '$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  csym_space_1_comma_234_comma_567_period_89: {
    // Persian (fa) from CLDR
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1_period_234_period_567_comma_89: {
    // new by es-CO
    positivePattern: '$n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_quote_234_quote_567_period_89_space_csym: {
    // new by fr-ch
    positivePattern: 'n $',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1234_comma_567_period_89: {
    // new by es-PR
    positivePattern: '$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 0,
    decimalDigits: 2,
  },
  leading_1_period_234_period_567_space_csym: {
    // new (not used)
    positivePattern: 'n $',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_1_space_234_space_567_comma_89: {
    // new by en-ZA, es-CR (WIN10), 'CSym_Space_1_Space_234_Space_567_Comma_89' in Win2012
    positivePattern: '$n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  // Negative format
  opar_csym_1_comma_234_comma_567_period_89_cpar: {
    negativePattern: '($n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1_comma_234_comma_567_period_89: {
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_space_1_period_234_period_567_comma_89: {
    negativePattern: '-$ n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_minus_1_period_234_period_567_comma_89: {
    negativePattern: '$ -n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_period_234_period_567_comma_89_space_csym: {
    negativePattern: '-n $',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_1_space_234_space_567_comma_89_space_csym_cpar: {
    negativePattern: '(n $)',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_space_234_space_567_comma_89_space_csym: {
    negativePattern: '-n $',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_comma_234_comma_567_period_89_space_csym: {
    negativePattern: '-n $',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_minus_1_quote_234_quote_567_period_89: {
    negativePattern: '$-n',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1_period_234_period_567_comma_89: {
    negativePattern: '-$n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1_comma_234_comma_567: {
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 0,
  },
  opar_csym_space_1234_comma_567_period_89_cpar: {
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 0,
    decimalDigits: 2,
  },
  csym_minus_12_comma_34_comma_567_period_89: {
    negativePattern: '$-n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  csym_space_minus_1_space_234_space_567_comma_89: {
    negativePattern: '$ -n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_minus_1_space_234_space_567_period_89: {
    negativePattern: '$ -n',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_space_1_space_234_space_567_comma_89: {
    negativePattern: '-$ n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_space_234_space_567_comma_89_csym: {
    negativePattern: '-n$',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_space_234_space_567_period_89_space_csym: {
    negativePattern: '-n $',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_1_period_234_period_567_cpar: {
    negativePattern: '($n)',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  opar_csym_1_comma_234_comma_567_cpar: {
    negativePattern: '($n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_minus_1_comma_234_comma_567_period_89: {
    negativePattern: '$-n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_12_comma_34_comma_567_period_89: {
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  minus_csym_space_1_comma_234_comma_567_period_89: {
    // Persian (fa) from CLDR
    negativePattern: '-$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_period_234_period_567_comma_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_quote_234_quote_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_space_234_space_567_comma_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_space_234_space_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_12_comma_34_comma_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  opar_csym_space_12_comma_34_comma_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  opar_1_comma_234_comma_567_period_89_space_csym_cpar: {
    // Accounting format
    negativePattern: '(n $)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_1_period_234_period_567_comma_89_space_csym_cpar: {
    // Accounting format
    negativePattern: '(n $)',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_1_space_234_space_567_comma_89_csym_cpar: {
    // Accounting format
    negativePattern: '(n$)',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_1_space_234_space_567_period_89_space_csym_cpar: {
    // Accounting format
    negativePattern: '(n $)',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_comma_234_comma_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1_period_234_period_567: {
    // For parity
    negativePattern: '-$n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  minus_csym_space_1_quote_234_quote_567_period_89: {
    // For parity
    negativePattern: '-$ n',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_space_1_space_234_space_567_period_89: {
    // For parity
    negativePattern: '-$ n',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_minus_1_comma_234_comma_567: {
    // For parity
    negativePattern: '$-n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_minus_1_period_234_period_567: {
    // For parity
    negativePattern: '$-n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_space_minus_1_quote_234_quote_567_period_89: {
    // For parity
    negativePattern: '$ -n',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_minus_1_comma_234_comma_567_period_89: {
    // For parity
    negativePattern: '$ -n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_space_12_comma_34_comma_567_period_89: {
    // For parity
    negativePattern: '-$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  minus_1_period_234_period_567_space_csym: {
    // new
    negativePattern: '-n $',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_minus_1_space_234_space_567_comma_89: {
    // new by en-ZA
    negativePattern: '$-n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_quote_234_quote_567_period_89_space_csym: {
    // new by fr-CH
    negativePattern: '-n $',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1_comma_234_comma_567_period_89_minus: {
    // new by ar
    negativePattern: '$n-',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_minus_1_period_234_period_567_comma_89: {
    // new by es-AR
    negativePattern: '$-n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_1_period_234_period_567_comma_89_cpar: {
    // new by es-CR
    negativePattern: '($n)',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1234_comma_567_period_89: {
    // new by es-pr
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 0,
    decimalDigits: 2,
  },
  minus_csym_1_space_234_space_567_comma_89: {
    // new by en-ZA, es-CR (Win10)
    negativePattern: '-$n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
}

// List all supported group and decimal separators
let supportedGroupSeparators = ''
let supportedDecimalSeparators = ''
Object.values(currencyNumberFormatInfo).forEach((info) => {
  if (!supportedGroupSeparators.includes(info.groupSeparator)) {
    supportedGroupSeparators += info.groupSeparator
  }

  if (!supportedDecimalSeparators.includes(info.decimalSeparator)) {
    supportedDecimalSeparators += info.decimalSeparator
  }
})

const currencyNoDecimalDigitsIsoCode = {
  pyg: 'pyg',
  idr: 'idr',
  isk: 'isk',
  jpy: 'jpy',
  krw: 'krw',
  bnd: 'bnd',
  myr: 'myr',
}
const currency3DecimalDigitsIsoCode = {
  // bhd: 'bhd',
  // iqd: 'iqd',
  // jod: 'jod',
  // kwd: 'kwd',
  // lyd: 'lyd',
  omr: 'omr',
  // tnd: 'tnd'
}

// Currency Support
function _getCurrencyCode(localePolicy: LocalePolicy): string {
  return localePolicy?.effectiveCurrencyCode || localePolicy?.currencyCode || ''
}

function _getCurrencySymbol(localePolicy: LocalePolicy): string {
  return currencyISOtoSymbol[_getCurrencyCode(localePolicy)] || '$'
}

function _getEffectiveCurrencyNegativeFormat(
  localePolicy: LocalePolicy
): string | undefined {
  if (
    localePolicy.currencySign === 'accounting' &&
    (localePolicy.effectiveCurrencyAccountingFormat ||
      localePolicy.currencyAccountingFormat)
  ) {
    return (
      localePolicy.effectiveCurrencyAccountingFormat ||
      localePolicy.currencyAccountingFormat
    )
  }

  return (
    localePolicy.effectiveCurrencyNegativeFormat ||
    localePolicy.currencyNegativeFormat
  )
}

function _getNumberFormatInfo(
  localePolicy: LocalePolicy,
  isPositive: boolean
): NumberFormatInfo {
  const effectiveCurrencyFormat =
    (isPositive
      ? localePolicy.effectiveCurrencyPositiveFormat ||
        localePolicy.currencyPositiveFormat
      : _getEffectiveCurrencyNegativeFormat(localePolicy)) || 'default'
  return currencyNumberFormatInfo[effectiveCurrencyFormat]
}

function split2IntDec(
  num: number,
  value: number | string,
  decPlaces: number
): string[] {
  const result = []
  let n: string,
    decN = ''
  if (decPlaces === 0) {
    n = Math.floor(num).toString()
  } else {
    n = num.toFixed(decPlaces)
  }
  const nums = n.split('.')
  if (nums.length > 1) {
    decN = nums[1]
  }
  if (n.indexOf('e+') >= 0) {
    let str: string = value.toString()
    if (typeof value !== 'string') {
      str = num.toLocaleString('fullwide', { useGrouping: false })
    }
    const decs = str.replace(/[^\d^.]/gi, '').split('.')
    result.push(decs[0])
    result.push(
      Number(decs[1] ? '0.' + decs[1] : '0')
        .toFixed(decPlaces)
        .slice(2)
    )
  } else {
    result.push(parseInt(n.toString()) + '')
    result.push(decN)
  }
  return result
}

function formatDigit(
  n: number,
  decPlaces: number,
  thouSeparator: string,
  decSeparator: string,
  value: number | string
): string {
  let j
  const [i, decN] = split2IntDec(n, value, decPlaces)
  j = (j = i.length) > 3 ? j % 3 : 0
  return (
    (j ? i.substring(0, j) + thouSeparator : '') +
    i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) +
    (decPlaces ? decSeparator + decN : '')
  )
}

function formatDigit2(
  n: number,
  decPlaces: number,
  thouSeparator: string,
  decSeparator: string,
  value: number | string
): string {
  let j
  const [ii, decN] = split2IntDec(n, value, decPlaces)
  const e = ii.slice(-1)
  const i = ii.substring(0, ii.length - 1)
  j = (j = i.length) > 2 ? j % 2 : 0
  return (
    (j ? i.substring(0, j) + thouSeparator : '') +
    i.substring(j).replace(/(\d{2})(?=\d)/g, '$1' + thouSeparator) +
    e +
    (decPlaces ? decSeparator + decN : '')
  )
}

function formatDigit0(
  n: number,
  decPlaces: number,
  thouSeparator: string,
  decSeparator: string,
  value: number | string
): string {
  const [i, decN] = split2IntDec(n, value, decPlaces)
  const s =
    i.length > 3
      ? i.substring(0, i.length - 3) + thouSeparator + i.substring(i.length - 3)
      : i
  return s + (decPlaces ? decSeparator + decN : '')
}

function __formatCurrency(
  localePolicy: LocalePolicy,
  digit: number,
  isoCode: string,
  value: number | string
): string {
  let currencySymbol = currencyISOtoSymbol[isoCode] || '$'
  let digitstr, numberInfo
  let negativePattern = ''
  let positivePattern = ''
  if (digit < 0) {
    numberInfo = _getNumberFormatInfo(localePolicy, false)
    negativePattern =
      numberInfo.negativePattern || numberInfo.positivePattern || '$$$$n'
  } else {
    numberInfo = _getNumberFormatInfo(localePolicy, true)
    positivePattern =
      numberInfo.positivePattern || numberInfo.negativePattern || '$$$$n'
  }
  const decimalDigits = _getCurrencyDecimalDigits(isoCode)
  const decimalSeparator = numberInfo.decimalSeparator
  const groupSeparator = numberInfo.groupSeparator
  if (numberInfo.groupDigits === 2) {
    digitstr = formatDigit2(
      Math.abs(digit),
      decimalDigits,
      groupSeparator,
      decimalSeparator,
      value
    )
  } else if (numberInfo.groupDigits === 0) {
    digitstr = formatDigit0(
      Math.abs(digit),
      decimalDigits,
      groupSeparator,
      decimalSeparator,
      value
    )
  } else {
    digitstr = formatDigit(
      Math.abs(digit),
      decimalDigits,
      groupSeparator,
      decimalSeparator,
      value
    )
  }
  // If the culture is not en-US AND it's own currency symbol is '$' AND we are formatting US dollars: show 'US$' instead of '$'
  // Also hard-code Mexico, Brazil, Malaysia, Philipines, and Taiwan
  const cultureName = localePolicy.cultureName?.replace('-', '_').toLowerCase()
  if (
    isoCode === 'usd' &&
    cultureName !== undefined &&
    cultureName !== 'en' &&
    cultureName !== 'en_us'
  ) {
    const predefinedPolicy = getRegionLocalePolicy(cultureName)
    if (
      (predefinedPolicy.effectiveCurrencyCode &&
        predefinedPolicy.effectiveCurrencyCode !== 'usd' &&
        currencyISOtoSymbol[predefinedPolicy.effectiveCurrencyCode].includes(
          '$'
        )) ||
      ['mx', 'br', 'my', 'ph', 'tw'].includes(cultureName.split('_')[1])
    ) {
      currencySymbol = 'US$'
    }
  }
  if (digit < 0) {
    digitstr = negativePattern
      .replace('n', digitstr)
      .replace('$', currencySymbol)
  } else {
    digitstr = positivePattern
      .replace('n', digitstr)
      .replace('$', currencySymbol)
  }
  return digitstr
}

function _formatCurrency(
  localePolicy: LocalePolicy,
  value: number | string,
  isoCode: string | undefined | EnumCurrencyCode,
  isLongFormat: boolean
): string {
  let digit = value as number
  const ccode = isoCode
    ? isoCode.toLowerCase()
    : _getCurrencyCode(localePolicy).toLowerCase()
  if (typeof value === 'string') {
    digit = Number(value.replace(/[^\d^.^-]/gi, ''))
  }
  let formatted = __formatCurrency(localePolicy, digit, ccode, value)
  if (isLongFormat || localePolicy.useLongCurrencyFormat) {
    formatted = formatted.replace('US$', '$')
    formatted = formatted + ' ' + ccode.toUpperCase()
  }
  return formatted
}

function _formatNumber(
  localePolicy: LocalePolicy,
  value: number | string,
  decPlaces: number
): string {
  let digit = value as number
  if (typeof value === 'string') {
    digit = Number(value.replace(/[^\d^.^-]/gi, ''))
  }
  let digitstr: string
  let numberInfo
  let pattern: string
  if (digit < 0) {
    numberInfo = _getNumberFormatInfo(localePolicy, false)
    pattern = numberInfo.negativePattern || '-n'
  } else {
    numberInfo = _getNumberFormatInfo(localePolicy, true)
    pattern = numberInfo.positivePattern || 'n'
  }

  // Remove currency symbol for number formats
  pattern = pattern
    .replace('$', '')
    .replace('( n', '(n')
    .replace('n )', 'n)')
    .trim()

  if (numberInfo.groupDigits === 2) {
    digitstr = formatDigit2(
      Math.abs(digit),
      decPlaces,
      numberInfo.groupSeparator,
      numberInfo.decimalSeparator,
      value
    )
  } else if (numberInfo.groupDigits === 0) {
    digitstr = formatDigit0(
      Math.abs(digit),
      decPlaces,
      numberInfo.groupSeparator,
      numberInfo.decimalSeparator,
      value
    )
  } else {
    digitstr = formatDigit(
      Math.abs(digit),
      decPlaces,
      numberInfo.groupSeparator,
      numberInfo.decimalSeparator,
      value
    )
  }

  digitstr = pattern.replace('n', digitstr)

  return digitstr
}

function _formatDigitN(digit: number, numberInfo: NumberFormatInfo): string {
  let digitstr
  if (numberInfo.groupDigits === 2) {
    digitstr = formatDigit2(
      Math.abs(digit),
      numberInfo.decimalDigits,
      numberInfo.groupSeparator,
      numberInfo.decimalSeparator,
      Math.abs(digit)
    )
  } else if (numberInfo.groupDigits === 0) {
    digitstr = formatDigit0(
      Math.abs(digit),
      numberInfo.decimalDigits,
      numberInfo.groupSeparator,
      numberInfo.decimalSeparator,
      Math.abs(digit)
    )
  } else {
    digitstr = formatDigit(
      Math.abs(digit),
      numberInfo.decimalDigits,
      numberInfo.groupSeparator,
      numberInfo.decimalSeparator,
      Math.abs(digit)
    )
  }
  return digitstr
}

/**
 * Parse a number string or scrubbed currency string into a numerical value
 *
 * @param {LocalePolicy} localePolicy The policy that governs formatting
 * @param {string} text The number or scrubbed currency string
 * @param {boolean} ignoreSymbol True if the currency symbol should be ignored in the number pattern, use this for parsing numbers
 * @param {NumberParseOptions} [options] Optional settings for making the parse rules more strict
 *
 * @returns {number | null} The numerical value if the input passes all parsing rules. Null if it doesn't pass rules. It is possible to get NaN (also a number type).
 * @throws {TypeError} When the locale policy is not populated with effective formatting data
 */
function __parseNumber(
  localePolicy: LocalePolicy,
  text: string,
  ignoreSymbol: boolean,
  options?: NumberParseOptions
): number | null {
  text = text.trim().toLowerCase()

  if (!text.length) {
    return NaN
  }

  let absolute = text.replace(/[-()]/g, '')
  const isPositive = absolute === text
  const currencyFormat = isPositive
    ? localePolicy.effectiveCurrencyPositiveFormat ||
      localePolicy.currencyPositiveFormat
    : _getEffectiveCurrencyNegativeFormat(localePolicy)
  const numberInfo = currencyFormat
    ? currencyNumberFormatInfo[currencyFormat]
    : undefined
  if (!numberInfo) {
    throw new TypeError(
      `localePolicy.${
        isPositive
          ? 'effectiveCurrencyPositiveFormat'
          : localePolicy.currencySign !== 'accounting'
          ? 'effectiveCurrencyNegativeFormat'
          : 'effectiveCurrencyAccountingFormat'
      } must match a defined format`
    )
  }

  // Verify text matches the given pattern
  if (options && options.strictPattern) {
    let pattern = escapeRegexSpecials(
      isPositive
        ? numberInfo.positivePattern ?? '$n'
        : numberInfo.negativePattern ?? '-$n'
    )
    if (ignoreSymbol) {
      pattern = pattern.replace('\\$', '')
    }
    pattern = `^${pattern.trim()}$`
    pattern = pattern.replace(
      'n',
      `[0-9${escapeRegexSpecials(
        numberInfo.decimalSeparator + numberInfo.groupSeparator
      )}]*`
    )
    if (!options || !options.strictSpaceMatching) {
      pattern = pattern.replace(/ /g, ' *')
    }
    const patternRegex = new RegExp(pattern)

    // Verify text matches the given pattern
    const result = patternRegex.exec(text)
    if (!result) {
      return null
    }
  }

  // Is it a valid number?
  // After removing supported currency symbols, supported separators, and
  // whitespace we should be able to parse the string as a valid number of
  // reasonable size.
  const numberString = absolute
    .replace(supportedCurrencySymbolRegex, '')
    .replace(
      new RegExp(
        `[${escapeRegexSpecials(
          supportedDecimalSeparators + supportedGroupSeparators + ' '
        )}]`,
        'g'
      ),
      ''
    )
  if (isNaN(Number(numberString))) {
    return NaN
  }
  if (!isFinite(Number(numberString))) {
    // Numbers that are too large are not parseable, return null
    return null
  }

  // Verify whole part and decimal part of number
  absolute = absolute.replace(
    new RegExp(
      `[^\\d${escapeRegexSpecials(
        numberInfo.decimalSeparator + numberInfo.groupSeparator
      )}]`,
      'g'
    ),
    ''
  )

  const [wholeDigits, decimalDigits, other] = absolute.split(
    numberInfo.decimalSeparator
  )
  if (other) {
    // more than 1 decimal separator
    return NaN
  }
  if (options && options.requireWholePart && !wholeDigits.length) {
    return null
  }
  if (options && options.requireDecimalPart && !decimalDigits) {
    return null
  }

  // Verify groups
  if (options && options.strictGroupMatching && wholeDigits.length) {
    const groups = wholeDigits.split(numberInfo.groupSeparator)

    if (groups.length === 1) {
      if (groups[0].length > 3 || !groups[0].length) {
        return null
      }
    } else if (numberInfo.groupDigits === 0) {
      if (
        (groups.length === 2 && groups[1].length !== 3) ||
        groups.length > 2
      ) {
        return null
      }
    } else {
      for (let i = 0; i < groups.length; i++) {
        const g = groups[i]
        if (
          !g.length || // No group should be empty
          (i === 0 && g.length > numberInfo.groupDigits) ||
          (i > 0 &&
            i < groups.length - 1 &&
            g.length !== numberInfo.groupDigits) ||
          (i === groups.length - 1 && g.length !== 3)
        ) {
          return null
        }
      }
    }

    // There should not be any group separators in the decimal part
    if (decimalDigits) {
      const decimalSplits = decimalDigits.split(numberInfo.groupSeparator)
      if (decimalSplits.length > 1) {
        return null
      }
    }
  }

  // Verify decimal digits
  if (
    options &&
    options.strictDecimalDigits &&
    ((decimalDigits && decimalDigits.length !== numberInfo.decimalDigits) ||
      (!decimalDigits && numberInfo.decimalDigits !== 0))
  ) {
    return null
  }

  // All strict rules have been verified, now parse the digits
  absolute = absolute.replace(
    new RegExp(escapeRegexSpecials(numberInfo.groupSeparator), 'g'),
    ''
  )
  absolute = absolute.replace(numberInfo.decimalSeparator.toString(), '.')
  const number = Number(absolute)
  return isPositive ? number : -number
}

/**
 * Parse a number string into a numerical value
 *
 * @param {LocalePolicy} localePolicy The policy that governs formatting
 * @param {string} text The number string
 * @param {NumberParseOptions} [options] Optional settings for making the parse rules more strict
 *
 * @returns {number | null} The numerical value if the input passes all parsing rules. Null if it doesn't pass rules. It is possible to get NaN (also a number type).
 * @throws {TypeError} When the locale policy is not populated with effective formatting data
 */
function _parseNumber(
  localePolicy: LocalePolicy,
  text: string,
  options?: NumberParseOptions
): number | null {
  return __parseNumber(localePolicy, text, true, options)
}

/**
 * Parse a currency string into a numerical value
 *
 * @param {LocalePolicy} localePolicy The policy that governs formatting
 * @param {string} text The currency string
 * @param {CurrencyParseOptions} [options] Optional settings for making the parse rules more strict
 *
 * @returns {number | null} The numerical value if the input passes all parsing rules. Null if it doesn't pass rules. It is possible to get NaN (also a number type).
 * @throws {TypeError} When the locale policy does is not populated with effective formatting data
 */
function _parseCurrency(
  localePolicy: LocalePolicy,
  text: string,
  options?: CurrencyParseOptions
): number | null {
  text = text.trim().toLowerCase()

  const strictIso = (options as CurrencyParseOptions)?.strictIsoCodeMatching
  const strictSym = (options as CurrencyParseOptions)?.strictCurrencySymbol

  const isoCode = _getCurrencyCode(localePolicy).toLowerCase()

  // Verify and replace the effective currency symbol from the locale policy
  let currencySymbol
  if (strictSym) {
    currencySymbol = isoCode.length ? currencyISOtoSymbol[isoCode] : undefined
    if (!currencySymbol) {
      throw new TypeError('localePolicy.currencyCode must be a valid ISO code')
    }
  } else {
    currencySymbol = _getCurrencySymbol(localePolicy)
  }

  const symbolCount = (
    text.match(new RegExp(escapeRegexSpecials(currencySymbol), 'g')) || []
  ).length
  if (strictSym && symbolCount !== 1) {
    return null
  }
  text = text.replace(currencySymbol, PATTERN_CURRENCY_SYMBOL)

  // Verify and remove the ISO code
  if (strictIso && localePolicy.useLongCurrencyFormat === undefined) {
    throw new TypeError(
      'localePolicy.useLongCurrencyFormat must be set when using strict ISO code option'
    )
  }
  const isoRegex = new RegExp(isoCode + '$') // Must be at end of string
  if (strictIso && localePolicy.useLongCurrencyFormat !== isoRegex.test(text)) {
    return null
  }
  text = text.replace(isoRegex, '').trim()
  // Any incorrect ISO code will be caught when doing pattern verification step in __parseNumber

  return __parseNumber(localePolicy, text, false, options)
}

function _getCurrencyDecimalDigits(
  isoCode: string,
  digitsFromLocale = 2
): number {
  let result = digitsFromLocale
  if (currencyNoDecimalDigitsIsoCode[isoCode]) {
    result = 0
  }
  if (currency3DecimalDigitsIsoCode[isoCode]) {
    result = 3
  }

  return result
}

export {
  getCurrencySymbol,
  getNumberFormatInfo,
  formatCurrency,
  formatNumber,
  parseCurrency,
  parseNumber,
  _getNumberFormatInfo,
  getCurrencyDecimalDigits,
  formatDigitN,
}

function getCurrencySymbol(localePolicy: LocalePolicy): string {
  try {
    const t0 = getPerformance()
    const result = _getCurrencySymbol(localePolicy)
    setTelemetry(getCurrencySymbol.name, t0)
    return result
  } catch (ex) {
    reportException(getCurrencySymbol.name, ex)
    return ''
  }
}
function getNumberFormatInfo(
  localePolicy: LocalePolicy,
  isPositive: boolean
): NumberFormatInfo {
  try {
    const t0 = getPerformance()
    const result = _getNumberFormatInfo(localePolicy, isPositive)
    setTelemetry(getNumberFormatInfo.name, t0)
    return result
  } catch (ex) {
    reportException(getNumberFormatInfo.name, ex)
    return {
      positivePattern: '$n',
      negativePattern: '-$n',
      decimalSeparator: '.',
      groupSeparator: ',',
      groupDigits: 3,
      decimalDigits: 2,
    }
  }
}
function formatCurrency(
  localePolicy: LocalePolicy,
  value: number | string,
  isoCode: string | CurrencyCode | undefined,
  isLongFormat: boolean
): string {
  try {
    const t0 = getPerformance()
    const result = _formatCurrency(localePolicy, value, isoCode, isLongFormat)
    setTelemetry(formatCurrency.name, t0)
    return result
  } catch (ex) {
    reportException(formatCurrency.name, ex)
    throw ex
  }
}
function formatNumber(
  localePolicy: LocalePolicy,
  value: number | string,
  decPlaces: number
): string {
  try {
    const t0 = getPerformance()
    const result = _formatNumber(localePolicy, value, decPlaces)
    setTelemetry(formatNumber.name, t0)
    return result
  } catch (ex) {
    reportException(formatNumber.name, ex)
    throw ex
  }
}
function formatDigitN(digit: number, numberInfo: NumberFormatInfo): string {
  try {
    const t0 = getPerformance()
    const result = _formatDigitN(digit, numberInfo)
    setTelemetry(formatNumber.name, t0)
    return result
  } catch (ex) {
    reportException(formatNumber.name, ex)
    throw ex
  }
}
function parseCurrency(
  localePolicy: LocalePolicy,
  text: string,
  options?: CurrencyParseOptions
): number | null {
  try {
    const t0 = getPerformance()
    const result = _parseCurrency(localePolicy, text, options)
    setTelemetry(parseCurrency.name, t0)
    return result
  } catch (ex) {
    reportException(parseCurrency.name, ex)
    throw ex
  }
}
function parseNumber(
  localePolicy: LocalePolicy,
  text: string,
  options?: NumberParseOptions
): number | null {
  try {
    const t0 = getPerformance()
    const result = _parseNumber(localePolicy, text, options)
    setTelemetry(parseNumber.name, t0)
    return result
  } catch (ex) {
    reportException(parseNumber.name, ex)
    throw ex
  }
}
function getCurrencyDecimalDigits(isoCode: string): number {
  try {
    const t0 = getPerformance()
    const result = _getCurrencyDecimalDigits(isoCode)
    setTelemetry(getCurrencyDecimalDigits.name, t0)
    return result
  } catch (ex) {
    reportException(getCurrencyDecimalDigits.name, ex)
    throw ex
  }
}
