import * as React from 'react'
import PropTypes from 'prop-types'
import { requiredPropMessage, warnInvalidThemeProp } from '../../../logging'
import { BaseMenuItem } from '../../../internal/components/BaseMenuItem'
import { CustomPropTypes } from '../../../support'
import { useIsInk } from '../../../theming'
import type { ConditionalTagRef, EventListenerProps } from '../../../types'
import { anchorTargets, AnchorTarget } from '../../../variables'
import type { BadgeProps } from '../../Badge'
import type { DotBadgeProps } from '../../DotBadge'
import { MenuContext } from '../MenuContext'

export interface MenuItemProps
  extends EventListenerProps<
    HTMLAnchorElement | HTMLButtonElement | HTMLInputElement
  > {
  /**
   * The text to present to assistive devices in order to identify the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText?: string
  /**
   * The "badge" element to display above the top-right of the Menu.Item.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   *
   * This is only available in the Olive theme.
   */
  badge?: React.ReactElement<DotBadgeProps | BadgeProps>
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * An optional description.
   */
  description?: string
  /**
   * Applies the 'disabled' attribute.
   */
  disabled?: boolean
  /**
   * The provided component will display at the end of the MenuItem.
   */
  endElement?: React.ReactNode
  /**
   * A React ref to assign to the HTML node representing the component.
   */
  forwardedRef?: ConditionalTagRef
  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element,
   * otherwise it renders as a button element.
   */
  href?: string
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*'?: React.EventHandler<
    React.SyntheticEvent<
      HTMLInputElement | HTMLAnchorElement | HTMLButtonElement
    >
  >
  /**
   * The relationship of the linked URL of a anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * A boolean for indicating if the MenuItem is selected. Only used for styling
   */
  selected?: boolean
  /**
   * The provided component will display at the start of the MenuItem.
   */
  startElement?: React.ReactNode
  /**
   * The HTML link target (if an href is _not_ provided, this is ignored).
   * When target="_blank" use rel="noreferrer" or rel="noopener" to avoid the vulnerability.
   */
  target?: AnchorTarget
  /**
   * The text of the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  text?: string
}

export function MenuItem({
  accessibilityText,
  badge,
  description,
  disabled = false,
  endElement,
  forwardedRef,
  href,
  onClick,
  rel,
  selected = false,
  startElement,
  target,
  text,
  ...restProps
}: MenuItemProps) {
  const isInk = useIsInk()
  const menuContext = React.useContext(MenuContext)

  const badgeNode = isInk ? undefined : badge

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'Menu.Item',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  if (isInk && badge) {
    warnInvalidThemeProp('Menu.Item', 'badge', 'Ink')
  }

  return (
    <BaseMenuItem
      {...restProps}
      accessibilityText={accessibilityText}
      aria-current={selected ? true : undefined}
      badge={badgeNode}
      data-selected-item={selected}
      description={description}
      disabled={disabled}
      endElement={endElement}
      forwardedRef={forwardedRef}
      href={href}
      onClick={onClick}
      rel={rel}
      role={menuContext.MenuItemRole}
      selected={selected}
      startElement={startElement}
      target={target}
      text={text}
    />
  )
}

MenuItem.targets = anchorTargets

MenuItem.propTypes = {
  accessibilityText: PropTypes.string,
  badge: PropTypes.element,
  'data-.*': PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  endElement: PropTypes.node,
  forwardedRef: CustomPropTypes.ReactRef,
  href: PropTypes.string,
  'on[A-Z].*': PropTypes.func,
  onClick: PropTypes.func,
  rel: PropTypes.string,
  selected: PropTypes.bool,
  startElement: PropTypes.node,
  target: PropTypes.oneOf(anchorTargets),
  text: PropTypes.string,
}

MenuItem.displayName = 'Menu.Item'
