import { useMemoizedDeepmerge } from '@ds/react-utils'
import type { DocuSignThemeId } from '../docusign-themes/docuSignThemeName'
import type { CobrandingTokens, Tokens } from '../types'
import { useCobrandingStyles } from './useCobrandingStyles'
import { useTheme } from './useTheme'

interface Config<P> {
  tokens: Tokens
  props: P | Record<string, never>
}

type StyleResolver<P, S> = (config: Config<P>) => S

export interface Styles<P, S, IS, OS, CS> {
  base: StyleResolver<P, S>
  ink?: StyleResolver<P, IS>
  olive?: StyleResolver<P, OS>
  cobranding?: (config: {
    tokens: CobrandingTokens
    props: P | Record<string, never>
  }) => CS
}

export function useThemeStyles<
  P,
  S extends Record<string, unknown>,
  IS extends Record<string, unknown>,
  OS extends Record<string, unknown>,
  CS extends Record<string, unknown>,
>(styles: Styles<P, S, IS, OS, CS>, props?: P) {
  const { tokens, name } = useTheme()
  const themeName = name.toLowerCase() as DocuSignThemeId

  const baseStylesWithTokens = styles.base({
    tokens: tokens as Tokens,
    props: props || {},
  })

  const themeStylesWithTokens = styles[themeName]?.({
    tokens: tokens as Tokens,
    props: props || {},
  })

  const stylesWithTheme = useMemoizedDeepmerge(
    baseStylesWithTokens,
    themeStylesWithTokens || {},
  ) as S & (IS & OS)

  const cobrandingStyles = useCobrandingStyles(styles.cobranding, props)

  const stylesWithCobranding = useMemoizedDeepmerge(
    stylesWithTheme,
    cobrandingStyles,
  ) as S & (IS & OS & CS)

  return stylesWithCobranding
}
