import { breakpoints, styles } from '../../../utilities'
import { zIndexes } from '../../../variables'
import type { HeaderProps } from '..'

export default (config: { props: Partial<HeaderProps> }) => {
  const { props } = config
  return {
    default: {
      flex: '0 0 auto',
      zIndex: zIndexes.aboveBaseLevel,
    },

    wrap: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0 auto',
      maxWidth: props.fullWidth ? 'none' : '1506px',
      minWidth: '320px',
      position: 'relative',

      [breakpoints.lessThan(breakpoints.Sizes.Small)]: {
        flexWrap: 'wrap',
        height: 'auto',
        maxWidth: 'none',
      },
    },

    hidden: styles.visuallyHidden,
  } as const
}
