import { isHexTriplet } from './isHexTriplet'
import { hasLowPerceivedBrightness } from './hasLowPerceivedBrightness'

/**
 * An accessible text color given the background color upon which it will display.
 *
 * @param {string} backgroundHexColor - A hex triplet prepended by '#', e.g. '#019AEF'.
 * @returns {string} A hex triplet for either a standard "light" or "dark" text color.
 */
export function contrastingGrayscaleTextColor(
  backgroundHexColor?: string,
): string | undefined {
  if (!backgroundHexColor || !isHexTriplet(backgroundHexColor)) {
    return undefined
  }

  const TEXT_LIGHT = '#f4f4f4'
  const TEXT_DARK = '#1e1e1e'

  return hasLowPerceivedBrightness(backgroundHexColor) ? TEXT_LIGHT : TEXT_DARK
}
