import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { dataProps, mergeRefs } from '@ds/react-utils'
import { MotionPresence, MotionVariant } from '@ds/motion'
import { useTranslate } from '@ds/comp-private'
import {
  CheckOlive,
  PebbleCheck,
  PebbleError,
  PebbleErrorOlive,
  PebbleInfo,
  PebbleInfoOlive,
  PebbleWarning,
  PebbleWarningOlive,
} from '@ds/icons'
import { CustomPropTypes } from '../../support'
import { useIsInk, useThemeStyles } from '../../theming'
import type { CloseButtonProps, DivForwardRef } from '../../types'
import { globalIds } from '../../variables'
import { ProgressCircle } from '../ProgressCircle'
import {
  ToastMessageAction,
  ToastMessageActionProps,
} from './ToastMessageAction'
import { ToastMessageClose } from './ToastMessageClose'
import { ToastMessagePortal } from './ToastMessagePortal'
import styles from './styles'
import { IconWithTheme } from '../../internal/components/IconWithTheme'

export const toastMessageKinds = [
  'danger',
  'information',
  'loading',
  'success',
  'warning',
] as const

export type ToastMessageKind = (typeof toastMessageKinds)[number]

const semanticIcons = {
  danger: (
    <IconWithTheme inkIcon={<PebbleError />} oliveIcon={<PebbleErrorOlive />} />
  ),
  information: (
    <IconWithTheme inkIcon={<PebbleInfo />} oliveIcon={<PebbleInfoOlive />} />
  ),
  success: (
    <IconWithTheme inkIcon={<PebbleCheck />} oliveIcon={<CheckOlive />} />
  ),
  warning: (
    <IconWithTheme
      inkIcon={<PebbleWarning />}
      oliveIcon={<PebbleWarningOlive />}
    />
  ),
} as const

export interface ToastMessageProps {
  /**
   * The call to action of the ToastMessage. Requires a ToastMessage.Action element.
   */
  action?: React.ReactElement<ToastMessageActionProps>
  /**
   * The ToastMessage content.
   */
  children: React.ReactNode
  /**
   * Adds a close button to the ToastMessage.
   * The value should be a ToastMessage.Close component, which takes an 'onClick'
   * prop whose value should be a function to invoke when it is clicked.
   */
  closeButton?: React.ReactElement<CloseButtonProps>
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * A React ref to assign to the HTML node representing the ToastMessage component.
   */
  forwardedRef?: DivForwardRef
  /**
   * The kind of ToastMessage
   */
  kind?: ToastMessageKind
  /**
   * Callback function fired after ToastMessage has animated out.
   */
  onExited?: () => void
  /**
   * Is this ToastMessage a child of a ToastMessage.Portal component
   * @ignore @internal
   */
  toastMessagePortalChild?: boolean
  /**
   * Displays the ToastMessage.
   */
  visible?: boolean
  /**
   * Visually hide the ToastMessage but still available in the DOM
   * that will be read by screen readers.
   */
  visuallyHidden?: boolean
}

/**
 * Toasts provide instant feedback about the outcome of an action taken.
 */
export function ToastMessage({
  action,
  children,
  closeButton,
  'data-qa': dataQA,
  forwardedRef,
  kind = 'information',
  onExited,
  toastMessagePortalChild = false,
  visible = false,
  visuallyHidden = false,
  ...restProps
}: ToastMessageProps) {
  const isInk = useIsInk()
  const translate = useTranslate()
  const sx = useThemeStyles(styles)

  const toastRef = useRef<HTMLDivElement>(null)
  const [toastHeight, setToastHeight] = useState(0)

  function getIconDescription(): string {
    switch (kind) {
      case 'danger':
        return translate('OLIVE:MESSAGE_DANGER:A11Y')
      case 'information':
        return translate('OLIVE:MESSAGE_INFORMATION:A11Y')
      case 'success':
        return translate('OLIVE:MESSAGE_SUCCESS:A11Y')
      case 'warning':
        return translate('OLIVE:MESSAGE_WARNING:A11Y')
      default:
        return ''
    }
  }

  const iconStyles = [sx.default.icon, kind !== 'loading' && sx[kind]?.icon]
  const iconDescription = getIconDescription()
  const iconDescriptionNode = iconDescription && (
    <span css={sx.hidden}>{iconDescription}</span>
  )

  const loadingNode = kind === 'loading' && (
    <div css={sx.loading.spinner} data-qa={dataQA && `${dataQA}-loading`}>
      <ProgressCircle
        dark
        hideText
        kind={isInk ? 'subtle' : 'default'}
        size="small"
        text={translate('loading')}
      />
    </div>
  )

  const iconNode = kind && kind !== 'loading' && (
    <div css={iconStyles} data-qa={dataQA && `${dataQA}-icon`}>
      {iconDescriptionNode}
      {semanticIcons[kind]}
    </div>
  )

  const startElementNode = (
    <div css={sx.default.startElement} data-qa={dataQA && `${dataQA}-start`}>
      {loadingNode}
      {iconNode}
    </div>
  )

  const closeButtonNode = closeButton && (
    <div css={sx.default.closeButton} data-qa={dataQA && `${dataQA}-close`}>
      {closeButton}
    </div>
  )

  const actionNode = action && (
    <div css={sx.default.action} data-qa={dataQA && `${dataQA}-action`}>
      {action}
    </div>
  )

  const wrapStyles = [
    sx.default.wrap,
    sx[kind].wrap,
    visuallyHidden && sx.visuallyHidden.wrap,
  ]

  const getToastHeight = useCallback(() => {
    const { current: toastElement } = toastRef
    if (visible && toastElement) {
      setToastHeight(toastElement?.getBoundingClientRect().height)
    }
  }, [visible])

  const toastMessageWithAnimation = (
    <MotionPresence onExit={onExited}>
      {visible && (
        <MotionVariant
          {...dataProps(restProps)}
          data-qa={dataQA}
          ref={mergeRefs(toastRef, forwardedRef)}
          animate="enter"
          custom={toastHeight}
          exit="exit"
          initial="initial"
          layout
          onAnimationStart={getToastHeight}
          variants={sx.motionVariants.toastWrap}
        >
          <MotionVariant
            data-qa={dataQA && `${dataQA}-wrap`}
            css={wrapStyles}
            custom={toastHeight}
            variants={sx.motionVariants.toastInner}
          >
            {startElementNode}

            <div css={sx.default.body} data-qa={dataQA && `${dataQA}-body`}>
              <div
                css={sx.default.content}
                data-qa={dataQA && `${dataQA}-content`}
              >
                {children}
              </div>
              {actionNode}
            </div>

            {closeButtonNode}
          </MotionVariant>
        </MotionVariant>
      )}
    </MotionPresence>
  )

  return toastMessagePortalChild ? (
    toastMessageWithAnimation
  ) : (
    <ToastMessagePortal targetId={globalIds.ToastMessageContainer}>
      {toastMessageWithAnimation}
    </ToastMessagePortal>
  )
}

ToastMessage.kinds = toastMessageKinds

ToastMessage.propTypes = {
  action: PropTypes.element,
  children: PropTypes.node.isRequired,
  closeButton: PropTypes.node,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  kind: PropTypes.oneOf(toastMessageKinds),
  onExited: PropTypes.func,
  toastMessagePortalChild: PropTypes.bool,
  visible: PropTypes.bool,
  visuallyHidden: PropTypes.bool,
}

ToastMessage.displayName = 'ToastMessage'

ToastMessage.Action = ToastMessageAction
ToastMessage.Close = ToastMessageClose
ToastMessage.Portal = ToastMessagePortal
