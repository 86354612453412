export const ariaCurrentValues = [
  'page',
  'step',
  'location',
  'date',
  'time',
  'true',
  'false',
] as const

export type AriaCurrentValues = (typeof ariaCurrentValues)[number]
