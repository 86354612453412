import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { dataProps, mergeRefs, useEventListener } from '@ds/react-utils'
import { logErrorOnce } from '@ds/logging'
import { version as OliveImagesVersion } from '@olive/images'
import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'
import type { ImageForwardRef } from '../../types'
import styles from './styles'

const loadingValues = ['eager', 'lazy'] as const
type ImageLoading = (typeof loadingValues)[number]

const objectFitValues = [
  'contain',
  'cover',
  'fill',
  'none',
  'scale-down',
] as const

export type ImageObjectFit = (typeof objectFitValues)[number]

const objectPositionValues = ['bottom', 'center', 'top'] as const
export type ImageObjectPosition = (typeof objectPositionValues)[number]

export interface ImageProps {
  /**
   * The alternative text for the 'alt' attribute.
   */
  alt: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * A React ref to assign to the `<img>` element
   */
  forwardedRef?: ImageForwardRef
  /**
   * The height of the Image in px units.
   */
  height?: number
  /**
   * If set to true, the image will be sized according to the relevant theme's icon sizing.
   *
   * 24x24px in Ink, 16x16px in Olive
   */
  iconSizing?: boolean
  /**
   * Provides a hint to the user agent on how to handle the loading of the image which is currently outside the window's visual viewport.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/loading
   */
  loading?: ImageLoading
  /**
   * The CSS object-fit property to specify how an Image should be resized to fit its container.
   *
   * - 'contain':    scaled to maintain its aspect ratio while fitting within the content box.
   * - 'cover':      content is sized to maintain aspect ratio while filling the entire content box.
   * - 'fill' :      content is sized to fill the element's content box.
   * - 'none' :      The replaced content is not resized.
   * - 'scaleDown' : The content is sized as if none or contain were specified.
   */
  objectFit?: ImageObjectFit
  /**
   * The vertical position to place the image when `objectFit` is provided.
   */
  objectPosition?: ImageObjectPosition
  /**
   * The placeholder accepts OliveSvg component to display image in following conditions.
   *
   * - `<img>` src fails to load
   * - `<img>` src takes time to fetch from http
   * - Until `<img>` loads on DOM
   * - It will replaced by `<img>` on a successful load of `<img>`
   */
  placeholder?: React.ReactNode
  /**
   * A list of media conditions / source sizes.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#sizes
   */
  sizes?: string
  /**
   * The `@olive/images` URL for the image to show.
   * - A list of all the [`available @olive/images URLs can be found here`](https://github.docusignhq.com/pages/olive/images/)
   * - To add new images to the @olive/images package see our [`contributing guidelines`](https://github.docusignhq.com/olive/images/blob/main/.github/CONTRIBUTING.md)
   */
  src: string
  /**
   * A list of possible image sources and size information.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#srcset
   */
  srcSet?: string
  /**
   * The width of the Image in px units.
   */
  width?: number
}

export function Image({
  alt,
  'data-qa': dataQa,
  forwardedRef,
  height,
  iconSizing = false,
  loading,
  objectFit,
  objectPosition,
  placeholder,
  sizes,
  src,
  srcSet,
  width,
  ...restProps
}: ImageProps) {
  const sx = useThemeStyles(styles)

  // Note: Although this is using `ref` in naming updating will trigger a re-render
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>()
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const handleImageLoaded = () => {
    if (!isImageLoaded) {
      setIsImageLoaded(true)
    }
  }

  useEventListener('load', handleImageLoaded, imageRef)

  const imageStyles = [
    sx.image,
    iconSizing && sx.iconSize,
    objectFit && [sx.objectFit, sx[objectFit], { objectPosition }],
    placeholder && !isImageLoaded && sx.hideImage,
    placeholder && isImageLoaded && sx.showImage,
  ]

  const placeholderStyles = [isImageLoaded && sx.hidePlaceholder]

  const placeholderNode = placeholder && (
    <span css={placeholderStyles} data-qa={dataQa && `${dataQa}-placeholder`}>
      {React.cloneElement(placeholder as React.ReactElement, { alt })}
    </span>
  )

  const onError = () =>
    logErrorOnce(
      {
        message: `Error loading image ${src}`,
        meta: { '@olive/images version': OliveImagesVersion },
      },
      'Image',
    )

  return (
    <>
      {placeholderNode}
      <img
        {...dataProps(restProps)}
        data-qa={dataQa}
        alt={alt}
        css={imageStyles}
        height={height}
        loading={loading}
        onError={onError}
        ref={mergeRefs(setImageRef, forwardedRef)}
        sizes={sizes}
        src={src}
        srcSet={srcSet}
        width={width}
      />
    </>
  )
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  height: PropTypes.number,
  iconSizing: PropTypes.bool,
  loading: PropTypes.oneOf(loadingValues),
  objectFit: PropTypes.oneOf(objectFitValues),
  objectPosition: PropTypes.oneOf(objectPositionValues),
  placeholder: PropTypes.node,
  sizes: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  width: PropTypes.number,
}

Image.displayName = 'Image'
