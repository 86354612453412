import React from 'react'

type MergeRefsArg<T> =
  | React.MutableRefObject<T>
  | React.RefCallback<T>
  | React.RefObject<T>
  | undefined
  | null

/**
 * A variadic function that takes any number of React refs as arguments and
 * returns a callback ref that, when assigned to an HTML DOM element, will
 * properly handle each of the provided refs. The use case that calls for this
 * is when two or more distinct refs need to be assigned to the same element
 * */

export const mergeRefs =
  <A>(...refs: Array<MergeRefsArg<A>>): React.RefCallback<A> =>
  (el: A) => {
    refs.forEach((ref: MergeRefsArg<A>) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(el)
        } else {
          ;(ref as React.MutableRefObject<A>).current = el
        }
      }
    })
  }
