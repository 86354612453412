/**
 * Add the 'focusable' attribute with value 'false' to each SVG descendant of
 * the provided element, and to the element itself if it is an SVG.
 */
export const svgFocusableFalse = (element?: Element | null) => {
  if (!element) {
    return
  }

  const SVG = /^svg$/i

  if (SVG.test(element.tagName)) {
    element.setAttribute('focusable', 'false')
  }

  Array.from(element.querySelectorAll('svg')).forEach((svg) => {
    svg.setAttribute('focusable', 'false')
  })
}
