import {
  PluralPhrases,
  TranslationDictionary,
  TranslationSubstitutionData,
} from './types'
import { Locale, Direction } from '../Common/types'
import {
  getPluralCategory,
  toLowerCaseLocale,
  PluralCategory,
  PLURAL_DEFAULT_CATEGORY,
  SupportedLocaleName,
  isRTL,
} from '@ds/i18nlayer'
import { isBrowser } from '@ds/logging'

/*

    Utility functions for translations

*/

const DICTIONARY_LOCALE_PROP = '_LOCALE'
const PLURAL_COUNT_PROP = 'PLURAL_COUNT'
const DEFAULT_LOCALE = 'en'

/**
 * Output a translation given an ID, dictionaries, and optionally, substitution data.
 *
 * @param textId The ID you wish to provide a translation for
 * @param dictionaries The dictionaries to search for a translation...in the sequence you want then searched
 * @param substitutionData An object containing the text which should be inserted for each substitution
 *

    This function will translate a given text ID to it's corresponding translation.
    It expects a set of one or more dictionaries to to be used for finding the translation.
    The search will begin in the first dictionary and continue until found, i.e., the default
    dictionary should be the last in the list. If the ID is not found it will return the ID.

    Once the translation string is found the function will "interpolate" any substitution data.
    Example:
        The translation ID is "COPYRIGHT"
        The translation text is "Copyright {{CURRENT_YEAR}} DocuSign"

        The translate function will replace {{CURRENT_YEAR}} with the data parameter.

        translate("COPYRIGHT", {CURRENT_YEAR, 2019}); // returns "Copyright 2019 DocuSign"


    Plural Handling.

    As noted above the translated phrase is normally a string, possibly with substitution parameters.
    However, the framework also supports a special translation type: plurals.  Plural translations are
    represented as an object.  The key is the plural category, the value is the phrase which should be
    used for that category.  Example:

    "SEND_TO": {
        "zero": "Send {{NAME}} nothing",
        "one": "Send {{NAME}} {{PLURAL_COUNT}} notice",
        "two": "Send {{NAME}} {{PLURAL_COUNT}} notices",
        "few": "Send {{NAME}} {{PLURAL_COUNT}} notices",
        "many": "Send {{NAME}} {{PLURAL_COUNT}} notices"
        "other": "Send {{NAME}} {{PLURAL_COUNT}} notices"
    },

    To be properly interpreted the translateText function must be called with:
    1.) Substitution data.  The substitution data must include the prop PLURAL_COUNT. This
        count (string or number) will be used to calculate which plural phrase is the best fit.
    2.) Pass TranslationDictionary objects which know the locale.  This is done via the _LOCALE prop
        on the dictionary.  Example:
            {
                "_LOCALE": "fr",
                "POWERED_BY": "Technologie {{DOCUSIGN_LOGO}}",
                "CONTACT_US": "Nous contacter",
                . . .
        The locale is used by the algorithm to decide which phrase is the best fit (varies by language)
        NOTE: If no _LOCALE prop is provided, english ("en") will be used as the fallback

    NOTE: All languages do not use all categories.  You might see less phrases.  For example english
    will only include "one" and "other".
        "SEND_TO": {
            "one": "Send {{NAME}} {{PLURAL_COUNT}} notice",
            "other": "Send {{NAME}} {{PLURAL_COUNT}} notices"
        },

    NOTE: All plural translations MUST include the "other" category.  This is used to determine if the
    object is for plural translations AND it is also used as the fallback if the category calculated is
    not found.

*/
export const translateText = (
  textId: string,
  dictionaries: TranslationDictionary[],
  substitutionData?: TranslationSubstitutionData
) => {
  const dictionary = dictionaries.find((dict) => dict[textId] !== undefined)
  if (dictionary) {
    const translatedPhrase = dictionary[textId]
    if (typeof translatedPhrase === 'string') {
      return interpolateTranslation(translatedPhrase, substitutionData)
    }
    const translatedPlural = getPluralPhrase(
      textId,
      dictionary,
      substitutionData
    )
    if (translatedPlural) {
      return interpolateTranslation(translatedPlural, substitutionData)
    }
  }
  return textId
}

export const interpolateTranslation = (
  text: string,
  data?: TranslationSubstitutionData
) => {
  if (data) {
    return Object.keys(data).reduce(
      (interpolatedString: string, substitutionParameter: string) => {
        return interpolatedString.replace(
          new RegExp('{{\\s*' + substitutionParameter + '\\s*}}', 'g'),
          data[substitutionParameter] + ''
        )
      },
      text
    )
  }
  return text
}

const getPluralPhrase = (
  textId: string,
  dictionary: TranslationDictionary,
  data?: TranslationSubstitutionData
) => {
  const pluralPhrases = dictionary[textId] as PluralPhrases
  if (typeof pluralPhrases === 'object') {
    const count = data ? data[PLURAL_COUNT_PROP] : undefined
    const locale = (dictionary[DICTIONARY_LOCALE_PROP] ??
      DEFAULT_LOCALE) as Locale
    const pluralOtherTranslation = pluralPhrases[PLURAL_DEFAULT_CATEGORY]
    if (pluralOtherTranslation && locale && count !== undefined) {
      const pluralCategory = calculatePluralCategory(
        toLowerCaseLocale(locale as SupportedLocaleName),
        count,
        pluralPhrases
      )
      // try and find a phrase for this count.
      const translation = pluralPhrases[pluralCategory]
      // fall back to ="other" if no specialization for this count
      // (being extra cautious here...the algorithm for deciding
      // catgegory knows the available phrases so this should not occur)
      return translation ? translation : pluralPhrases[PLURAL_DEFAULT_CATEGORY]
    }
  }
  return undefined
}

const calculatePluralCategory = (
  locale: Locale,
  count: number | string,
  phrases: PluralPhrases
): string => {
  const num = typeof count === 'string' ? parseInt(count, 10) : count
  if (isNaN(num)) {
    return PLURAL_DEFAULT_CATEGORY
  }
  return getPluralCategory(
    locale as SupportedLocaleName,
    num,
    new Set(Object.keys(phrases)) as Set<PluralCategory>
  )
}

/*
    The following lang get/set & listening functions support both a browser
    and node environment and allow for reactive handling of locale changes.
    In a browser the active locale is put on the HTML lang attribute as this
    provides a common source of truth for all code on the page (it is also
    an accessability requirement). In a node environment the active locale
    is stored on a global.
*/

const NODE_LANGUAGE_GLOBAL = 'DocuSignLanguage'
const NODE_DIRECTION_GLOBAL = 'DocuSignDirection'

export type LangListener = (locale?: Locale) => void
type LangListenerRemover = () => void

export type DirListener = (dir: Direction) => void
type DirListenerRemover = () => void

export const langListeners: Map<LangListener, LangListenerRemover> = new Map()
export const dirListeners: Map<DirListener, DirListenerRemover> = new Map()

export const getLang = (): string | undefined => {
  return isBrowser()
    ? document.documentElement!.lang
    : global[NODE_LANGUAGE_GLOBAL]
}

export const setLang = (lang: Locale, setDirection = false) => {
  if (isBrowser()) {
    document.documentElement.lang = lang
  } else {
    // eslint-disable-next-line no-undef
    const oldLang = global[NODE_LANGUAGE_GLOBAL]
    // eslint-disable-next-line no-undef
    global[NODE_LANGUAGE_GLOBAL] = lang
    if (oldLang !== lang) {
      langListeners.forEach((_, listener) => listener(lang))
    }
  }
  if (setDirection) {
    setDir(isRTL(lang) ? 'rtl' : 'ltr')
  }
}

export const addLangListener = (listener: LangListener) => {
  if (isBrowser()) {
    const mutationObserver = new MutationObserver(() => {
      listener(getLang())
    })
    mutationObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['lang'],
    })
    langListeners.set(listener, () => {
      mutationObserver.disconnect()
      langListeners.delete(listener)
    })
  } else {
    langListeners.set(listener, () => langListeners.delete(listener))
  }
}

export const removeLangListener = (listener: LangListener) => {
  const remover = langListeners.get(listener)
  remover && remover()
}

export const getDir = () => {
  return isBrowser()
    ? document.documentElement!.dir
    : global[NODE_DIRECTION_GLOBAL]
}

export const setDir = (dir: Direction) => {
  if (isBrowser()) {
    document.documentElement.dir = dir
  } else {
    // eslint-disable-next-line no-undef
    const oldDir = global[NODE_DIRECTION_GLOBAL]
    // eslint-disable-next-line no-undef
    global[NODE_DIRECTION_GLOBAL] = dir
    if (oldDir !== dir) {
      dirListeners.forEach((_, listener) => listener(dir))
    }
  }
}

export const addDirListener = (listener: DirListener) => {
  if (isBrowser()) {
    const mutationObserver = new MutationObserver(() => {
      listener(getDir())
    })
    mutationObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['dir'],
    })
    dirListeners.set(listener, () => {
      mutationObserver.disconnect()
      dirListeners.delete(listener)
    })
  } else {
    dirListeners.set(listener, () => dirListeners.delete(listener))
  }
}

export const removeDirListener = (listener: DirListener) => {
  const remover = dirListeners.get(listener)
  remover && remover()
}
