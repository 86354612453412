import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'
import styles from './styles'

export interface HeaderRightProps {
  /**
   * Children in the right side of the Header.
   */
  children: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function HeaderRight({ children, ...restProps }: HeaderRightProps) {
  const sx = useThemeStyles(styles)

  return (
    <div {...dataProps(restProps)} css={sx.default}>
      {children}
    </div>
  )
}

HeaderRight.propTypes = {
  children: PropTypes.node.isRequired,
}

HeaderRight.displayName = 'Header.End'
