/**
 * @param {string} prefix - The prefix to filter the keys in props (regex, ^ not necessary)
 * @param {Object} [props={}]
 * @returns {Object} An object containing all of the key:value pairs from the
 * input object where the key was prefixed by the supplied prefix
 *
 * Example:
 * filterProps('on', { onClick: any, anotherProp: any }) => { onClick: any }
 */
export const filterProps = (prefix: string, props = {}) => {
  const filter = new RegExp(`^${prefix}`)

  return Object.keys(props).reduce(
    (collectedOnProps: Record<string, unknown>, prop: string) =>
      filter.test(prop)
        ? { ...collectedOnProps, [`${prop}`]: props[prop] }
        : collectedOnProps,
    {}
  )
}

export const ariaProps = (props = {}) => filterProps('aria-.', props)
export const dataProps = (props = {}) => filterProps('data-.', props)
export const onProps = (props = {}) => filterProps('on[A-Z]', props)
