/* eslint-disable no-console */

import { ClientContext } from './ClientContext'
import { Event } from './Event'
import { Counter } from './Counter'
import { Sender, SendPayload } from './Sender'
import { TelemetryWrapper } from './TelemetryWrapper'
import { ApplicationVariables } from '../TelemetryRecorder'

export interface TelemetryConfig {
  endpointUrl: () => string
  instrumentationKey: () => string

  clientContext: () => ClientContext

  applicationVariables?: () => Partial<ApplicationVariables>

  disableTelemetry: () => boolean
  emitLineDelimitedJson: () => boolean
  maxBatchSizeBytes: () => number
  maxBatchIntervalMs: () => number
}

export class TelemetryContext {
  private appContext: ClientContext
  private getApplicationVariables?: () => Partial<ApplicationVariables>
  public sender: Sender

  constructor(config: TelemetryConfig, httpHandler: SendPayload) {
    this.appContext = config.clientContext()
    this.getApplicationVariables = config.applicationVariables
    this.sender = new Sender(config, httpHandler)
  }

  /**
   * Queue a telemetry item to be sent
   */
  public send(item: Event | Counter) {
    // validate input
    if (!item) {
      console.log('invalid telemetry item: ' + item)
      return
    }

    // ensure a sender was constructed
    if (!this.sender) {
      console.log('Sender was not initialized')
      return
    }

    const applicationVariables =
      this.getApplicationVariables && this.getApplicationVariables()
    if (applicationVariables) {
      item.applyApplicationVariables(applicationVariables)
    }
    item.applyClientContext(this.appContext)

    if (item instanceof Event) {
      const telemetry = {
        telemetryType: 'event',
        telemetryData: item,
      } as TelemetryWrapper
      this.sender.send(telemetry)
    } else if (item instanceof Counter) {
      const telemetry = {
        telemetryType: 'counter',
        telemetryData: item,
      } as TelemetryWrapper
      this.sender.send(telemetry)
    }
  }
}
