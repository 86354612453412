import { cultureNames } from './data/cultureNames'
import { languageToLocale } from './data/languageToLocale'
import { byRegion } from './data/byRegion'
import { languageSet } from './data/languageSet'

let isIntlLocale: boolean | undefined
// Invert language set
const byLanguage = {}

function isIntlLocaleSupported() {
  try {
    if (isIntlLocale !== undefined) {
      return isIntlLocale
    }
    isIntlLocale = false
    if (typeof Intl !== 'object' || typeof Intl['Locale'] !== 'function') {
      return false
    }
    const locale = new Intl.Locale('en-US')
    isIntlLocale = locale.region === 'US'
  } catch (_err) {
    return false
  }
  return isIntlLocale
}

function byLocale(language: string | undefined): string | undefined {
  const lang = language ? language.replace('-', '_').toLowerCase() : ''
  return languageToLocale[lang] ||
    cultureNames.find(function (obj) {
      return obj === lang
    })
    ? lang
    : undefined
}

function findSupportedLocale(language: string | undefined): string | undefined {
  try {
    if (Object.keys(byLanguage).length === 0) {
      Object.keys(languageSet).forEach((key) => {
        const languages = languageSet[key]
        languages.forEach((lang: string) => {
          byLanguage[lang] = key
        })
      })
    }
    const lang = language ? language.replace('_', '-') : ''
    if (!isIntlLocaleSupported()) {
      const codes = lang.split('-')
      return (
        byLocale(lang) || // check exact match from our locale policy
        byRegion[codes.length > 1 ? codes[1].toUpperCase() : ''] ||
        byLanguage[codes[0].toLowerCase()] ||
        undefined
      )
    }
    const locale = new Intl.Locale(lang)
    return (
      byLocale(lang) || // check exact match from our list
      byRegion[locale.region as string] ||
      byLanguage[locale.language] ||
      undefined
    )
  } catch (_err) {
    return undefined
  }
}

export { findSupportedLocale, isIntlLocaleSupported }
