import { useMemo, useContext } from 'react'
import { createId, generateGuid } from '@ds/base'
import { UniqueIdContext } from './UniqueIdProvider'

type IdType = 'short' | 'guid'

/**
 * @param type Determines which function is used to generate the id.
 *
 * @param prefix A string to be prefixed to the unique ID returned. Only is prepended when the
 * type argument is 'short', otherwise, it's ignored.
 *
 * @returns A string that will be consistent across renders of your React component.
 */
export const useUniqueId = (type: IdType, prefix?: string): string => {
  const getIdFromContext = useContext(UniqueIdContext)

  const id = useMemo(() => {
    if (getIdFromContext && type === 'short') {
      return getIdFromContext(prefix || '')
    }
    return getId(type, prefix)
  }, [getIdFromContext, prefix, type])

  return id
}

const getId = (type: IdType, prefix?: string) => {
  return type === 'short' ? createId(prefix) : generateGuid()
}
