export const locales = [
  'ar',
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'en-GB',
  'en-PS',
  'es-MX',
  'es',
  'et',
  'fa',
  'fi',
  'fr-CA',
  'fr',
  'he',
  'hi',
  'hr',
  'hu',
  'hy',
  'id',
  'it',
  'ja',
  'en',
  'ko',
  'lt',
  'lv',
  'ms',
  'nl',
  'no',
  'pl',
  'pt-BR',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sr',
  'sv',
  'th',
  'tr',
  'uk',
  'vi',
  'zh-CN',
  'zh-TW',
]
