import { inkTokens } from '@ds/tokens'
import type { BannerKind } from '../../../../components/Banner'

const warningTokens = {
  backgroundColor: inkTokens.light.messageBgColorWarning,
  textColor: inkTokens.light.fontColorDefault,
  iconColor: inkTokens.light.iconColorDefault,
} as const

export interface BannerTokensInk {
  backgroundColor: string
  borderColor?: string
  textColor: string
  iconColor?: string
  focusColor?: string
}

const tokens: Record<BannerKind, BannerTokensInk> = {
  danger: {
    backgroundColor: inkTokens.light.messageBgColorError,
    textColor: inkTokens.dark.fontColorDefault,
    iconColor: inkTokens.dark.iconColorDefault,
    focusColor: inkTokens.dark.focusColor,
  },
  information: {
    backgroundColor: inkTokens.light.messageBgColorEmphasis,
    borderColor: inkTokens.light.borderColorSubtleInverse,
    textColor: inkTokens.dark.fontColorDefault,
    iconColor: inkTokens.dark.iconColorDefault,
    focusColor: inkTokens.dark.focusColor,
  },
  neutral: {
    backgroundColor: inkTokens.light.messageBgColorEmphasis,
    borderColor: inkTokens.light.borderColorSubtleInverse,
    textColor: inkTokens.dark.fontColorDefault,
    focusColor: inkTokens.dark.focusColor,
  },
  success: {
    backgroundColor: inkTokens.light.messageBgColorSuccess,
    textColor: inkTokens.dark.fontColorDefault,
    iconColor: inkTokens.dark.iconColorDefault,
    focusColor: inkTokens.dark.focusColor,
  },
  promo: {
    backgroundColor: inkTokens.light.messageBgColorPromo,
    textColor: inkTokens.dark.fontColorDefault,
    focusColor: inkTokens.dark.focusColor,
  },
  warning: warningTokens,
  subtle: {
    backgroundColor: inkTokens.light.messageBgColorSubtle,
    borderColor: inkTokens.light.borderColorSubtle,
    textColor: inkTokens.light.fontColorDefault,
  },
  // use default kind value for unsupported values
  edit: warningTokens,
  view: warningTokens,
}

export default tokens
