/* eslint-disable no-param-reassign */
/*
 *  @ds/i18nlayer - date/time format data
 */
export interface TimezoneInfo {
  display: string
  IANA: string
  utcOffset: string
  abbrev: string
  abbrevDT: string
  name: string
  value: string
}

export const timeZones: TimezoneInfo[] = [
  {
    // "index": 1,
    display: '(UTC+04:30) Kabul',
    IANA: 'Asia/Kabul',
    utcOffset: '4.5',
    abbrev: 'AFT',
    abbrevDT: 'AFDT',
    name: 'Afghanistan Standard Time',
    value: 'tz_01_afghanistan',
  },
  {
    // "index": 2,
    display: '(UTC-09:00) Alaska',
    IANA: 'America/Anchorage',
    utcOffset: '-9',
    abbrev: 'AKST',
    abbrevDT: 'AKDT',
    name: 'Alaskan Standard Time',
    value: 'tz_02_alaskan',
  },
  {
    // "index": 3,
    display: '(UTC+03:00) Kuwait, Riyadh',
    IANA: 'Asia/Riyadh',
    utcOffset: '3',
    abbrev: 'ABST',
    abbrevDT: 'ABDT',
    name: 'Arab Standard Time',
    value: 'tz_03_arab',
  },
  {
    // "index": 4,
    display: '(UTC+04:00) Abu Dhabi, Muscat',
    IANA: 'Asia/Dubai',
    utcOffset: '4',
    abbrev: 'ARBST',
    abbrevDT: 'ARBDT',
    name: 'Arabian Standard Time',
    value: 'tz_04_arabian',
  },
  {
    // "index": 5,
    display: '(UTC+03:00) Baghdad',
    IANA: 'Asia/Baghdad',
    utcOffset: '3',
    abbrev: 'ARST',
    abbrevDT: 'ARDT',
    name: 'Arabic Standard Time',
    value: 'tz_05_arabic',
  },
  {
    // "index": 6,
    display: '(UTC-03:00) Buenos Aires',
    IANA: 'America/Buenos_Aires',
    utcOffset: '-3',
    abbrev: 'ART',
    abbrevDT: 'ARDT',
    name: 'Argentina Standard Time',
    value: 'tz_06_argentina',
  },
  {
    // "index": 7,
    display: '(UTC-04:00) Atlantic Time (Canada)',
    IANA: 'America/Halifax',
    utcOffset: '-4',
    abbrev: 'AST',
    abbrevDT: 'ADT',
    name: 'Atlantic Standard Time',
    value: 'tz_07_atlantic',
  },
  {
    // "index": 8,
    display: '(UTC+09:30) Darwin',
    IANA: 'Australia/Darwin',
    utcOffset: '9.5',
    abbrev: 'ACST',
    abbrevDT: 'ACDT',
    name: 'AUS Central Standard Time',
    value: 'tz_08_aus_central',
  },
  {
    // "index": 9,
    display: '(UTC+10:00) Canberra, Melbourne, Sydney',
    IANA: 'Australia/Sydney',
    utcOffset: '10',
    abbrev: 'AEST',
    abbrevDT: 'AEDT',
    name: 'AUS Eastern Standard Time',
    value: 'tz_09_aus_eastern',
  },
  {
    // "index": 10,
    display: '(UTC+04:00) Baku',
    IANA: 'Asia/Baku',
    utcOffset: '4',
    abbrev: 'AZT',
    abbrevDT: 'AZST',
    name: 'Azerbaijan Standard Time',
    value: 'tz_10_azerbaijan',
  },
  {
    // "index": 11,
    display: '(UTC-01:00) Azores',
    IANA: 'Atlantic/Azores',
    utcOffset: '-1',
    abbrev: 'AZOT',
    abbrevDT: 'AZOST',
    name: 'Azores Standard Time',
    value: 'tz_11_azores',
  },
  {
    // "index": 12,
    display: '(UTC+06:00) Dhaka',
    IANA: 'Asia/Dhaka',
    utcOffset: '6',
    abbrev: 'BAST',
    abbrevDT: 'BADT',
    name: 'Bangladesh Standard Time',
    value: 'tz_12_bangladesh',
  },
  {
    // "index": 13,
    display: '(UTC-06:00) Saskatchewan',
    IANA: 'America/Regina',
    utcOffset: '-6',
    abbrev: 'CST',
    abbrevDT: 'CDT',
    name: 'Canada Central Standard Time',
    value: 'tz_13_canada_central',
  },
  {
    // "index": 14,
    display: '(UTC-01:00) Cape Verde Is.',
    IANA: 'Atlantic/Cape_Verde',
    utcOffset: '-1',
    abbrev: 'CVT',
    abbrevDT: 'CVDT',
    name: 'Cape Verde Standard Time',
    value: 'tz_14_cape_verde',
  },
  {
    // "index": 15,
    display: '(UTC+04:00) Yerevan',
    IANA: 'Asia/Yerevan',
    utcOffset: '4',
    abbrev: 'CAUST',
    abbrevDT: 'CAUDT',
    name: 'Caucasus Standard Time',
    value: 'tz_15_caucasus',
  },
  {
    // "index": 16,
    display: '(UTC+09:30) Adelaide',
    IANA: 'Australia/Adelaide',
    utcOffset: '9.5',
    abbrev: 'ACST',
    abbrevDT: 'ACDT',
    name: 'Cen. Australia Standard Time',
    value: 'tz_16_central_australia',
  },
  {
    // "index": 17,
    display: '(UTC-06:00) Central America',
    IANA: 'America/Guatemala',
    utcOffset: '-6',
    abbrev: 'CST',
    abbrevDT: 'CDT',
    name: 'Central America Standard Time',
    value: 'tz_17_central_america',
  },
  {
    // "index": 18,
    display: '(UTC+06:00) Astana',
    IANA: 'Asia/Almaty',
    utcOffset: '6',
    abbrev: 'BTT',
    abbrevDT: 'BTTDT',
    name: 'Central Asia Standard Time',
    value: 'tz_18_central_asia',
  },
  {
    // "index": 19,
    display: '(UTC-04:00) Cuiaba',
    IANA: 'America/Cuiaba',
    utcOffset: '-4',
    abbrev: 'AMT',
    abbrevDT: 'AMST',
    name: 'Central Brazilian Standard Time',
    value: 'tz_19_central_brazilian',
  },
  {
    // "index": 20,
    display: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    IANA: 'Europe/Budapest',
    utcOffset: '1',
    abbrev: 'CET',
    abbrevDT: 'CEST',
    name: 'Central Europe Standard Time',
    value: 'tz_20_central_europe',
  },
  {
    // "index": 21,
    display: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    IANA: 'Europe/Warsaw',
    utcOffset: '1',
    abbrev: 'CET',
    abbrevDT: 'CEST',
    name: 'Central European Standard Time',
    value: 'tz_21_central_european',
  },
  {
    // "index": 22,
    display: '(UTC+11:00) Solomon Is., New Caledonia',
    IANA: 'Pacific/Guadalcanal',
    utcOffset: '11',
    abbrev: 'SBT',
    abbrevDT: 'SBDT',
    name: 'Central Pacific Standard Time',
    value: 'tz_22_central_pacific',
  },
  {
    // "index": 23,
    display: '(UTC-06:00) Central Time (US & Canada)',
    IANA: 'America/Chicago',
    utcOffset: '-6',
    abbrev: 'CST',
    abbrevDT: 'CDT',
    name: 'Central Standard Time',
    value: 'tz_23_central',
  },
  {
    // "index": 24,
    display: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    IANA: 'America/Mexico_City',
    utcOffset: '-6',
    abbrev: 'CST',
    abbrevDT: 'CDT',
    name: 'Central Standard Time (Mexico)',
    value: 'tz_24_central_mexico',
  },
  {
    // "index": 25,
    display: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    IANA: 'Asia/Shanghai',
    utcOffset: '8',
    abbrev: 'CST',
    abbrevDT: 'CDT',
    name: 'China Standard Time',
    value: 'tz_25_china',
  },
  {
    // "index": 26,
    display: '(UTC-12:00) International Date Line West',
    IANA: 'Etc/GMT+12',
    utcOffset: '-12',
    abbrev: 'BIT',
    abbrevDT: 'BITDT',
    name: 'Dateline Standard Time',
    value: 'tz_26_dateline',
  },
  {
    // "index": 27,
    display: '(UTC+03:00) Nairobi',
    IANA: 'Africa/Nairobi',
    utcOffset: '3',
    abbrev: 'EAT',
    abbrevDT: 'EADT',
    name: 'E. Africa Standard Time',
    value: 'tz_27_east_africa',
  },
  {
    // "index": 28,
    display: '(UTC+10:00) Brisbane',
    IANA: 'Australia/Brisbane',
    utcOffset: '10',
    abbrev: 'AEST',
    abbrevDT: 'AEDT',
    name: 'E. Australia Standard Time',
    value: 'tz_28_east_australia',
  },
  {
    // "index": 29,
    display: '(UTC+03:00) Minsk',
    IANA: 'Europe/Minsk',
    utcOffset: '3',
    abbrev: 'FET',
    abbrevDT: 'FEDT',
    name: 'Belarus Standard Time',
    value: 'tz_29_east_europe',
  },
  {
    // "index": 30,
    display: '(UTC-03:00) Brasilia',
    IANA: 'America/Sao_Paulo',
    utcOffset: '-3',
    abbrev: 'BRT',
    abbrevDT: 'BRST',
    name: 'E. South America Standard Time',
    value: 'tz_30_east_south_america',
  },
  {
    // "index": 31,
    display: '(UTC-05:00) Eastern Time (US & Canada)',
    IANA: 'America/New_York',
    utcOffset: '-5',
    abbrev: 'EST',
    abbrevDT: 'EDT',
    name: 'Eastern Standard Time',
    value: 'tz_31_eastern',
  },
  {
    // "index": 32,
    display: '(UTC+02:00) Cairo',
    IANA: 'Africa/Cairo',
    utcOffset: '2',
    abbrev: 'EET',
    abbrevDT: 'EEDT',
    name: 'Egypt Standard Time',
    value: 'tz_32_egypt',
  },
  {
    // "index": 33,
    display: '(UTC+05:00) Ekaterinburg',
    IANA: 'Asia/Yekaterinburg',
    utcOffset: '5',
    abbrev: 'YEKT',
    abbrevDT: 'YEKT',
    name: 'Ekaterinburg Standard Time',
    value: 'tz_33_ekaterinburg',
  },
  {
    // "index": 34,
    display: '(UTC+12:00) Fiji',
    IANA: 'Pacific/Fiji',
    utcOffset: '12',
    abbrev: 'FJT',
    abbrevDT: 'FJDT',
    name: 'Fiji Standard Time',
    value: 'tz_34_fiji',
  },
  {
    // "index": 35,
    display: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    IANA: 'Europe/Kiev',
    utcOffset: '2',
    abbrev: 'EET',
    abbrevDT: 'EEDT',
    name: 'FLE Standard Time',
    value: 'tz_35_fli',
  },
  {
    // "index": 36,
    display: '(UTC+04:00) Tbilisi',
    IANA: 'Asia/Tbilisi',
    utcOffset: '4',
    abbrev: 'GET',
    abbrevDT: 'GEDT',
    name: 'Georgian Standard Time',
    value: 'tz_36_georgian',
  },
  {
    // "index": 37,
    display: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    IANA: 'Europe/London',
    utcOffset: '0',
    abbrev: 'GMT',
    abbrevDT: 'BST',
    name: 'GMT Standard Time',
    value: 'tz_37_gmt',
  },
  {
    // "index": 38,
    display: '(UTC-03:00) Greenland',
    IANA: 'America/Godthab',
    utcOffset: '-3',
    abbrev: 'GNST',
    abbrevDT: 'GNDT',
    name: 'Greenland Standard Time',
    value: 'tz_38_greenland',
  },
  {
    // "index": 39,
    display: '(UTC+00:00) Monrovia, Reykjavik',
    IANA: 'Atlantic/Reykjavik',
    utcOffset: '0',
    abbrev: 'GMT',
    abbrevDT: 'GDT',
    name: 'Greenwich Standard Time',
    value: 'tz_39_greenwich',
  },
  {
    // "index": 40,
    display: '(UTC+02:00) Athens, Bucharest',
    IANA: 'Europe/Bucharest',
    utcOffset: '2',
    abbrev: 'EET',
    abbrevDT: 'EEDT',
    name: 'GTB Standard Time',
    value: 'tz_40_gtb',
  },
  {
    // "index": 41,
    display: '(UTC-10:00) Hawaii',
    IANA: 'Pacific/Honolulu',
    utcOffset: '-10',
    abbrev: 'HAST',
    abbrevDT: 'HADT',
    name: 'Hawaiian Standard Time',
    value: 'tz_41_hawaiian',
  },
  {
    // "index": 42,
    display: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    IANA: 'Asia/Calcutta',
    utcOffset: '5.5',
    abbrev: 'IST',
    abbrevDT: 'IDT',
    name: 'India Standard Time',
    value: 'tz_42_india',
  },
  {
    // "index": 43,
    display: '(UTC+03:30) Tehran',
    IANA: 'Asia/Tehran',
    utcOffset: '3.5',
    abbrev: 'IRST',
    abbrevDT: 'IRDT',
    name: 'Iran Standard Time',
    value: 'tz_43_iran',
  },
  {
    // "index": 44,
    display: '(UTC+02:00) Jerusalem',
    IANA: 'Asia/Jerusalem',
    utcOffset: '2',
    abbrev: 'ISST',
    abbrevDT: 'ISDT',
    name: 'Israel Standard Time',
    value: 'tz_44_israel',
  },
  {
    // "index": 45,
    display: '(UTC+03:00) Amman',
    IANA: 'Asia/Amman',
    utcOffset: '3',
    abbrev: 'AST',
    abbrevDT: 'AST',
    name: 'Jordan Standard Time',
    value: 'tz_45_jordan',
  },
  {
    // "index": 46,
    display: '(UTC+02:00) Kaliningrad',
    IANA: 'Europe/Kaliningrad',
    utcOffset: '2',
    abbrev: 'EET',
    abbrevDT: 'EEDT',
    name: 'Kaliningrad Standard Time',
    value: 'tz_46_kaliningrad',
  },
  {
    // "index": 47,
    display: '(UTC+12:00) Petropavlovsk-Kamchatsky',
    IANA: 'Asia/Kamchatka',
    utcOffset: '12',
    abbrev: 'PETT',
    abbrevDT: 'PETDT',
    name: 'Kamchatka Standard Time',
    value: 'tz_47_kamchatka',
  },
  {
    // "index": 48,
    display: '(UTC+09:00) Seoul',
    IANA: 'Asia/Seoul',
    utcOffset: '9',
    abbrev: 'KST',
    abbrevDT: 'KDT',
    name: 'Korea Standard Time',
    value: 'tz_48_korea',
  },
  {
    // "index": 49,
    display: '(UTC+11:00) Magadan',
    IANA: 'Asia/Magadan',
    utcOffset: '11',
    abbrev: 'MAGT',
    abbrevDT: 'MAGDT',
    name: 'Magadan Standard Time',
    value: 'tz_49_magadan',
  },
  {
    // "index": 50,
    display: '(UTC+04:00) Port Louis',
    IANA: 'Indian/Mauritius',
    utcOffset: '4',
    abbrev: 'MUT',
    abbrevDT: 'MUDT',
    name: 'Mauritius Standard Time',
    value: 'tz_50_mauritius',
  },
  {
    // "index": 51,
    display: '(UTC-02:00) Mid-Atlantic',
    IANA: 'Etc/GMT+2',
    utcOffset: '-2',
    abbrev: 'MAST',
    abbrevDT: 'MADT',
    name: 'Mid-Atlantic Standard Time',
    value: 'tz_51_mid_atlantic',
  },
  {
    // "index": 52,
    display: '(UTC+02:00) Beirut',
    IANA: 'Asia/Beirut',
    utcOffset: '2',
    abbrev: 'EET',
    abbrevDT: 'EEDT',
    name: 'Middle East Standard Time',
    value: 'tz_52_middle_east',
  },
  {
    // "index": 53,
    display: '(UTC-03:00) Montevideo',
    IANA: 'America/Montevideo',
    utcOffset: '-3',
    abbrev: 'UYT',
    abbrevDT: 'UYDT',
    name: 'Montevideo Standard Time',
    value: 'tz_53_montevideo',
  },
  {
    // "index": 54,
    display: '(UTC+01:00) Casablanca',
    IANA: 'Africa/Casablanca',
    utcOffset: '0',
    abbrev: 'WET',
    abbrevDT: 'WEST',
    name: 'Morocco Standard Time',
    value: 'tz_54_morocco',
  },
  {
    // "index": 55,
    display: '(UTC-07:00) Mountain Time (US & Canada)',
    IANA: 'America/Denver',
    utcOffset: '-7',
    abbrev: 'MST',
    abbrevDT: 'MDT',
    name: 'Mountain Standard Time',
    value: 'tz_55_mountain',
  },
  {
    // "index": 56,
    display: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    IANA: 'America/Chihuahua',
    utcOffset: '-7',
    abbrev: 'MST',
    abbrevDT: 'MDT',
    name: 'Mountain Standard Time (Mexico)',
    value: 'tz_56_mountain_mexico',
  },
  {
    // "index": 57,
    display: '(UTC+06:30) Yangon (Rangoon)',
    IANA: 'Asia/Rangoon',
    utcOffset: '6.5',
    abbrev: 'MMT',
    abbrevDT: 'MMDT',
    name: 'Myanmar Standard Time',
    value: 'tz_57_myanmar',
  },
  {
    // "index": 58,
    display: '(UTC+07:00) Novosibirsk',
    IANA: 'Asia/Novosibirsk',
    utcOffset: '7',
    abbrev: 'NCAST',
    abbrevDT: 'NCADT',
    name: 'N. Central Asia Standard Time',
    value: 'tz_58_north_central_asia',
  },
  {
    // "index": 59,
    display: '(UTC+02:00) Windhoek',
    IANA: 'Africa/Windhoek',
    utcOffset: '2',
    abbrev: 'NMST',
    abbrevDT: 'NMDT',
    name: 'Namibia Standard Time',
    value: 'tz_59_namibia',
  },
  {
    // "index": 60,
    display: '(UTC+05:45) Kathmandu',
    IANA: 'Asia/Katmandu',
    utcOffset: '5.75',
    abbrev: 'NPT',
    abbrevDT: 'NPDT',
    name: 'Nepal Standard Time',
    value: 'tz_60_nepal',
  },
  {
    // "index": 61,
    display: '(UTC+12:00) Auckland, Wellington',
    IANA: 'Pacific/Auckland',
    utcOffset: '12',
    abbrev: 'NZST',
    abbrevDT: 'NZDT',
    name: 'New Zealand Standard Time',
    value: 'tz_61_new_zealand',
  },
  {
    // "index": 62,
    display: '(UTC-03:30) Newfoundland',
    IANA: 'America/St_Johns',
    utcOffset: '-3.5',
    abbrev: 'NST',
    abbrevDT: 'NDT',
    name: 'Newfoundland Standard Time',
    value: 'tz_62_new_foundland',
  },
  {
    // "index": 63,
    display: '(UTC+08:00) Irkutsk',
    IANA: 'Asia/Irkutsk',
    utcOffset: '8',
    abbrev: 'IRKT',
    abbrevDT: 'IRKDT',
    name: 'North Asia East Standard Time',
    value: 'tz_63_north_asia_east',
  },
  {
    // "index": 64,
    display: '(UTC+07:00) Krasnoyarsk',
    IANA: 'Asia/Krasnoyarsk',
    utcOffset: '7',
    abbrev: 'KRAT',
    abbrevDT: 'KRADT',
    name: 'North Asia Standard Time',
    value: 'tz_64_north_asia',
  },
  {
    // "index": 65,
    display: '(UTC-04:00) Santiago',
    IANA: 'America/Santiago',
    utcOffset: '-4',
    abbrev: 'PSAST',
    abbrevDT: 'PSADT',
    name: 'Pacific SA Standard Time',
    value: 'tz_65_pacific_sa',
  },
  {
    // "index": 66,
    display: '(UTC-08:00) Pacific Time (US & Canada)',
    IANA: 'America/Los_Angeles',
    utcOffset: '-8',
    abbrev: 'PST',
    abbrevDT: 'PDT',
    name: 'Pacific Standard Time',
    value: 'tz_66_pacific',
  },
  {
    // "index": 67,
    display: '(UTC-08:00) Baja California',
    IANA: 'America/Santa_Isabel',
    utcOffset: '-8',
    abbrev: 'PST',
    abbrevDT: 'PDT',
    name: 'Pacific Standard Time (Mexico)',
    value: 'tz_67_pacific_mexico',
  },
  {
    // "index": 68,
    display: '(UTC+05:00) Islamabad, Karachi',
    IANA: 'Asia/Karachi',
    utcOffset: '5',
    abbrev: 'PKT',
    abbrevDT: 'PKDT',
    name: 'Pakistan Standard Time',
    value: 'tz_68_pakistan',
  },
  {
    // "index": 69,
    display: '(UTC-04:00) Asuncion',
    IANA: 'America/Asuncion',
    utcOffset: '-4',
    abbrev: 'PYT',
    abbrevDT: 'PYST',
    name: 'Paraguay Standard Time',
    value: 'tz_69_paraguay',
  },
  {
    // "index": 70,
    display: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    IANA: 'Europe/Paris',
    utcOffset: '1',
    abbrev: 'CET',
    abbrevDT: 'CEST',
    name: 'Romance Standard Time',
    value: 'tz_70_romance',
  },
  {
    // "index": 71,
    display: '(UTC+03:00) Moscow, St. Petersburg',
    IANA: 'Europe/Moscow',
    utcOffset: '3',
    abbrev: 'MSK',
    abbrevDT: 'MSKDT',
    name: 'Russian Standard Time',
    value: 'tz_71_russian',
  },
  {
    // "index": 72,
    display: '(UTC-03:00) Cayenne, Fortaleza',
    IANA: 'America/Cayenne',
    utcOffset: '-3',
    abbrev: 'SAEST',
    abbrevDT: 'SAEDT',
    name: 'SA Eastern Standard Time',
    value: 'tz_72_sa_eastern',
  },
  {
    // "index": 73,
    display: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    IANA: 'America/Bogota',
    utcOffset: '-5',
    abbrev: 'SAPST',
    abbrevDT: 'SAPDT',
    name: 'SA Pacific Standard Time',
    value: 'tz_73_sa_pacific',
  },
  {
    // "index": 74,
    display: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    IANA: 'America/La_Paz',
    utcOffset: '-4',
    abbrev: 'SAWST',
    abbrevDT: 'SAWDT',
    name: 'SA Western Standard Time',
    value: 'tz_74_sa_western',
  },
  {
    // "index": 75,
    display: '(UTC+13:00) Samoa',
    IANA: 'Pacific/Apia',
    utcOffset: '13',
    abbrev: 'WST',
    abbrevDT: 'WST',
    name: 'Samoa Standard Time',
    value: 'tz_75_samoa',
  },
  {
    // "index": 76,
    display: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    IANA: 'Asia/Bangkok',
    utcOffset: '7',
    abbrev: 'SEAST',
    abbrevDT: 'SEADT',
    name: 'SE Asia Standard Time',
    value: 'tz_76_se_asia',
  },
  {
    // "index": 77,
    display: '(UTC+08:00) Kuala Lumpur, Singapore',
    IANA: 'Asia/Singapore',
    utcOffset: '8',
    abbrev: 'SGT',
    abbrevDT: 'SGDT',
    name: 'Singapore Standard Time',
    value: 'tz_77_singapore',
  },
  {
    // "index": 78,
    display: '(UTC+02:00) Harare, Pretoria',
    IANA: 'Africa/Johannesburg',
    utcOffset: '2',
    abbrev: 'SAST',
    abbrevDT: 'SADT',
    name: 'South Africa Standard Time',
    value: 'tz_78_south_africa',
  },
  {
    // "index": 79,
    display: '(UTC+05:30) Sri Jayawardenepura',
    IANA: 'Asia/Colombo',
    utcOffset: '5.5',
    abbrev: 'SLT',
    abbrevDT: 'SLTDT',
    name: 'Sri Lanka Standard Time',
    value: 'tz_79_sriLanka',
  },
  {
    // "index": 80,
    display: '(UTC+02:00) Damascus',
    IANA: 'Asia/Damascus',
    utcOffset: '2',
    abbrev: 'EET',
    abbrevDT: 'EEDT',
    name: 'Syria Standard Time',
    value: 'tz_80_syria',
  },
  {
    // "index": 81,
    display: '(UTC+08:00) Taipei',
    IANA: 'Asia/Taipei',
    utcOffset: '8',
    abbrev: 'CST',
    abbrevDT: 'CST',
    name: 'Taipei Standard Time',
    value: 'tz_81_taipei',
  },
  {
    // "index": 82,
    display: '(UTC+10:00) Hobart',
    IANA: 'Australia/Hobart',
    utcOffset: '10',
    abbrev: 'AEST',
    abbrevDT: 'AEDT',
    name: 'Tasmania Standard Time',
    value: 'tz_82_tasmania',
  },
  {
    // "index": 83,
    display: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    IANA: 'Asia/Tokyo',
    utcOffset: '9',
    abbrev: 'JST',
    abbrevDT: 'JDT',
    name: 'Tokyo Standard Time',
    value: 'tz_83_tokyo',
  },
  {
    // "index": 84,
    display: "(UTC+13:00) Nuku'alofa",
    IANA: 'Pacific/Tongatapu',
    utcOffset: '13',
    abbrev: 'TOT',
    abbrevDT: 'TODT',
    name: 'Tonga Standard Time',
    value: 'tz_84_tonga',
  },
  {
    // "index": 85,
    display: '(UTC+03:00) Istanbul',
    IANA: 'Europe/Istanbul',
    utcOffset: '3',
    abbrev: 'EET',
    abbrevDT: 'EEDT',
    name: 'Turkey Standard Time',
    value: 'tz_85_turkey',
  },
  {
    // "index": 86,
    display: '(UTC+08:00) Ulaanbaatar',
    IANA: 'Asia/Ulaanbaatar',
    utcOffset: '8',
    abbrev: 'ULAT',
    abbrevDT: 'ULADT',
    name: 'Ulaanbaatar Standard Time',
    value: 'tz_86_ulaanbaatar',
  },
  {
    // "index": 87,
    display: '(UTC-05:00) Indiana (East)',
    IANA: 'America/Indianapolis',
    utcOffset: '-5',
    abbrev: 'EST',
    abbrevDT: 'EDT',
    name: 'US Eastern Standard Time',
    value: 'tz_87_us_eastern',
  },
  {
    // "index": 88,
    display: '(UTC-07:00) Arizona',
    IANA: 'America/Phoenix',
    utcOffset: '-7',
    abbrev: 'MST',
    abbrevDT: 'MDT',
    name: 'US Mountain Standard Time',
    value: 'tz_88_us_mountain',
  },
  {
    // "index": 89,
    display: '(UTC-04:00) Caracas',
    IANA: 'America/Caracas',
    utcOffset: '-4',
    abbrev: 'VET',
    abbrevDT: 'VET',
    name: 'Venezuela Standard Time',
    value: 'tz_89_venezuela',
  },
  {
    // "index": 90,
    display: '(UTC+10:00) Vladivostok',
    IANA: 'Asia/Vladivostok',
    utcOffset: '10',
    abbrev: 'VLAT',
    abbrevDT: 'VLADT',
    name: 'Vladivostok Standard Time',
    value: 'tz_90_vladivostok',
  },
  {
    // "index": 91,
    display: '(UTC+08:00) Perth',
    IANA: 'Australia/Perth',
    utcOffset: '8',
    abbrev: 'AWST',
    abbrevDT: 'AWDT',
    name: 'W. Australia Standard Time',
    value: 'tz_91_west_australia',
  },
  {
    // "index": 92,
    display: '(UTC+01:00) West Central Africa',
    IANA: 'Africa/Lagos',
    utcOffset: '1',
    abbrev: 'WAT',
    abbrevDT: 'WADT',
    name: 'W. Central Africa Standard Time',
    value: 'tz_92_west_central_africa',
  },
  {
    // "index": 93,
    display: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    IANA: 'Europe/Berlin',
    utcOffset: '1',
    abbrev: 'CET',
    abbrevDT: 'CEST',
    name: 'W. Europe Standard Time',
    value: 'tz_93_west_europe',
  },
  {
    // "index": 94,
    display: '(UTC+05:00) Ashgabat, Tashkent',
    IANA: 'Asia/Tashkent',
    utcOffset: '5',
    abbrev: 'WAST',
    abbrevDT: 'WADT',
    name: 'West Asia Standard Time',
    value: 'tz_94_west_asia',
  },
  {
    // "index": 95,
    display: '(UTC+10:00) Guam, Port Moresby',
    IANA: 'Pacific/Port_Moresby',
    utcOffset: '10',
    abbrev: 'WPST',
    abbrevDT: 'WPDT',
    name: 'West Pacific Standard Time',
    value: 'tz_95_west_pacific',
  },
  {
    // "index": 96,
    display: '(UTC+09:00) Yakutsk',
    IANA: 'Asia/Yakutsk',
    utcOffset: '9',
    abbrev: 'YAKT',
    abbrevDT: 'YAKDT',
    name: 'Yakutsk Standard Time',
    value: 'tz_96_yakutsk',
  },
]

export const timeFormats = {
  none: '',
  hhmm: 'HH:mm',
  hmm: 'h:mm',
  hhmmss: 'HH:mm:ss',
  hmmss: 'h:mm:ss',
  hhmmtt: 'HH:mm tt',
  hmmtt: 'h:mm tt',
  hhmmsstt: 'HH:mm:ss tt',
  hmmsstt: 'h:mm:ss tt',
  custom: 'H:mm',
  _hhmmsstt: 'hh:mm:ss tt',
}

export const dateFormats = {
  default: {
    _: 'M/d/yyyy',
    de: 'dd.MM.yyyy',
    es: 'dd/MM/yyyy',
    ru: 'dd.MM.yyyy',
    fr: 'dd/MM/yyyy',
    it: 'dd/MM/yyyy',
    nl: 'dd/MM/yyyy',
    pl: 'dd.MM.yyyy',
    pt_br: 'dd/MM/yyyy',
    pt: 'dd/MM/yyyy',
    zh_cn: 'yyyy/M/d',
    zh_tw: 'yyyy/M/d',
    ja: 'yyyy/M/d',
    ko: 'yyyy/M/d',
    ar: 'dd/MM/yy',
    bg: 'd.M.yyyy',
    cs: 'd. M. yyyy',
    hr: 'd.M.yyyy.',
    da: 'dd-MM-yyyy',
    en_gb: 'dd/MM/yyyy',
    et: 'd.MM.yyyy',
    fa: 'dd/MM/yyyy',
    fi: 'd.M.yyyy',
    fr_ca: 'yyyy-MM-dd',
    el: 'd/M/yyyy',
    he: 'dd/MM/yyyy',
    hi: 'dd-MM-yyyy',
    hu: 'yyyy.MM.dd.',
    hy: 'dd.MM.yyyy',
    id: 'dd/MM/yyyy',
    lv: 'dd.MM.yyyy.',
    lt: 'yyyy-MM-dd',
    ms: 'dd/MM/yyyy',
    no: 'dd.MM.yyyy',
    ro: 'dd.MM.yyyy',
    sr: 'd.M.yyyy.',
    sk: 'd.M.yyyy',
    sl: 'd.M.yyyy',
    es_mx: 'dd/MM/yyyy',
    sv: 'yyyy-MM-dd',
    th: 'd/M/yyyy',
    tr: 'd.M.yyyy',
    uk: 'dd.MM.yyyy',
    vi: 'dd/MM/yyyy',
  },
  mm_dd_yyyy: {
    _: 'MM-dd-yyyy',
  },
  mmddyyyy: {
    _: 'MM/dd/yyyy',
  },
  mmddyy: {
    _: 'MM/dd/yy',
  },
  mdyyyy: {
    _: 'M/d/yyyy',
    de: 'dd.MM.yyyy',
    es: 'dd/MM/yyyy',
    ru: 'dd.MM.yyyy',
    fr: 'dd/MM/yyyy',
    it: 'dd/MM/yyyy',
    nl: 'dd/MM/yyyy',
    pl: 'dd.MM.yyyy',
    pt_br: 'dd/MM/yyyy',
    pt: 'dd/MM/yyyy',
    zh_cn: 'yyyy/M/d',
    zh_tw: 'yyyy/M/d',
    ja: 'yyyy/M/d',
    ko: 'yyyy/M/d',
  },
  mmm_dd_yyyy: {
    _: 'MMM-dd-yyyy',
    zh_cn: 'M-dd-yyyy',
    zh_tw: 'M-dd-yyyy',
    ja: 'M-dd-yyyy',
    ko: 'M-dd-yyyy',
  },
  mmmd_yyyy: {
    _: 'MMM d, yyyy',
    zh_cn: 'M d, yyyy',
    zh_tw: 'M d, yyyy',
    ja: 'M d, yyyy',
    ko: 'M d, yyyy',
  },
  mmmmd_yyyy: {
    _: 'MMMM d, yyyy',
    zh_cn: 'yyyy年M月d日',
    zh_tw: 'yyyy年M月d日',
    ja: 'yyyy年M月d日',
    ko: 'yyyy년M월d일',
  },
  dmyyyy: {
    _: 'd/M/yyyy',
  },
  d_m_yyyy: {
    _: 'd-M-yyyy',
  },
  dd_mm_yy: {
    _: 'dd-MM-yy',
  },
  dd_mm_yyyy: {
    _: 'dd-MM-yyyy',
  },
  dd_mmm_yy: {
    _: 'dd-MMM-yy',
    zh_cn: 'dd-M-yy',
    zh_tw: 'dd-M-yy',
    ja: 'dd-M-yy',
    ko: 'dd-M-yy',
  },
  dd_mmm_yyyy: {
    _: 'dd-MMM-yyyy',
    zh_cn: 'dd-M-yyyy',
    zh_tw: 'dd-M-yyyy',
    ja: 'dd-M-yyyy',
    ko: 'dd-M-yyyy',
  },
  ddmmmmyyyy: {
    _: 'dd MMMM yyyy',
    zh_cn: 'yyyy年M月d日',
    zh_tw: 'yyyy年M月d日',
    ja: 'yyyy年M月d日',
    ko: 'yyyy년M월d일',
    de: 'dd. MMMM yyyy',
    es: 'dd \\d\\e MMMM \\d\\e yyyy',
    fr: 'd MMMM yyyy',
    it: 'd MMMM yyyy',
    nl: 'd MMMM yyyy',
    pt_br: 'dd \\d\\e MMMM \\d\\e yyyy',
    pt: 'dd \\d\\e MMMM \\d\\e yyyy',
    ru: 'dd MMMM yyyy г.',
  },
  yyyy_mm_dd: {
    _: 'yyyy-MM-dd',
  },
  yyyy_mmm_dd: {
    _: 'yyyy-MMM-dd',
    zh_cn: 'yyyy-M-dd',
    zh_tw: 'yyyy-M-dd',
    ja: 'yyyy-M-dd',
    ko: 'yyyy-M-dd',
  },
  ddmmyyyy_de: {
    _: 'dd.MM.yyyy',
  },
  ddmmyyyy: {
    _: 'dd/MM/yyyy',
  },
  yyyymmdd: {
    _: 'yyyy/MM/dd',
  },
  yyyymd: {
    _: 'yyyy/M/d',
  },
  yyyy_mmmm_d: {
    // not in v1.4
    _: 'yyyy MMMM d',
    zh_cn: 'yyyy年M月d日',
    zh_tw: 'yyyy年M月d日',
    ja: 'yyyy年M月d日',
    ko: 'yyyy년M월d일',
  },
  longformat: {
    _: 'MMMM d, yyyy',
    en: 'MMMM d, yyyy',
    de: 'dd. MMMM yyyy',
    es: 'dd \\d\\e MMMM \\d\\e yyyy',
    fr: 'd MMMM yyyy',
    it: 'd MMMM yyyy',
    nl: 'd MMMM yyyy',
    pt_br: 'dd \\d\\e MMMM \\d\\e yyyy',
    pt: 'dd \\d\\e MMMM \\d\\e yyyy',
    ru: 'dd MMMM yyyy г.',
    zh_cn: 'yyyy年M月d日',
    zh_tw: 'yyyy年M月d日',
    ja: 'yyyy年M月d日',
    ko: 'yyyy년M월d일',
  },
  custom: {
    _: 'custom',
  },
}

export interface DateParseRegX {
  m: number[]
  d: number[]
  y: number[]
  regx: string
}

export interface DateParsePatterns {
  [key: string]: DateParseRegX
}

export const dateParsePattern: DateParsePatterns = {
  dmy: {
    m: [5, 11, 17],
    d: [3, 9, 15],
    y: [20],
    regx: '^((((0?[1-9])|[12]\\d|3[01])[ ]*[^0-9][ ]*((0?(1|3|5|7|8))|10|12))|(((0?[1-9])|[12]\\d|30)[ ]*[^0-9][ ]*((0?(4|6|9))|11))|(((0?[1-9])|[12]\\d)[ ]*[^0-9][ ]*(0?2)))(([ ]*[^0-9][ ]*((19|20|)\\d\\d)[ ]*[^0-9]*)|)$',
  },
  mdy: {
    m: [3, 9, 15],
    d: [6, 12, 16],
    y: [20],
    regx: '^((((0?(1|3|5|7|8))|10|12)[ ]*[^0-9][ ]*((0?[1-9])|[12]\\d|3[01]))|(((0?(4|6|9))|11)[ ]*[^0-9][ ]*((0?[1-9])|[12]\\d|30))|((0?2)[ ]*[^0-9][ ]*((0?[1-9])|[12]\\d)))(([ ]*[^0-9][ ]*((19|20|)\\d\\d)[ ]*[^0-9]*)|)$',
  },
  ymd: {
    m: [5, 11, 17],
    d: [8, 14, 18],
    y: [1],
    regx: '^((19|20|)\\d\\d)[ ]*[^0-9][ ]*((((0?(1|3|5|7|8))|10|12)[ ]*[^0-9][ ]*((0?[1-9])|[12]\\d|3[01]))|(((0?(4|6|9))|11)[ ]*[^0-9][ ]*((0?[1-9])|[12]\\d|30))|((0?2)[ ]*[^0-9][ ]*((0?[1-9])|[12]\\d)))[ ]*[^0-9]*$',
  },
}

export const JapaneseEraInfo = [
  {
    yyyy: 'R',
    jaYyyy: '令和',
    year: 2019,
    startDate: '20190501',
  },
  {
    yyyy: 'H',
    jaYyyy: '平成',
    year: 1989,
    startDate: '19890108',
  },
  {
    yyyy: 'S',
    jaYyyy: '昭和',
    year: 1926,
    startDate: '19261226',
  },
]

export const dateFormatAllLocalesInfo = {
  '_.ampm': ['', ''],
  'ar.month': [
    'محرم',
    'صفر',
    'ربيع الأول',
    'ربيع الثاني',
    'جمادى الأولى',
    'جمادى الثانية',
    'رجب',
    'شعبان',
    'رمضان',
    'شوال',
    'ذو القعدة',
    'ذو الحجة',
  ],
  'ar.mshort': [
    'محرم',
    'صفر',
    'ربيع الأول',
    'ربيع الثاني',
    'جمادى الأولى',
    'جمادى الثانية',
    'رجب',
    'شعبان',
    'رمضان',
    'شوال',
    'ذو القعدة',
    'ذو الحجة',
  ],
  'ar.ampm': ['ص', 'م'],
  'ar.ampmshort': ['ص', 'م'],
  'ar.days': [
    'الأحد',
    'الإثنين',
    'الثلاثاء',
    'الأربعاء',
    'الخميس',
    'الجمعة',
    'السبت',
  ],
  'ar.dayshort': ['ح', 'ن', 'ث', 'ر', 'خ', 'ج', 'س'],
  'ar.firstday': 0,

  'bg_bg.month': [
    'януари',
    'февруари',
    'март',
    'април',
    'май',
    'юни',
    'юли',
    'август',
    'септември',
    'октомври',
    'ноември',
    'декември',
  ],
  'bg_bg.mshort': [
    'яну',
    'фев',
    'мар',
    'апр',
    'май',
    'юни',
    'юли',
    'авг',
    'сеп',
    'окт',
    'ное',
    'дек',
  ],
  'bg_bg.ampm': ['', ''],
  'bg_bg.ampmshort': ['', ''],
  'bg_bg.days': [
    'неделя',
    'понеделник',
    'вторник',
    'сряда',
    'четвъртък',
    'петък',
    'събота',
  ],
  'bg_bg.dayshort': ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  'bg_bg.firstday': 1,

  'cs_cz.month': [
    'leden',
    'únor',
    'březen',
    'duben',
    'květen',
    'červen',
    'červenec',
    'srpen',
    'září',
    'říjen',
    'listopad',
    'prosinec',
  ],
  'cs_cz.month_genitive': [
    'ledna',
    'února',
    'března',
    'dubna',
    'května',
    'června',
    'července',
    'srpna',
    'září',
    'října',
    'listopadu',
    'prosince',
  ],
  'cs_cz.mshort': [
    'led',
    'úno',
    'bře',
    'dub',
    'kvě',
    'čvn',
    'čvc',
    'srp',
    'zář',
    'říj',
    'lis',
    'pro',
  ],
  'cs_cz.ampm': ['dop.', 'odp.'],
  'cs_cz.ampmshort': ['d', 'o'],
  'cs_cz.days': [
    'neděle',
    'pondělí',
    'úterý',
    'středa',
    'čtvrtek',
    'pátek',
    'sobota',
  ],
  'cs_cz.dayshort': ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  'cs_cz.firstday': 1,

  'da_dk.month': [
    'januar',
    'februar',
    'marts',
    'april',
    'maj',
    'juni',
    'juli',
    'august',
    'september',
    'oktober',
    'november',
    'december',
  ],
  'da_dk.mshort': [
    'jan',
    'feb',
    'mar',
    'apr',
    'maj',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec',
  ],
  'da_dk.ampm': ['', ''],
  'da_dk.ampmshort': ['', ''],
  'da_dk.days': [
    'søndag',
    'mandag',
    'tirsdag',
    'onsdag',
    'torsdag',
    'fredag',
    'lørdag',
  ],
  'da_dk.dayshort': ['sø', 'ma', 'ti', 'on', 'to', 'fr', 'lø'],
  'da_dk.firstday': 1,

  'de_de.month': [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  'de_de.mshort': [
    'Jan',
    'Feb',
    'Mrz',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
  'de_de.ampm': ['', ''],
  'de_de.ampmshort': ['', ''],
  'de_de.days': [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  'de_de.dayshort': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  'de_de.firstday': 1,

  'el_gr.month': [
    'Ιανουάριος',
    'Φεβρουάριος',
    'Μάρτιος',
    'Απρίλιος',
    'Μάιος',
    'Ιούνιος',
    'Ιούλιος',
    'Αύγουστος',
    'Σεπτέμβριος',
    'Οκτώβριος',
    'Νοέμβριος',
    'Δεκέμβριος',
  ],
  'el_gr.month_genitive': [
    'Ιανουαρίου',
    'Φεβρουαρίου',
    'Μαρτίου',
    'Απριλίου',
    'Μαΐου',
    'Ιουνίου',
    'Ιουλίου',
    'Αυγούστου',
    'Σεπτεμβρίου',
    'Οκτωβρίου',
    'Νοεμβρίου',
    'Δεκεμβρίου',
  ],
  'el_gr.mshort': [
    'Ιαν',
    'Φεβ',
    'Μαρ',
    'Απρ',
    'Μαϊ',
    'Ιουν',
    'Ιουλ',
    'Αυγ',
    'Σεπ',
    'Οκτ',
    'Νοε',
    'Δεκ',
  ],
  'el_gr.ampm': ['πμ', 'μμ'],
  'el_gr.ampmshort': ['π', 'μ'],
  'el_gr.days': [
    'Κυριακή',
    'Δευτέρα',
    'Τρίτη',
    'Τετάρτη',
    'Πέμπτη',
    'Παρασκευή',
    'Σάββατο',
  ],
  'el_gr.dayshort': ['Κυ', 'Δε', 'Τρ', 'Τε', 'Πε', 'Πα', 'Σα'],
  'el_gr.firstday': 1,

  'en_au.month': [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  'en_au.mshort': [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  'en_au.ampm': ['AM', 'PM'],
  'en_au.ampmshort': ['A', 'P'],
  'en_au.days': [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  'en_au.dayshort': ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  'en_au.firstday': 1,

  'en_gb.month': [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  'en_gb.mshort': [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  'en_gb.ampm': ['AM', 'PM'],
  'en_gb.ampmshort': ['A', 'P'],
  'en_gb.days': [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  'en_gb.dayshort': ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  'en_gb.firstday': 1,

  'en_us.month': [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  'en_us.mshort': [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  'en_us.ampm': ['AM', 'PM'],
  'en_us.ampmshort': ['A', 'P'],
  'en_us.days': [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  'en_us.dayshort': ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  'en_us.firstday': 0,

  'es_es.month': [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],
  'es_es.mshort': [
    'ene.',
    'feb.',
    'mar.',
    'abr.',
    'may.',
    'jun.',
    'jul.',
    'ago.',
    'sep.',
    'oct.',
    'nov.',
    'dic.',
  ],
  'es_es.ampm': ['', ''],
  'es_es.ampmshort': ['', ''],
  'es_es.days': [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado',
  ],
  'es_es.dayshort': ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  'es_es.firstday': 1,

  'es_mx.month': [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],
  'es_mx.mshort': [
    'ene.',
    'feb.',
    'mar.',
    'abr.',
    'may.',
    'jun.',
    'jul.',
    'ago.',
    'sep.',
    'oct.',
    'nov.',
    'dic.',
  ],
  'es_mx.ampm': ['a. m.', 'p. m.'],
  'es_mx.ampmshort': ['a', 'p'],
  'es_mx.days': [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado',
  ],
  'es_mx.dayshort': ['do.', 'lu.', 'ma.', 'mi.', 'ju.', 'vi.', 'sá.'],
  'es_mx.firstday': 0,

  'et_ee.month': [
    'jaanuar',
    'veebruar',
    'märts',
    'aprill',
    'mai',
    'juuni',
    'juuli',
    'august',
    'september',
    'oktoober',
    'november',
    'detsember',
  ],
  'et_ee.mshort': [
    'jaan',
    'veebr',
    'märts',
    'apr',
    'mai',
    'juuni',
    'juuli',
    'aug',
    'sept',
    'okt',
    'nov',
    'dets',
  ],
  'et_ee.ampm': ['AM', 'PM'],
  'et_ee.ampmshort': ['A', 'P'],
  'et_ee.days': [
    'pühapäev',
    'esmaspäev',
    'teisipäev',
    'kolmapäev',
    'neljapäev',
    'reede',
    'laupäev',
  ],
  'et_ee.dayshort': ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
  'et_ee.firstday': 1,

  'fa_ir.month': [
    'فروردین',
    'اردیبهشت',
    'خرداد',
    'تیر',
    'مرداد',
    'شهریور',
    'مهر',
    'آبان',
    'آذر',
    'دی',
    'بهمن',
    'اسفند',
  ],
  'fa_ir.mshort': [
    'فروردین',
    'اردیبهشت',
    'خرداد',
    'تیر',
    'مرداد',
    'شهریور',
    'مهر',
    'آبان',
    'آذر',
    'دی',
    'بهمن',
    'اسفند',
  ],
  'fa_ir.ampm': ['ق.ظ', 'ب.ظ'],
  'fa_ir.ampmshort': ['ق', 'ب'],
  'fa_ir.days': [
    'يكشنبه',
    'دوشنبه',
    'سه شنبه',
    'چهارشنبه',
    'پنجشنبه',
    'جمعه',
    'شنبه',
  ],
  'fa_ir.dayshort': ['ی', 'د', 'س', 'چ', 'پ', 'ج', 'ش'],
  'fa_ir.firstday': 6,

  'fi_fi.month': [
    'tammikuu',
    'helmikuu',
    'maaliskuu',
    'huhtikuu',
    'toukokuu',
    'kesäkuu',
    'heinäkuu',
    'elokuu',
    'syyskuu',
    'lokakuu',
    'marraskuu',
    'joulukuu',
  ],
  'fi_fi.month_genitive': [
    'tammikuuta',
    'helmikuuta',
    'maaliskuuta',
    'huhtikuuta',
    'toukokuuta',
    'kesäkuuta',
    'heinäkuuta',
    'elokuuta',
    'syyskuuta',
    'lokakuuta',
    'marraskuuta',
    'joulukuuta',
  ],
  'fi_fi.mshort': [
    'tammi',
    'helmi',
    'maalis',
    'huhti',
    'touko',
    'kesä',
    'heinä',
    'elo',
    'syys',
    'loka',
    'marras',
    'joulu',
  ],
  'fi_fi.ampm': ['ap.', 'ip.'],
  'fi_fi.ampmshort': ['a', 'i'],
  'fi_fi.days': [
    'sunnuntai',
    'maanantai',
    'tiistai',
    'keskiviikko',
    'torstai',
    'perjantai',
    'lauantai',
  ],
  'fi_fi.dayshort': ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la'],
  'fi_fi.firstday': 1,

  'fr_ca.month': [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  'fr_ca.mshort': [
    'janv.',
    'févr.',
    'mars',
    'avr.',
    'mai',
    'juin',
    'juil.',
    'août',
    'sept.',
    'oct.',
    'nov.',
    'déc.',
  ],
  'fr_ca.ampm': ['', ''],
  'fr_ca.ampmshort': ['', ''],
  'fr_ca.days': [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
  ],
  'fr_ca.dayshort': ['di', 'lu', 'ma', 'me', 'je', 've', 'sa'],
  'fr_ca.firstday': 0,

  'fr_fr.month': [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  'fr_fr.mshort': [
    'janv.',
    'févr.',
    'mars',
    'avr.',
    'mai',
    'juin',
    'juil.',
    'août',
    'sept.',
    'oct.',
    'nov.',
    'déc.',
  ],
  'fr_fr.ampm': ['', ''],
  'fr_fr.ampmshort': ['', ''],
  'fr_fr.days': [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
  ],
  'fr_fr.dayshort': ['di', 'lu', 'ma', 'me', 'je', 've', 'sa'],
  'fr_fr.firstday': 1,

  'he_il.month': [
    'ינואר',
    'פברואר',
    'מרץ',
    'אפריל',
    'מאי',
    'יוני',
    'יולי',
    'אוגוסט',
    'ספטמבר',
    'אוקטובר',
    'נובמבר',
    'דצמבר',
  ],
  'he_il.mshort': [
    'ינו',
    'פבר',
    'מרץ',
    'אפר',
    'מאי',
    'יונ',
    'יול',
    'אוג',
    'ספט',
    'אוק',
    'נוב',
    'דצמ',
  ],
  'he_il.ampm': ['AM', 'PM'],
  'he_il.ampmshort': ['A', 'P'],
  'he_il.days': [
    'יום ראשון',
    'יום שני',
    'יום שלישי',
    'יום רביעי',
    'יום חמישי',
    'יום שישי',
    'שבת',
  ],
  'he_il.dayshort': ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
  'he_il.firstday': 0,

  'hi_in.month': [
    'जनवरी',
    'फरवरी',
    'मार्च',
    'अप्रैल',
    'मई',
    'जून',
    'जुलाई',
    'अगस्त',
    'सितम्बर',
    'अक्तूबर',
    'नवम्बर',
    'दिसम्बर',
  ],
  'hi_in.mshort': [
    'जनवरी',
    'फरवरी',
    'मार्च',
    'अप्रैल',
    'मई',
    'जून',
    'जुलाई',
    'अगस्त',
    'सितम्बर',
    'अक्तूबर',
    'नवम्बर',
    'दिसम्बर',
  ],
  'hi_in.ampm': ['पूर्वाह्न', 'अपराह्न'],
  'hi_in.ampmshort': ['प', 'अ'],
  'hi_in.days': [
    'रविवार',
    'सोमवार',
    'मंगलवार',
    'बुधवार',
    'गुरुवार',
    'शुक्रवार',
    'शनिवार',
  ],
  'hi_in.dayshort': ['र', 'स', 'म', 'ब', 'ग', 'श', 'श'],
  'hi_in.firstday': 1,

  'hr_hr.month': [
    'siječanj',
    'veljača',
    'ožujak',
    'travanj',
    'svibanj',
    'lipanj',
    'srpanj',
    'kolovoz',
    'rujan',
    'listopad',
    'studeni',
    'prosinac',
  ],
  'hr_hr.month_genitive': [
    'siječnja',
    'veljače',
    'ožujka',
    'travnja',
    'svibnja',
    'lipnja',
    'srpnja',
    'kolovoza',
    'rujna',
    'listopada',
    'studenog',
    'prosinca',
  ],
  'hr_hr.mshort': [
    'sij',
    'vlj',
    'ožu',
    'tra',
    'svi',
    'lip',
    'srp',
    'kol',
    'ruj',
    'lis',
    'stu',
    'pro',
  ],
  'hr_hr.ampm': ['', ''],
  'hr_hr.ampmshort': ['', ''],
  'hr_hr.days': [
    'nedjelja',
    'ponedjeljak',
    'utorak',
    'srijeda',
    'četvrtak',
    'petak',
    'subota',
  ],
  'hr_hr.dayshort': ['ne', 'po', 'ut', 'sr', 'če', 'pe', 'su'],
  'hr_hr.firstday': 1,

  'hu_hu.month': [
    'január',
    'február',
    'március',
    'április',
    'május',
    'június',
    'július',
    'augusztus',
    'szeptember',
    'október',
    'november',
    'december',
  ],
  'hu_hu.mshort': [
    'jan.',
    'febr.',
    'márc.',
    'ápr.',
    'máj.',
    'jún.',
    'júl.',
    'aug.',
    'szept.',
    'okt.',
    'nov.',
    'dec.',
  ],
  'hu_hu.ampm': ['de.', 'du.'],
  'hu_hu.ampmshort': ['d', 'u'],
  'hu_hu.days': [
    'vasárnap',
    'hétfő',
    'kedd',
    'szerda',
    'csütörtök',
    'péntek',
    'szombat',
  ],
  'hu_hu.dayshort': ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'],
  'hu_hu.firstday': 1,

  'hy_am.month': [
    'Հունվար',
    'Փետրվար',
    'Մարտ',
    'Ապրիլ',
    'Մայիս',
    'Հունիս',
    'Հուլիս',
    'Օգոստոս',
    'Սեպտեմբեր',
    'Հոկտեմբեր',
    'Նոյեմբեր',
    'Դեկտեմբեր',
  ],
  'hy_am.month_genitive': [
    'հունվարի',
    'փետրվարի',
    'մարտի',
    'ապրիլի',
    'մայիսի',
    'հունիսի',
    'հուլիսի',
    'օգոստոսի',
    'սեպտեմբերի',
    'հոկտեմբերի',
    'նոյեմբերի',
    'դեկտեմբերի',
  ],
  'hy_am.mshort': [
    'Հնվ',
    'Փտվ',
    'Մրտ',
    'Ապր',
    'Մյս',
    'Հնս',
    'Հլս',
    'Օգս',
    'Սպտ',
    'Հկտ',
    'Նյմ',
    'Դկտ',
  ],
  'hy_am.ampm': ['', ''],
  'hy_am.ampmshort': ['', ''],
  'hy_am.days': [
    'Կիրակի',
    'Երկուշաբթի',
    'Երեքշաբթի',
    'Չորեքշաբթի',
    'Հինգշաբթի',
    'Ուրբաթ',
    'Շաբաթ',
  ],
  'hy_am.dayshort': ['Կ', 'Ե', 'Ե', 'Չ', 'Հ', 'Ո', 'Շ'],
  'hy_am.firstday': 1,

  'id_id.month': [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ],
  'id_id.mshort': [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Agt',
    'Sep',
    'Okt',
    'Nov',
    'Des',
  ],
  'id_id.ampm': ['AM', 'PM'],
  'id_id.ampmshort': ['A', 'P'],
  'id_id.days': [
    'Minggu',
    'Senin',
    'Selasa',
    'Rabu',
    'Kamis',
    'Jumat',
    'Sabtu',
  ],
  'id_id.dayshort': ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
  'id_id.firstday': 0,

  'it_it.month': [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre',
  ],
  'it_it.mshort': [
    'gen',
    'feb',
    'mar',
    'apr',
    'mag',
    'giu',
    'lug',
    'ago',
    'set',
    'ott',
    'nov',
    'dic',
  ],
  'it_it.ampm': ['', ''],
  'it_it.ampmshort': ['', ''],
  'it_it.days': [
    'domenica',
    'lunedì',
    'martedì',
    'mercoledì',
    'giovedì',
    'venerdì',
    'sabato',
  ],
  'it_it.dayshort': ['do', 'lu', 'ma', 'me', 'gi', 've', 'sa'],
  'it_it.firstday': 1,

  'ja_jp.month': [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  'ja_jp.mshort': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ],
  'ja_jp.ampm': ['午前', '午後'],
  'ja_jp.ampmshort': ['前', '後'],
  'ja_jp.days': [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ],
  'ja_jp.dayshort': ['日', '月', '火', '水', '木', '金', '土'],
  'ja_jp.firstday': 0,

  'ko_kr.month': [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ],
  'ko_kr.mshort': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ],
  'ko_kr.ampm': ['오전', '오후'],
  'ko_kr.ampmshort': ['전', '후'],
  'ko_kr.days': [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ],
  'ko_kr.dayshort': ['일', '월', '화', '수', '목', '금', '토'],
  'ko_kr.firstday': 0,

  'lt_lt.month': [
    'sausis',
    'vasaris',
    'kovas',
    'balandis',
    'gegužė',
    'birželis',
    'liepa',
    'rugpjūtis',
    'rugsėjis',
    'spalis',
    'lapkritis',
    'gruodis',
  ],
  'lt_lt.month_genitive': [
    'sausio',
    'vasario',
    'kovo',
    'balandžio',
    'gegužės',
    'birželio',
    'liepos',
    'rugpjūčio',
    'rugsėjo',
    'spalio',
    'lapkričio',
    'gruodžio',
  ],
  'lt_lt.mshort': [
    'saus.',
    'vas.',
    'kov.',
    'bal.',
    'geg.',
    'birž.',
    'liep.',
    'rugp.',
    'rugs.',
    'spal.',
    'lapkr.',
    'gruod.',
  ],
  'lt_lt.ampm': ['priešpiet', 'popiet'],
  'lt_lt.ampmshort': ['p', 'o'],
  'lt_lt.days': [
    'sekmadienis',
    'pirmadienis',
    'antradienis',
    'trečiadienis',
    'ketvirtadienis',
    'penktadienis',
    'šeštadienis',
  ],
  'lt_lt.dayshort': ['Sk', 'Pr', 'An', 'Tr', 'Kt', 'Pn', 'Št'],
  'lt_lt.firstday': 1,

  'lv_lv.month': [
    'janvāris',
    'februāris',
    'marts',
    'aprīlis',
    'maijs',
    'jūnijs',
    'jūlijs',
    'augusts',
    'septembris',
    'oktobris',
    'novembris',
    'decembris',
  ],
  'lv_lv.mshort': [
    'janv.',
    'febr.',
    'marts',
    'apr.',
    'maijs',
    'jūn.',
    'jūl.',
    'aug.',
    'sept.',
    'okt.',
    'nov.',
    'dec.',
  ],
  'lv_lv.ampm': ['priekšp.', 'pēcp.'],
  'lv_lv.ampmshort': ['p', 'ē'],
  'lv_lv.days': [
    'svētdiena',
    'pirmdiena',
    'otrdiena',
    'trešdiena',
    'ceturtdiena',
    'piektdiena',
    'sestdiena',
  ],
  'lv_lv.dayshort': ['Sv', 'Pr', 'Ot', 'Tr', 'Ce', 'Pk', 'Se'],
  'lv_lv.firstday': 1,

  'ms_my.month': [
    'Januari',
    'Februari',
    'Mac',
    'April',
    'Mei',
    'Jun',
    'Julai',
    'Ogos',
    'September',
    'Oktober',
    'November',
    'Disember',
  ],
  'ms_my.mshort': [
    'Jan',
    'Feb',
    'Mac',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Ogo',
    'Sep',
    'Okt',
    'Nov',
    'Dis',
  ],
  'ms_my.ampm': ['PG', 'PTG'],
  'ms_my.ampmshort': ['G', 'T'],
  'ms_my.days': [
    'Ahad',
    'Isnin',
    'Selasa',
    'Rabu',
    'Khamis',
    'Jumaat',
    'Sabtu',
  ],
  'ms_my.dayshort': ['Ah', 'Is', 'Se', 'Ra', 'Kh', 'Ju', 'Sa'],
  'ms_my.firstday': 1,

  'nb_no.month': [
    'januar',
    'februar',
    'mars',
    'april',
    'mai',
    'juni',
    'juli',
    'august',
    'september',
    'oktober',
    'november',
    'desember',
  ],
  'nb_no.mshort': [
    'jan',
    'feb',
    'mar',
    'apr',
    'mai',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'des',
  ],
  'nb_no.ampm': ['a.m.', 'p.m.'],
  'nb_no.ampmshort': ['a', 'p'],
  'nb_no.days': [
    'søndag',
    'mandag',
    'tirsdag',
    'onsdag',
    'torsdag',
    'fredag',
    'lørdag',
  ],
  'nb_no.dayshort': ['sø.', 'ma.', 'ti.', 'on.', 'to.', 'fr.', 'lø.'],
  'nb_no.firstday': 1,

  'nl_nl.month': [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december',
  ],
  'nl_nl.mshort': [
    'jan',
    'feb',
    'mrt',
    'apr',
    'mei',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec',
  ],
  'nl_nl.ampm': ['', ''],
  'nl_nl.ampmshort': ['', ''],
  'nl_nl.days': [
    'zondag',
    'maandag',
    'dinsdag',
    'woensdag',
    'donderdag',
    'vrijdag',
    'zaterdag',
  ],
  'nl_nl.dayshort': ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
  'nl_nl.firstday': 1,

  'pl_pl.month': [
    'styczeń',
    'luty',
    'marzec',
    'kwiecień',
    'maj',
    'czerwiec',
    'lipiec',
    'sierpień',
    'wrzesień',
    'październik',
    'listopad',
    'grudzień',
  ],
  'pl_pl.month_genitive': [
    'stycznia',
    'lutego',
    'marca',
    'kwietnia',
    'maja',
    'czerwca',
    'lipca',
    'sierpnia',
    'września',
    'października',
    'listopada',
    'grudnia',
  ],
  'pl_pl.mshort': [
    'sty',
    'lut',
    'mar',
    'kwi',
    'maj',
    'cze',
    'lip',
    'sie',
    'wrz',
    'paź',
    'lis',
    'gru',
  ],
  'pl_pl.ampm': ['AM', 'PM'],
  'pl_pl.ampmshort': ['A', 'P'],
  'pl_pl.days': [
    'niedziela',
    'poniedziałek',
    'wtorek',
    'środa',
    'czwartek',
    'piątek',
    'sobota',
  ],
  'pl_pl.dayshort': ['nie', 'pon', 'wto', 'śro', 'czw', 'pią', 'sob'],
  'pl_pl.firstday': 1,

  'pt_br.month': [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ],
  'pt_br.mshort': [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ],
  'pt_br.ampm': ['', ''],
  'pt_br.ampmshort': ['', ''],
  'pt_br.days': [
    'domingo',
    'segunda-feira',
    'terça-feira',
    'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado',
  ],
  'pt_br.dayshort': ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  'pt_br.firstday': 0,

  'pt_pt.month': [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ],
  'pt_pt.mshort': [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ],
  'pt_pt.ampm': ['', ''],
  'pt_pt.ampmshort': ['', ''],
  'pt_pt.days': [
    'domingo',
    'segunda-feira',
    'terça-feira',
    'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado',
  ],
  'pt_pt.dayshort': ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  'pt_pt.firstday': 0,

  'ro_ro.month': [
    'ianuarie',
    'februarie',
    'martie',
    'aprilie',
    'mai',
    'iunie',
    'iulie',
    'august',
    'septembrie',
    'octombrie',
    'noiembrie',
    'decembrie',
  ],
  'ro_ro.mshort': [
    'ian.',
    'feb.',
    'mar.',
    'apr.',
    'mai',
    'iun.',
    'iul.',
    'aug.',
    'sept.',
    'oct.',
    'nov.',
    'dec.',
  ],
  'ro_ro.ampm': ['a.m.', 'p.m.'],
  'ro_ro.ampmshort': ['a', 'p'],
  'ro_ro.days': [
    'duminică',
    'luni',
    'marți',
    'miercuri',
    'joi',
    'vineri',
    'sâmbătă',
  ],
  'ro_ro.dayshort': ['du.', 'lu.', 'ma.', 'mi.', 'joi', 'vi.', 'sâ.'],
  'ro_ro.firstday': 1,

  'ru_ru.month': [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  'ru_ru.month_genitive': [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ],
  'ru_ru.mshort': [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек',
  ],
  'ru_ru.ampm': ['', ''],
  'ru_ru.ampmshort': ['', ''],
  'ru_ru.days': [
    'воскресенье',
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
  ],
  'ru_ru.dayshort': ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  'ru_ru.firstday': 1,

  'sk_sk.month': [
    'január',
    'február',
    'marec',
    'apríl',
    'máj',
    'jún',
    'júl',
    'august',
    'september',
    'október',
    'november',
    'december',
  ],
  'sk_sk.month_genitive': [
    'januára',
    'februára',
    'marca',
    'apríla',
    'mája',
    'júna',
    'júla',
    'augusta',
    'septembra',
    'októbra',
    'novembra',
    'decembra',
  ],
  'sk_sk.mshort': [
    'jan',
    'feb',
    'mar',
    'apr',
    'máj',
    'jún',
    'júl',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec',
  ],
  'sk_sk.ampm': ['AM', 'PM'],
  'sk_sk.ampmshort': ['A', 'P'],
  'sk_sk.days': [
    'nedeľa',
    'pondelok',
    'utorok',
    'streda',
    'štvrtok',
    'piatok',
    'sobota',
  ],
  'sk_sk.dayshort': ['ne', 'po', 'ut', 'st', 'št', 'pi', 'so'],
  'sk_sk.firstday': 1,

  'sl_si.month': [
    'januar',
    'februar',
    'marec',
    'april',
    'maj',
    'junij',
    'julij',
    'avgust',
    'september',
    'oktober',
    'november',
    'december',
  ],
  'sl_si.mshort': [
    'jan.',
    'feb.',
    'mar.',
    'apr.',
    'maj',
    'jun.',
    'jul.',
    'avg.',
    'sep.',
    'okt.',
    'nov.',
    'dec.',
  ],
  'sl_si.ampm': ['dop.', 'pop.'],
  'sl_si.ampmshort': ['d', 'p'],
  'sl_si.days': [
    'nedelja',
    'ponedeljek',
    'torek',
    'sreda',
    'četrtek',
    'petek',
    'sobota',
  ],
  'sl_si.dayshort': ['ned.', 'pon.', 'tor.', 'sre.', 'čet.', 'pet.', 'sob.'],
  'sl_si.firstday': 1,

  'sr.month': [
    'januar',
    'februar',
    'mart',
    'april',
    'maj',
    'jun',
    'jul',
    'avgust',
    'septembar',
    'oktobar',
    'novembar',
    'decembar',
  ],
  'sr.mshort': [
    'jan',
    'feb',
    'mar',
    'apr',
    'maj',
    'jun',
    'jul',
    'avg',
    'sep',
    'okt',
    'nov',
    'dec',
  ],
  'sr.ampm': ['pre podne', 'po podne'],
  'sr.ampmshort': ['p', 'o'],
  'sr.days': [
    'nedelja',
    'ponedeljak',
    'utorak',
    'sreda',
    'četvrtak',
    'petak',
    'subota',
  ],
  'sr.dayshort': ['ne', 'po', 'ut', 'sr', 'če', 'pe', 'su'],
  'sr.firstday': 1,

  'sv_se.month': [
    'januari',
    'februari',
    'mars',
    'april',
    'maj',
    'juni',
    'juli',
    'augusti',
    'september',
    'oktober',
    'november',
    'december',
  ],
  'sv_se.mshort': [
    'jan',
    'feb',
    'mar',
    'apr',
    'maj',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec',
  ],
  'sv_se.ampm': ['', ''],
  'sv_se.ampmshort': ['', ''],
  'sv_se.days': [
    'söndag',
    'måndag',
    'tisdag',
    'onsdag',
    'torsdag',
    'fredag',
    'lördag',
  ],
  'sv_se.dayshort': ['sö', 'må', 'ti', 'on', 'to', 'fr', 'lö'],
  'sv_se.firstday': 1,

  'th_th.month': [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ],
  'th_th.mshort': [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ],
  'th_th.ampm': ['AM', 'PM'],
  'th_th.ampmshort': ['A', 'P'],
  'th_th.days': [
    'อาทิตย์',
    'จันทร์',
    'อังคาร',
    'พุธ',
    'พฤหัสบดี',
    'ศุกร์',
    'เสาร์',
  ],
  'th_th.dayshort': ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
  'th_th.firstday': 1,

  'tr_tr.month': [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ],
  'tr_tr.mshort': [
    'Oca',
    'Şub',
    'Mar',
    'Nis',
    'May',
    'Haz',
    'Tem',
    'Ağu',
    'Eyl',
    'Eki',
    'Kas',
    'Ara',
  ],
  'tr_tr.ampm': ['ÖÖ', 'ÖS'],
  'tr_tr.ampmshort': ['Ö', 'S'],
  'tr_tr.days': [
    'Pazar',
    'Pazartesi',
    'Salı',
    'Çarşamba',
    'Perşembe',
    'Cuma',
    'Cumartesi',
  ],
  'tr_tr.dayshort': ['Pa', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
  'tr_tr.firstday': 1,

  'uk_ua.month': [
    'січень',
    'лютий',
    'березень',
    'квітень',
    'травень',
    'червень',
    'липень',
    'серпень',
    'вересень',
    'жовтень',
    'листопад',
    'грудень',
  ],
  'uk_ua.month_genitive': [
    'січня',
    'лютого',
    'березня',
    'квітня',
    'травня',
    'червня',
    'липня',
    'серпня',
    'вересня',
    'жовтня',
    'листопада',
    'грудня',
  ],
  'uk_ua.mshort': [
    'Січ',
    'Лют',
    'Бер',
    'Кві',
    'Тра',
    'Чер',
    'Лип',
    'Сер',
    'Вер',
    'Жов',
    'Лис',
    'Гру',
  ],
  'uk_ua.ampm': ['', ''],
  'uk_ua.ampmshort': ['', ''],
  'uk_ua.days': [
    'неділя',
    'понеділок',
    'вівторок',
    'середа',
    'четвер',
    "п'ятниця",
    'субота',
  ],
  'uk_ua.dayshort': ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  'uk_ua.firstday': 1,

  'vi_vn.month': [
    'Tháng Giêng',
    'Tháng Hai',
    'Tháng Ba',
    'Tháng Tư',
    'Tháng Năm',
    'Tháng Sáu',
    'Tháng Bảy',
    'Tháng Tám',
    'Tháng Chín',
    'Tháng Mười',
    'Tháng Mười Một',
    'Tháng Mười Hai',
  ],
  'vi_vn.mshort': [
    'Thg1',
    'Thg2',
    'Thg3',
    'Thg4',
    'Thg5',
    'Thg6',
    'Thg7',
    'Thg8',
    'Thg9',
    'Thg10',
    'Thg11',
    'Thg12',
  ],
  'vi_vn.ampm': ['SA', 'CH'],
  'vi_vn.ampmshort': ['S', 'C'],
  'vi_vn.days': [
    'Chủ Nhật',
    'Thứ Hai',
    'Thứ Ba',
    'Thứ Tư',
    'Thứ Năm',
    'Thứ Sáu',
    'Thứ Bảy',
  ],
  'vi_vn.dayshort': ['C', 'H', 'B', 'T', 'N', 'S', 'B'],
  'vi_vn.firstday': 1,

  'zh_cn.month': [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  'zh_cn.mshort': [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  'zh_cn.ampm': ['上午', '下午'],
  'zh_cn.ampmshort': ['上', '下'],
  'zh_cn.days': [
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
  ],
  'zh_cn.dayshort': ['日', '一', '二', '三', '四', '五', '六'],
  'zh_cn.firstday': 1,

  'zh_tw.month': [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  'zh_tw.mshort': [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  'zh_tw.ampm': ['上午', '下午'],
  'zh_tw.ampmshort': ['上', '下'],
  'zh_tw.days': [
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
  ],
  'zh_tw.dayshort': ['日', '一', '二', '三', '四', '五', '六'],
  'zh_tw.firstday': 0,
}
