import * as React from 'react'
import PropTypes from 'prop-types'
import { calculateTokens } from '../../cobranding/calculateTokens'
import type { CobrandingConfig, CobrandingTheme } from '../../types'

export const CobrandingContext = React.createContext<CobrandingTheme>({
  tokens: {},
})

export interface CobrandingProps {
  /**
   * Application content to receive cobranding styles.
   */
  children: React.ReactNode
  /**
   * The provided config will be used to apply cobranding styles to eligible components.
   */
  config: CobrandingConfig
}

/**
 * Wrap your app with the `Cobranding` component to apply custom colors to eligible components.
 */
export function Cobranding({ children, config }: CobrandingProps) {
  const tokens = React.useMemo(() => calculateTokens(config), [config])

  const cobrandingTheme = React.useMemo(
    () => ({
      tokens,
    }),
    [tokens],
  )

  return (
    <CobrandingContext.Provider value={cobrandingTheme}>
      {children}
    </CobrandingContext.Provider>
  )
}

Cobranding.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    buttonMainBackgroundColor: PropTypes.string,
    buttonMainTextColor: PropTypes.string,
    buttonPrimaryBackgroundColor: PropTypes.string,
    buttonPrimaryTextColor: PropTypes.string,
    headerBackgroundColor: PropTypes.string,
    headerTextColor: PropTypes.string,
    logoUrl: PropTypes.string,
    showLogoOnWhite: PropTypes.bool,
  }).isRequired,
}
