export const i18nLinks = {
  url_paths: {
    // Terms & Conditions
    term: {
      de: 'de-de/rechtliches/agb',
      es: 'es-es/legal/condiciones-de-uso',
      es_mx: 'es-es/legal/condiciones-de-uso',
      fr: 'fr-fr/mentiones-legales/termes-et-conditions',
      fr_ca: 'fr-fr/mentiones-legales/termes-et-conditions',
      ja: 'ja-jp/legal/terms-and-conditions',
      pt_br: 'pt-br/legalidade/termos-e-condicoes',
      pt: 'pt-br/legalidade/termos-e-condicoes',
      en_au: 'en-au/legal/terms-and-conditions',
      en_gb: 'en-gb/legal/terms-and-conditions',
      en_ca: 'en-ca/legal/terms-and-conditions',
      it: 'it-it/legale/termini-e-condizioni',
      en_ph: 'en-ph/legal/terms-and-conditions',
      en_my: 'en-my/legal/terms-and-conditions',
      en_sg: 'en-sg/legal/terms-and-conditions',
      en_tw: 'en-tw/legal/terms-and-conditions',
      _: 'legal/terms-and-conditions',
    },
    // iframe Terms & Conditions
    iterm: {
      de: 'de-de/iframe/unternehmen/agb',
      es: 'es-es/iframe/empresa/condiciones-de-uso/web',
      es_mx: 'es-mx/iframe/compania/condiciones-de-uso/web',
      fr: 'fr-fr/iframe/conditions-generales-d-utilisation',
      fr_ca: 'fr-fr/iframe/conditions-generales-d-utilisation',
      ja: 'ja-jp/iframe/company/terms-and-conditions/web',
      pt_br: 'pt-br/iframe/termos-e-condicoes',
      pt: 'pt-br/iframe/termos-e-condicoes',
      en_au: 'en-au/iframe/company/terms-and-conditions/web',
      en_gb: 'en-gb/iframe/company/terms-and-conditions/web',
      en_ca: 'en-ca/iframe/company/terms-and-conditions/web',
      _: 'iframe/company/terms-and-conditions/web',
    },
    privacy: {
      de: 'de-de/datenschutzerklaerung/datenschutz',
      es: 'es-es/empresa/politica-de-privacidad',
      es_mx: 'es-mx/privacidad',
      fr: 'fr-fr/politique-de-confidentialite',
      fr_ca: 'fr-fr/politique-de-confidentialite',
      ja: 'ja-jp/privacy',
      nl: 'nl-nl/privacy',
      pt_br: 'pt-br/privacidade',
      pt: 'pt-br/privacidade',
      en_au: 'en-au/privacy',
      en_gb: 'en-gb/privacy',
      en_ca: 'en-ca/privacy',
      _: 'privacy',
    },
    iprivacy: {
      de: 'de-de/iframe/unternehmen/datenschutz',
      es: 'es-es/iframe/empresa/política-de-privacidad',
      es_mx: 'es-mx/iframe/compania/politica-de-privacidad',
      fr: 'fr-fr/iframe/societe/politique-de-confidentialite',
      fr_ca: 'fr-fr/iframe/societe/politique-de-confidentialite',
      ja: 'ja-jp/iframe/company/privacy-policy',
      nl: 'nl-nl/iframe/bedrijf/privacybeleid',
      pt_br: 'pt-br/iframe/politica-de-privacidade',
      pt: 'pt-br/iframe/politica-de-privacidade',
      en_au: 'en-au/iframe/company/privacy-policy',
      en_gb: 'en-gb/iframe/company/privacy-policy',
      en_ca: 'en-ca/iframe/company/privacy-policy',
      _: 'iframe/company/privacy-policy',
    },
    ip: {
      de: 'unternehmen/geistiges_eigentum',
      fr: 'IP',
      fr_ca: 'IP',
      en_au: 'IP',
      en_gb: 'IP',
      en_ca: 'IP',
      _: 'IP',
    },
    iip: {
      fr: 'iframe/ip',
      fr_ca: 'iframe/ip',
      en_au: 'iframe/ip',
      en_gb: 'iframe/ip',
      en_ca: 'iframe/ip',
      _: 'iframe/IP',
    },
    support: {
      de: 'de/contactSupport',
      es: 'es/contactSupport',
      es_mx: 'es/contactSupport',
      fr: 'fr/contactSupport',
      fr_ca: 'fr/contactSupport',
      ja: 'jp/contactSupport',
      pt_br: 'br/contactSupport',
      pt: 'br/contactSupport',
      _: 'contactSupport',
    },
    ariadnext_aes: {
      fr: 'sites/default/files/gtu_fr-aes.pdf',
      fr_ca: 'sites/default/files/gtu_fr-aes.pdf',
      _: 'sites/default/files/gtu_en-aes.pdf',
    },
    ariadnext_qes: {
      fr: 'sites/default/files/gtu_fr-qes.pdf',
      fr_ca: 'sites/default/files/gtu_fr-qes.pdf',
      _: 'sites/default/files/gtu_en-qes.pdf',
    },
    idv_eu_adv: {
      _: 'conditions-generales-d-utilisation/GTU-EU-Advanced-with-IDV-{lang}',
    },
    eu_adv: {
      _: 'conditions-generales-d-utilisation/GTU-EU-Advanced-{lang}',
    },
    preference: {
      _: 'preference-center/?params={lang}',
    },
  },
  radmin_new_doc_to_guide_map: {
    'api-keys': 'api-and-keys',
    'audit-logs': 'account-audit-logs',
    'billing-usage': 'billing-and-usage-information',
    'branding-admin': 'configure-branding',
    comments: 'comments-settings',
    'custom-fields-admin': 'custom-fields',
    'remind-expire-defaults': 'reminders-and-expirations',
    'welcome-admin': 'welcome-to-administration',
  },
  replace_language_to: {
    _: {
      de: 'de',
      fr: 'fr',
      fr_ca: 'fr',
      ja: 'jp',
      pt_br: 'br',
      pt: 'br',
      en_au: 'en',
      en_gb: 'en',
      en_ca: 'en',
      _: 'en',
    },
    dses: {
      de: 'de',
      fr: 'fr',
      fr_ca: 'fr',
      ja: 'jp',
      pt_br: 'bp',
      pt: 'bp',
      en_au: 'en',
      en_gb: 'en',
      en_ca: 'en',
      _: 'en',
    },
    idnow: {
      de: '6',
      es: '16',
      fr: '11',
      fr_ca: '11',
      it: '21',
      _: '1',
    },
    defrjp: {
      de: 'de',
      fr: 'fr',
      fr_ca: 'fr',
      ja: 'jp',
      en_au: 'en',
      en_gb: 'en',
      en_ca: 'en',
      _: 'en',
    },
    support: {
      de: 'de',
      es: 'es',
      es_mx: 'es',
      fr: 'fr',
      fr_ca: 'fr',
      ja: 'jp',
      pt_br: 'br',
      pt: 'br',
      en_au: 'en',
      en_gb: 'en',
      en_ca: 'en',
      _: 'en',
    },
    idv_eu_adv: {
      de: 'DE',
      ja: 'JP',
      fr: 'FR',
      fr_ca: 'FR',
      _: 'EN',
    },
    eu_adv: {
      de: 'DE',
      es: 'ES',
      es_mx: 'ES',
      nl: 'NL',
      it: 'IT',
      ja: 'JP',
      fr: 'FR',
      fr_ca: 'FR',
      en_au: 'EN',
      en_gb: 'EN',
      en_ca: 'EN',
      _: 'EN',
    },
    rsclanguage: {
      de: 'de',
      es: 'es',
      es_mx: 'es',
      fr: 'fr',
      fr_ca: 'fr',
      it: 'it',
      ja: 'ja',
      ko: 'ko',
      nl: 'nl_NL',
      nl_nl: 'nl_NL',
      pt_br: 'pt_BR',
      pt: 'pt_BR',
      zh_tw: 'zh_TW',
      _: 'en_US',
    },
    rsc_LANG: {
      de: 'dede',
      es: 'esxm',
      es_mx: 'esxm',
      fr: 'frfr',
      fr_ca: 'frfr',
      it: 'itit',
      ja: 'jajp',
      ko: 'kokr',
      nl: 'nlnl',
      nl_nl: 'nlnl',
      pt_br: 'ptbr',
      pt: 'ptbr',
      zh_tw: 'zhtw',
      _: 'enus',
    },
    preference: {
      de: 'bG9jYWxlaWQ9ZGU=',
      en_au: 'bG9jYWxlaWQ9ZW5fQVU=',
      en_ca: 'bG9jYWxlaWQ9ZW5fQ0E=',
      en_gb: 'bG9jYWxlaWQ9ZW5fR0I=',
      en_my: 'bG9jYWxlaWQ9ZW5fTVk=',
      en_ph: 'bG9jYWxlaWQ9ZW5fUEg=',
      en_sg: 'bG9jYWxlaWQ9ZW5fU0c=',
      en_tw: 'bG9jYWxlaWQ9ZW5fVFc=',
      es: 'bG9jYWxlaWQ9ZXM=',
      es_mx: 'bG9jYWxlaWQ9ZXNfTVg=',
      fr: 'bG9jYWxlaWQ9ZnI=',
      it: 'bG9jYWxlaWQ9aXQ=',
      ja: 'bG9jYWxlaWQ9amE=',
      nl: 'bG9jYWxlaWQ9bmw=',
      pt: 'bG9jYWxlaWQ9cHQ=',
      _: 'bG9jYWxlaWQ9ZW4=',
    },
  },
  to_be_localized_pages: [
    {
      base_url: 'https://support.docusign.com/',
      url_paths: [
        'login?from=/en/guides/ndse-admin-guide',
        'login?from=/en/guides/ndse-user-guide',
        'login?from=/en/guides/dses-user-guide',
        'en/guides/ndse-admin-guide',
        'en/guides/org-admin-guide',
        'en/guides/ndse-user-guide',
        'en/guides/dses-user-guide',
        'en/guides/managing-payment',
        'en/contactSupport',
        'en/knowledgeSearch',
        'en/articles/',
        'en/releasenotes',
      ],
      regex: /\/en\//,
      replacement: '/{lang}/',
      languages: '_',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: [
        'login?from=/guides/ndse-admin-guide',
        'login?from=/guides/ndse-user-guide',
        'login?from=/guides/dses-user-guide',
        'guides/ndse-admin-guide',
        'guides/ndse-user-guide',
        'guides/dses-user-guide',
      ],
      regex: /\/guides\//,
      replacement: '/{lang}/guides/',
      languages: '_',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: [
        'articles/sms-service',
        'articles/Get-Started-with-DocuSign-eSignature-part-one',
        'articles/Welcome-to-DocuSign-New-User-Onboarding',
      ],
      regex: /\/articles\//,
      replacement: '/{lang}/articles/',
      languages: 'defrjp',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['releasenotes'],
      regex: /\/releasenotes/,
      replacement: '/{lang}/releasenotes',
      languages: '_',
    },
    {
      base_url: 'https://support.docusign.com',
      url_paths: [''],
      equal: true,
      regex: /.com/,
      replacement: '.com/{lang}/',
      languages: 'support',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['en/'],
      equal: true,
      regex: /\/en\//,
      replacement: '/{lang}/',
      languages: 'support',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['', 'contactSupport'],
      equal: true,
      regex: /.com/,
      replacement: '.com/{lang}',
      languages: 'support',
    },
    {
      base_url: 'https://docs.docusign.com/',
      url_paths: ['supportdocs/simplified-sending-guide/Content/'],
      regex: /guide\/Content\//,
      replacement: 'guide-{lang}/Content/',
      languages: 'dses',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['s/articles/', 's/releasenotes'],
      regex: /language=en(_US)?/,
      replacement: 'language={lang}',
      languages: 'rsclanguage',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['s/'],
      equal: true,
      regex: /language=en(_US)?/,
      replacement: 'language={lang}',
      languages: 'rsclanguage',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['en/guides/esign-admin-release-notes'],
      regex: /\/en\/guides\/esign-admin-release-notes/,
      replacement:
        '/s/document-item?language={lang}&rsc_301&bundleId=meh1626122393871&topicId=cut1626724527550.html',
      languages: 'rsclanguage',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['s/document-item'],
      regex: /language=en_US/,
      replacement: 'language={lang}',
      languages: 'rsclanguage',
      regex2: /_LANG=enus/,
      replacement2: '_LANG={lang}',
      languages2: 'rsc_LANG',
    },
    {
      base_url: 'https://ps-ws.dsf.docusign.net/',
      url_paths: [
        'ds-server/psm/resources/psm-idnow/idnow_3XIDNDS/pdf/GTU.pdf#page=',
      ],
      regex: /page=[0-9]+/,
      replacement: 'page={lang}',
      languages: 'idnow',
    },
    {
      base_url: 'https://pref.docusign.com/',
      url_paths: ['preference-center', 'preference-center/'],
      equal: true,
      regex: /$/,
      replacement: '?params={lang}',
      languages: 'preference',
    },
    {
      base_url: 'https://pref.docusign.com/',
      url_paths: ['preference-center/'],
      regex: /\?params=[a-zA-Z0-9]+=/,
      replacement: '?params={lang}',
      languages: 'preference',
    },
  ],
  localized_license_pages: [
    {
      base_url: 'https://www.docusign.com/',
      url_paths: {
        'legal/terms-and-conditions': 'term',
        'legal/terms-and-conditions/': 'term',
        'company/terms-and-conditions/web': 'term',
        'company/terms-of-use': 'term',
        privacy: 'privacy',
        'company/privacy-policy': 'privacy',
        IP: 'ip',
        'iframe/company/terms-and-conditions/web': 'iterm',
        'iframe/company/terms-of-use': 'iterm',
        'iframe/company/privacy-policy': 'iprivacy',
        'iframe/IP': 'iip',
      },
    },
    {
      base_url: 'https://www.docusign.fr/',
      url_paths: {
        'sites/default/files/gtu_en-aes.pdf': 'ariadnext_aes',
        'sites/default/files/gtu_en-qes.pdf': 'ariadnext_qes',
        'conditions-generales-d-utilisation/GTU-ID-Check-for-AES-EN':
          'ariadnext_aes',
        'conditions-generales-d-utilisation/GTU-ID-Check-In-Person-for-QES-EN':
          'ariadnext_qes',
        'conditions-generales-d-utilisation/GTU-EU-Advanced-with-IDV-EN':
          'idv_eu_adv',
        'conditions-generales-d-utilisation/GTU-EU-Advanced-EN': 'eu_adv',
      },
    },
  ],
  countryToLanguage: {
    au: 'en_au',
    bg: 'bg',
    br: 'pt_br',
    ca: 'en_ca',
    cn: 'zh_cn',
    cz: 'cs',
    dk: 'da',
    de: 'de',
    gb: 'en_gb',
    gr: 'el',
    us: 'en',
    es: 'es',
    ee: 'et',
    ir: 'fa',
    fi: 'fi',
    fr: 'fr',
    il: 'he',
    in: 'hi',
    hr: 'hr',
    hu: 'hu',
    id: 'id',
    it: 'it',
    jp: 'ja',
    kr: 'ko',
    lt: 'lt',
    lv: 'lv',
    my: 'ms',
    mx: 'es_mx',
    no: 'nb',
    nl: 'nl',
    pl: 'pl',
    pt: 'pt',
    ro: 'ro',
    ru: 'ru',
    sk: 'sk',
    si: 'sl',
    se: 'sv',
    th: 'th',
    tr: 'tr',
    tw: 'zh_cn',
    ua: 'uk',
    vn: 'vi',
  },
  baseURL_keys: {
    ariadnext_aes: 'fr',
    ariadnext_qes: 'fr',
    idv_eu_adv: 'fr',
    eu_adv: 'fr',
    support: 'support',
    preference: 'preference',
    term: '_',
    iterm: '_',
    privacy: '_',
    iprivacy: '_',
  },
  baseURLs: {
    preference: 'https://pref.docusign.com/',
    idnow: 'https://ps-ws.dsf.docusign.net/',
    support: 'https://support.docusign.com/',
    de: 'https://www.docusign.de/',
    es: 'https://www.docusign.com.es/',
    es_mx: 'https://www.docusign.mx/',
    fr: 'https://www.docusign.fr/',
    fr_ca: 'https://www.docusign.fr/',
    ja: 'https://www.docusign.jp/',
    nl: 'https://www.docusign.nl/',
    pt_br: 'https://www.docusign.com.br/',
    pt: 'https://www.docusign.com.br/',
    en_au: 'https://www.docusign.com.au/',
    en_gb: 'https://www.docusign.co.uk/',
    en_ca: 'https://www.docusign.ca/',
    _: 'https://www.docusign.com/',
  },
  supportToHelpCenterUrls: {
    // NDSE
    'ndse-user-guide-view-document-details':
      'hfx1578456670511/page/otr1578456349846.html',
    'ndse-user-guide-using-bulk-send':
      'hfx1578456670511/page/nlq1578456393591.html',
    'ndse-user-guide-change-your-password':
      'hfx1578456670511/page/tfs1578456454393.html',
    'ndse-user-guide-create-a-bulk-send-recipient-list':
      'hfx1578456670511/page/byv1578456392556.html',
    'ndse-user-guide-manage-automatic-template-matching':
      'hfx1578456670511/page/fxo1578456612662.html',
    'ndse-user-guide-manage-connected-apps':
      'hfx1578456670511/page/qsm1578456669396.html',
    'ndse-user-guide-manage-contacts':
      'hfx1578456670511/page/twh1578456324503.html',
    'ndse-user-guide-manage-custom-fields':
      'hfx1578456670511/page/mns1578456285568.html',
    'ndse-user-guide-manage-notifications':
      'hfx1578456670511/page/dhc1578456326388.html',
    'ndse-user-guide-manage-your-identity':
      'hfx1578456670511/page/mss1578456650494.html',
    'ndse-user-guide-manage-your-stamps':
      'hfx1578456670511/page/dys1578456666697.html',
    'ndse-user-guide-managing-documents':
      'hfx1578456670511/page/ghu1578456429097.html',
    'ndse-user-guide-multiple-recipient-bulk-send':
      'hfx1578456670511/page/kjz1578456386508.html',
    'ndse-user-guide-send-a-document':
      'hfx1578456670511/page/lak1578456412477.html',
    'ndse-user-guide-system-requirements':
      'hfx1578456670511/page/oiu1578456458990.html',
    'ndse-user-guide-using-reports':
      'hfx1578456670511/page/wou1578456440763.html',
    'ndse-user-guide-view-and-modify-reports':
      'hfx1578456670511/page/ekf1578456440590.html',
    'ndse-user-guide-switch-accounts':
      'hfx1578456670511/page/gmc1578456462118.html',
    'ndse-user-guide-drawing-fields':
      'hfx1578456670511/page/ilm1598911310881.html',
    'ndse-user-guide-working-with-templates':
      'hfx1578456670511/page/dqj1578456412286.html',
    releasenotes:
      '?labelkey=eSignature&name_filter.field=title&name_filter.value=Release+Notes&rpp=10&sort.field=last_revised&sort.value=dec',
  },
  helpCenterLanguages: {
    es: 'es-XM',
    nl: 'nl-NL',
    it: 'it-IT',
    ko: 'ko-KR',
  },
  radminNewDocUrl:
    'https://www.docusign.com/support/new/documentation/ndse-admin-guide/',
  radminSupportDocUrl: 'https://support.docusign.com/guides/ndse-admin-guide-',
}
