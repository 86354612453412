import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useMemo,
} from 'react'
import { useEscapeListener } from '@ds/react-utils'

/*
    This component is meant to be used with popups, such as dialogs, tooltips, or floating menus. When one
    popup is opened inside of another we only want the Escape key to close only the one on top. Then, when
    Escape is pressed again the other popup should close.

    EscapeToClose is both a context provider and a context consumer. It uses context to keep a count of how
    many other instances of itself are rendered lower in the tree. When the count is 0 it responds to the
    Escape key by calling the supplied onClose handler.
*/

type ContextValue = {
  register: () => void
  unregister: () => void
}

const Context = createContext<ContextValue>({
  register: () => undefined,
  unregister: () => undefined,
})

export type EscapeToCloseProps = {
  /** Will not call onClose if false. Will not prevent ancestors from calling onClose if false. */
  enabled?: boolean
  /** A handler to be called in response to the Escape key. If no handler is passed the component will not be enabled. */
  onClose?: (event: Event) => void
  children?: React.ReactNode
}

/**
 * Listens for Escape keydown events on the document and calls the supplied `onClose` handler, but only if
 * there are no other instances of EscapeToClose rendered lower in the React tree.
 */
export function EscapeToClose({
  enabled = true,
  onClose,
  children,
}: EscapeToCloseProps) {
  const context = useContext(Context)

  // If onClose isn't provided the component will not prevent ancestors from handling Escape.
  const reallyIsEnabled = enabled && onClose !== undefined

  useEffect(() => {
    if (reallyIsEnabled) {
      context.register()
      return () => {
        /*
          This needs to run asyncronously to make sure all Escape handlers are called before any unregistrations
          take place. Otherwise we can be in a situation where a child popup closes and unregisters, then a
          parent believes it doesn't have any descendants and closes itself too.
        */
        setTimeout(() => {
          context.unregister()
        })
      }
    }
    return undefined
  }, [reallyIsEnabled, context])

  const descendantCountRef = useRef(0)

  useEscapeListener((event) => {
    if (descendantCountRef.current === 0 && reallyIsEnabled) {
      onClose(event)
    }
  })

  const contextValue = useMemo(
    () => ({
      register() {
        descendantCountRef.current += 1
      },
      unregister() {
        descendantCountRef.current -= 1
      },
    }),
    [],
  )

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}
