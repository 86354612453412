const Sizes = {
  ExtraSmall: 544,
  Small: 768,
  Medium: 980,
  Large: 1200,
  ExtraLarge: 1880,
} as const

/**
 * @param max - The maximum screen width to be represented in pixels (exclusive).
 *
 * @returns A CSS media query containing max-width.
 */
const lessThan = (max: number): string => {
  const upperBound = max - 1
  return `@media screen and (max-width: ${upperBound}px)`
}

/**
 * @param min - The minimum screen width to be represented in pixels (inclusive).
 *
 * @returns A CSS media query containing min-width.
 */
const greaterThan = (min: number): string =>
  `@media screen and (min-width: ${min}px)`

/**
 * @param min The minimum screen width to be represented in pixels (inclusive).
 * @param max The maximum screen width to be represented in pixels (exclusive).
 *
 * @returns A CSS media query containing min-width and max-width.
 */
const inBetween = (min: number, max: number): string => {
  const upperBound = max - 1
  return `@media screen and (min-width: ${min}px) and (max-width: ${upperBound}px)`
}

export const breakpoints = {
  Sizes,
  lessThan,
  greaterThan,
  inBetween,
}
