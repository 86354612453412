import type { HeaderContextValue } from '../../HeaderContext'
import type { Tokens } from '../../../../theming/types'
import type { HeaderTitleProps } from '..'

export default (config: {
  tokens: Tokens
  props: Partial<HeaderTitleProps> & HeaderContextValue
}) => {
  const { tokens, props } = config

  function getBorderColor() {
    return props.dark
      ? tokens.borderColorSubtleInverse
      : tokens.borderColorSubtle
  }

  return {
    borderColor: props.precededByHeaderAction ? getBorderColor() : undefined,
    color: props.dark ? tokens.fontColorInverse : tokens.fontColorSubtle,
    paddingInlineEnd: '15px',
  } as const
}
