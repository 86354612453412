import { CreateFocusCss } from '../../../../styles'
import type { HeaderContextValue } from '../../HeaderContext'
import type { Tokens } from '../../../../theming/types'

export default (config: { tokens: Tokens; props: HeaderContextValue }) => {
  const { tokens, props } = config
  return {
    wrap: {
      height: '64px',
    },

    button: {
      '&:hover, &:active, &[aria-expanded="true"]': {
        backgroundColor: props.dark
          ? tokens.dark.ctaBgColorSecondaryHover
          : tokens.ctaBgColorSecondaryHover,
      },

      ...CreateFocusCss({
        outlineColor: props.dark ? tokens.dark.focusColor : tokens.focusColor,
      }),
    },
  } as const
}
