import { oliveMessageTokens } from '@ds/tokens'
import type { BannerKind } from '../../../../components/Banner'
import colors from '../colors'

const warningTokens = {
  backgroundColor: colors.sunglowYellow,
  textColor: colors.gray10,
  focusColor: colors.funBlue,
}

export interface BannerTokensOlive {
  backgroundColor: string
  borderColor?: string
  textColor?: string
  iconColor?: string
  focusColor?: string
}

const tokens: Record<BannerKind, BannerTokensOlive> = {
  danger: {
    backgroundColor: colors.persianRed,
    focusColor: colors.white,
  },
  warning: warningTokens,
  success: {
    backgroundColor: colors.mantisGreen,
    focusColor: colors.white,
  },
  information: {
    backgroundColor: oliveMessageTokens.messageBgColorEmphasis,
    textColor: colors.black,
    iconColor: colors.funBlue,
  },
  neutral: {
    backgroundColor: colors.gray9,
    focusColor: colors.white,
  },
  promo: {
    backgroundColor: colors.DocuSignBlueDark2,
    textColor: colors.sunglowYellow,
    focusColor: colors.white,
  },
  edit: {
    backgroundColor: colors.DocuSignBlueDark2,
    focusColor: colors.white,
  },
  view: {
    backgroundColor: colors.DocuSignBlueDark2,
    focusColor: colors.white,
  },
  subtle: warningTokens,
}

export default tokens
