export default () => {
  return {
    default: {
      alignContent: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
  } as const
}
