import type { PopoverAlignment, PopoverLocation, PopoverPosition } from '..'

/**
 * Popover placements
 */
type PopoverBasePlacement = 'top' | 'bottom' | 'right' | 'left'
type PopoverVariationPlacement =
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'
export type PopoverPlacement = PopoverBasePlacement | PopoverVariationPlacement

/**
 * Supported Popover locations
 */
export const locations = ['above', 'below', 'before', 'after'] as const

export const locationLookup: { [key: string]: PopoverLocation } = {
  top: 'above',
  bottom: 'below',
  left: 'before',
  right: 'after',
}

/**
 * Supported Popover alignments
 */
export const alignments = ['start', 'center', 'end'] as const

/**
 * Converts a Popover placement to `location` and `alignment`
 */
export const parsePlacement = (
  placement: PopoverPlacement,
): [PopoverLocation, PopoverAlignment] => {
  const [popoverLocation, popoverAlignment] = placement.split('-')

  const location = locationLookup[popoverLocation]

  const alignment: PopoverAlignment =
    popoverAlignment === undefined
      ? 'center'
      : (popoverAlignment as 'start' | 'end')

  return [location, alignment]
}

/**
 * Converts a `location` & `alignment` to a Popover placement
 */
export const formatPlacement = ({
  alignment,
  location,
}: PopoverPosition): PopoverPlacement => {
  const placementLocation = {
    above: 'top',
    below: 'bottom',
    before: 'left',
    after: 'right',
  }[location] as PopoverBasePlacement

  const placementAlignment = {
    start: '-start',
    center: '',
    end: '-end',
  }[alignment]

  return `${placementLocation}${placementAlignment}` as PopoverPlacement
}

export const swapLocation = (location: PopoverLocation) => {
  if (location === 'before') return 'after'
  if (location === 'after') return 'before'
  return location
}

export const swapAlignment = (alignment: PopoverAlignment) => {
  if (alignment === 'start') return 'end'
  if (alignment === 'end') return 'start'
  return alignment
}
