import { useState, useEffect, useRef } from 'react'
import { TranslationsWarehouse, Locale } from '@ds/base'

/*
    This react hook is used to force re-render when a locale is
    loaded by a TranslationsWarehouse.
*/

export function useLocaleLoaded<TextKey extends string = string>(
  translationsWarehouse: TranslationsWarehouse<TextKey>
) {
  const isMountedRef = useRef(false)
  const [, setLastLoadedLocale] = useState<Locale>()

  // listen for locale loads and refresh if updated
  useEffect(() => {
    isMountedRef.current = true
    function handleLocaleLoaded(locale: Locale) {
      if (isMountedRef.current) {
        setLastLoadedLocale(locale)
      }
    }
    translationsWarehouse.addLoadListener(handleLocaleLoaded)
    return () => {
      isMountedRef.current = false
      translationsWarehouse.removeLoadListener(handleLocaleLoaded)
    }
  }, [translationsWarehouse])
}
