import React from 'react'
import PropTypes from 'prop-types'
import { ClassNames } from '@emotion/react'
import {
  DocusignAppDsLogoDefault,
  DocusignAppDsLogoInverse,
  DocusignAppLogo,
} from '@olive/svg'
import { dataProps } from '@ds/react-utils'
import colors from '../../theming/docusign-themes/olive/colors'
import styles from './styles'

export const docusignLogoColors = ['black', 'white'] as const
export type DocuSignLogoColor = 'black' | 'white'

const rebrandLogos = {
  black: DocusignAppDsLogoDefault,
  white: DocusignAppDsLogoInverse,
}

const logoColor = {
  black: colors.black,
  white: colors.white,
}

export interface DocuSignLogoProps {
  /**
   * The fill color to apply to the logo.
   */
  color?: DocuSignLogoColor
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * Use the logo introduced in the 2024 rebrand
   */
  rebrand2024?: boolean
}

export function DocuSignLogo({
  color = 'black',
  rebrand2024 = false,
  ...restProps
}: DocuSignLogoProps) {
  /* @ts-expect-error - legacy API */
  const fill = restProps.hexColor || logoColor[color]

  const Logo = rebrand2024 ? rebrandLogos[color] : DocusignAppLogo

  return (
    <span {...dataProps(restProps)}>
      <ClassNames>
        {({ css }) => (
          <Logo
            aria-label="Docusign"
            className={css(styles.DocuSignLogo)}
            fill={rebrand2024 ? null : fill}
            height="100%"
            role="img"
            width="100%"
          />
        )}
      </ClassNames>
    </span>
  )
}

DocuSignLogo.colors = docusignLogoColors

DocuSignLogo.propTypes = {
  color: PropTypes.oneOf(DocuSignLogo.colors),
  'data-.*': PropTypes.string,
  rebrand2024: PropTypes.bool,
}

DocuSignLogo.displayName = 'DocuSignLogo'
