export default `
  html {
    box-sizing: border-box;
    text-size-adjust: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    margin: 0;
    text-rendering: optimizeLegibility;
  }

  *,
  ::after,
  ::before {
    box-sizing: inherit;
  }

  fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0.01em 0 0 0;
  }

  legend {
    display: table;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
`
