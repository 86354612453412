import { useThemeContext } from '@ds/theme-context'
import { consoleWarn } from '../../logging'
import { OliveDocuSignTheme } from '../docusign-themes'

export function useTheme() {
  const { fallbackTheme, ...theme } = useThemeContext()

  if (fallbackTheme) {
    consoleWarn(
      `WARN @ds/ui: @ds/ui components should be wrapped inside of a <Theme> component. All styles will fall back to the "Olive" theme but will be removed in a future version.`,
    )
    return OliveDocuSignTheme
  }

  return theme
}
