const ARROW_HEIGHT = '12px'
const ARROW_POSITION = '-12px'

export default () => {
  return {
    arrow: {
      zIndex: 1,
      position: 'absolute',
      height: ARROW_HEIGHT,
      pointerEvents: 'none',
    },

    popoverTop: {
      bottom: ARROW_POSITION,
    },

    popoverBottom: {
      top: ARROW_POSITION,
      transform: 'rotate(180deg)',
    },

    popoverLeft: {
      right: ARROW_POSITION,
      transform: 'rotate(270deg)',
    },

    popoverRight: {
      left: ARROW_POSITION,
      transform: 'rotate(90deg)',
    },
  } as const
}
