import { breakpoints } from '../../../../utilities'

export default () =>
  ({
    default: {
      alignItems: 'center',
      display: 'flex',
      height: 'inherit',
      flex: '1 1 25%',
      minWidth: '196px',

      [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
        flexBasis: 'auto',
      },
    },
  }) as const
