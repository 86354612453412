import { keyframes } from '@emotion/react'
import type { Tokens } from '../../../theming/types'
import type { ProgressCircleProps } from '..'

const CIRCUMFERENCE_SMALL = '37.69911184307752'
export const CIRCUMFERENCE_MEDIUM = '106.81415022205297'
const CIRCUMFERENCE_LARGE = '226.1946710584651'

const expandCollapseSmall = keyframes`
  0% {
    stroke-dasharray: 3px, 38px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 29px, 38px;
    stroke-dashoffset: -5px;
  }
  100% {
    stroke-dasharray: 29px, 38px;
    stroke-dashoffset: -35px;
  }
`

const expandCollapseMedium = keyframes`
  0% {
    stroke-dasharray: 9px, 106px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 80px, 106px;
    stroke-dashoffset: -13px;
  }
  100% {
    stroke-dasharray: 80px, 106px;
    stroke-dashoffset: -97px;
  }
`

const expandCollapseLarge = keyframes`
  0% {
    stroke-dasharray: 19px, 226px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 170px, 226px;
    stroke-dashoffset: -28px;
  }
  100% {
    stroke-dasharray: 170px, 226px;
    stroke-dashoffset: -207px;
  }
`

export default (config: {
  props: Partial<ProgressCircleProps>
  tokens: Tokens
}) => {
  const { props, tokens } = config
  return {
    default: {
      circleFill: {
        stroke: props.dark
          ? tokens.dark.barFillColorDefault
          : tokens.barFillColorDefault,
      },

      circleTrack: {
        stroke: props.dark
          ? tokens.dark.barTrackColorDefault
          : tokens.barTrackColorDefault,
      },
    },

    subtle: {
      circleFill: {
        stroke: props.dark
          ? tokens.dark.barFillColorSubtle
          : tokens.barFillColorSubtle,
      },
    },

    small: {
      height: '16px',
      centerPointX: 8,
      centerPointY: 8,
      radius: 6,
      fillStrokeWidth: 4,
      trackStrokeWidth: 1.5,
      width: '16px',
      strokeDasharray: CIRCUMFERENCE_SMALL,
    },

    medium: {
      height: '40px',
      centerPointX: 20,
      centerPointY: 20,
      radius: 17,
      fillStrokeWidth: 6,
      trackStrokeWidth: 2,
      width: '40px',
      strokeDasharray: CIRCUMFERENCE_MEDIUM,
    },

    large: {
      height: '80px',
      centerPointX: 40,
      centerPointY: 40,
      radius: 36,
      fillStrokeWidth: 8,
      trackStrokeWidth: 2,
      width: '80px',
      strokeDasharray: CIRCUMFERENCE_LARGE,
    },

    // Will be removed in FRNTEND-3500
    indeterminate: {
      small: {
        circleFill: {
          animation: `${expandCollapseSmall} 1.4s ease-in-out infinite`,
          strokeDasharray: '43px, 57px',
          strokeDashoffset: '0px',
        },
      },

      medium: {
        circleFill: {
          animation: `${expandCollapseMedium} 1.4s ease-in-out infinite`,
          strokeDasharray: '90px, 120px',
          strokeDashoffset: '0px',
        },
      },

      large: {
        circleFill: {
          animation: `${expandCollapseLarge} 1.4s ease-in-out infinite`,
          strokeDasharray: '180px, 239px',
          strokeDashoffset: '0px',
        },
      },
    },
  } as const
}
