import * as React from 'react'

export const ariaDisclosure = {
  MenuItemRole: undefined,
  MenuGroupRole: undefined,
  MenuRootRole: undefined,
}

export const ariaMenu = {
  MenuItemRole: 'menuitem',
  MenuGroupRole: 'group',
  MenuRootRole: 'menu',
}

export type MenuContextValue = {
  MenuItemRole?: string
  MenuGroupRole?: string
  MenuRootRole?: string
}

export interface MenuContextProviderProps {
  /**
   * The aria pattern determines which roles are assigned to
   * the underlying elements.
   *
   * When passed 'menu' roles will follow an aria menu pattern.
   * This pattern is only appropriate when creating an application
   * that behaves like a native desktop application e.g. Google Docs
   *
   * When passed 'disclosure' the underlying Menu components will
   * not receive a role.  For most web application 'menus' this is
   * the most appropriate choice.
   */
  ariaPattern: 'menu' | 'disclosure'
  /**
   * The content of your Menu
   */
  children: React.ReactNode
}

export const MenuContext = React.createContext<MenuContextValue>(ariaMenu)

export const MenuContextProvider = ({
  ariaPattern = 'menu',
  children,
}: MenuContextProviderProps) => {
  return (
    <MenuContext.Provider
      value={ariaPattern === 'menu' ? ariaMenu : ariaDisclosure}
    >
      {children}
    </MenuContext.Provider>
  )
}
